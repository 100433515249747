import { Messenger } from '../../api'
import { UserVoipsMenu } from './UserVoipsMenu'
import { rem } from '../../helpers/style'
import { ChatBubbleRounded, VideocamRounded } from '@mui/icons-material'
import { sendVideoRoomRequest } from '../../store/actions'
import { getConversationsArray, getMe, getTranslation } from '../../store/selectors'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Statuses, User } from '../../api/rest'
import { ActionButton } from './ActionButton'
import { MenuButton } from './MenuButton'

interface UserButtonProps {
  from: string
  user?: User
  buttons?: {
    phone: boolean
    chat: boolean
    video: boolean
  }
  beforeClick?: Function
}

//TODO: testare il caso in cui un utente abbia più client html
export const UserButton: React.FC<UserButtonProps> = (props) => {
  const {
    from,
    user,
    buttons = {
      phone: true,
      chat: true,
      video: true
    }
  } = props

  const [chatInfo, setChatInfo] = useState<{ path: string; state: { otherMember: User } | undefined }>({
    path: '/messenger',
    state: undefined
  })

  const me = useSelector(getMe)
  const conversations = useSelector(getConversationsArray)
  const translation = useSelector(getTranslation)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!user) return
    const conv = Messenger.getConversationByMembers(conversations, [
      Messenger.getMemberFromUser(me),
      Messenger.getMemberFromUser(user)
    ])
    if (conv) {
      setChatInfo({ path: `/messenger/${conv.id}`, state: undefined })
    } else {
      setChatInfo({ path: '/messenger/new', state: { otherMember: user } })
    }
  }, [user, conversations, me])

  const videoEnabled: boolean = useMemo(() => {
    if (!user || !user.userVoips) return false
    const htmlClients = user.userVoips.filter((u) => {
      if (typeof u.type === 'number') return u.type === 1
      if ('id' in u.type) return u.type.id === 1
      return false
    })
    if (htmlClients.length === 0) return false
    // const onlineHtmlClient = htmlClients.filter((h) => h.voip.state?.id === Statuses.idle)
    // return onlineHtmlClient.length > 0
    return user.state?.id === Statuses.idle
  }, [user])

  const videoCall = async () => {
    if (!user) return
    props.beforeClick && props.beforeClick()
    dispatch(sendVideoRoomRequest([Messenger.getMemberFromUser(user)]) as any)
  }

  return (
    <Container>
      <ButtonContainer colNum={Object.values(buttons).reduce((a, c) => (c ? a + 1 : a), 0)}>
        {buttons.phone && user && <UserVoipsMenu user={user} beforeClick={props.beforeClick} />}
        {buttons.chat && (
          <MenuButton
            tooltip={translation?.tooltips?.chat}
            disabled={!user}
            path={chatInfo.path}
            state={chatInfo.state}
            from={from}
            size="small"
            beforeClick={props.beforeClick}
          >
            <ChatBubbleRounded style={{ fontSize: rem(0.8) }} />
          </MenuButton>
        )}
        {buttons.video && (
          <ActionButton
            tooltip={translation?.tooltips?.videocall}
            size="small"
            disabled={!videoEnabled}
            onClick={() => videoCall()}
          >
            <VideocamRounded />
          </ActionButton>
        )}
      </ButtonContainer>
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  place-content: center;
  width: 100%;
`

const ButtonContainer = styled.div<{ colNum: number }>`
  grid-area: button;
  display: grid;
  grid-template-columns: repeat(${({ colNum }) => colNum}, 1fr);
  height: 100%;
  width: 100%;
  grid-column-gap: ${rem(0.3)};
`
