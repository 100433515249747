import React from 'react'
import styled from 'styled-components'
import { Contact, User } from '../../../../api/rest'
import { Address, ContactButton, UserButton } from '../../../common'

interface AddressBookEntryProps {
  contacts?: Contact[]
  users?: User[]
  from?: string
}

const AddressBookEntry: React.FC<AddressBookEntryProps> = (props) => {
  const { contacts, users, from = '/phone/address-book' } = props

  return (
    <Container>
      {contacts &&
        contacts.map((c, index) => (
          <Address type={'contact'} contact={c} key={`ext${index}${c.secondName}${c.firstName}-${index}`}>
            {c.addresses && c.addresses.length > 0 && (
              <ContactButton key={`${c.firstName}${c.addresses[0].value}`} contact={c} from={from} />
            )}
          </Address>
        ))}
      {users &&
        users.map((u, index) => (
          <Address type={'user'} user={u} key={`${u.username}-${index}`}>
            {u?.userVoips && u.userVoips.filter((uv) => !uv.deleted).length > 0 && (
              <UserButton key={`${u.id}${u.userVoips[0].voip.extension}-${index}`} from={from} user={u} />
            )}
          </Address>
        ))}
    </Container>
  )
}

export default AddressBookEntry

const Container = styled.div`
  display: grid;
  user-select: text;
`
