import {Chat, ChatEvent, Guest} from '../../../rest'
import {
  bookChat,
  closeMediaChat,
  eventReceived,
  proactiveGuestsAdd,
  proactiveGuestsList,
  proactiveGuestsRemove,
  removeChat,
  sneakpeekReceived,
  transferRequestReceived,
  transferToOperatorAccepted,
  transferToOperatorRejected,
  unbookChat
} from '../../../../store/actions'
import {RootState} from '../../../../store/types'
import {ThunkDispatch} from 'redux-thunk'
import {Socket} from 'socket.io-client'
import * as evs from '../eventsTypes'

export const handleChatEvents = (socket: Socket, dispatch: ThunkDispatch<RootState, any, any>) => {
  // arriva alla prima connessione la lista di utenti con widget in modalità proactive
  socket.on(evs.proactive_guests_list, (guests: Guest[]) => {
    const guestsMap = new Map(guests.map((g) => [g.id, g]))
    dispatch(proactiveGuestsList(guestsMap))
  })
  socket.on(evs.proactive_guests_add, (guest: Guest) => {
    dispatch(proactiveGuestsAdd(guest))
  })
  socket.on(evs.proactive_guests_remove, (guest: Guest) => {
    dispatch(proactiveGuestsRemove(guest))
  })
  // arriva la richiesta per accettare una chat in coda
  socket.on(evs.booking, (chat: Chat) => {
    window.occlient.emit('chat:booking', chat)
    dispatch(bookChat(chat))
  })
  socket.on(evs.booking_from_supervisor, (chat: Chat) => {
    window.occlient.emit('chat:booking', chat)
    dispatch(bookChat(chat))
  })
  // viene ritirata la possibilità di accettare la chat in coda
  socket.on(evs.booking_cancelled, (chatId: string) => {
    window.occlient.emit('chat:booking-cancelled', chatId)
    dispatch(unbookChat(chatId))
  })
  // riceve i messaggi della chat, possono essere di sistema o degli operatori o dei guest
  socket.on(evs.event, (payload: { chat: string; event: ChatEvent }) => {
    window.occlient.emit('chat:event', payload)
    dispatch(eventReceived(payload))
  })
  socket.on(evs.sneakpeek, (payload: { chat: string; text: string }) => {
    dispatch(sneakpeekReceived(payload))
  })
  socket.on(evs.transfer_request, (chat: Chat) => {
    window.occlient.emit('chat:transfer-request', chat)
    dispatch(transferRequestReceived(chat))
  })
  socket.on(evs.transfer_deleted, (payload: { chat: string }) => {
    window.occlient.emit('chat:transfer-canceled', payload.chat)
    dispatch(removeChat(payload.chat))
  })
  socket.on(evs.transfer_complete, (payload: { chat: string }) => {
    window.occlient.emit('chat:transfer-complete', payload.chat)
    dispatch(transferToOperatorAccepted(payload.chat))
  })
  socket.on(evs.transfer_rejected, (payload: { chat: string }) => {
    window.occlient.emit('chat:transfer-reject', payload.chat)
    dispatch(transferToOperatorRejected(payload.chat))
  })
  // ferma la chiamata multimediale (audio / video / condivisione schermo)
  socket.on(evs.stop_media, () => {
    dispatch(closeMediaChat())
  })
}
