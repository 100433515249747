import { Message } from '../../api/rest'
import { rem } from '../../helpers/style'
import { DoneAllRounded, DoneRounded } from '@mui/icons-material'
import { getMe, getTheme } from '../../store/selectors'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

type Props = {
  message: Message
  style?: React.CSSProperties
  colors?: {
    sent?: string
    received?: string
    read?: string
  }
  opacities?: {
    sent?: number
    received?: number
    read?: number
  }
}

export const MessageAck: React.FC<Props> = (props) => {
  const theme = useSelector(getTheme)

  const {
    message,
    style = {},
    colors = getDefaultColors(props.colors, theme),
    opacities = getDefaultOpacities(props.opacities)
  } = props
  const [type, setType] = useState<'sent' | 'received' | 'read'>('sent')
  const me = useSelector(getMe)

  useEffect(() => {
    const acksOfInterest = message.acks.filter((a) => a.username !== me.username)
    const read = acksOfInterest.every((a) => a.read)
    if (read) {
      setType('read')
      return
    }
    const received = acksOfInterest.every((a) => a.received)
    if (received) {
      setType('received')
      return
    }
    setType('sent')
  }, [message, me.username])

  const toRender = () => {
    switch (type) {
      case 'sent':
        return (
          <DoneRounded
            style={{ placeSelf: 'center', fontSize: rem(0.8), opacity: opacities.sent, ...style }}
            htmlColor={colors.sent}
          />
        )
      case 'received':
        return (
          <DoneAllRounded
            style={{ placeSelf: 'center', fontSize: rem(0.8), opacity: opacities.received, ...style }}
            htmlColor={colors.received}
          />
        )
      case 'read':
        return (
          <DoneAllRounded
            style={{ placeSelf: 'center', fontSize: rem(0.8), opacity: opacities.read, ...style }}
            htmlColor={colors.read}
          />
        )
    }
  }
  return toRender()
}

const getDefaultColors = (colors: { sent?: string; received?: string; read?: string } = {}, theme = 'light') => ({
  sent: colors.sent || theme === 'light' ? '#8a8a8a' : '#FFFFFF',
  received: colors.received || theme === 'light' ? '#8a8a8a' : '#FFFFFF',
  read: colors.read || theme === 'light' ? '#003cff' : '#FFFFFF'
})

const getDefaultOpacities = (opacities: { sent?: number; received?: number; read?: number } = {}) => ({
  sent: opacities.sent || 0.5,
  received: opacities.received || 0.5,
  read: opacities.read || 1
})
