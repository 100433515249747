import {LoginAction, LogoutAction, SetMe} from '../login/types'
import {Contact, CRM, Outbound, Pause, Queue, Status, User, VoipInfo} from '../../api/rest'

export const INIT_PHONE = 'INIT_PHONE'
export const STATUS_CHANGE = 'STATUS_CHANGE'
export const SELECT_OUTBOUND = 'SELECT_OUTBOUND'
export const SET_CALL_IN_PROGRESS = 'SET_CALL_IN_PROGRESS'
export const CALL_STATUS_CHANGE = 'CALL_STATUS_CHANGE'
export const ADD_VOIP = 'ADD_VOIP'
export const REMOVE_VOIPS = 'REMOVE_VOIPS'
export const SET_LOST_CALLS = 'SET_LOST_CALLS'
export const SET_BITRATE = 'SET_BITRATE'
export const SET_LOST_CONNECTION = 'SET_LOST_CONNECTION'
export const SET_RECONNECTING = 'SET_RECONNECTING'
export const SET_CORE_CONNECTED = 'SET_CORE_CONNECTED'

export type StatusChangePaylaod = Pause | Status

export enum Bounds {
  out = 'out',
  in = 'in'
}

export enum CallStatus {
  pending = 1, // stato iniziale in cui si valuta se far partire la chiamata
  incoming, // chiamata in arrivo
  calling, // in attesa di risposta per le outbounbd
  ongoing, // chiamata in corso
  hold, // chiamata in attesa
  muted, // chiamata mutata
  transferring // chiamata in fase di trasferimento
}

export interface HangupOptions {
  voip?: VoipInfo
}

export interface TransferOption {
  phoneNumber: string
  to?: User | Contact | Queue
  blind?: boolean
  onCancel?: Function
  type: 'add' | 'transfer'
}

export interface Call {
  outbound?: string
  id?: string | null
  number: string
  prefix: string
  startTime?: number
  type?: 'inbound' | 'outbound' | 'predictive' | 'callback'
  boundness: Bounds
  voip: VoipInfo
  status: CallStatus
  isInternal: boolean
  answered?: boolean
  jsep?: any
  user?: User
  transferringTo?: User | Contact | Queue | string
  transferringToExtension?: string
  // significa che il destinatario del trasferimento ha risposto e si può concludere con un'unione o un trasferimento
  canCloseTransfer?: boolean
  // significa che al destinatario del trasferimento ha iniziato a squillare il telefono e si può cancellare la richiesta
  canCancelTransfer?: boolean
  // raggruppa tutti i membri della conversazione oltre l'ultimo aggiunto
  others?: Array<User | Contact | Queue | string>
  info?: any
  customer?: string
  service?: string
  inbound?: string
  queue?: string
  CRM?: CRM
  autoAnswerTimeout?: number
  autoAnswerTime?: number
  spying?: boolean
  recordingFilename?: string
  duration?: number
}

export interface VocalOrder {
  callId: string
  file: string
}

export interface PhoneState {
  // voip attualmente registrati e in funzione, possono differire dai voip dell'utente (eg: non tutti registrati)
  voips: VoipInfo[]
  // rappresentazione locale della chiamata in corso se presente
  callInProgress: Call | null
  // presente se l'operatore sta registrando parte della chiamata
  vocalOrder: VocalOrder | null
  // status attuale dell'utente, somma dello stato dei suoi interni voip e aggiornato unicamente dal core tramite socket
  status: StatusChangePaylaod
  // outbound selezionata dalla select nella pagina con il tastierino
  selectedOutbound: Outbound | null
  // chiamate perse per visualizzare la notifica
  lostCalls: number
  // bitrate attuale della chiamata in corso
  bitrate: string | null
  // indica se si sta tentando di ristabilire la connessione al server
  reconnecting: boolean
  coreConnected: boolean
}

export interface StatusChangeAction {
  type: typeof STATUS_CHANGE
  payload: StatusChangePaylaod
}

export interface SelectOutboundAction {
  type: typeof SELECT_OUTBOUND
  payload: Outbound
}

export interface AddVoipAction {
  type: typeof ADD_VOIP
  payload: VoipInfo
}

export interface SetCallInProgressAction {
  type: typeof SET_CALL_IN_PROGRESS
  payload: Call | null
}

export interface CallStatusChangeAction {
  type: typeof CALL_STATUS_CHANGE
  payload: CallStatus
}

export interface SetLostCallsAction {
  type: typeof SET_LOST_CALLS
  payload: number
}

export interface SetBitrateAction {
  type: typeof SET_BITRATE
  payload: string | null
}

export interface RemoveVoips {
  type: typeof REMOVE_VOIPS
}

export interface SetReconnecting {
  type: typeof SET_RECONNECTING
  payload: boolean
}

export interface SetCoreConnected {
  type: typeof SET_CORE_CONNECTED
  payload: boolean
}

export type PhoneActionTypes =
  | StatusChangeAction
  | SelectOutboundAction
  | AddVoipAction
  | SetCallInProgressAction
  | CallStatusChangeAction
  | SetLostCallsAction
  | SetBitrateAction
  | SetMe
  | RemoveVoips
  | LoginAction
  | SetReconnecting
  | LogoutAction
  | SetCoreConnected
