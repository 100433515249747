export const rem = (amount: number, fontSize?: number) =>
  fontSize ? `${(amount * fontSize).toFixed(2)}px` : `calc(var(--font-size) * ${amount})`

// Area:
const canIncrementMore = (increment: number, count: number, width: number, height: number, margin = 10) => {
  let i = 0
  let w = 0
  let h = increment * 0.75 + margin * 2
  while (i < count) {
    if (w + increment > width) {
      w = 0
      h = h + increment * 0.75 + margin * 2
    }
    w = w + increment + margin * 2
    i++
  }
  if (h > height) return false
  else return increment
}

// Dish:
export const getMaxVideoWidthAndMargin = (scenary: HTMLElement, participants: number) => {
  // variables:
  let margin = 2
  let width = scenary.offsetWidth - margin * 2
  let height = scenary.offsetHeight - margin * 2
  let max = 0

  // loop (i recommend you optimize this) but how
  let i = 1
  while (i < 5000) {
    let w = canIncrementMore(i, participants, width, height, margin)
    if (w === false) {
      max = i - 1
      break
    }
    i++
  }

  // set styles
  max = max - margin * 2
  return { width: max, margin: margin }
}
