import { getMaxVideoWidthAndMargin } from '../../../../helpers/style'
import {
  getMe,
  getMessengerInvites,
  getMessengerLocalStream,
  getMessengerRemoteStreams,
  getMessengerVideoRoom,
  getUsers
} from '../../../../store/selectors'
import elementResizeEvent, { unbind } from 'element-resize-event'
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { keyframes } from 'styled-components'
import { ReactComponent as Loader } from '../../../../images/ellipsis-loading.svg'
import { changeRoute } from '../../../../store/actions'
import { Avatar } from '../../../common'
import { Video } from '../../../common/Video'
import VideoCallSettings from './VideoCallSettings'
import VideoCallTopBar from './VideoCallTopBar'

type Props = {
  hide: boolean
  showChats?: boolean
}

const VideoCall: React.FC<Props> = ({ hide, showChats }) => {
  const videosContainerRef = useRef<HTMLDivElement>(null)

  const [videoWidth, setVideoWidth] = useState(0)
  const [videoMargin, setVideoMargin] = useState(0)
  const [showSettings, setShowSettings] = useState(false)

  const videoroom = useSelector(getMessengerVideoRoom)
  const localStream = useSelector(getMessengerLocalStream)
  const remoteStreams = useSelector(getMessengerRemoteStreams)
  const invites = useSelector(getMessengerInvites)
  const users = useSelector(getUsers)
  const me = useSelector(getMe)

  const dispatch = useDispatch()

  /** metodo che imposta width ed height che deve avere ogni video per essere renderizzato coerentemente
   * all'attuale dimensione del client */
  const setDimensions = useCallback(
    (videosContainer: HTMLDivElement) => {
      const remotes = videoroom && remoteStreams ? remoteStreams.size : 0
      const local = localStream ? 1 : 0
      const pendings = videoroom && invites ? invites.filter((i) => i.pending).length : 0
      const participants = remotes + local + pendings
      const { width, margin } = getMaxVideoWidthAndMargin(videosContainer, participants)
      setVideoWidth(width)
      setVideoMargin(margin)
    },
    [videoroom, remoteStreams, localStream, invites]
  )

  /** se sono su questa pagina ma non c'è una videoroom, deve esserci un errore, torno a messenger */
  useEffect(() => {
    if (!videoroom) {
      dispatch(changeRoute({ current: '/messenger' }))
    }
  }, [videoroom, dispatch])

  /** quando il dom è pronto, viene impostato il listener sulla dimensione del div contenitore dei video
   * e viene ricalcolata la giusta dimensione dei video ad ogni ridimensionamento */
  useLayoutEffect(() => {
    if (!videosContainerRef.current || !videoroom) return
    const videosContainer = videosContainerRef.current
    elementResizeEvent(videosContainerRef.current, () => {
      setDimensions(videosContainer)
    })
    setDimensions(videosContainer)
    return () => {
      if (videosContainer) unbind(videosContainer)
    }
  }, [localStream, remoteStreams, showChats, invites, videoroom, setDimensions])

  const getUserFullname = (id: number) => {
    const user = users.get(id)
    if (!user || !user.name || !user.surname) return ''
    return `${user.name} ${user.surname}`
  }

  return (
    <Container hide={hide} showChats={!!showChats}>
      <VideoCallTopBar />
      <Videos
        ref={videosContainerRef}
        participants={videoroom && videoroom.remoteStreams ? videoroom.remoteStreams.size + 1 : 1}
        onMouseEnter={() => setShowSettings(true)}
        onMouseLeave={() => setShowSettings(false)}>
        {localStream && (
          <VideoContainer>
            <Video
              stream={localStream}
              width={`${videoWidth}px`}
              style={{ margin: videoMargin }}
              withoutSettings
              autoPlay
              muted
              noVideoComponent={() => <Avatar fullname={`${me.name} ${me.surname}`} />}
            />
          </VideoContainer>
        )}
        {remoteStreams &&
          [...remoteStreams?.entries()].map(([key, s]) => (
            <VideoContainer key={key}>
              <Video
                stream={s}
                width={`${videoWidth}px`}
                style={{ margin: videoMargin }}
                autoPlay
                noVideoComponent={() => <Avatar fullname={getUserFullname(key)} />}
              />
            </VideoContainer>
          ))}
        {invites &&
          invites
            .filter((i) => i.pending)
            .map((i) => (
              <VideoContainer>
                <Pending style={{ width: videoWidth, aspectRatio: '4 / 3' }}>
                  <Avatar fullname={i.fullname} style={{ placeSelf: 'center' }} />
                  <div>{i.fullname}</div>
                  <Loader style={{ placeSelf: 'start center' }} />
                </Pending>
              </VideoContainer>
            ))}
        <VideoCallSettings show={showSettings} modalContainer={videosContainerRef.current} />
      </Videos>
    </Container>
  )
}

export default VideoCall

//region Style

const Container = styled.div<{ hide: boolean; showChats: boolean }>`
  display: ${({ hide }) => (hide ? 'none' : 'grid')};
  grid-template-rows: 55px auto;
  grid-template-areas: 'topbar' 'videos';
  width: ${({ showChats }) => (showChats ? 'calc(var(--width) - 300px)' : 'var(--width)')};
  height: 100%;
  position: relative;
`

const Videos = styled.div<{ participants: number }>`
  display: flex;
  grid-area: videos;
  align-content: center;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  position: absolute;
  left: 0;
  bottom: 0;
  width: calc(100% - var(--navbar-width));
  top: 0;
  right: 0;
`

const show = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.4) translateY(20px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
`
const VideoContainer = styled.div`
  position: relative;
  vertical-align: middle;
  align-self: center;
  overflow: hidden;
  display: inline-block;
  animation: ${show} 0.4s ease;
`

const Pending = styled.div`
  background-color: ${({ theme }) => theme.palette.background.default};
  place-content: center;
  border-radius: 10px;
  display: grid;
  grid-template-rows: 3fr 20px 4fr;
  grid-template-columns: 1fr;
  text-align: center;

  & svg {
    width: 80px;
    height: auto;
  }
`

//endregion
