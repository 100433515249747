import {CustomerChat, ElectronProxy, JanusPool, Messenger, Phone} from '../../api'
import {coreLogin, getAuthToken, getNewToken, getPayloadByLoginMethod} from '../../api/authentication'
import log from '../../helpers/logger'
import logger from '../../helpers/logger'
import {changeRoute} from '../applicationState/actions'
import history from '../../constants/history'
import {getLogin, getOtpRequired, getPath, getRefreshTimeout} from '../selectors'
import * as t from './types'
import {LoginPayload, SetRenewTimeout} from './types'
import {getTranslation} from '../../helpers/getTranslation'
import {setTranslation} from '../language/actions'
import {setTheme} from '../theme/actions'
import {Dispatch} from 'redux'
import {ThunkDispatch} from 'redux-thunk'
import {RootState} from '../types'
import {notifyDisconnection} from '../phone/actions'
import {wsConnect, wsDisconnect} from '../sockets/actions'

const updateThemeAndLanguage = (loginPayload: LoginPayload, dispatch: Dispatch<any>) => {
  const languageCode = loginPayload.user.language?.code || 'it_IT'
  getTranslation(languageCode).then((data) => {
    dispatch(setTranslation(languageCode, { ...data }))
  })
  dispatch(setTheme(loginPayload.user.theme || sessionStorage.getItem('theme') || 'light'))
}

export const login = (payload: t.LoginPayload, firstAuth = true) => (dispatch: ThunkDispatch<any, any, any>) =>  {
  sessionStorage.setItem('@occlient/token', payload.token)
  sessionStorage.setItem('@occlient/user', JSON.stringify(payload.user))
  sessionStorage.removeItem('@occlient/selectedLoginMethod')
  Messenger.token = payload.token
  ElectronProxy.token = payload.token
  Phone.token = payload.token
  window.occlient.setLanguage(payload.language?.code || 'it_IT')
  log.debug('Connecting sockets after login')
  dispatch(wsConnect({ name: 'messenger' }))
  dispatch(wsConnect({ name: 'core' }))
  dispatch(handleRenewToken(payload.token))
  firstAuth && window.occlient.emit('auth-login-success', payload.user)
  dispatch({ type: t.LOGIN, payload })
}

export const tokenRenewed = (payload: t.LoginPayload): t.LoginActionTypes => {
  sessionStorage.setItem('@occlient/token', payload.token)
  Messenger.token = payload.token
  ElectronProxy.token = payload.token
  Phone.token = payload.token
  return { type: t.TOKEN_RENEWED, payload }
}

export const handleLogout =  (forced: boolean = false) => (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) =>{
  const timeout = getRefreshTimeout(getState())
  timeout && clearTimeout(timeout)
  forced && dispatch(notifyDisconnection())
  sessionStorage.removeItem('@occlient/token')
  sessionStorage.removeItem('@occlient/user')
  sessionStorage.removeItem('@occlient/currentPath')
  sessionStorage.removeItem('@occlient/previousPath')
  JanusPool.destroyAll(false).catch((e) => logger.error(e))
  Messenger.token = null
  ElectronProxy.token = null
  Phone.token = null
  Messenger.handleLogout().catch(() => {})
  CustomerChat.handleLogout().catch(() => {})
  log.debug('Disconnecting sockets due to logout')
  dispatch(wsDisconnect({ name: 'core', logout: true }))
  dispatch(wsDisconnect({ name: 'messenger', logout: true }))
  // Se il logout è richiesto esplicitamente dall'utente, emette l'evento
  !forced && window.occlient.emit('auth-logout-success')

  dispatch({type: t.LOGOUT})
}

export const setLoginError = (payload: string): t.LoginActionTypes => ({
  type: t.LOGIN_ERROR,
  payload
})

export const setLoginMethods = (payload: { methods: t.LoginMethod[]; otp: boolean }): t.LoginActionTypes => ({
  type: t.SET_LOGIN_METHODS,
  payload
})

export const selectLoginMethod = (payload: t.LoginMethod | null): t.LoginActionTypes => {
  if (payload) sessionStorage.setItem('@occlient/selectedLoginMethod', JSON.stringify(payload))
  else {
    sessionStorage.removeItem('@occlient/selectedLoginMethod')
  }
  return { type: t.SELECT_LOGIN_METHOD, payload }
}

/** è stato reperito un code da querystring */
export const gotAuthCode =
  (code: string, selectedMethodId?: string): t.CanDispatchAnyAction =>
  (dispatch, getState) => {
    history.push('/loading')
    const { selectedMethod, methods } = getLogin(getState())
    const selectedFromLS = sessionStorage.getItem('@occlient/selectedLoginMethod')
    let selected = selectedMethod || (selectedFromLS ? JSON.parse(selectedFromLS) : null)
    if(selectedMethodId && !selected) {
      selected = methods.find((m) => m.id === parseInt(selectedMethodId))
    }
    const OTP = getOtpRequired(getState())
    selected &&
      getAuthToken(selected, code)
        .then((token: string | null) => {
          if (token) {
            if (OTP) {
              log.silly('OTP is required, redirecting to otp page')
              dispatch(changeRoute({ current: '/login/otp', previous: 'login', state: { token } }))
            } else {
              log.silly('Got authorization code, logging in to core')
              dispatch(loginWithPayload(selected.id, token))
            }
          }
        })
        .catch((e) => {
          log.error('getAuthToken failed', e)
          history.push('/login')
          window.occlient.emit('auth-login-error')
        })
        .finally(() => {
          // pulisce la barra del browser rimuovendo il code dalla querystring
          window.history.replaceState({}, '', window.location.href.replace(window.location.search, ''))
        })
  }

/** è stato reperito un token da querystring */
export const gotAuthToken =
  (token: string,  selectedMethodId: string): t.CanDispatchAnyAction =>
  (dispatch, getState) => {

    history.push('/loading')
    const { selectedMethod, methods } = getLogin(getState())
    const selectedFromLS = sessionStorage.getItem('@occlient/selectedLoginMethod')
    let selected = selectedMethod || (selectedFromLS ? JSON.parse(selectedFromLS) : null)
    if(selectedMethodId && !selected) {
      selected = methods.find((m) => m.id === parseInt(selectedMethodId))
    }

    const OTP = getOtpRequired(getState())

    if (OTP) {
      dispatch(changeRoute({ current: '/login/otp', previous: 'login', state: { token } }))
    } else {
      selected && dispatch(loginWithPayload(selected.id, token))
      window.history.replaceState({}, '', window.location.href.replace(window.location.search, ''))
    }
  }

export const gotTokenAndUser =
  (token: string): t.CanDispatchAnyAction =>
  (dispatch, getState) => {
    const { current } = getPath(getState())
    const pathToGo = ['/login/otp', '/login', '/error', '/'].includes(current) ? '/phone' : current || '/phone'

    getNewToken(token)
      .then((loginPayload) => {
        JanusPool.initialize(loginPayload.config.turn).catch((e) => logger.error(e))
        dispatch(login(loginPayload, false))
        dispatch(changeRoute({ previous: '/login', current: pathToGo }))
      })
      .catch((e) => {
        log.error('Could not renew token', e)
        dispatch(handleLogout(true))
        history.push('/login')
        window.occlient.emit('auth-login-error')
      })
  }

export const loginWithPayload =
  (loginMethodId: number, token: string, payload: Object = {}): t.CanDispatchAnyAction =>
  (dispatch, getState) => {
    const { isAuthenticated } = getLogin(getState())
    if (isAuthenticated) {
      log.error('Already authenticated')
      return
    }
    const { current } = getPath(getState())
    const pathToGo = ['/login/otp', '/login', '/error'].includes(current) ? '/phone' : current || '/phone'

    const payloadToSend = getPayloadByLoginMethod(loginMethodId, token, payload)

    coreLogin(loginMethodId, payloadToSend)
      .then((loginPayload) => {
        // se il core risponde correttamente cancello il metodo di login selezionato e salvo su redux le informazioni
        // avvio inoltre la routine per il rinnovo del token
        log.info('Login successful')
        updateThemeAndLanguage(loginPayload, dispatch)
        if (window.occlient.settings.isSalesforce) {
          if (window.opener) {
            window.opener.occlient.emit('salesforce-login-success', loginPayload)
            return
          }
        }
        dispatch(login(loginPayload))
        JanusPool.initialize(loginPayload.config.turn).catch((e) => logger.error(e))
        history.push(pathToGo)
        sessionStorage.removeItem('@occlient/selectedLoginMethod')
      })
      .catch((e) => {
        log.error('coreLogin error', e)
        dispatch(setLoginError(e.message))
      })
  }

export const handleRenewToken = (tokenToUse: string) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => RootState
) => {
  const timeout = window.setTimeout(() => {
    const {isAuthenticated} = getLogin(getState())
      isAuthenticated && getNewToken(tokenToUse)
        .then((renewPayload) => {
          dispatch(setRenewTimeout(timeout))
          dispatch(tokenRenewed(renewPayload))
          dispatch(handleRenewToken(renewPayload.token))
        })
        .catch(() => {
          dispatch(handleLogout(true))
          history.push('/login')
        })
  }, renewTokenInterval)
}

export const setRenewTimeout = (payload: number): SetRenewTimeout => ({
  type:  t.RENEW_TOKEN_TIMEOUT,
  payload
})

const renewTokenInterval = (Math.random() * 20 + 30) * 60000



