import { User } from '../../../../../api/rest'
import { rem } from '../../../../../helpers/style'
import { getCallInProgress, getTranslation } from '../../../../../store/selectors'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { getContactLabel } from './helpers'

interface CallInfoAttendeesProps {
  font?: number
}

const CallInfoAttendees: React.FC<CallInfoAttendeesProps> = ({ font }) => {
  const [user, setUser] = useState<User | undefined>(undefined)
  const [isConference, setIsConference] = useState(false)
  const call = useSelector(getCallInProgress)
  const translation = useSelector(getTranslation)

  useEffect(() => {
    if (!call || !call.status) return
    if (!call.others) setIsConference(false)
    else setIsConference(call.others.length > 0)
    setUser(call.user)
  }, [call])

  return (
    <Container>
      <ContactInfo font={font || 1}>
        {getContactLabel(user || call?.number)} {!user && isConference && call?.number}
      </ContactInfo>
      {!isConference && (
        <Supplementary font={font || 0.8}>{user && `${translation.voip} ${call?.number}`}</Supplementary>
      )}
      {isConference && (
        <List>
          {call?.others?.map((o) => (
            <ContactInfo font={font || 1}>{getContactLabel(o)}</ContactInfo>
          ))}
        </List>
      )}
    </Container>
  )
}

export default CallInfoAttendees

//region Style

const Container = styled.div`
  display: grid;
  place-self: center;
  place-items: center;
  padding: ${rem(0.6)} 0;
`
interface Options {
  font: number
}

const ContactInfo = styled.div<Options>`
  font-size: ${({ font }) => rem(font)};
`

const Supplementary = styled.div<Options>`
  font-size: ${({ font }) => rem(font)};
`

const List = styled.div``

//endregion
