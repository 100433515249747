import { Chat, Conversation, DeviceTypes } from '../../api/rest'
import { Avatar } from './Avatar'
import { MessageAck } from './MessageAck'
import { rem } from '../../helpers/style'
import { DeleteRounded, GroupRounded } from '@mui/icons-material'
import { Badge, IconButton } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { getTheme } from '../../store/selectors'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import devicesIcons from '../pages/CustomerChat/common/devicesIcons'

type Props = {
  conversation: Chat | Conversation
  goToConversation: (a0: Conversation | Chat) => void
  paramsId: string
  badgeContent: number
  otherFullname: string
  shouldRenderMessageAck: boolean
  lastMessageUser?: string
  lastMessageBody: string
  timestamp: string
  withDelete?: boolean
  deleteChat?: (chatId: string) => void
}
export const ConversationCard: React.FC<Props> = (props) => {
  const {
    conversation,
    goToConversation,
    paramsId,
    badgeContent,
    otherFullname,
    shouldRenderMessageAck,
    lastMessageUser,
    lastMessageBody,
    timestamp,
    withDelete = false,
    deleteChat = function () {}
  } = props

  const theme = useSelector(getTheme)
  const muiTheme = useTheme()

  const messageAckOpts = {
    light: {
      colors: {
        sent: muiTheme.palette.grey[500],
        received: muiTheme.palette.grey[500],
        read: muiTheme.palette.primary.dark
      },
      opacities: {}
    },
    dark: {
      colors: {},
      opacities: {}
    }
  }

  return (
    <Container
      key={conversation.id}
      onClick={() => goToConversation(conversation)}
      selected={paramsId === conversation.id}
    >
      {(conversation as Conversation).name ? (
        <Badge
          badgeContent={badgeContent}
          color="secondary"
          overlap="circular"
          style={{ minHeight: 0, placeSelf: 'center', gridArea: 'avatar' }}
        >
          <Avatar
            size={rem(1.7)}
            fontSize={rem(0.9)}
            style={{ gridArea: 'avatar', placeSelf: 'center' }}
            letterSpacing="-1px"
          >
            <GroupRounded />
          </Avatar>
        </Badge>
      ) : (
        <Badge
          badgeContent={badgeContent}
          color="secondary"
          overlap="circular"
          style={{ minHeight: 0, placeSelf: 'center', gridArea: 'avatar' }}
        >
          <Avatar fullname={otherFullname} size={rem(1.7)} fontSize={rem(0.9)} letterSpacing="-2px">
            {devicesIcons[(conversation as any).guest?.info?.client_info.device as DeviceTypes]}
          </Avatar>
        </Badge>
      )}
      <Name>{(conversation as Conversation).name || otherFullname}</Name>
      <LastMessage withAck={shouldRenderMessageAck} withUser={!!(conversation as Conversation).name}>
        {shouldRenderMessageAck && 'messages' in conversation && (
          <MessageAck
            message={conversation.messages[conversation.messages.length - 1]}
            colors={messageAckOpts[theme].colors}
            opacities={messageAckOpts[theme].opacities}
          />
        )}
        <LastMessageBody>
          {(conversation as Conversation).name && (
            <span style={{ opacity: 1, fontWeight: 600 }}>{lastMessageUser || ''} </span>
          )}
          {lastMessageBody}
        </LastMessageBody>
      </LastMessage>
      <Timestamp>
        {withDelete ? (
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation()
              deleteChat(conversation.id)
            }}
          >
            <DeleteRounded />
          </IconButton>
        ) : (
          timestamp
        )}
      </Timestamp>
    </Container>
  )
}

//region Style

const Container = styled.div<{ selected: boolean }>`
  display: grid;
  width: 100%;
  height: 100%;
  padding: ${rem(0.3)};
  grid-template-areas: 'avatar name timestamp' 'avatar lastmessage timestamp';
  grid-template-rows: 1fr 1fr;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: ${rem(0.5)};
  cursor: pointer;
  place-content: center stretch;
  padding: ${rem(0.5)} ${rem(0.8)} ${rem(0.5)} ${rem(0.5)};
  ${({ selected, theme }) => selected && 'background: ' + theme.palette.action.selected};

  &:hover {
    background: ${({ theme }) => theme.palette.action.hover};
  }
`

const Name = styled.div`
  grid-area: name;
  font-size: ${rem(0.8)};
  align-self: end;
  height: min-content;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  font-weight: bold;
`

const LastMessage = styled.div<{ withAck: boolean; withUser: boolean }>`
  grid-area: lastmessage;
  display: grid;
  grid-template-columns: ${({ withAck }) => (withAck ? '15px auto' : '1fr')};
  grid-column-gap: ${({ withUser }) => (withUser ? rem(0.5) : rem(0.25))};
  font-size: ${rem(0.65)};
  height: 100%;
  width: 100%;
  overflow: hodden;
  text-overflow: ellipsis;
  place-self: center start;
`

const Timestamp = styled.div`
  grid-area: timestamp;
  place-self: center end;
  font-size: ${rem(0.65)};
  color: ${({ theme }) => theme.palette.text?.secondary};
`

const LastMessageBody = styled.div`
  width: 100%;
  place-self: center start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.palette.text?.secondary};
`

//endregion
