import { Chat } from '../../rest'
import { setChats } from '../../../store/customerChats/actions'
import { RootState } from '../../../store/types'
import { ThunkDispatch } from 'redux-thunk'
import { Socket } from 'socket.io-client'
import log from '../../../helpers/logger'
import { handleChatEvents, handleCoreEvents } from './eventsHandlers'
import * as t from './eventsTypes'

export const addEventListeners = (
  socket: Socket,
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => RootState
) => {
  handleCoreEvents(socket, dispatch, getState)
  handleChatEvents(socket, dispatch)
}

export const subscribeToChannels = (socket: Socket, dispatch: ThunkDispatch<any, any, any>) => {
  log.debug('Subscribing and joining to core channels')
  socket.emit('join', 'users')
  socket.emit('join', 'addressbooks')
  socket.emit('join', 'pauses')
  socket.emit('join', 'queues')
  socket.emit('join', 'departments')
  socket.emit('join', 'proactiveGuests')
  socket.emit('join', 'outbounds')
  socket.emit('join', 'statuses')
  socket.emit('chat:operator-login', (ack: { chats: Chat[] }) => {
    if (ack.chats && ack.chats.length) {
      ack.chats.forEach((c) => window.occlient.emit('chat:resume', c))
      const chatsMap = new Map(ack.chats.map((c) => [c.id, c]))
      dispatch(setChats(chatsMap))
    }
  })
}

export const events = [...Object.values(t)]
