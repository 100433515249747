import { SvgIcon } from '@mui/material'
import { getMe, getMessengerVideoRoom, getPath, getTranslation } from '../../../store/selectors'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { ReactComponent as AnswerIcon } from '../../../images/call.svg'
import { ReactComponent as HangIcon } from '../../../images/hang.svg'
import { changeRoute, videoRequestResponse } from '../../../store/actions'
import { ActionButton } from '../../common'

const VideoRequest: React.FC = () => {
  const videoroom = useSelector(getMessengerVideoRoom)
  const me = useSelector(getMe)
  const { previous } = useSelector(getPath)
  const translation = useSelector(getTranslation)

  const dispatch = useDispatch()

  useEffect(() => {
    if (videoroom === null || !videoroom.pin || !videoroom.room || !videoroom.sender || !videoroom.members) {
      dispatch(changeRoute({ current: previous, previous }) as any)
      return
    }
  }, [videoroom, previous, dispatch])

  return (
    <Container>
      {videoroom && (
        <Info>
          <div>{translation.incomingVideoCall}</div>
          <div
            style={{
              fontSize: '1.4em'
            }}
          >
            {videoroom.sender?.surname} {videoroom.sender?.name}
          </div>
          {videoroom.members && videoroom.members.length > 2 && (
            <div>
              {translation.others}:{' '}
              {videoroom.members
                .filter((m) => m.username !== me.username && m.username !== videoroom.sender?.username)
                .map((m) => `${m.surname} ${m.name}`)
                .join(', ')}
            </div>
          )}
        </Info>
      )}
      {videoroom && (
        <Buttons>
          <ActionButton
            onClick={() =>
              dispatch(
                videoRequestResponse({
                  response: true,
                  members: videoroom.members || []
                }) as any
              )
            }
          >
            <SvgIcon style={{ fontSize: 'calc(var(--font-size) * 2)' }}>
              <AnswerIcon />
            </SvgIcon>
          </ActionButton>
          <ActionButton
            onClick={() =>
              dispatch(
                videoRequestResponse({
                  response: false,
                  members: videoroom.members || []
                }) as any
              )
            }
          >
            <SvgIcon style={{ fontSize: 'calc(var(--font-size) * 2)' }}>
              <HangIcon />
            </SvgIcon>
          </ActionButton>
        </Buttons>
      )}
    </Container>
  )
}

export default VideoRequest

//region Style

const Container = styled.div`
  display: grid;
  grid-template-rows: [info] 1fr [buttons] 1fr;
  width: 100%;
  height: 100%;
  padding: 10px;
`

const Info = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  place-content: center;
  margin-top: 15px;
  text-align: center;
  font-size: 1.3em;
  height: 150px;
  color: #0a8da9;
`

const Buttons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-content: end end;
  margin-bottom: 10px;
`

//endregion
