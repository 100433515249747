import { User } from '../../../../api/rest'
import { rem } from '../../../../helpers/style'
import { changeRoute } from '../../../../store/applicationState/actions'
import { getConversationsArray, getDimensions, getPath, getTranslation, getUsersArray } from '../../../../store/selectors'
import React, { useEffect, useState } from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { AvatarWithStatus, SearchBar } from '../../../common'

type Props = {
  closeModal: Function
}
const NewChat: React.FC<Props> = ({ closeModal }) => {
  const [search, setSearch] = useState('')
  const [usersToShow, setUsersToShow] = useState<User[]>([])

  const users = useSelector(getUsersArray)
  const { fontSize } = useSelector(getDimensions)
  const conversations = useSelector(getConversationsArray)
  const { current } = useSelector(getPath)
  const translation = useSelector(getTranslation)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!search) {
      setUsersToShow([...users])
      return
    }

    const s = search.toLowerCase()
    const newUsersToShow = users.filter((u: User) => {
      if (u.name.toLowerCase().includes(s)) return true
      return u.surname.toLowerCase().includes(s)
    })

    newUsersToShow.sort((a, b) => a.name.localeCompare(b.name) || a.surname.localeCompare(b.surname))
    setUsersToShow(newUsersToShow)
  }, [search, users])

  const selectUser = (u: User) => {
    // controlla se esiste già una conversazione singola con quell'utente
    const alreadyKnownConversation = conversations.find(
      (c) => c.members.length === 2 && !!c.members.find((m) => m.username === u.username)
    )
    if (alreadyKnownConversation) {
      dispatch(changeRoute({ current: `/messenger/${alreadyKnownConversation.id}`, previous: current }))
    } else {
      dispatch(changeRoute({ current: '/messenger/new', state: { otherMember: u } }))
    }
    closeModal()
  }

  return (
    <Container>
      <Label>{translation.newConversation}</Label>
      <SearchBar
        value={search}
        setValue={setSearch}
        input={{
          onChange: (event) => setSearch(event.target.value),
          style: { width: '100%', height: rem(1.3, fontSize), placeSelf: 'center', padding: rem(0.2, fontSize) }
        }}
        style={{
          width: 'fit-content',
          placeContent: 'center',
          placeSelf: 'start center',
          height: rem(1.3, fontSize),
          maxWidth: 600
        }}
      />
      <List>
        {usersToShow
          .sort((a, b) => `${a.surname} ${a.name}`.localeCompare(`${b.surname} ${b.name}`))
          .map((u) => (
            <UserCard key={u.id} onClick={() => selectUser(u)}>
              <AvatarWithStatus user={u} avatarSize="30px" statusSize="15px" fontSize="15px" letterSpacing="-2px" />
              <UserName>
                {u.surname} {u.name}
              </UserName>
            </UserCard>
          ))}
      </List>
    </Container>
  )
}

export default NewChat

//region Style

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr 2fr 10fr;
  grid-template-columns: 1fr;
  width: 100%;
  height: 100%;
  place-content: start center;
`

const Label = styled.div`
  font-size: ${rem(0.8)};
  color: ${({ theme }) => theme.palette.primary.main};
`

const List = styled(ScrollBar)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
`

const UserCard = styled.div`
  display: grid;
  grid-template-columns: 40px auto;
  width: 100%;
  margin-block: 3px;
  grid-column-gap: 10px;
  border-radius: 15px;
  place-content: center start;
  max-width: 500px;
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.palette.action.hover};
  }
`

const UserName = styled.div`
  place-self: center start;
`
//endregion
