import { IRegistryCall } from '../../api/database/types'
import { getDateInfo } from '../../helpers/common'
import { rem } from '../../helpers/style'
import {
  PhoneCallbackRounded,
  PhoneDisabledRounded,
  PhoneForwardedRounded,
  PhoneMissedRounded
} from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'
import { changeRoute } from '../../store/applicationState/actions'
import { requestCall } from '../../store/phone/actions'
import { getCanCall, getPath, getTranslation } from '../../store/selectors'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMatch } from 'react-router-dom'
import styled from 'styled-components'
import { ActionButton } from './ActionButton'
import { Duration } from 'luxon'

interface RegistryCallProps {
  registryCall: IRegistryCall
  redirect?: boolean
  from: string
  beforeClick?: Function
  clickable?: boolean
}

export const RegistryCall: React.FC<RegistryCallProps> = (props) => {
  const { registryCall, from, clickable = true } = props
  const match = useMatch('/phone/address-book/:type/:id/:from?')

  const canCall = useSelector(getCanCall)
  const translation = useSelector(getTranslation)
  const path = useSelector(getPath)
  const dispatch = useDispatch()

  const muiTheme = useTheme()

  const getRedirect = (): string => {
    let redirect = ''
    if (registryCall.userId) redirect += `/phone/address-book/int/${registryCall.userId}`
    else redirect += `/phone/address-book/ext/${registryCall.userName}`
    if (from === '/phone') redirect += '/home'
    if (from === '/phone/history') redirect += '/registry'
    return redirect
  }

  const handleClick = () => {
    if(!clickable) return
    props.beforeClick && props.beforeClick()
    const path = getRedirect()
    // Se sono già sulla pagina di dettaglio utente non faccio redirect
    if (match?.pathname === '/phone/address-book/:type/:id/:from?') return
    dispatch(changeRoute({ current: path, previous: from }))
  }

  const getTooltipTitle = () => {
    if (registryCall.outcome === 'notAnswered' && registryCall.type === 'out')
      return translation?.tooltips?.notAnsweredOut
    if (registryCall.outcome === 'notAnswered' && registryCall.type === 'in') return translation?.tooltips?.notAnsweredIn
    if (registryCall.outcome === 'answered' && registryCall.type === 'out') return translation?.tooltips?.answeredOut
    if (registryCall.outcome === 'answered' && registryCall.type === 'in') return translation?.tooltips?.answeredIn
    return ''
  }

  return (
    <Container>
      <ActionButton
        tooltip={getTooltipTitle()}
        disabled={!canCall || registryCall.userName === 'Open Communication'}
        onClick={() => canCall && dispatch(requestCall({ number: registryCall.telephone, from: path.previous }))}
        style={{ gridArea: 'response', placeSelf: 'center' }}
        size="small"
      >
        {registryCall.outcome === 'notAnswered' && registryCall.type === 'out' && (
          <PhoneDisabledRounded htmlColor={canCall ? muiTheme.palette.error.main : muiTheme.palette.action.disabled} />
        )}
        {registryCall.outcome === 'notAnswered' && registryCall.type === 'in' && (
          <PhoneMissedRounded htmlColor={canCall ? muiTheme.palette.error.main : muiTheme.palette.action.disabled} />
        )}
        {registryCall.outcome === 'answered' && registryCall.type === 'out' && (
          <PhoneForwardedRounded
            htmlColor={canCall ? muiTheme.palette.primary.main : muiTheme.palette.action.disabled}
          />
        )}
        {registryCall.outcome === 'answered' && registryCall.type === 'in' && (
          <PhoneCallbackRounded
            htmlColor={canCall ? muiTheme.palette.primary.main : muiTheme.palette.action.disabled}
          />
        )}
      </ActionButton>
      <Info row={registryCall.userId ? 2 : 1} clickable={clickable} onClick={handleClick}>
        <Name>{registryCall.userName}</Name>
        {registryCall.userId && <PhoneNumber>{registryCall.telephone}</PhoneNumber>}
        {registryCall.outbound && <PhoneNumber>{registryCall.outbound.name}</PhoneNumber>}
        {registryCall.inbound && <PhoneNumber>{registryCall.inbound}</PhoneNumber>}
      </Info>
      <InfoTime row={registryCall.duration ? 2 : 1}>
        <Timestamp>{getDateInfo(registryCall.timestamp)}</Timestamp>
        {registryCall.duration && registryCall.duration > 0 && (
          <DurationElem>{formatDuration(registryCall.duration)}</DurationElem>
        )}
      </InfoTime>
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: [response]30px [info]1fr [time]auto;
  grid-column-gap: calc(var(--font-size) * 0.4);
  overflow: hidden;
  place-content: center start;
  margin: ${rem(0.2)} 0;
  padding: 0 10px;

  &:hover {
    background: ${({ theme }) => theme.palette.action.hover};
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`
const Name = styled.div`
  align-self: start;
  text-align: left;
  font-size: max(${rem(0.6)}, 12px);
  font-weight: bold;
`
const PhoneNumber = styled.div`
  font-size: max(${rem(0.6)}, 10px);
`
const Timestamp = styled.div`
  font-size: ${rem(0.65)};
  justify-self: flex-end;
  align-self: center;
`

const DurationElem = styled.div`
  font-size: ${rem(0.65)};
  justify-self: flex-end;
  align-self: center;
`

interface InfoProps {
  row: number
  clickable?: boolean
}

const Info = styled.div<InfoProps>`
  grid-area: info;
  display: grid;
  grid-template-rows: ${({ row }) => `repeat(${row}, 1fr)`};
  overflow: hidden;
  align-self: center;
  place-content: start;
  ${({clickable}) => clickable && 'cursor: pointer;'}
`

const InfoTime = styled.div<InfoProps>`
  grid-area: time;
  display: grid;
  grid-template-rows: ${({ row }) => `repeat(${row}, 1fr)`};
  overflow: hidden;
  align-self: center;
  place-content: start;
  cursor: pointer;
`

const formatDuration = (duration: number) => {
  const { hours, minutes, seconds } = Duration.fromMillis(duration).shiftTo('hours', 'minutes', 'seconds').toObject()
  return `${hours ? `${Math.round(hours)}h` : ''} ${minutes ? `${Math.round(minutes)}m` : ''} ${
    seconds ? `${Math.round(seconds)}s` : ''
  }`
}
