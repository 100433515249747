import {rem} from '../../../../helpers/style'
import {CallMergeRounded as CallMergeIcon, ImportExportRounded as CallTransferIcon} from '@mui/icons-material'
import {SvgIcon} from '@mui/material'
import {cancelTransfer, completeTransfer, joinCalls} from '../../../../store/phone/actions'
import {getCallInProgress, getTranslation} from '../../../../store/selectors'
import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useMatch} from 'react-router-dom'
import styled from 'styled-components'
import CancelTransfer from '../../../animated-icons/call/cancelTransfer'
import {ActionButton} from '../../../common'
import CallInfoTransferring from './CallInfo/CallInfoTransferring'

const TransferringCall: React.FC = () => {
  const match = useMatch('/phone/transferring-call/:type')!

  const type = match?.params?.type

  const translation = useSelector(getTranslation)
  const call = useSelector(getCallInProgress)
  const dispatch = useDispatch()

  return (
    <Container>
      <CallInfoTransferring type={type as 'add' | 'transfer'} labelFont={0.7} />
      <ButtonContainer>
        <IconContainer>
          <ActionButton
            onClick={() => {
              if (!call?.canCloseTransfer) return
              dispatch(joinCalls())
            }}
            label={translation.merge}
            disabled={!call?.canCloseTransfer}
          >
            <CallMergeIcon />
          </ActionButton>
        </IconContainer>
        <IconContainer>
          <ActionButton
            onClick={() => {
              if (!call?.canCloseTransfer) return
              dispatch(completeTransfer())
            }}
            label={translation.transfer}
            disabled={!call?.canCloseTransfer}
          >
            <CallTransferIcon />
          </ActionButton>
        </IconContainer>
      </ButtonContainer>
      <FunctionalButton>
        <ActionButton onClick={() => dispatch(cancelTransfer())} disabled={!call?.canCancelTransfer}>
          <SvgIcon style={{ fontSize: rem(1.8) }}>
            <CancelTransfer disabled={!call?.canCancelTransfer} />
          </SvgIcon>
        </ActionButton>
      </FunctionalButton>
    </Container>
  )
}

export default TransferringCall

//region Style

const Container = styled.div`
  display: grid;
  grid-template-rows: 3.5fr 7.5fr;
  height: 100%;
  overflow: hidden;
  padding: ${rem(0.5)} ${rem(1.5)};
`
const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-content: center;
`
const IconContainer = styled.div`
  place-self: center;
  width: fit-content;
`
const FunctionalButton = styled.div`
  place-self: center;
  display: grid;
  width: 70%;
  height: 100%;
`

//endregion
