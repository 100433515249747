import {ElectronProxy} from '../../api'
import CustomerChat from '../../api/customerChat'
import {Chat, ChatEvent, Guest, Statuses} from '../../api/rest'
import {Videoroom} from '@bytewise/janus'
import logger from '../../helpers/logger'
import {changeRoute} from '../applicationState/actions'
import {
  getBookedChats,
  getChats,
  getMe,
  getMediaChat,
  getNotifications,
  getPath,
  getProactiveGuests,
  getSneakPeeks,
  getStatus,
  getTranslation,
  getUnreadEvents,
  getUnreadEventsByChat,
  getUsers
} from '../selectors'
import {RootState} from '../types'
import {Dispatch} from 'redux'
import {ThunkDispatch} from 'redux-thunk'
import eventTone from '../../sounds/chat_tone.mp3'
import chatTone from '../../sounds/new_chat_tone.mp3'
import * as t from './types'
import {DateTime} from 'luxon'

const ChatTone = new Audio()
const EventTone = new Audio()

type TD = ThunkDispatch<RootState, null, any>

export const setChats = (payload: Map<string, Chat>) => {
  return {type: t.SET_CHATS, payload}
}

//region State handlers

export const setSneakPeeks = (payload: Map<string, string>) => {
  return {type: t.SET_SNEAKPEEKS, payload}
}

// Aumenta il contatore di messaggi non letti per una chat, o lo inizializza a 1
export const addUnreadEvent = (chatId: string) => (dispatch: TD, getState: () => RootState) => {
  const unreadEvents = getUnreadEvents(getState())
  const unreadEventsByChat = getUnreadEventsByChat(getState())

  const chat = unreadEventsByChat.get(chatId)

  const newUnreadEvents = new Map(unreadEventsByChat)

  newUnreadEvents.set(chatId, (chat || 0) + 1)

  ElectronProxy.setBadge(unreadEvents + 1, 'messenger')

  dispatch({
    type: t.SET_UNREAD_EVENTS,
    payload: {
      unreadEvents: unreadEvents + 1,
      unreadEventsByChat: newUnreadEvents
    }
  })
}

// Quando la chat viene aperta, tolgie la sua entry nella mappa di messaggi non letti
export const readEventsForChat = (chatId: string) => (dispatch: TD, getState: () => RootState) => {
  const unreadEvents = getUnreadEvents(getState())
  const unreadEventsByChat = getUnreadEventsByChat(getState())

  const chat = unreadEventsByChat.get(chatId)

  const newUnreadEvents = new Map(unreadEventsByChat)

  newUnreadEvents.delete(chatId)

  dispatch({
    type: t.SET_UNREAD_EVENTS,
    payload: {
      unreadEvents: unreadEvents - (chat || 0),
      unreadEventsByChat: newUnreadEvents
    }
  })
}

// Inserisce una chat tra le booked
export const bookChat = (chat: Chat) => (dispatch: TD, getState: () => RootState) => {
  const oldBooked = getBookedChats(getState())
  const translation = getTranslation(getState())
  const newBooked = new Map(oldBooked)

  newBooked.set(chat.id, chat)
  dispatch({type: t.SET_BOOKED_CHATS, payload: newBooked})
  const status = getStatus(getState())

  ChatTone.src = chatTone
  ChatTone.play().catch((e) => logger.error(e))

  const {current} = getPath(getState())

  if (status.id === Statuses.idle && !current.includes('customer-chat')) {
    dispatch(changeRoute({current: '/customer-chat'}))
  }
  //TODO: traduzioni
  const title = chat.supervisor
    ? `${translation.chatAssignedBy} ${chat.supervisor.surname} ${chat.supervisor.name}`
    : translation.incomingChat
  new Notification(title, {
    body: `${translation.department}: ${chat.departments[chat.departments.length - 1].name}`,
    silent: true
  }).onclick = () => {
    dispatch(changeRoute({current: `/customer-chat`, previous: current}))
  }
}

// Rimuove una chat tra le booked
export const unbookChat = (chatId: string) => (dispatch: TD, getState: () => RootState) => {
  const oldBooked = getBookedChats(getState())
  const newBooked = new Map(oldBooked)

  newBooked.delete(chatId)
  dispatch({type: t.SET_BOOKED_CHATS, payload: newBooked})
}

// Quando viene accettata una booked chat, viene aggiunta singolarmente
export const addSpiedChat = (chat: Chat) => (dispatch: TD, getState: () => RootState) => {
  const oldChats = getChats(getState())
  const newChats = new Map(oldChats)

  newChats.set(chat.id, {...chat, spied: true})

  logger.debug(newChats)
  dispatch(setChats(newChats))
}

// Quando viene accettata una booked chat, viene aggiunta singolarmente
export const addChat = (chat: Chat) => (dispatch: TD, getState: () => RootState) => {
  const oldChats = getChats(getState())
  const newChats = new Map(oldChats)

  newChats.set(chat.id, {...chat, spied: false})

  dispatch(unbookChat(chat.id))
  dispatch(setChats(newChats))
}

// Quando si elimina una chat conclusa non se ne tiene più traccia
export const removeChat = (chatId: string) => (dispatch: TD, getState: () => RootState) => {
  const oldChats = getChats(getState())
  const newChats = new Map(oldChats)

  newChats.delete(chatId)

  dispatch(setChats(newChats))
}

export const proactiveGuestsList = (payload: Map<string, Guest>) => ({type: t.PROACTIVE_GUESTS_LIST, payload})

export const proactiveGuestsAdd = (guest: Guest) => (dispatch: Dispatch, getState: () => RootState) => {
  const guests = getProactiveGuests(getState())
  const newGuests = new Map(guests)
  newGuests.set(guest.id, guest)
  dispatch(proactiveGuestsList(newGuests))
}

export const proactiveGuestsRemove = (guest: Guest) => (dispatch: Dispatch, getState: () => RootState) => {
  const guests = getProactiveGuests(getState())
  const newGuests = new Map(guests)
  newGuests.delete(guest.id)
  dispatch(proactiveGuestsList(newGuests))
}

//endregion

//region Events handler

export const eventReceived =
  (payload: { chat: string; event: ChatEvent }) => (dispatch: TD, getState: () => RootState) => {
    const chats = getChats(getState())
    const me = getMe(getState())
    const chat = chats.get(payload.chat)
    const {current} = getPath(getState())

    if (!chat) {
      // non conosco la chat relativa a questo evento
      return
    }

    const newChat = {...chat}
    const oldEvents = chat.events || []

    if (payload.event.type === 'message' && payload.event.user.id === me.id) {
      // se è un mio messaggio devo togliere il tempId dall'event
      if (!payload.event.tempID) return
      const oldEvents = chat.events || []
      newChat.events = oldEvents.map((e) => {
        if (e.tempID === payload.event.tempID) {
          return {...e, tempID: undefined}
        } else {
          return e
        }
      })
    } else {
      newChat.events = [...oldEvents, payload.event]
    }

    // Se arriva un evento di chiusura, chiudo la chat
    if (
      payload.event.type === 'system' &&
      ['operator close', 'customer close', 'system close'].includes(payload.event.subtype as string)
    ) {
      dispatch(closeMediaChat())
      dispatch(closeChat(chat.id))
      newChat.closed = true
    }

    // Se il guest rifiuta la richiesta media, la chiudo
    if (payload.event.type === 'system' && payload.event.subtype === 'media request refused') {
      dispatch(closeMediaChat())
    }

    // se è un evento di systema che notifica l'accettazione di una mediachat va iniziata la registrazione
    if (payload.event.type === 'system' && payload.event.subtype === 'media request accepted') {
      const mediachat = getMediaChat(getState())
      if (mediachat && mediachat.publisherSession) {
        // aggiorno la mediachat con lo startTime
        dispatch(updateMediaChat({startTime: DateTime.now().toMillis()}))
        logger.debug('Starting recording mediachat since it was accepted')
        mediachat.publisherSession.startRecording().catch((e) => logger.error(e))
      }
    }

    // Se non sono sulla pagina della chat, aggiunto l'evento ai messaggi non letti
    if (!current.includes(chat.id)) {
      dispatch(addUnreadEvent(chat.id))
    }

    // Aggiorno le chat nello state
    const newChats = new Map(chats)
    newChats.set(newChat.id, newChat)
    dispatch(setChats(newChats))

    // Se non è un evento di notifica, riproduce il suono e manda la notifica
    if (payload.event.type !== 'system' && payload.event.user.id !== me.id) {
      EventTone.src = eventTone
      EventTone.play().catch((e) => logger.error(e))
      const chatEventNotification = new Notification(`${payload.event.user.surname} ${payload.event.user.name}`, {
        body: payload.event.text,
        silent: true
      })
      chatEventNotification.onclick = () => {
        dispatch(changeRoute({current: `/messenger/${chat.id}`, previous: current}))
      }
      window.onfocus = () => {
        chatEventNotification && chatEventNotification.close()
      }
    }
  }

export const addEvent = (payload: { chat: string; event: ChatEvent }) => (dispatch: TD, getState: () => RootState) => {
  const chats = getChats(getState())
  const chat = chats.get(payload.chat)
  if (!chat) {
    // non conosco la chat relativa a questo evento
    return
  }

  const oldEvents = chat.events || []
  chat.events = [...oldEvents, payload.event]
  const newChats = new Map(chats)
  dispatch(setChats(newChats))
}

export const closeChat = (chatId: string) => (dispatch: TD, getState: () => RootState) => {
  const chats = getChats(getState())
  const chat = chats.get(chatId)

  if (!chat) {
    // non conosco la chat relativa a questo evento
    return
  }

  const newChats = new Map(chats)
  chat.closed = true

  dispatch(setChats(newChats))
}

export const sneakpeekReceived =
  (payload: { chat: string; text: string }) => (dispatch: TD, getState: () => RootState) => {
    const sneakpeeks = getSneakPeeks(getState())

    const newSneakpeeks = new Map(sneakpeeks)

    if (payload.text === '') {
      newSneakpeeks.delete(payload.chat)
    } else {
      newSneakpeeks.set(payload.chat, payload.text)
    }

    dispatch(setSneakPeeks(newSneakpeeks))
  }

export const transferToOperatorResponse =
  (payload: { chatId: string; operatorId: number; response: boolean }) => (dispatch: TD, getState: () => RootState) => {
    if (!payload.response) {
      // TODO: alert?
      return
    }
    const chats = getChats(getState())
    const chat = chats.get(payload.chatId)
    const me = getMe(getState())

    if (!chat) return

    const newChats = new Map(chats)
    const newChat = {...chat}

    newChat.transferer = me.id
    newChat.transferee = payload.operatorId

    newChats.set(newChat.id, newChat)

    dispatch(setChats(newChats))
  }

export const transferToOperatorCancel = (chatId: string) => (dispatch: TD, getState: () => RootState) => {
  const chats = getChats(getState())
  const chat = chats.get(chatId)

  if (!chat) return

  const newChats = new Map(chats)
  const newChat = {...chat}

  newChat.transferer = undefined
  newChat.transferee = undefined

  newChats.set(newChat.id, newChat)

  dispatch(setChats(newChats))
}

export const transferRequestReceived = (chat: Chat) => (dispatch: TD, getState: () => RootState) => {
  dispatch(addChat(chat))
  const translation = getTranslation(getState())

  const notifications = getNotifications(getState())
  const status = getStatus(getState())
  const {current} = getPath(getState())

  // Se è idle e non si trova sulla pagina delle chat, fa un redirect
  if (status.id === Statuses.idle && !current.includes('customer-chat')) {
    dispatch(changeRoute({current: '/customer-chat'}))
    ChatTone.play().catch((e) => logger.error(e))
    return
  } else {
    // Altrimenti parte una notifica
    if (!notifications) return
    let title = 'Trasferimento chat in arrivo'
    if (chat.transferer) {
      const users = getUsers(getState())
      const transferer = users.get(chat.transferer)
      if (transferer) title = `${transferer.surname} ${transferer.name} vuole trasferire una chat`
    }

    new Notification(title, {
      body: `${translation.department}: ${chat.departments[chat.departments.length - 1].name}`,
      silent: true
    }).onclick = () => {
      dispatch(changeRoute({current: `/customer-chat/${chat.id}`, previous: current}))
    }
  }
}

export const transferToOperatorAccept = (chatId: string) => (dispatch: TD, getState: () => RootState) => {
  const chats = getChats(getState())
  const chat = chats.get(chatId)

  if (!chat) return

  const newChats = new Map(chats)
  const newChat = {...chat}

  newChat.transferer = undefined
  newChat.transferee = undefined

  newChats.set(newChat.id, newChat)

  dispatch(setChats(newChats))
}

export const transferToOperatorReject = (chatId: string) => (dispatch: TD) => {
  dispatch(removeChat(chatId))
  dispatch(changeRoute({current: '/customer-chat'}))
}

export const transferToOperatorAccepted = (chatId: string) => (dispatch: TD) => {
  dispatch(removeChat(chatId))
  dispatch(changeRoute({current: '/customer-chat'}))
}

export const transferToOperatorRejected = (chatId: string) => (dispatch: TD, getState: () => RootState) => {
  const chats = getChats(getState())
  const chat = chats.get(chatId)

  if (!chat) return

  const newChats = new Map(chats)
  const newChat = {...chat}

  newChat.transferer = undefined
  newChat.transferee = undefined

  newChats.set(newChat.id, newChat)

  dispatch(setChats(newChats))
}

//endregion

//region Media

export const updateMediaChat =
  (payload: Partial<t.MediaChatState>): t.UpdateMediaChatAction =>
    (dispatch, getState) => {
      const mediachat = getMediaChat(getState())
      let newMediaChat: t.MediaChatState
      if (mediachat === null) {
        newMediaChat = {...(payload as t.MediaChatState)}
      } else {
        newMediaChat = {...mediachat, ...payload}
      }
      dispatch({
        type: t.SET_MEDIACHAT,
        payload: newMediaChat
      })
    }

export const setChatPrivateId = (payload: number): t.ChatsActionTypes => ({
  type: t.SET_PRIVATE_ID,
  payload
})

export const addChatSubscriberSession =
  (payload: { id: number; session: Videoroom }): t.AddRemovePublisherSessionThunkAction =>
    (dispatch, getState) => {
      const mediachat = getMediaChat(getState())

      if (!mediachat) return

      const newSessions = mediachat.subscriberSessions ? new Map(mediachat.subscriberSessions) : new Map()

      newSessions.set(payload.id, payload.session)

      dispatch({
        type: t.SET_SUBSCRIBER_SESSIONS,
        payload: newSessions
      })
    }

export const removeChatSubscriberSession =
  (id: number): t.AddRemovePublisherSessionThunkAction =>
    (dispatch, getState) => {
      const mediachat = getMediaChat(getState())

      if (!mediachat) return

      const newSessions = mediachat.subscriberSessions ? new Map(mediachat.subscriberSessions) : new Map()
      const session = newSessions.get(id)
      if (session) {
        session.removeAllListeners()
        newSessions.delete(id)
      }

      if (newSessions.size === 0) {
        // TODO: chiudere la videochiamata, sei rimasto solo
      }

      dispatch({
        type: t.SET_SUBSCRIBER_SESSIONS,
        payload: newSessions
      })
    }

export const setChatLocalStream = (payload: MediaStream): t.ChatsActionTypes => ({
  type: t.SET_LOCAL_STREAM,
  payload: new MediaStream(payload)
})

export const setGuestStream = (payload: MediaStream): t.ChatsActionTypes => ({
  type: t.SET_GUEST_STREAM,
  payload: new MediaStream(payload)
})

export const addChatRemoteStream =
  (payload: { id: number; stream: MediaStream }): t.AddRemoveRemoteStreamsThunkAction =>
    (dispatch, getState) => {
      const mediachat = getMediaChat(getState())

      if (!mediachat) return

      const newStreams = mediachat.remoteStreams ? new Map(mediachat.remoteStreams) : new Map()

      newStreams.set(payload.id, payload.stream)

      dispatch({
        type: t.SET_REMOTE_STREAMS,
        payload: newStreams
      })
    }

export const removeChatRemoteStream =
  (payload: number): t.AddRemoveRemoteStreamsThunkAction =>
    (dispatch, getState) => {
      const mediachat = getMediaChat(getState())

      if (!mediachat) return

      const newStreams = mediachat.remoteStreams ? new Map(mediachat.remoteStreams) : new Map()

      newStreams.delete(payload)

      if (newStreams.size === 0) {
        dispatch(closeMediaChat())
      } else {
        dispatch({
          type: t.SET_REMOTE_STREAMS,
          payload: newStreams
        })
      }
    }

// quando mandi la richiesta di videochiamata ad un altro client
export const sendMediaChatRequest =
  (payload: { type: t.MediaType; chatId: string }): t.CanDispatchAnyAction =>
    async () => {
      try {
        await CustomerChat.initializeVideoRoom(payload.chatId, payload.type)
        CustomerChat.requestMedia()
      } catch (err) {
        logger.error('Could not start media chat')
      }
    }

// quando va chiusa la videoroom in diversi casi
export const closeMediaChat = (): t.CanDispatchAnyAction => async () => {
  await CustomerChat.closeMediaChat()
}

//endregion
