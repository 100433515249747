import {IFavorite, IRegistryCall} from '../../../../api/database/types'
import { rem } from '../../../../helpers/style'
import { HistoryRounded, StarBorderRounded } from '@mui/icons-material'
import { changeRoute } from '../../../../store/applicationState/actions'
import {getDimensions, getMe, getRegistryLoading, getTranslation, getUsers} from '../../../../store/selectors'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Address, ContactButton, RegistryCall, Title, UserButton } from '../../../common'
import { formatFavorites } from '../../../../helpers'
import ScrollBar from 'react-perfect-scrollbar'
import {getRegistry} from '../../../../helpers/registry'

const Home: React.FC = () => {
  const me = useSelector(getMe)
  const registry = getRegistry()

  const users = useSelector(getUsers)
  const translation = useSelector(getTranslation)
  const { fontSize } = useSelector(getDimensions)
  const registryLoading = useSelector(getRegistryLoading)

  const dispatch = useDispatch()

  const favorites = useMemo(() => {
    return formatFavorites(me?.favorites, users)
  }, [me?.favorites, users])

  const getFavUser = (fav: IFavorite) => {
    return users.get(fav.favoriteId!)
  }

  const renderAddress = (f: IFavorite) => {
    if (f.type === 'user') {
      const user = getFavUser(f)

      return (
        <Address key={f.favoriteId} user={user} type={'user'}>
          {user?.userVoips && <UserButton from={'/phone'} user={user} />}
        </Address>
      )
    } else {
      return (
        <Address
          type={f.type!}
          key={f.favoriteId}
          name={f.firstName}
          surname={f.secondName}
          number={f.addresses && f.addresses.length > 0 ? f.addresses[0].value : String(f.favoriteId!)}
        >
          {f.addresses && f.addresses.length > 0 && <ContactButton from={'/phone'} contact={f} />}
        </Address>
      )
    }
  }

  return (
    <Container>
      <Favorites>
        <Title
          label={translation.favorites}
          icon={<StarBorderRounded />}
          style={{ padding: '3px 5px' }}
          onClick={() => dispatch(changeRoute({ current: '/phone/favorites' }) as any)}
        />
        <div>
          {favorites && favorites.length > 0 ? (
            favorites
              .filter((f) => f.firstName)
              .sort((i, j) => i.orderNumber - j.orderNumber)
              .slice(0, 4)
              .map(renderAddress)
          ) : (
            <Empty>{translation.favoritesNoEntry}</Empty>
          )}
        </div>
      </Favorites>
      <Registry>
        <Title
          label={translation.registry}
          icon={<HistoryRounded />}
          style={{ padding: '3px 5px' }}
          onClick={() => dispatch(changeRoute({ current: '/phone/history' }) as any)}
        />
        <RegistryList style={{ paddingBottom: fontSize * 2 + 8 }}>
          {registry && registry.length > 0 && !registryLoading ? (
            registry
              .sort((a: IRegistryCall, b: IRegistryCall) => b.timestamp - a.timestamp)
              .map((r: IRegistryCall, index: number) => <RegistryCall key={`${r.timestamp}-${index}`} registryCall={r} from={'/phone'} />)
          ) : (
            <Empty>{translation.registryNoEntry}</Empty>
          )}
        </RegistryList>
      </Registry>
    </Container>
  )
}

export default Home

//region Style

const Container = styled.div`
  height: calc(var(--height) - var(--topbar-height));
  display: grid;
  grid-template-rows: auto 1fr;
  grid-row-gap: ${rem(0.9)};
  overflow: hidden;
  padding-top: ${rem(0.5)};
`
const Favorites = styled.div`
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-rows: auto 1fr;
`
const Registry = styled.div`
  display: grid;
  height: 100%;
  overflow: hidden;
  width: 100%;
  grid-template-rows: auto 1fr;
  grid-row-gap: ${rem(0.3)};
  align-self: end;
`
const RegistryList = styled(ScrollBar)`
  height: 100%;
`

const Empty = styled.div`
  font-size: ${rem(0.8)};
  width: auto;
  margin-left: ${rem(1)};
`
//endregion
