import { Chat, Guest } from '../../../api/rest'
import { Modal } from '../../common'
import ChatBody from '../../pages/CustomerChat/conversation/ChatBody'
import ProactiveBody from '../../pages/CustomerChat/conversation/ProactiveBody'
import ChatList from '../../pages/CustomerChat/list/ChatList'
import CloseChatDialog from '../../pages/CustomerChat/modals/CloseChatDialog'
import CustomizedMessagesModal from '../../pages/CustomerChat/modals/CustomizedMessagesModal'
import TransferModal from '../../pages/CustomerChat/modals/TransferModal'
import UserInfoModal from '../../pages/CustomerChat/modals/UserInfoModal'
import { Dialog } from '@mui/material'
import { changeRoute } from '../../../store/applicationState/actions'
import { getChats, getDimensions, getProactiveGuests, getTranslation } from '../../../store/selectors'
import qs from 'query-string'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { useMatch } from 'react-router-dom'
import styled from 'styled-components'
import { small } from '../../../constants'
import withAuthentication from '../../hoc/withAuthentication'

const ChatPage: React.FC = () => {
  const [chat, setChat] = useState<Chat | null>(null)
  const [guest, setGuest] = useState<Guest | null>(null)
  const [showChats, setShowChats] = useState(true)
  const [showUserInfoModal, setShowUserInfoModal] = useState(false)
  const [showCloseDialog, setShowCloseDialog] = useState(false)
  const [showTransferModal, setShowTransferModal] = useState(false)
  const [showCustomizedMessagesModal, setShowCustomizedMessagesModal] = useState(false)

  const { width } = useSelector(getDimensions)
  const { params } = useMatch('/customer-chat/:id?')!
  const translation = useSelector(getTranslation)
  const chats = useSelector(getChats)
  const proactiveGuests = useSelector(getProactiveGuests)

  const dispatch = useDispatch()

  const location = useLocation()

  useEffect(() => {
    if (params.id) {
      setShowChats(width > small.width * 1.5)
      // Se c'è una conversazione attiva o ne sto creando una non deve esserci proactive nell'url
      const { proactive } = qs.parse(location.search)
      if (proactive) {
        // è stato premuto su un utente collegato dal widget
        const gu = proactiveGuests.get(params.id)
        if (!gu) {
          dispatch(changeRoute({ current: '/customer-chat' }) as any)
        } else {
          setGuest(gu)
          setChat(null)
        }
      } else {
        // altrimenti è una normale chat
        const ch = chats.get(params.id)
        if (!ch) {
          dispatch(changeRoute({ current: '/customer-chat' }) as any)
        } else {
          setChat(ch)
          setGuest(null)
        }
      }
    } else {
      // altrimenti è sempre visibile
      setShowChats(true)
      setChat(null)
      setGuest(null)
    }
  }, [params, width, chats, proactiveGuests, location.search, dispatch])

  return (
    <Container hasList={showChats} hasConversation={width > small.width * 1.5}>
      {showChats && <ChatList />}
      {params.id && chat && (
        <ChatBody
          chat={chat}
          openUserInfoModal={() => setShowUserInfoModal(true)}
          openCloseDialog={() => setShowCloseDialog(true)}
          openTransferModal={() => setShowTransferModal(true)}
          openCustomizedMessagesModal={() => setShowCustomizedMessagesModal(true)}
        />
      )}
      {params.id && guest && <ProactiveBody guest={guest} />}
      {!params.id && width > small.width * 1.5 && <NoSelected>{translation.noConversationSelected}</NoSelected>}
      <Modal closeModal={() => setShowUserInfoModal(false)} isVisible={showUserInfoModal} style={{ maxWidth: 450 }}>
        <UserInfoModal chat={chat} guest={guest} />
      </Modal>
      <Dialog open={showCloseDialog} onClose={() => setShowCloseDialog(false)}>
        <CloseChatDialog chat={chat} close={() => setShowCloseDialog(false)} />
      </Dialog>
      <Modal closeModal={() => setShowTransferModal(false)} isVisible={showTransferModal} style={{ maxWidth: 450 }}>
        <TransferModal chat={chat} close={() => setShowTransferModal(false)} />
      </Modal>
      <Modal
        closeModal={() => setShowCustomizedMessagesModal(false)}
        isVisible={showCustomizedMessagesModal}
        style={{ width: '80%', maxWidth: 450, height: 'initial', minHeight: 100, maxHeight: 600 }}
      >
        <CustomizedMessagesModal chat={chat} close={() => setShowCustomizedMessagesModal(false)} />
      </Modal>
    </Container>
  )
}

export default withAuthentication(ChatPage)

//region Style

type ContainerProps = {
  hasList: boolean
  hasConversation: boolean
}
const Container = styled.div<ContainerProps>`
  position: relative;
  display: grid;
  width: 100%;
  height: calc(var(--height) - var(--topbar-height));
  overflow: hidden;
  outline: none;
  grid-template-columns: ${({ hasList, hasConversation }) =>
    hasList ? (hasConversation ? '300px auto' : '1fr') : '1fr'};
`

const NoSelected = styled.div`
  display: grid;
  place-self: center;
  place-content: center;
  width: 100%;
  height: 100%;
`
//endregion
