import { IFavorite } from '../../../../api/database/types'
import { User } from '../../../../api/rest'
import { rem } from '../../../../helpers/style'
import { RemoveCircleOutlineRounded } from '@mui/icons-material'
import { Grow, IconButton, Tooltip } from '@mui/material'
import { getTranslation, getUsers } from '../../../../store/selectors'
import React, { useEffect, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { ReactComponent as ReorderIcon } from '../../../../images/icons/reorder.svg'

import { Avatar, AvatarWithStatus, ContactButton, UserButton } from '../../../common'

type FavoriteProps = {
  favorite: IFavorite
  from: string
  index: number
  enableEdit?: boolean
  removeFavorite: (id: number) => void
}

export const Favorite: React.FC<FavoriteProps> = ({ favorite, index, from, enableEdit = false, removeFavorite }) => {
  const [user, setUser] = useState<User>()
  const [, setOpen] = useState(false)
  const users = useSelector(getUsers)
  const translation = useSelector(getTranslation)

  useEffect(() => {
    setOpen(false)
    if (favorite.type === 'user') {
      const userSelected = users.get(favorite.favoriteId)
      setUser(userSelected)
    } else setUser(undefined)
  }, [favorite, favorite.orderNumber, users])

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',

    // change background colour if dragging
    background: 'inherit',

    // styles we need to apply on draggables
    ...draggableStyle
  })

  return (
    <Draggable key={favorite.favoriteId} draggableId={`${favorite.favoriteId}`} index={index}>
      {(provided, snapshot) => (
        <Container
          key={favorite.favoriteId}
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          <InfoContainer>
            <Grow in={enableEdit}>
              <Drag
                {...provided.dragHandleProps}
                onMouseDown={() => {
                  setOpen(false)
                }}
              >
                <ReorderIcon width={14} height={14} fill={'rgb(112,112,112, 0.4)'} />
              </Drag>
            </Grow>
            {favorite.type === 'user' ? (
              <AvatarWithStatus
                avatarSize="min(calc(var(--topbar-height) - 10px), 37px)"
                statusSize="min(calc(var(--topbar-height) / 3), 15px)"
                fontSize="max(min(calc(var(--font-size) * 0.5), 20px), 14px)"
                letterSpacing="max(calc(var(--topbar-height) / -20),-2px)"
                user={user}
              />
            ) : (
              <Avatar
                name={favorite.firstName}
                surname={favorite.secondName}
                size="min(calc(var(--topbar-height) - 10px), 37px)"
                fontSize="max(min(calc(var(--font-size) * 0.5), 20px), 14px)"
                letterSpacing="-2px"
              />
            )}
            <Info>
              {favorite.type === 'user' ? `${favorite.secondName} ${favorite.firstName}` : `${favorite.favoriteId}`}
            </Info>
            {!enableEdit && user && user.userVoips && user.userVoips.length > 0 && (
              <UserButton key={`${user.id}${user.userVoips[0].voip.extension}-${index}`} from={from} user={user} />
            )}
            {!enableEdit && favorite.type === 'contact' && favorite.addresses && favorite.addresses.length > 0 && (
              <ContactButton from={'/phone/favorites'} contact={favorite} />
            )}
            {enableEdit && (
              <IconButton onClick={() => favorite.favoriteId && removeFavorite(favorite.favoriteId)}>
                <Tooltip title={translation?.tooltips?.delete}>
                  <RemoveCircleOutlineRounded />
                </Tooltip>
              </IconButton>
            )}
          </InfoContainer>
        </Container>
      )}
    </Draggable>
  )
}

//region Style

const Container = styled.div`
  display: grid;
  margin-left: calc(var(--font-size) * 0.3);
  margin-bottom: max(calc(var(--font-size) * 0.3), 12px);
  padding-right: 3px;
  &:hover {
    background: ${({ theme }) => theme.palette.action.hover};
  }
`

const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  grid-column-gap: ${rem(0.2)};
`
const Drag = styled.div`
  place-self: center;
  margin-right: max(calc(var(--font-size) * 0.1), 1px);
`

const Info = styled.div`
  align-self: center;
  justify-self: start;
  font-size: max(calc(var(--font-size) * 0.6), 13px);
  padding-left: 5px;
`

//endregion
