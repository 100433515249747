import CallInfoAutoAnswer from '../../../pages/Phone/Call/CallInfo/CallInfoAutoAnswer'
import CallInfoInbound from '../../../pages/Phone/Call/CallInfo/CallInfoInbound'
import { rem } from '../../../../helpers/style'
import { SvgIcon } from '@mui/material'
import { answer, hangup } from '../../../../store/phone/actions'
import { getCallInProgress, getTheme, getTranslation } from '../../../../store/selectors'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { ReactComponent as AnswerIcon } from '../../../../images/call.svg'
import { ReactComponent as HangIcon } from '../../../../images/hang.svg'
import { ActionButton } from '../../../common'

const IncomingCall: React.FC = () => {
  const call = useSelector(getCallInProgress)
  const theme = useSelector(getTheme)
  const translation = useSelector(getTranslation)

  const dispatch = useDispatch()

  return (
    <Container>
      <Info>
        {call?.type === 'inbound' && <div>{translation.incomingCall}</div>}
        {call?.type === 'callback' && <div>{translation.incomingCallBack}</div>}
        {call?.type === 'predictive' && <div>{translation.incomingPredictiveCall}</div>}
        <div
          style={{
            fontSize: rem(1),
            color: theme === 'light' ? '#000000' : '#FFFFFF'
          }}
        >
          {call?.user ? `${call.user.surname} ${call.user.name}` : call?.number || translation.unknown}
        </div>
        <div>{call?.user && `${translation.voip} ${call.number}`}</div>
        {!call?.isInternal && <CallInfoInbound />}
        {!call?.isInternal && <CallInfoAutoAnswer />}
      </Info>
      <Buttons>
        <ActionButton onClick={() => dispatch(answer() as any)}>
          <SvgIcon style={{ fontSize: rem(2) }}>
            <AnswerIcon />
          </SvgIcon>
        </ActionButton>
        <ActionButton onClick={() => dispatch(hangup() as any)}>
          <SvgIcon style={{ fontSize: rem(2) }}>
            <HangIcon />
          </SvgIcon>
        </ActionButton>
      </Buttons>
    </Container>
  )
}

export default IncomingCall

//region Style

const Container = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-rows: [info] 1fr [buttons] 1fr;
  width: 100%;
  height: 100%;
  padding: 10px;
  max-height: 450px;
`

const Info = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  place-content: center;
  margin-top: 15px;
  text-align: center;
  font-size: ${rem(0.9)};
  height: 150px;
  color: #0a8da9;
  padding: ${rem(0.5)} ${rem(1.5)};
`

const Buttons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-content: end end;
  margin-bottom: 10px;
`

//endregion
