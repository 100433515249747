import CustomerChat from '../../../../api/customerChat'
import { Chat, ChatEvent, CustomizedMessage } from '../../../../api/rest'
import { rem } from '../../../../helpers/style'
import { useRest } from '../../../../hooks'
import { ExpandLessRounded, ExpandMoreRounded, SendRounded } from '@mui/icons-material'
import { Card, CircularProgress, Collapse, IconButton, Typography } from '@mui/material'
import { addEvent } from '../../../../store/customerChats/actions'
import { getMe, getTranslation } from '../../../../store/selectors'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

type Props = {
  close: Function
  chat: Chat | null
}

const CustomizedMessagesModal: React.FC<Props> = ({ close, chat }) => {
  const [expanded, setExpanded] = useState<number | null>(null)

  const me = useSelector(getMe)
  const translation = useSelector(getTranslation)

  const dispatch = useDispatch()

  const { results: customizedMessages, loading } = useRest<CustomizedMessage>('/customized-messages', {
    withCache: true
  })

  const sendMessage = (text: string, timer: number) => {
    if (!chat) return
    const now = DateTime.now()
    const event: ChatEvent = {
      tempID: now.toMillis(),
      date: now.toSQL(),
      type: 'message',
      user: CustomerChat.getUserForChat(me),
      text
    }
    setTimeout(() => {
      CustomerChat.sendEvent({ ...event, chat: chat.id })
      dispatch(addEvent({ chat: chat.id, event }))
    }, timer * 1000)
    close()
  }

  return (
    <Container>
      {loading && <CircularProgress color="primary" />}
      {customizedMessages.length ? (
        customizedMessages.map((cm) => (
          <StyledCard key={cm.id}>
            <Header>
              <IconButton onClick={() => sendMessage(cm.fullText, cm.timeout || 0)}>
                <SendRounded />
              </IconButton>
              <div
                style={{
                  maxWidth: '100%',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {cm.shortDescription} {cm.timeout ? `(${cm.timeout} sec)` : null}
              </div>
              <IconButton
                onClick={() => {
                  if (expanded === cm.id) setExpanded(null)
                  else setExpanded(cm.id)
                }}
              >
                {expanded === cm.id ? <ExpandLessRounded /> : <ExpandMoreRounded />}
              </IconButton>
            </Header>

            <Collapse in={expanded === cm.id}>
              <Typography style={{ gridArea: 'expand', padding: rem(0.3), width: '100%' }}>{cm.fullText}</Typography>
            </Collapse>
          </StyledCard>
        ))
      ) : (
        <p>{translation.customerChatSystemEvents.noCustomMessages}</p>
      )}
    </Container>
  )
}

export default CustomizedMessagesModal

//region style

const Container = styled(ScrollBar)`
  display: grid;
  place-self: center;
  place-items: center;
  grid-template-rows: 1fr;
  margin-top: 10px;
  height: fit-content;
  width: 100%;
  max-width: 400px;
  grid-row-gap: ${rem(0.7)};
  padding: ${rem(0.2)};
`

const StyledCard = styled(Card)`
  width: 100%;
  display: grid;
`

const Header = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  place-items: center start;
`
//endregion
