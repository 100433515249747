import { IconButton, Tooltip } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import styled from 'styled-components'

type PhoneButtonProps = {
  onClick: () => void
  label?: string
  disabled: boolean
  isActive?: boolean
  icon: React.ReactElement
  activeIcon: React.ReactElement
  size?: 'small' | 'medium'
  tooltip?: string
}

export const PhoneButton: React.FC<PhoneButtonProps> = (props) => {
  const { onClick, label, disabled, isActive, icon, activeIcon, size = 'medium', tooltip = '' } = props

  const theme = useTheme()

  const enabled = () => {
    if (disabled === undefined || disabled === null) return true
    else return !disabled
  }

  return (
    <Tooltip title={tooltip}>
      <Container label={label} disabled={!enabled()}>
        <IconButton
          disabled={!enabled()}
          onClick={() => enabled() && onClick()}
          style={{
            background: isActive ? theme.palette.action.disabled : ''
          }}
          size={size}
        >
          {isActive ? activeIcon : icon}
        </IconButton>
        {label && <Label disabled={disabled}>{label}</Label>}
      </Container>
    </Tooltip>
  )
}

interface ContainerProps {
  label?: string
  disabled?: boolean
}

const Container = styled.div<ContainerProps>`
  place-self: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  ${({ label }) => label && 'display: grid; grid-template-rows: 3fr 1fr; '}
`

interface LabelProps {
  disabled?: boolean
}

const Label = styled.div<LabelProps>`
  place-self: center;
  color: ${({ theme, disabled }) => (disabled ? theme.palette.text?.disabled : theme.palette.text?.primary)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-size: calc(var(--font-size) * 0.6);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50px;
`
