import axios, { AxiosResponse } from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { coreApi } from '../configuration'
import log from '../helpers/logger'
import { formatValues, makeHeader } from './helpers'
import cache from './helpers/CacheManager'
import { AnyObject } from './types'

interface Options<F> {
  lazy?: boolean
  initialFilters?: F
  withCache?: boolean
}

interface Parameters<F> {
  filters?: F
  id?: number | string
  afterPath?: string
}

interface CallsOptions {
  afterPath?: string
  dontThrow?: boolean
}

interface HookObject<E = AnyObject, F = AnyObject> {
  result: E
  results: E[]
  setResult: any
  setResults: any
  get: (args?: Parameters<F>) => Promise<E[]>
  getById: (args?: Parameters<F>) => Promise<E>
  post: (values?: E, opts?: CallsOptions) => Promise<AxiosResponse>
  put: (id: any, values?: E, opts?: CallsOptions) => Promise<AxiosResponse>
  remove: (id: any, opts?: CallsOptions) => Promise<AxiosResponse>
  loading: boolean
  error: string
}

/**
 * Hook per la gestione delle chiamate rest autenticate verso il server
 * @template E tipo EntityObject, dice come è fatta l'entità
 * @template F tipo FilterObject, dice come sono fatti i possibili filtri di ricerca
 * @param url {string} path relativo a cui mandare le richieste
 * @param options {Options} oggetto per la configurazione dell'hook
 * @param options.lazy {boolean} crea l'hook senza chiamare la get dell'url
 * @param options.initialFilters {Object} filtri iniziali per la get
 * @returns { HookObject<E, F>}
 */
function useRest<E = AnyObject, F = AnyObject>(url: string, options: Options<F> = {}): HookObject<E, F> {
  const { lazy, initialFilters, withCache } = options
  const [result, setResult] = useState<E>({} as E)
  const [results, setResults] = useState<E[]>([] as E[])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  //region METHODS
  const get = useCallback(
    ({ filters, afterPath }: Parameters<F> = {}): Promise<E[]> => {
      let completeUrl = `${coreApi}/${url}`
      if (afterPath) completeUrl += afterPath
      if (filters) {
        completeUrl += '?'
        const arrayFilters: string[] = []
        Object.entries(formatValues(filters)).forEach(([key, value]) => {
          if (value === null || value === undefined) return
          arrayFilters.push(`${key}=${encodeURIComponent(value.toString())}`)
          if (arrayFilters.length > 0) {
            completeUrl += '&'
            completeUrl += arrayFilters.join('&')
          }
        })
      } else {
        // Se è un get all provo ad accedere alla cache
        if (withCache) {
          const fromCache = cache.getEntity<E>(afterPath ? url + afterPath : url)
          if (fromCache) {
            log.silly(`GET FROM CACHE: ${completeUrl}`)
            setResults(fromCache)
            return new Promise((resolve) => resolve(fromCache))
          }
        }
      }
      log.silly(`GET: ${completeUrl}`)
      setLoading(true)
      return axios
        .get(completeUrl, makeHeader())
        .then(({ data }) => {
          setResults(data.payload)
          if (withCache) {
            cache.setEntity(afterPath ? url + afterPath : url, data.payload)
          }
          return data.payload
        })
        .catch((error) => {
          setLoading(false)
          setError(error.message)
        })
        .finally(() => setLoading(false))
    },
    [url, withCache]
  )

  const getById = useCallback(
    ({ filters, id, afterPath }: Parameters<F> = {}): Promise<E> => {
      let completeUrl = `${coreApi}/${url}`
      if (id) completeUrl += `/${id}`
      if (afterPath) completeUrl += afterPath
      if (filters) {
        completeUrl += '?'
        const arrayFilters: string[] = []
        Object.entries(formatValues(filters)).forEach(([key, value]) => {
          if (value === null || value === undefined) return
          arrayFilters.push(`${key}=${encodeURIComponent(value.toString())}`)
          if (arrayFilters.length > 0) {
            completeUrl += '&'
            completeUrl += arrayFilters.join('&')
          }
        })
      }
      log.silly(`GET: ${completeUrl}`)
      setLoading(true)
      return axios
        .get(completeUrl, makeHeader())
        .then(({ data }) => {
          setResult(data.payload)
          return data.payload
        })
        .catch((error) => {
          setLoading(false)
          setError(error.message)
        })
        .finally(() => setLoading(false))
    },
    [url]
  )

  const post = (values?: E, opts: CallsOptions = {}): Promise<AxiosResponse> => {
    let completeUrl = `${coreApi}/${url}`
    if (opts.afterPath) completeUrl += opts.afterPath
    setLoading(true)
    log.silly(`POST: ${completeUrl}`)
    return axios
      .post(completeUrl, values ? formatValues(values) : null, makeHeader())
      .then(({ data }) => data.payload)
      .catch((error) => {
        setLoading(false)
        setError(error.message)
        throw error
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const put = (id: any, values?: E, opts: CallsOptions = {}): Promise<AxiosResponse> => {
    let completeUrl = `${coreApi}/${url}`
    if (id) completeUrl += `/${id}`
    if (opts.afterPath) completeUrl += opts.afterPath
    setLoading(true)
    log.silly(`PUT: ${completeUrl}`)
    return axios
      .put(completeUrl, values ? formatValues(values) : null, makeHeader())
      .then(({ data }) => data.payload)
      .catch((error) => {
        setLoading(false)
        setError(error.message)
        throw error
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const remove = (id: any, opts: CallsOptions = {}): Promise<AxiosResponse> => {
    let completeUrl = `${coreApi}/${url}`
    if (id) completeUrl += `/${id}`
    if (opts.afterPath) completeUrl += opts.afterPath
    setLoading(true)
    log.silly(`DELETE: ${completeUrl}`)
    return axios
      .delete(completeUrl, makeHeader())
      .then(({ data }) => data.payload)
      .catch((error) => {
        setLoading(false)
        setError(error.message)
        throw error
      })
      .finally(() => {
        setLoading(false)
      })
  }
  //endregion

  useEffect(() => {
    if (lazy) return
    if (withCache) {
      const fromCache = cache.getEntity<E>(url)
      if (fromCache) {
        log.silly(`GET FROM CACHE: ${url}`, fromCache)
        setResults(fromCache)
        return
      }
    }
    initialFilters ? get({ filters: initialFilters }) : get()
  }, [lazy, withCache, initialFilters, url, get])

  return {
    get,
    getById,
    post,
    put,
    remove,
    result,
    results,
    setResult,
    setResults,
    loading,
    error
  }
}

export default useRest
