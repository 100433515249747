import isElectron from './isElectron'
import axios from 'axios'
import { translations } from '../configuration'

// richiede la traduzione a un url se non è presente nel localstorage la traduzione richiesta
export const getTranslation = async (language: string) => {
  if (isElectron()) {
    return require(`../../public/translations/${language}.json`)
  } else {
    const { data } = await axios.get(`${translations}/${language}.json`)
    return data
  }
}
