import { Chat, Guest, UserForChat } from '../../api/rest'
import { Videoroom } from '@bytewise/janus'
import { RootState } from '../types'
import { AnyAction } from 'redux'
import { ThunkAction } from 'redux-thunk'

export const SET_CHATS = 'SET_CHATS'
export const SET_BOOKED_CHATS = 'SET_BOOKED_CHATS'
export const SET_SNEAKPEEKS = 'SET_SNEAK_PEEKS'
export const SET_UNREAD_EVENTS = 'SET_UNREAD_EVENTS'
export const SET_MEDIACHAT = 'SET_MEDIA_CHAT'
export const MEDIACHAT_UPDATE = 'MEDIACHAT_UPDATE'
export const ADD_PUBLISHER_SESSION = 'ADD_CHAT_PUBLISHER_SESSION'
export const SET_PRIVATE_ID = 'SET_CHAT_PRIVATE_ID'
export const SET_SUBSCRIBER_SESSIONS = 'SET_CHAT_SUBSCRIBER_SESSIONS'
export const SET_LOCAL_STREAM = 'SET_CHAT_LOCAL_STREAM'
export const SET_REMOTE_STREAMS = 'SET_CHAT_REMOTE_STREAMS'
export const SET_GUEST_STREAM = 'SET_GUEST_STREAM'
export const PROACTIVE_GUESTS_LIST = 'PROACTIVE_GUESTS_LIST'

export type MediaType = 'audio' | 'video' | 'cobrowse'

export type MediaChatState = {
  pin?: string
  room?: number
  sender?: UserForChat
  members?: Array<UserForChat | Guest>
  publisherSession?: Videoroom
  subscriberSessions?: Map<number, Videoroom>
  localStream?: MediaStream
  remoteStreams?: Map<number, MediaStream>
  private_id?: number
  startTime?: number
  chatId: string
  type: MediaType
  guestStream?: MediaStream
}

export interface ChatsState {
  chats: Map<string, Chat>
  proactiveGuests: Map<string, Guest>
  bookedChats: Map<string, Chat>
  unreadEvents: number
  unreadEventsByChat: Map<string, number>
  sneakpeeks: Map<string, string>
  mediachat: MediaChatState | null
}

export interface SetChats {
  type: typeof SET_CHATS
  payload: Map<string, Chat>
}

export interface BookChat {
  type: typeof SET_BOOKED_CHATS
  payload: Map<string, Chat>
}

export interface SetSneakpeeks {
  type: typeof SET_SNEAKPEEKS
  payload: Map<string, string>
}

export interface SetUnreadEvents {
  type: typeof SET_UNREAD_EVENTS
  payload: {
    unreadEvents: number
    unreadEventsByChat: Map<string, number>
  }
}

export interface SetMediaChat {
  type: typeof SET_MEDIACHAT
  payload: MediaChatState | null
}

export interface AddPublisherSession {
  type: typeof ADD_PUBLISHER_SESSION
  payload: Videoroom
}

export interface SetPrivateId {
  type: typeof SET_PRIVATE_ID
  payload: number
}

export interface SetSubscriberSessions {
  type: typeof SET_SUBSCRIBER_SESSIONS
  payload: Map<number, Videoroom>
}

export interface SetLocalStream {
  type: typeof SET_LOCAL_STREAM
  payload: MediaStream
}

export interface SetRemoteStreams {
  type: typeof SET_REMOTE_STREAMS
  payload: Map<number, MediaStream>
}

export interface MediaChatUpdate {
  type: typeof MEDIACHAT_UPDATE
  payload: Partial<MediaChatState>
}

export interface SetGuestStream {
  type: typeof SET_GUEST_STREAM
  payload: MediaStream
}

export interface SetProactiveGuests {
  type: typeof PROACTIVE_GUESTS_LIST
  payload: Map<string, Guest>
}

export type UpdateMediaChatAction = ThunkAction<void, RootState, null, SetMediaChat>
export type AddRemovePublisherSessionThunkAction = ThunkAction<void, RootState, null, SetSubscriberSessions>
export type AddRemoveRemoteStreamsThunkAction = ThunkAction<void, RootState, null, SetRemoteStreams>
export type CanDispatchAnyAction = ThunkAction<void, RootState, null, AnyAction>

export type ChatsActionTypes =
  | SetChats
  | BookChat
  | SetSneakpeeks
  | SetUnreadEvents
  | SetMediaChat
  | AddPublisherSession
  | SetSubscriberSessions
  | SetLocalStream
  | SetRemoteStreams
  | MediaChatUpdate
  | SetPrivateId
  | SetGuestStream
  | SetProactiveGuests
