import { ApiWithRedux } from '../ApiWithRedux'
import { LanguageCode } from '../rest'
import logger from '../../helpers/logger'
import { AnyObject } from '../../hooks/types'
import {
  changeRoute,
  changeRouteFromExternal,
  executeActionFromExternal,
  setDimensions
} from '../../store/applicationState/actions'
import { changeStatus } from '../../store/phone/actions'
import { Call, StatusChangePaylaod } from '../../store/phone/types'
import throttle from 'lodash/throttle'
import isElectron from '../../helpers/isElectron'
import { answer, hangup } from '../../store/phone/actions'

const electron = isElectron() ? window.require('electron') : null

/**
 * Classe che mette in comunicazione la parte react con la parte electron (renderer e main process)
 */
class ElectronProxy extends ApiWithRedux {
  token: string | null

  constructor() {
    super()
    this.token = null

    electron && this.addListeners()
  }

  addListeners() {
    electron?.ipcRenderer.on('change-state', (_: Event, stateOrPause: StatusChangePaylaod) => {
      this.handleChangeState(stateOrPause)
    })
    electron?.ipcRenderer.on('change-route', (_: Event, route: string) => {
      console.log('*** CHANGE ROUTE ', route)
      this.dispatch(changeRoute({ current: route }))
    })
    electron?.ipcRenderer.on(
      'change-route-from-external',
      (_: Event, payload: { path: string; querystring?: AnyObject }) => {
        this.dispatch(changeRouteFromExternal(payload))
      }
    )
    electron?.ipcRenderer.on(
      'execute-action-from-external',
      (_: Event, payload: { action: string; querystring: { token: string; action: string; [key: string]: any } }) => {
        if (!payload.querystring.token) {
          logger.warn('Could not execute action because there is no token')
          return
        }
        if (!payload.action) {
          logger.warn('Could not execute action because there is no token')
          return
        }
        this.dispatch(executeActionFromExternal(payload.action, payload.querystring))
      }
    )
    electron?.ipcRenderer.on('size-change-end', () => {
      this.dispatch(
        setDimensions({
          width: window.innerWidth,
          height: window.innerHeight
        })
      )
    })
    electron?.ipcRenderer.on(
      'size-change',
      throttle(() => {
        this.dispatch(
          setDimensions({
            width: window.innerWidth,
            height: window.innerHeight
          })
        )
      }, 200)
    )

    // Evento lanciato al click sulla notifica
    electron?.ipcRenderer.on('click-notify', () => {
      this.dispatch(changeRoute({ current: '/phone/incoming-call' }))
    })
    // Evento lanciato quando l'utente vuole rispondere dalla notifica
    electron?.ipcRenderer.on('answer-from-notification', () => {
      this.dispatch(answer())
    })
    // Evento lanciato quando l'utente vuole rifiutare la chiamata dalla notifica
    electron?.ipcRenderer.on('decline-from-notification', () => {
      this.dispatch(hangup())
    })
  }

  changeState(state: StatusChangePaylaod) {
    if (!electron) return
    electron.ipcRenderer.send('change-state', state)
  }

  changeLanguage(language: LanguageCode) {
    if (!electron) return
    electron.ipcRenderer.send('change-language', language)
  }

  handleChangeState(stateOrPause: StatusChangePaylaod): void {
    if (!electron) return
    this.dispatch(changeStatus(stateOrPause))
  }

  incomingCall(call: Call) {
    if (!electron) return
    electron.ipcRenderer.send('incoming-call', JSON.parse(JSON.stringify(call)))
  }

  acceptedCall() {
    if (!electron) return
    electron.ipcRenderer.send('call-start')
  }

  callEnd() {
    if (!electron) return
    electron.ipcRenderer.send('call-end')
  }

  missedCall(call: Call) {
    if (!electron) return
    electron.ipcRenderer.send('missed-call', JSON.parse(JSON.stringify(call)))
  }

  notifyDisconnection() {
    if (!electron) return
    electron.ipcRenderer.send('disconnect')
  }

  minifyClient(minimize: boolean) {
    if (!electron) return

    const width = localStorage.getItem('@occlient/appWidth')
    const height = localStorage.getItem('@occlient/appHeight')
    let oldDimension = null

    if (width && height) {
      oldDimension = {
        height: height && parseInt(height),
        width: width && parseInt(width)
      }
    }
    electron.ipcRenderer.send('minimize-client', minimize, oldDimension)
  }

  resizeWindow(width: number, height: number) {
    if (!electron) return
    electron.ipcRenderer.send('do-resize', { width, height })
  }

  setBadge(count: number, type: 'phone' | 'messenger' | 'chat') {
    if (!electron) return
    const lostCalls = this.state.phone.lostCalls
    const unreadMessages = this.state.messenger.unreadMessages
    const unreadEvents = this.state.customerChats.unreadEvents

    let total = count
    switch (type) {
      case 'phone':
        total += unreadMessages + unreadEvents
        break
      case 'messenger':
        total += lostCalls + unreadEvents
        break
      case 'chat':
        total += lostCalls + unreadMessages
    }
    electron.ipcRenderer.send('set-badge', total)
  }
}

const electronProxy = new ElectronProxy()
export default electronProxy
