import { DateTime } from 'luxon'

const MAX_WIDTH = 4096
const MIN_WIDTH = 250
const MAX_HEIGHT = 2160
const MIN_HEIGHT = 250
const MIN_FONT_SIZE = 16
const MAX_FONT_SIZE = 22

/**
 * Calcola il fontsize di partenza in base alle dimensioni del client con la formula:
 * minFontSize+(maxFontSize-minFontSize)*((width-minWidth)/(maxWidth-minWidth)+(height-minheight)/(maxHeight-minHeight))
 * @param width
 * @param height
 */
export const getFontSize = (width: number, height: number) => {
  const fontSizeDiff = MAX_FONT_SIZE - MIN_FONT_SIZE
  const widthRatio = Math.abs(width - MIN_WIDTH) / (MAX_WIDTH - MIN_WIDTH)
  const heightRatio = Math.abs(height - MIN_HEIGHT) / (MAX_HEIGHT - MIN_HEIGHT)
  return parseInt((MIN_FONT_SIZE + fontSizeDiff * (widthRatio + heightRatio)).toFixed(2))
}

export const stripHtml = (html: string) => {
  let tmp = document.createElement('DIV')
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ''
}

/**
 * Ritorna la data formattata in modo standard in base anche al tempo trascorso
 * @param time può essere un millis o una stringa, in tal caso va specificato il type
 * @param type gestiamo stringhe di tipo "sql" oppure "iso" (con T e Z)
 */
export const getDateInfo = (time: number | string, type: 'sql' | 'iso' = 'sql') => {
  const now = DateTime.now()
  let callTime: DateTime | null = null
  if (typeof time === 'number') {
    callTime = DateTime.fromMillis(time)
  } else {
    if (type === 'iso') callTime = DateTime.fromISO(time)
    if (type === 'sql') callTime = DateTime.fromSQL(time)
  }
  if (!callTime) return ''
  if (now.hasSame(callTime, 'day')) {
    return callTime.toLocaleString(DateTime.TIME_SIMPLE)
  } else if (now.hasSame(callTime, 'week')) {
    return callTime.toLocaleString({ weekday: 'short' })
  }
  return `${callTime.toLocaleString({ day: '2-digit', month: 'short' })}`
}

type TypesFromLS = number | string | Object
export const getFromLSOrDefault = (key: string, def: TypesFromLS): TypesFromLS => {
  const fromLS = localStorage.getItem(key)
  if (!fromLS || fromLS === 'undefined' || fromLS === 'null') {
    return def
  } else {
    if (typeof def === 'number') return parseInt(fromLS)
    if (typeof def === 'string') return fromLS
    if (typeof def === 'object') return JSON.parse(fromLS)

    return def
  }
}
