import {Phone} from './api'
import Error from './components/pages/Error/Error'
import Info from './components/pages/Info/Info'
import OperatorQueues from './components/pages/OperatorQueues/OperatorQueues'
import OTP from './components/pages/Login/OTP'
import {createTheme, ThemeProvider as MuiThemeProvider} from '@mui/material/styles'
import React, {useState} from 'react'

import 'react-perfect-scrollbar/dist/css/styles.css'
import {Route, Routes} from 'react-router-dom'
import {DefaultTheme, ThemeProvider} from 'styled-components'
import pkg from '../package.json'
import './App.css'
import AuthManager from './components/managers/AuthManager'
import DevicesManager from './components/managers/DevicesManager'
import CustomerChat from './components/pages/CustomerChat/ChatEntryPoint'
import SpyCustomerChat from './components/pages/CustomerChat/SpyChatEntryPoint'
import Login from './components/pages/Login/Login'
import Messenger from './components/pages/Messenger/MessengerEntryPoint'
import NotFound from './components/pages/NotFound/NotFound'
import PhonePage from './components/pages/Phone/Phone'
import Settings from './components/pages/Settings/SettingsEntryPoint'
import Template from './components/template/Template'
import history from './constants/history'
import log from './helpers/logger'
import AppStateManager from './components/managers/AppStateManager'
import themes from './themes'
import {getFontSize} from './helpers/common'
import {smaller} from './constants'
import Loading from './components/pages/Loading'
import {CustomRouter} from "./components/common";

log.oc('•', `OpenCommunication Client V.${pkg.version}`)

// Si assicura che al refresh o alla chiusura della pagina vengano deregistrati gli interni
window.addEventListener('beforeunload', () => {
  Phone.unregisterUserVoips()
})

const App: React.FC = () => {
  const theme = sessionStorage.getItem('@occlient/theme')
  const [currentTheme, setCurrentTheme] = useState<DefaultTheme>(
    theme === 'light'
      ? createTheme(themes.light(getFontSize(smaller.width, smaller.height)))
      : createTheme(themes.dark(getFontSize(smaller.width, smaller.height)))
  )

  return (
    <CustomRouter history={history}>
      <AuthManager/>
      <AppStateManager setCurrentTheme={setCurrentTheme}/>
      <DevicesManager/>
      <MuiThemeProvider theme={currentTheme}>
        <ThemeProvider theme={currentTheme}>
          <Routes>
            <Route path="/" element={<Template/>}>
              <Route path="login/otp" element={<OTP/>}/>
              <Route path="login" element={<Login/>}/>
              <Route path="phone/*" element={<PhonePage/>}/>
              <Route path="messenger/*" element={<Messenger/>}/>
              <Route path="customer-chat/:id?" element={<CustomerChat/>}/>
              <Route path="spy-customer-chat/:id?" element={<SpyCustomerChat/>}/>
              <Route path="settings" element={<Settings/>}/>
              <Route path="operator-queues" element={<OperatorQueues/>}/>
              <Route path="info" element={<Info/>}/>
              <Route path="error" element={<Error/>}/>
              <Route path="loading" element={<Loading/>}/>
              <Route path="*" element={<NotFound/>}/>
            </Route>
          </Routes>
        </ThemeProvider>
      </MuiThemeProvider>
    </CustomRouter>
  )
}

export default App
