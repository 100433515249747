import { ElectronProxy } from '../../../../api'
import { rem } from '../../../../helpers/style'
import { DeleteForeverRounded, HistoryRounded } from '@mui/icons-material'
import { resetLostCalls, setRegistry } from '../../../../store/phone/actions'
import { getDimensions, getLostCalls, getRegistry, getTranslation } from '../../../../store/selectors'
import React, { useCallback, useEffect, useState } from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { ActionButton, Modal, RegistryCall, Select, Title } from '../../../common'
import { useRest } from '../../../../hooks'
import { SET_ME } from '../../../../store/login/types'
import logger from '../../../../helpers/logger'
import { Button, Tooltip } from '@mui/material'

interface SelectValue {
  value: string
  label: string
}

const Registry: React.FC = () => {
  const [type, setType] = useState<string>('all')
  const translation = useSelector(getTranslation)
  const registry = useSelector(getRegistry)
  const { fontSize } = useSelector(getDimensions)
  const [modalOpen, setModalOpen] = useState(false)

  const lostCalls = useSelector(getLostCalls)

  const dispatch = useDispatch()

  const { post: updateCallRegistryStart } = useRest('my-registry', { lazy: true })

  useEffect(() => {
    // Questo effect viene richiamato anche quando non hai il client in focus, quindi fa sparire i badge di chiamate
    // perse se lo lasci sulla pagina registry. Questo controllo evita la perdita di badge delle chiamate perse in
    // questo caso
    if (!document.hasFocus()) return
    dispatch(resetLostCalls())
    ElectronProxy.setBadge(0, 'phone')
  }, [dispatch])

  const clear = useCallback(() => {
    updateCallRegistryStart({})
      .then((payload) => {
        dispatch(setRegistry([]))
        dispatch({ type: SET_ME, payload })
      })
      .catch((e) => logger.error(e))
  }, [dispatch, updateCallRegistryStart])

  return (
    <Container
      onMouseEnter={() => {
        // Quando si torna sulla pagina se non si era in focus l'unico modo per resettare le chiamate è
        // agganciarsi ad un evento sul mouse
        if (lostCalls) {
          dispatch(resetLostCalls())
          ElectronProxy.setBadge(0, 'phone')
        }
      }}
    >
      {modalOpen && (
        <Modal height={'min-content'} closeModal={() => setModalOpen(false)} isVisible={modalOpen}>
          <div style={{ padding: '1rem 0.6rem' }}>
            <strong style={{ textAlign: 'center' }}>{translation.registryDeletionConfirmation}</strong>
            <div style={{ marginTop: '2rem', display: 'flex', justifyContent: 'space-between' }}>
              <Button
                color="error"
                size="small"
                onClick={() => {
                  clear()
                  setModalOpen(false)
                }}
              >
                {translation.yes}
              </Button>
              <Button
                size="small"
                onClick={() => {
                  setModalOpen(false)
                }}
              >
                {translation.no}
              </Button>
            </div>
          </div>
        </Modal>
      )}
      <TitleContainer>
        <Title label={translation.registry} icon={<HistoryRounded />} style={{ padding: '3px 5px' }} />
        <SelectContainer>
          <Select<SelectValue>
            selected={{ value: type, label: '' }}
            options={[
              { value: 'all', label: translation.all },
              { value: 'in', label: translation.inbound },
              { value: 'out', label: translation.outbound }
            ]}
            onChange={(t) => {
              t && setType(t.value)
            }}
            getLabel={(t) => t.label}
            getValue={(t) => t.value}
            required
          />
        </SelectContainer>
        <ActionButton
          size="small"
          disabled={type !== 'all' || registry.length === 0}
          onClick={() => {
            setModalOpen(true)
          }}
        >
          <Tooltip title={translation?.tooltips?.delete}>
            <DeleteForeverRounded />
          </Tooltip>
        </ActionButton>
      </TitleContainer>
      <ScrollBar>
        {registry && registry.length > 0 ? (
          registry
            .sort((r, r2) => {
              if (r2) return r2.timestamp - r.timestamp
              return 1
            })
            .filter((call) => {
              return type === 'all' || call.type === type
            })
            .map((r) => <RegistryCall key={r.timestamp} registryCall={r} from={`/phone/history`} />)
        ) : (
          <div style={{ display: 'grid', textAlign: 'center', fontSize: rem(1.1), width: '100%' }}>
            {translation.registryNoEntry}
          </div>
        )}
        <div style={{ height: fontSize * 2 + 8 }}></div>
      </ScrollBar>
    </Container>
  )
}

export default Registry

//region Style

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  overflow: hidden;
  padding-top: ${rem(0.5)};
`

const TitleContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  padding: 0 3px;
`

const SelectContainer = styled.div`
  place-self: center;
  width: 100%;
`

//endregion
