import { User, VoipType } from '../../../../api/rest'
import { rem } from '../../../../helpers/style'
import { useRest } from '../../../../hooks'
import { ArrowBackRounded, HistoryRounded, PhoneRounded, StarBorderRounded, StarRounded } from '@mui/icons-material'
import { goBack, requestCall } from '../../../../store/actions'
import { getCanCall, getDimensions, getMe, getPath, getTranslation, getUsers } from '../../../../store/selectors'
import React, { useCallback, useEffect, useState } from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import { useDispatch, useSelector } from 'react-redux'
import { useMatch } from 'react-router-dom'
import styled from 'styled-components'
import { ActionButton, Avatar, AvatarWithStatus, RegistryCall, Title, UserButton } from '../../../common'
import logger from '../../../../helpers/logger'
import { SET_ME } from '../../../../store/login/types'
import {getRegistry} from '../../../../helpers/registry'
import {IRegistryCall} from '../../../../api/database/types'

interface IContact {
  firstName: string
  secondName?: string
  favorite?: boolean
  address?: {
    name: string
    number: string
    stateId?: number
  }[]
}

const ContactInfo: React.FC = () => {
  const [contactInfo, setContactInfo] = useState<IContact>()
  const [favorite, setFavorite] = useState<boolean>(false)

  const users = useSelector(getUsers)
  const me = useSelector(getMe)
  const { previous, current } = useSelector(getPath)
  const translation = useSelector(getTranslation)
  const { fontSize } = useSelector(getDimensions)
  const registry = getRegistry()
  const path = useSelector(getPath)

  const { post: addFavorites, remove: removeFavorite } = useRest<{ favoritesIds: number[] }>('my-favorites', {
    lazy: true
  })
  const match = useMatch(`/phone/address-book/:type/:id/:from?`)
  const canCall = useSelector(getCanCall)

  const dispatch = useDispatch()
  const { id, type, from } = match?.params!

  useEffect(() => {
    let contact: IContact
    if (id && type) {
      if (type === 'int') {
        // se la chiamata proviene da un numero di oc recupero l'utente
        const user = users.get(parseInt(id))
        //chiedo al db se l'utente è gia un preferito
        const alreadyFavorite = me.favorites.findIndex((f) => f.favoriteId === parseInt(id))
        setFavorite(alreadyFavorite >= 0)
        //genero le informazioni utili
        contact = {
          firstName: user ? user.name : 'unknown',
          secondName: user ? user.surname : 'unknown',
          address:
            user &&
            user.userVoips.map((v) => {
              const type = v.type as VoipType
              return {
                name: 'name' in type ? type.name : v.voip.pbx.name,
                number: String(v.voip.extension),
                stateId: v.voip.state?.id
              }
            })
        }
      } else {
        //chiamata proviene da un numero esterno
        //verifico se presente gia tra i preferiti
        const alreadyFavorite = me.favorites.findIndex((f) => f.favoriteId === parseInt(id))
        setFavorite(alreadyFavorite >= 0)
        //genero le informazioni utili
        contact = {
          firstName: id,
          address: [
            {
              name: id,
              number: id
            }
          ]
        }
      }
      setContactInfo(contact)
    }
  }, [users, me.favorites, id, type])

  const goBackWithDefaultRoute = (): void => {
    let previousPath = previous
    if (from) {
      switch (from) {
        case 'registry':
          previousPath = '/phone/history'
          break
        case 'address-book':
          previousPath = '/phone/address-book'
          break
        case 'home':
          previousPath = '/phone'
          break
      }
    }
    dispatch(goBack(previousPath) as any)
  }

  const changeFavorite = useCallback(() => {
    if (!favorite) {
      addFavorites({ favoritesIds: [parseInt(id!)] })
        .then((payload) => {
          dispatch({ type: SET_ME, payload })
        })
        .catch((e) => logger.error(e))
      return true
    } else {
      removeFavorite(id)
        .then((payload) => {
          dispatch({ type: SET_ME, payload })
        })
        .catch((e) => logger.error(e))
      return false
    }
  }, [dispatch, addFavorites, removeFavorite, favorite, id])

  return (
    <Container>
      <InfoContainer>
        <GoBack>
          <ActionButton onClick={() => goBackWithDefaultRoute()}>
            <ArrowBackRounded />
          </ActionButton>
        </GoBack>
        <ProfileContainer>
          {type === 'int' ? (
            <AvatarWithStatus
              avatarSize={rem(3)}
              statusSize={`min(${rem(1)}, 30px)`}
              fontSize={`${fontSize * 1.5}px`}
              letterSpacing={`-${fontSize * 0.2}px`}
              user={id ? users.get(parseInt(id)) : undefined}
              style={{ placeSelf: 'center' }}
            />
          ) : (
            <Avatar
              size={rem(3)}
              fontSize={rem(1.5)}
              letterSpacing={`-${fontSize * 0.2}px`}
              surname={'registered'}
              name={'not'}
              style={{ placeSelf: 'center' }}
            />
          )}
          <Name>{`${contactInfo?.secondName || ''} ${contactInfo?.firstName || ''}`}</Name>
          {type === 'int' && (
            <UserButton
              from={current}
              user={users.get(parseInt(id as string)) as User}
              buttons={{ phone: false, video: true, chat: true }}
            />
          )}
        </ProfileContainer>
        <Favorite>
          <ActionButton onClick={() => changeFavorite()}>
            {favorite ? <StarRounded /> : <StarBorderRounded />}
          </ActionButton>
        </Favorite>
      </InfoContainer>
      <AddressList>
        {contactInfo?.address?.map((a) => (
          <AddressItem
            name={type === 'int' ? `${a.name} (${a.number})` : a.name}
            key={type === 'int' ? `${a.name} (${a.number})` : a.name}
          >
            <ActionButton
              disabled={!canCall || (!!a.stateId && a.stateId !== 0)}
              onClick={() => canCall && dispatch(requestCall({ number: a.number, from: path.previous }) as any)}
              size="small"
            >
              <PhoneRounded />
            </ActionButton>
          </AddressItem>
        ))}
      </AddressList>
      <RegistrySection>
        <Title
          label={translation.registry}
          labelStyle={{ fontSize: rem(1), fontWeight: 500 }}
          icon={<HistoryRounded />}
          style={{ padding: '3px 5px' }}
        >
          <HistoryRounded style={{ placeSelf: 'center' }} />
        </Title>
        <RegistryList>
          {registry &&
            registry
              .filter((call: IRegistryCall) => {
                return id && call.userId === parseInt(id)
              })
              .sort((c: IRegistryCall, c2: IRegistryCall) => {
                if (c2) return c2.timestamp - c.timestamp
                return 1
              })
              .map((r: IRegistryCall) => (
                <RegistryCall
                  clickable={false}
                  from={`/phone/address-book/${type}/${id}/${from}`}
                  key={r.timestamp}
                  registryCall={r}
                  redirect={false}
                />
              ))}
        </RegistryList>
      </RegistrySection>
    </Container>
  )
}

export default ContactInfo

interface AddressProps {
  name: string
  children: React.ReactNode
}

//Componente per la visualizzazzione degli indirizzi telefonici
const AddressItem: React.FC<AddressProps> = (props) => {
  const { name, children } = props
  return (
    <AddressContainer>
      <Info>{name}</Info>
      <ButtonContainer>{children}</ButtonContainer>
    </AddressContainer>
  )
}

//region Style
const Container = styled(ScrollBar)`
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-template-columns: 1fr;
  place-content: start center;
  width: 100%;
  height: 100%;
  user-select: text;
`
const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  place-content: start center;
`
const Name = styled.div`
  font-size: calc(var(--font-size) * 0.8);
  font-weight: 600;
`
const GoBack = styled.div`
  margin-top: calc(var(--font-size) * 0.7);
  margin-left: calc(var(--font-size) * 0.35);
  place-self: start;
  cursor: pointer;
`
const ProfileContainer = styled.div`
  align-self: end;
  justify-self: center;
  display: grid;
  grid-template-rows: 5fr 1fr;
  row-gap: calc(var(--font-size) * 0.2);
`
const Favorite = styled.div`
  margin-top: calc(var(--font-size) * 0.7);
  margin-right: calc(var(--font-size) * 0.7);
  align-self: start;
  justify-self: end;
  cursor: pointer;
`

const RegistrySection = styled.div`
  display: grid;
  row-gap: calc(var(--font-size) * 0.8);
  place-content: start center;
  width: 100%;
  max-width: 500px;
  grid-template-columns: 1fr;
`

const RegistryList = styled.div`
  display: grid;
  place-content: start center;
  width: 100%;
  max-width: 500px;
  grid-template-columns: 1fr;
`
const AddressList = styled.div`
  padding: calc(var(--font-size) * 0.5);
  display: grid;
  row-gap: calc(var(--font-size) * 0.5);
  place-content: start center;
  width: 100%;
  max-width: 500px;
`
const AddressContainer = styled.div`
  display: grid;
  grid-template-columns: 7fr 3fr;
`
const Info = styled.div`
  place-self: center;
  font-size: calc(var(--font-size) * 0.8);
`
const ButtonContainer = styled.div`
  align-self: center;
  justify-self: center;
  display: grid;
  grid-template-columns: 1fr;
  width: 70%;
`
//endregion
