import { CustomerChat } from '../../../../api'
import { Guest } from '../../../../api/rest'
import devicesIcons from '../../../pages/CustomerChat/common/devicesIcons'
import GuestInfo from '../../../pages/CustomerChat/common/GuestInfo'
import { rem } from '../../../../helpers/style'
import { ArrowBackRounded } from '@mui/icons-material'
import { AppBar, Avatar, Badge, Button, IconButton, Toolbar } from '@mui/material'
import { changeRoute } from '../../../../store/applicationState/actions'
import { getUnreadEvents } from '../../../../store/selectors'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

const ProactiveBody: React.FC<{ guest: Guest }> = ({ guest }) => {
  const unreadMessages = useSelector(getUnreadEvents)
  const dispatch = useDispatch()

  const goBack = () => {
    dispatch(changeRoute({ current: '/customer-chat' }))
  }

  return (
    <Container>
      <AppBar
        position="sticky"
        color="inherit"
        style={{
          height: 'fit-content',
          minWidth: 0,
          minHeight: 0,
          boxSizing: 'border-box',
          boxShadow:
            '2px 2px 4px -1px rgb(0 0 0 / 20%), 3px 4px 5px 0px rgb(0 0 0 / 14%), 7px 1px 10px 0px rgb(0 0 0 / 12%)'
        }}
      >
        <Toolbar
          style={{
            display: 'grid',
            gridTemplateColumns: 'auto auto 1fr auto',
            gridColumnGap: rem(0.5),
            minWidth: 'unset',
            minHeight: 'unset',
            width: '100%',
            fontSize: rem(0.7),
            padding: `${rem(0.2)} ${rem(0.4)}`
          }}
        >
          <IconButton edge="start" color="inherit" onClick={goBack} size="small">
            <Badge
              badgeContent={unreadMessages}
              color="secondary"
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              variant="dot"
            >
              <ArrowBackRounded />
            </Badge>
          </IconButton>
          <Avatar style={{ minHeight: 0, placeSelf: 'center', width: 30, height: 30 }}>
            {devicesIcons[guest.info.client_info.device]}
          </Avatar>
          {CustomerChat.getGuestFullname(guest)}
        </Toolbar>
      </AppBar>
      <Info>
        <GuestInfo guest={guest} />
      </Info>
      <Button
        variant="contained"
        style={{ width: 'fit-content', placeSelf: 'start center', color: '#FFFFFF' }}
        onClick={() => {
          window.occlient.chat.startProactiveChat({
            departmentId: guest.info.client_info.department,
            guestId: guest.id
          })
        }}
      >
        Ingaggia chat
      </Button>
    </Container>
  )
}

export default ProactiveBody

//region Style

const Container = styled.div`
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr 70px;
  width: 100%;
  height: 100%;
`

const Info = styled.div`
  display: grid;
  place-self: center;
  background: ${({ theme }) => theme.palette.action.selected};
  border-radius: 15px;
  padding: ${rem(0.5)} ${rem(1)};
`
//endregion
