import { CustomerChat } from '../../../../api'
import { Chat } from '../../../../api/rest'
import { SearchBar } from '../../../common'
import ChatListActive from '../../../pages/CustomerChat/list/ChatListActive'
import ChatListProactive from '../../../pages/CustomerChat/list/ChatListProactive'
import { rem } from '../../../../helpers/style'
import { ForumRounded, PublicRounded } from '@mui/icons-material'
import { Button, Card, Tooltip } from '@mui/material'
import { changeRoute } from '../../../../store/applicationState/actions'
import { getChatsArray, getProactiveGuestsArray, getTranslation } from '../../../../store/selectors'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

const ChatList: React.FC = () => {
  const [tab, setTab] = useState<'chats' | 'proactive'>('chats')
  const [search, setSearch] = useState('')
  const [active, setActive] = useState<Chat[]>([])
  const [spied, setSpied] = useState<Chat[]>([])
  const [closed, setClosed] = useState<Chat[]>([])
  const [inTransfer, setInTransfer] = useState<Chat[]>([])

  const chats = useSelector(getChatsArray)
  const proactiveChats = useSelector(getProactiveGuestsArray)
  const translation = useSelector(getTranslation)

  const dispatch = useDispatch()

  useEffect(() => {
    const ac: Chat[] = []
    const cl: Chat[] = []
    const tr: Chat[] = []
    const sc: Chat[] = []
    chats.forEach((c) => {
      if (c.spied) {
        sc.push(c)
        return
      }
      if (c.closed) {
        cl.push(c)
      } else {
        if (c.transferer || c.transferee) tr.push(c)
        else ac.push(c)
      }
    })
    setActive(ac.sort(CustomerChat.sortByLastMessageDate))
    setClosed(cl.sort(CustomerChat.sortByLastMessageDate))
    setSpied(sc.sort(CustomerChat.sortByLastMessageDate))
    setInTransfer(tr.sort(CustomerChat.sortByLastMessageDate))
  }, [chats])

  const goToChat = (c: Chat) => {
    const basePath = c.spied ? `spy-customer-chat` : `customer-chat`
    dispatch(changeRoute({ current: `/${basePath}/${c.id}`, previous: '/customer-chat' }) as any)
  }

  const getLastMessageBody = (c: Chat): string => {
    if (!c.events) return ''
    const lastMessage = c.events[c.events.length - 1]
    if (!lastMessage) return ''

    return lastMessage.type === 'system' ? CustomerChat.parseSystemMessage(lastMessage, translation) : lastMessage.text
  }

  return (
    <Conversations elevation={3}>
      <Top>
        <Button
          size="small"
          style={{ width: 50, minWidth: 0, borderRadius: 10 }}
          disableElevation={true}
          variant="contained"
          color={tab === 'chats' ? 'primary' : 'inherit'}
          onClick={() => {
            setTab('chats')
          }}
        >
          <Tooltip title={translation?.tooltips?.customerChats} disableInteractive>
            <ForumRounded htmlColor={tab === 'chats' ? '#FFFFFF' : '#000000'} />
          </Tooltip>
        </Button>
        <Button
          size="small"
          style={{ width: 50, minWidth: 0, borderRadius: 10 }}
          disableElevation={true}
          variant="contained"
          color={tab === 'proactive' ? 'primary' : 'inherit'}
          onClick={() => {
            setTab('proactive')
          }}
        >
          <Tooltip title={translation?.tooltips?.proactiveChats} disableInteractive>
            <PublicRounded htmlColor={tab === 'proactive' ? '#FFFFFF' : '#000000'} />
          </Tooltip>
        </Button>
        <SearchBar
          value={search}
          setValue={setSearch}
          style={{ placeSelf: 'center end', marginRight: 0 }}
          input={{
            onChange: (event) => setSearch(event.target.value),
            style: { height: rem(1.2) }
          }}
        />
      </Top>
      {tab === 'chats' && (
        <ChatListActive
          active={active}
          closed={closed}
          spied={spied}
          inTransfer={inTransfer}
          goToChat={goToChat}
          getLastMessageBody={getLastMessageBody}
        />
      )}
      {tab === 'proactive' && <ChatListProactive proactive={proactiveChats} />}
    </Conversations>
  )
}

export default ChatList

//region Style

const Conversations = styled(Card)`
  display: grid;
  grid-template-rows: [top] ${rem(3)} [content] auto;
  height: calc(var(--height) - var(--topbar-height));
  width: 100%;
  place-self: center;
  border-radius: 0;
`

const Top = styled.div`
  display: grid;
  grid-row: top;
  grid-template-columns: 1fr 1fr 4fr;
  grid-template-areas: 'icon icon searchbar';
  place-content: center;
  padding: ${rem(0.5)};
  grid-column-gap: ${rem(0.3)};
`

//endregion
