import { IFavorite } from '../../api/database/types'
import { requestCall } from '../../store/phone/actions'
import { getCanCall, getTranslation } from '../../store/selectors'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Contact, User } from '../../api/rest'
import { ReactComponent as PhoneIcon } from '../../images/icons/phone.svg'
import { ActionButton } from './ActionButton'
import { Avatar } from './Avatar'
import { AvatarWithStatus } from './AvatarWithStatus'

interface AddressModalProps {
  user?: User
  contact?: Contact | IFavorite
  from: string
}

const AdressModal: React.FC<AddressModalProps> = (props) => {
  const { contact, user, from } = props

  const { chooseANumberTitle } = useSelector(getTranslation)
  const canCall = useSelector(getCanCall)
  const dispatch = useDispatch()

  //region Info
  const getName = () => {
    if (contact?.firstName) return contact.firstName
    if (contact?.secondName) return ''
    return 'not'
  }
  const getSurname = () => {
    if (contact?.secondName) return contact.secondName
    if (contact?.firstName) return ''
    return 'registered'
  }
  //endregion

  return (
    <Container>
      <ProfileContainer>
        {user && (
          <AvatarWithStatus
            avatarSize="max(min(var(--topbar-height), 85px),34px)"
            statusSize="min(calc(var(--topbar-height) / 3), 30px)"
            fontSize="max(min(calc(var(--font-size)), 32px), 16px)"
            letterSpacing="min(calc(var(--topbar-height) / -20),-2px)"
            user={user || undefined}
          />
        )}
        {contact && (
          <Avatar
            size="max(min(var(--topbar-height), 85px),34px)"
            fontSize="max(min(calc(var(--font-size) * 0.5), 22px), 16px)"
            letterSpacing="-2px"
            surname={getSurname()}
            name={getName()}
          />
        )}
        <Name>
          {user && `${user.surname} ${user.name}`}
          {contact && `${contact?.secondName || ''} ${contact?.firstName || ''}`}
        </Name>
      </ProfileContainer>
      <AdddressContainer>
        <Title>{chooseANumberTitle}</Title>
        <AddressList>
          {user && user.userVoips.map(() => <ContactContainer>voip</ContactContainer>)}
          {contact &&
            contact.addresses.map((a) => (
              <ContactContainer>
                <Label>{a.value}</Label>
                <ActionButton
                  disabled={!canCall}
                  onClick={() => canCall && dispatch(requestCall({ number: a.value, from }))}
                >
                  {<PhoneIcon />}
                </ActionButton>
              </ContactContainer>
            ))}
        </AddressList>
      </AdddressContainer>
    </Container>
  )
}

export default AdressModal

const Container = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 1.5fr 4fr;
`
const ProfileContainer = styled.div`
  align-self: start;
  justify-self: center;
  display: grid;
  grid-template-rows: 5fr 1fr;
  row-gap: calc(var(--font-size) * 0.2);
`
const Name = styled.div`
  font-size: calc(var(--font-size) * 0.8);
  font-weight: 600;
`
const AdddressContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 10fr;
`
const Title = styled.div`
  place-self: center;
  color: #0a8da9;
  font-size: calc(var(--font-size) * 0.55);
`
const AddressList = styled.div`
  justify-self: center;
  width: 70%;
`
const ContactContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  margin: calc(var(--font-size) * 0.45) 0;
`
const Label = styled.div``
