import { CustomerChat } from '../../../../api'
import { Guest } from '../../../../api/rest'
import devicesIcons from '../../../pages/CustomerChat/common/devicesIcons'
import { rem } from '../../../../helpers/style'
import { Avatar, Tooltip } from '@mui/material'
import { changeRoute } from '../../../../store/applicationState/actions'
import { getDepartments, getTranslation } from '../../../../store/selectors'
import { DateTime } from 'luxon'
import React from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import { useDispatch, useSelector } from 'react-redux'
import { useMatch } from 'react-router-dom'
import styled from 'styled-components'

type Props = {
  proactive: Guest[]
}

const ChatListProactive: React.FC<Props> = (props) => {
  const { proactive } = props

  const translation = useSelector(getTranslation)
  const departments = useSelector(getDepartments)

  const dispatch = useDispatch()
  const { params } = useMatch("/customer-chat/:id?")!

  const getDepartmentName = (guest: Guest) => {
    if (!guest.info.client_info.department) return translation.unknown
    const dep = departments.get(guest.info.client_info.department)
    if (!dep) return translation.unknown

    return dep.name
  }

  return (
    <Container>
      <Title>{translation.onlineUsers}</Title>
      <List>
        {proactive.length === 0 && <Empty>{translation.activeChatsNoEntry}</Empty>}
        {proactive.map((guest) => (
          <Card
            key={guest.id}
            selected={params.id === guest.id}
            onClick={() => dispatch(changeRoute({ current: `/customer-chat/${guest.id}?proactive=true` }) as any)}
          >
            <Tooltip title={guest.info.client_info.browser} arrow placement={'right'}>
              <Avatar style={{ minHeight: 0, placeSelf: 'center', gridArea: 'avatar' }}>
                {devicesIcons[guest.info.client_info.device]}
              </Avatar>
            </Tooltip>
            <Name>{CustomerChat.getGuestFullname(guest)}</Name>
            <Url>
              [{getDepartmentName(guest)}] {guest.info.client_info.pageTitle}
            </Url>
            <Timestamp>
              {DateTime.fromISO(guest.info.client_info.timestamp).toLocaleString(DateTime.TIME_SIMPLE)}
            </Timestamp>
          </Card>
        ))}
      </List>
    </Container>
  )
}

export default ChatListProactive

//region Style

const Container = styled(ScrollBar)`
  display: grid;
  grid-row: content;
  grid-template-rows: auto 1fr;
  grid-row-gap: ${rem(0.7)};
`

const List = styled.div`
  display: grid;
  height: 99%;
  width: 100%;
  grid-template-rows: repeat(auto-fill, minmax(50px, 1fr));
  grid-template-columns: 1fr;
  place-content: start center;
`

const Empty = styled.div`
  width: 100%;
  font-size: ${rem(0.7)};
  padding: 0 ${rem(0.8)};
`

const Title = styled.div`
  font-size: ${rem(0.75)};
  font-weight: 600;
  color: ${({ theme }) => theme.palette.primary.main};
  padding: 0 ${rem(0.8)};
`

const Card = styled.div<{ selected: boolean }>`
  display: grid;
  width: 100%;
  height: 100%;
  padding: ${rem(0.3)};
  grid-template-areas:
    'avatar name timestamp'
    'avatar url timestamp';
  grid-template-rows: 1fr 1fr;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: ${rem(0.5)};
  cursor: pointer;
  place-content: center stretch;
  padding: ${rem(0.5)};
  border-radius: 15px;
  ${({ selected, theme }) => selected && 'background: ' + theme.palette.action.selected};
`

const Name = styled.div`
  grid-area: name;
  font-size: ${rem(0.7)};
  align-self: end;
  height: 100%;
`

const Timestamp = styled.div`
  grid-area: timestamp;
  place-self: center end;
  font-size: ${rem(0.65)};
  color: ${({ theme }) => theme.palette.text?.secondary};
  font-style: italic;
`

const Url = styled.div`
  grid-area: url;
  display: grid;
  font-size: ${rem(0.65)};
  height: 100%;
  width: 100%;
  place-self: center start;
`

//endregion
