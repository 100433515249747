import Notification from './Notification'
import { RestoreRounded } from '@mui/icons-material'
import { ReactComponent as ChatIcon } from '../../../images/icons/chat_apps_script.svg'
import { ReactComponent as MessageIcon } from '../../../images/icons/business_messages.svg'
import { getLostCalls, getUnreadEvents, getUnreadMessages } from '../../../store/selectors'
import elementResizeEvent, { unbind } from 'element-resize-event'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { SvgIcon } from '@mui/material'

const ICON_WIDTH = 40

const Notifications: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null)

  // numero di icone che è possibile mostrare alla dimensione attuale
  const [canShow, setCanShow] = useState(0)
  const [width, setWidth] = useState(0)
  const [totalNotifications, setTotalNotifications] = useState(0)
  // attuale width del container

  const lostCalls = useSelector(getLostCalls)
  const unreadMessages = useSelector(getUnreadMessages)
  const unreadEvents = useSelector(getUnreadEvents)

  useLayoutEffect(() => {
    if (!containerRef.current) return
    const container = containerRef.current
    elementResizeEvent(container, () => setWidth(container.offsetWidth))

    return () => {
      unbind(container)
    }
  }, [])

  useEffect(() => {
    const total = [lostCalls, unreadEvents, unreadMessages].reduce((acc, curr) => {
      if (curr) return acc + 1
      else return acc
    }, 0)
    const newCanShow = Math.floor(width / ICON_WIDTH)
    setTotalNotifications(total)
    setCanShow(newCanShow)
  }, [width, lostCalls, unreadMessages, unreadEvents])

  return (
    <Container ref={containerRef} cols={totalNotifications}>
      {[
        lostCalls ? <Notification key={1} count={lostCalls} icon={<RestoreRounded />} path="/phone/history" /> : null,
        unreadMessages ? (
          <Notification
            key={2}
            count={unreadMessages}
            icon={<SvgIcon component={MessageIcon} inheritViewBox />}
            path="/messenger"
          />
        ) : null,
        unreadEvents ? (
          <Notification key={3} count={unreadEvents} icon={<SvgIcon component={ChatIcon} inheritViewBox />} path="/customer-chat" />
        ) : null
      ]
        .filter((x) => !!x)
        .splice(0, canShow)}
      {totalNotifications > canShow && <Plus>+{totalNotifications - canShow}</Plus>}
    </Container>
  )
}

export default Notifications

//region Style

const Container = styled.div<{ cols: number }>`
  display: grid;
  grid-area: notifications;
  grid-template-columns: repeat(${({ cols }) => cols}, ${ICON_WIDTH}px);
  place-items: center;
  width: 100%;
  height: var(--topbar-height);
  white-space: nowrap;
  overflow: hidden;
`

const Plus = styled.div`
  font-size: 12px;
  place-self: center;
`
//endregion
