import {setTheme} from './store/actions'
import React from 'react'
import {Provider} from 'react-redux'
import App from './App'
import './index.css'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import store from './store'
import './types'
import {createRoot, Root} from "react-dom/client"
import isElectron from "./helpers/isElectron"

const RootComponent: React.FC = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  )
}

const getElementAndRender  = () =>{
  const container = document.getElementById('bytewise-oc-client')!
  const root = createRoot(container)
  root.render(<RootComponent />)
  return root
}


// decreta la preferenza dell'utente sul tema (o precedentemente salvato o dalle preferenze di sistema)
const themeFromLS = sessionStorage.getItem('@occlient/theme')
if (themeFromLS === 'light' || themeFromLS === 'dark') {
  store.dispatch(setTheme(themeFromLS))
} else {
  const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)')
  if (darkThemeMq.matches) {
    store.dispatch(setTheme('dark'))
  } else {
    store.dispatch(setTheme('light'))
  }
}

let root: Root
// decreta se il client non va montato subito


if (Boolean(sessionStorage.getItem('delayClientMount')) || window.occlient.settings.delayMount) {

  window.occlient.on('mount', () => {
    if(!['/norole', '/onlyclient'].includes(window.location.pathname)) {
      root = getElementAndRender()
    }
  })
}
window.occlient.on('unmount', () => {
  if(root) root.unmount()
})

if(window.occlient.settings.isSalesforce || window.location.pathname.includes('/client') || isElectron()){
  root = getElementAndRender()
}

serviceWorkerRegistration.unregister()
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
