import { DynamicVolumeIcon } from './DynamicVolumeIcon'
import { FullscreenExitRounded, FullscreenRounded, VolumeOffRounded as ImgVolumeOff } from '@mui/icons-material'
import { Fade, IconButton, Slider } from '@mui/material'
import { getSelectedDevices } from '../../store/selectors'
import React, { CSSProperties, useEffect, useRef, useState, VideoHTMLAttributes } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { smaller } from '../../constants'
import logger from '../../helpers/logger'

interface Settings {
  volume: number
  isFullscreen: boolean
  muted: boolean
}

type HTMLVideoElementEnhanced = HTMLVideoElement & {
  setSinkId: (arg0: string) => Promise<void>
}

type Props = VideoHTMLAttributes<HTMLVideoElement> & {
  stream?: MediaStream
  displayName?: string
  style?: CSSProperties
  noVideoComponent?: () => any
  withoutSettings?: boolean
  width?: string
  height?: string
  goFullScreen?: () => Promise<void>
}

export const Video: React.FC<Props> = (props) => {
  const {
    stream,
    displayName,
    noVideoComponent,
    style,
    withoutSettings = false,
    width = `${smaller.width}px`,
    height = 'auto',
    goFullScreen,
    ...rest
  } = props

  const refVideo = useRef<HTMLVideoElementEnhanced>(null)
  const refContainer = useRef<HTMLDivElement>(null)

  const [hasAudio, setHasAudio] = useState(true)
  const [hasVideo, setHasVideo] = useState(true)
  const [showSettings, setShowSettings] = useState(false)
  const [settings, setSettings] = useState<Settings>({
    muted: !!props.muted,
    isFullscreen: false,
    volume: 1
  })

  const selectedDevices = useSelector(getSelectedDevices)

  useEffect(() => {
    document.onfullscreenchange = function () {
      setSettings((prevState) => ({ ...prevState, isFullscreen: !!document.fullscreenElement }))
    }
    return () => {
      document.onfullscreenchange = function () {}
    }
  }, [])

  useEffect(() => {
    if (!refVideo.current || !stream) return
    // Quando il publisher cambia le sue impostazioni, non viene mandato un nuovo stream ma viene
    // direttamente mutato lo stesso stream, quindi per accorgersi dei cambiamenti sfruttiamo questi
    // event listeners sull'oggetto stream.
    // useEffect viene rieseguito solo se stream diventa un altro oggetto, quindi è giusto mettere di nuovo on
    // senza curarsi di eliminare eventuali listener pregressi, perchè erano su un altro oggetto MediaStream
    function onTrackChange(this: MediaStream) {
      setHasVideo(!!this.getVideoTracks()[0])
      setHasAudio(!!this.getAudioTracks()[0])
    }
    stream.onaddtrack = onTrackChange
    stream.onremovetrack = onTrackChange
    const newHasVideo = !!stream.getVideoTracks()[0]
    const newHasAudio = !!stream.getAudioTracks()[0]
    setHasVideo(newHasVideo)
    setHasAudio(newHasAudio)
    try {
      refVideo.current.srcObject = stream
    } catch (e) {
      try {
        refVideo.current.src = URL.createObjectURL(stream as any)
      } catch (e) {
        logger.error('Error attaching stream to element')
      }
    }
  }, [stream])

  useEffect(() => {
    if (!hasAudio) setSettings((old) => ({ ...old, muted: !!rest.muted }))
  }, [hasAudio, rest.muted])

  useEffect(() => {
    if (!refVideo.current) return
    refVideo.current.volume = settings.volume
  }, [settings])

  useEffect(() => {
    if (selectedDevices.audio && refVideo.current) {
      refVideo.current.load()
      refVideo.current.setSinkId(selectedDevices.audio.deviceId).catch((e: Error) => {
        logger.error(e.message)
      })
    }
  }, [selectedDevices])

  const handleFullscreen = () => {
    if (!refContainer.current) return
    // se non è attualmente fullscreen
    if (!settings.isFullscreen) {
      // controllo se mi è stata passata una funzione per andare fullscreen
      if (goFullScreen) {
        goFullScreen().catch((err) => console.error(err))
      } else {
        // altrimenti ci mando il container di questo componente
        refContainer.current.requestFullscreen().catch((err) => console.error(err))
      }
    } else {
      // altrimenti rimuovo il fullscreen
      document.exitFullscreen().catch((err) => console.error(err))
    }
  }

  return (
    <Container
      ref={refContainer}
      style={style || {}}
      onMouseEnter={() => !withoutSettings && setShowSettings(true)}
      onMouseLeave={() => !withoutSettings && setShowSettings(false)}
      fullscreen={settings.isFullscreen}
    >
      {stream && (
        <VideoElement show={hasVideo} width={width} height={height} ref={refVideo} muted={settings.muted} {...rest} />
      )}
      {!withoutSettings && (
        <Fade in={showSettings}>
          <SettingsContainer>
            <ButtonsContainer>
              {hasVideo && (
                <IconButton onClick={() => handleFullscreen()}>
                  {settings.isFullscreen ? <FullscreenExitRounded /> : <FullscreenRounded />}
                </IconButton>
              )}
              <IconButton onClick={() => setSettings({ ...settings, muted: !settings.muted })}>
                <DynamicVolumeIcon volume={settings.volume} muted={settings.muted} />
              </IconButton>
              <CustomSlider
                min={0}
                max={1}
                step={0.1}
                disabled={settings.muted}
                value={settings.volume}
                color="secondary"
                onChange={(_: any, newVolume: number | number[]) => setSettings({ ...settings, volume: newVolume as number })}
              />
            </ButtonsContainer>
          </SettingsContainer>
        </Fade>
      )}
      <NoVideo width={width} show={!hasVideo || !stream}>
        {noVideoComponent ? noVideoComponent() : 'No Video'}
      </NoVideo>
      {!hasAudio && (
        <ImgVolumeOff
          style={{
            position: 'absolute',
            right: 10,
            bottom: 10,
            background: 'rgba(0,0,0,0.3)',
            borderRadius: '50%',
            padding: 10,
            fill: '#ffffff'
          }}
        />
      )}
    </Container>
  )
}

//region style

const Container = styled.div<{ fullscreen: boolean }>`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  background-color: transparent;
  border-radius: 10px;

  @media all and (display-mode: fullscreen) {
    height: 100%;
    width: auto;
  }
`

type VideoElementProps = {
  show: boolean
  width: string
  height: string
}
const VideoElement = styled.video<VideoElementProps>`
  ${({ show }) => !show && 'display: none;'};
  ${({ width }) => `width: ${width};`};
  ${({ height }) => `height: ${height};`};
  border-radius: 10px;
  @media all and (display-mode: fullscreen) {
    height: 100%;
    width: auto;
  }
`

const NoVideo = styled.div<{ show: boolean; width?: string }>`
  ${({ show }) => (!show ? 'display: none;' : 'display: grid')};
  aspect-ratio: 4 / 3;
  background-color: rgba(0, 0, 0, 0.6);
  place-items: center;
  width: ${({ width }) => width || '100%'};
  border-radius: 10px;
`

const SettingsContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-bottom: 10px;
  display: grid;
  place-content: end center;
`

const ButtonsContainer = styled.div`
  display: flex;
  place-items: center start;
  position: absolute;
  bottom: 5px;
  left: 5px;
  min-width: 200px;
  backdrop-filter: blur(20px) saturate(125%);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 5px 15px;
`
const CustomSlider = styled(Slider)(() => ({
  width: 100,
  height: 5,
  '& .MuiSlider-thumb': {
    height: 15,
    width: 15
  }
}))

//endregion
