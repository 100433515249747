import { DefaultTheme } from 'styled-components'
import components from './components'

const lightTheme = (fontSize: number): DefaultTheme => ({
  palette: {
    primary: {
      dark: '#005f7a',
      main: '#0A8DA9',
      light: '#57bddb',
      contrastText: '#ffffff'
    },
    secondary: {
      dark: '#ff9800',
      main: '#ffb74d',
      light: '#ffe97d'
    },
    background: {
      paper: '#fff',
      default: '#fafafa'
    },
    text: {
      primary: '#565656',
      secondary: '#62727b',
      disabled: '#eceff1'
    },
    action: {
      active: '#565656',
      hover: 'rgba(2, 136, 209, 0.2)',
      disabled: '#c7cbcc'
    }
  },
  typography: {
    fontFamily: `"Open Sans", sans-serif`
  },
  components: components(fontSize)
})

export default lightTheme
