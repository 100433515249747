import { getNewToken, isTokenForUser } from '../../api/authentication'
import { Device } from '@bytewise/janus'
import { getFontSize } from '../../helpers/common'
import logger from '../../helpers/logger'
import { AnyObject } from '../../hooks/types'
import { login } from '../login/actions'
import { getLogin, getPath, getSettings } from '../selectors'
import { RootState } from '../types'
import { Dispatch } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import history from '../../constants/history'
import * as t from './types'
import {JanusPool} from "../../api";

export const changeRoute =
  (payload: { current: string; previous?: string; state?: any }) => (dispatch: Dispatch, getState: () => RootState) => {
    payload.state ? history.push(payload.current, payload.state) : history.push(payload.current)
    const { current } = getPath(getState())
    payload.previous = payload.previous || current
    sessionStorage.setItem('@occlient/currentPath', payload.current)
    sessionStorage.setItem('@occlient/previousPath', payload.previous)
    dispatch({ type: t.SET_PATH, payload })
  }

export const changeRouteFromExternal =
  (payload: { path: string; querystring?: AnyObject }) =>
  (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    const { isAuthenticated } = getLogin(getState())
    const { path, querystring = {} } = payload
    const { token, ...state } = querystring

    if (isAuthenticated) {
      dispatch(changeRoute({ current: path, state }))
    } else {
      if (!token) {
        dispatch(changeRoute({ current: '/login' }))
      } else {
        getNewToken(token)
          .then((renewPayload) => {
            dispatch(login(renewPayload))
            JanusPool.initialize(renewPayload.config.turn).catch((e) => logger.error(e))
            dispatch(changeRoute({ current: path, state }))
          })
          .catch((e) => {
            logger.error('Could not get new token to login from external', e)
            dispatch(changeRoute({ current: '/login' }))
          })
      }
    }
  }

export const executeActionFromExternal =
  (action: string, payload: { token: string; [key: string]: any }) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    const { isAuthenticated, user } = getLogin(getState())
    const { token, ...querystring } = payload

    if (isAuthenticated) {
      if (await isTokenForUser(token, user!)) {
        // si era già loggati ed il token inviato è valido e per lo stesso utente, si può procedere
        window.occlient.executeActionFromExternal(action, querystring)
      }
    } else {
      getNewToken(token)
        .then((renewPayload) => {
          dispatch(login(renewPayload))
          JanusPool.initialize(renewPayload.config.turn).catch((e) => logger.error(e))
          // si è ora loggati ed il token inviato è valido, si può procedere
          window.occlient.executeActionFromExternal(action, querystring)
        })
        .catch((e) => {
          logger.error('Could not get new token to login from external', e)
          dispatch(changeRoute({ current: '/login' }))
        })
    }
  }

/**
 * azione per tornare alla rotta precedente
 * @param defaultRoute se la rotta precedente è uguale a quella corrente serve una rotta di fallback
 *                     per non rimanere incastrati
 */
export const goBack =
  (defaultRoute: string = '/phone') =>
  (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    const { previous, current } = getPath(getState())
    let goTo = defaultRoute
    if (previous !== current && previous !== '/login') goTo = previous
    dispatch(changeRoute({ current: goTo }))
  }

export const setDevices = (payload: Device[]): t.ApplicationStateActionTypes => {
  return { type: t.SET_DEVICES, payload }
}

export const setRenderedPages = (payload: number): t.ApplicationStateActionTypes => {
  return { type: t.SET_RENDERED_PAGES, payload }
}

export const setSettings = (payload: t.Settings) => {
  localStorage.setItem('@occlient/settings', JSON.stringify(payload))
  return {
    type: t.SET_SETTINGS,
    payload
  }
}

export const editSettings =
  (payload: Partial<t.Settings>) => (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    const currSetting = getSettings(getState())
    dispatch(setSettings({ ...currSetting, ...payload }))
  }

export const setDimensions = (payload: Omit<t.Dimensions, 'fontSize'>): t.ApplicationStateActionTypes => {
  if (!localStorage.getItem('@occlient/minimized')) {
    localStorage.setItem('@occlient/appWidth', String(payload.width))
    localStorage.setItem('@occlient/appHeight', String(payload.height))
  }
  return {
    type: t.SET_DIMENSIONS,
    payload: {
      ...payload,
      fontSize: getFontSize(payload.width, payload.height)
    }
  }
}

export const setRegistryLoading = (payload: boolean): t.ApplicationStateActionTypes => {
  return { type: t.SET_REGISTRY_LOADING, payload }
}

export const setVisibility = (payload: boolean): t.ApplicationStateActionTypes => {
  // se è visibile il client non è minimizzato
  if (payload) {
    localStorage.removeItem('@occlient/minimized')
  } else {
    // altrimenti è minimizzato e lo salva in sessione
    localStorage.setItem('@occlient/minimized', 'true')
  }
  return {
    type: t.SET_VISIBILITY,
    payload
  }
}
