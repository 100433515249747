import { rem } from '../../helpers/style'
import { getDimensions } from '../../store/selectors'
import React from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

type TitleProps = {
  label: string
  labelStyle?: React.CSSProperties
  style?: React.CSSProperties
  icon?: React.ReactElement
  fontSize?: number
  onClick?: React.MouseEventHandler
  children?: React.ReactNode
}

export const Title: React.FC<TitleProps> = (props) => {
  const { label, style = {}, labelStyle = {}, icon, fontSize = 0.9, onClick } = props

  const dim = useSelector(getDimensions)

  return (
    <Container
      withIcon={!!icon}
      fontSize={dim.fontSize}
      style={onClick ? { cursor: 'pointer', ...style } : style}
      onClick={onClick}
    >
      {icon ? <Icon>{icon}</Icon> : null}
      <Label style={{ fontSize: rem(fontSize, dim.fontSize), ...labelStyle }}>{label}</Label>
    </Container>
  )
}

const Container = styled.div<{ withIcon: boolean; fontSize: number }>`
  display: grid;
  width: 100%;
  grid-template-columns: ${({ withIcon }) => (withIcon ? 'auto 1fr' : '1fr')};
  ${({ withIcon, fontSize }) =>
    withIcon &&
    css`
      grid-column-gap: ${rem(0.5, fontSize)};
    `};
  justify-self: start;
  align-self: center;
  place-content: center;
  padding: ${({ fontSize }) => rem(0.3, fontSize)} 0;
`

const Icon = styled.div`
  display: grid;
  place-self: center;
`

const Label = styled.div`
  font-weight: 500;
`
