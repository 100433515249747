export const status_change = 'status-change'
export const cancel_transfer = 'can-cancel-transfer'
export const can_close_transfer = 'can-close-transfer'
export const incoming_call = 'incoming-call'
export const users_list = 'users-list'
export const users_update = 'users-update'
export const queues_list = 'queues-list'
export const queue_call_answered = 'queue-call-answered'
export const queues_update = 'queues-update'
export const addressbooks_list = 'addressbooks-list'
export const addressbooks_update = 'addressbooks-update'
export const transfer_closed = 'transfer-closed'
export const pauses_list = 'pauses-list'
export const pauses_update = 'pauses-update'
export const departments_list = 'departments-list'
export const departments_update = 'departments-update'
export const statuses_list = 'statuses-list'
export const statuses_update = 'statuses-update'
export const outbounds_list = 'outbounds-list'
export const outbounds_update = 'outbounds-update'
export const proactive_guests_list = 'proactiveGuests-list'
export const proactive_guests_add = 'proactiveGuests-add'
export const proactive_guests_remove = 'proactiveGuests-remove'
export const force_logout = 'force-logout'
