import { Pause, Statuses } from '../api/rest'
import { sortUsers } from '../helpers/filters'
import { RootState } from './types'

// Login
export const getLogin = (state: RootState) => state.login
export const getToken = (state: RootState) => state.login.token
export const getUser = (state: RootState) => state.login.user
export const getCanICallWithoutOutbound = (state: RootState) => state.login.user?.canCall
export const getLoginMethods = (state: RootState) => state.login.methods
export const getIsAuthenticated = (state: RootState) => state.login.isAuthenticated
export const getSelectedMethod = (state: RootState) => state.login.selectedMethod
export const getMe = (state: RootState) => state.login.me
export const getOtpRequired = (state: RootState) => state.login.otpRequired
export const getRefreshTimeout = (state:RootState) => state.login.renewTimeout

// Language
export const getSelectedLanguage = (state: RootState) => state.language.selected
export const getTranslation = (state: RootState) => state.language.translation

// Theme
export const getTheme = (state: RootState) => state.theme

// Phone
export const getStatus = (state: RootState) => state.phone.status
export const getSelectedOutbound = (state: RootState) => state.phone.selectedOutbound
export const getVoips = (state: RootState) => state.phone.voips
export const getCallInProgress = (state: RootState) => state.phone.callInProgress
export const getLostCalls = (state: RootState) => state.phone.lostCalls
export const getBitrate = (state: RootState) => state.phone.bitrate
export const getCoreConnected = (state: RootState) => state.phone.coreConnected
export const getReconnecting = (state: RootState) => state.phone.reconnecting
export const getCanCall = (state: RootState) => {
  const status = state.phone.status
  if (state.phone.reconnecting) return false
  if (status.isStatus) {
    return status.id === Statuses.idle
  } else {
    return (status as Pause).callsEnabled
  }
}

// Application state
export const getPath = (state: RootState) => state.applicationState.path
export const getDevices = (state: RootState) => state.applicationState.devices
export const getDimensions = (state: RootState) => state.applicationState.dimensions
export const getVisibility = (state: RootState) => state.applicationState.visible
export const getSettings = (state: RootState) => state.applicationState.settings
export const getSelectedDevices = (state: RootState) => state.applicationState.settings.selectedDevices
export const getNotifications = (state: RootState) => state.applicationState.settings.notifications
export const getRingerVolume = (state: RootState) => state.applicationState.settings.ringerVolume
export const getAudioVolume = (state: RootState) => state.applicationState.settings.audioVolume
export const getTurnServer = (state: RootState) => state.applicationState.turnServer
export const getPeers = (state: RootState) => state.applicationState.peers

export const getRegistryLoading = (state: RootState) => state.applicationState.registryLoading

// Customer chats
export const getChats = (state: RootState) => state.customerChats.chats
export const getChatsArray = (state: RootState) => [...state.customerChats.chats.values()]
export const getBookedChats = (state: RootState) => state.customerChats.bookedChats
export const getBookedChatsArray = (state: RootState) => [...state.customerChats.bookedChats.values()]
export const getUnreadEvents = (state: RootState) => state.customerChats.unreadEvents
export const getUnreadEventsByChat = (state: RootState) => state.customerChats.unreadEventsByChat
export const getProactiveGuests = (state: RootState) => state.customerChats.proactiveGuests
export const getProactiveGuestsArray = (state: RootState) => [...state.customerChats.proactiveGuests.values()]
export const getSneakPeeks = (state: RootState) => state.customerChats.sneakpeeks
export const getMediaChat = (state: RootState) => state.customerChats.mediachat
export const getChatLocalStream = (state: RootState) => state.customerChats.mediachat?.localStream
export const getGuestStream = (state: RootState) => state.customerChats.mediachat?.guestStream
// Entities
export const getUsers = (state: RootState) => state.entities.users
export const getUsersArray = (state: RootState) => [...state.entities.users.values()].sort(sortUsers)
export const getAddressBooks = (state: RootState) => state.entities.addressbooks
export const getAddressBooksArray = (state: RootState) => [...state.entities.addressbooks.values()]
export const getPauses = (state: RootState) => state.entities.pauses
export const getStatuses = (state: RootState) => state.entities.statuses
export const getDepartments = (state: RootState) => state.entities.departments
export const getDepartmentsArray = (state: RootState) => [...state.entities.departments.values()]
export const getOutbounds = (state: RootState) => state.entities.outbounds

// Messenger
export const getConversations = (state: RootState) => state.messenger.conversations
export const getConversationsArray = (state: RootState) =>
  [...state.messenger.conversations.values()].sort((a, b) => b.lastMessage - a.lastMessage)
export const getUnreadMessagesByChat = (state: RootState) => state.messenger.unreadMessagesByChat
export const getUnreadMessages = (state: RootState) => state.messenger.unreadMessages
export const getMessengerVideoRoom = (state: RootState) => state.messenger.videoroom
export const getMessengerLocalStream = (state: RootState) => state.messenger.videoroom?.localStream
export const getMessengerRemoteStreams = (state: RootState) => state.messenger.videoroom?.remoteStreams
export const getMessengerInvites = (state: RootState) => state.messenger.videoroom?.invites
