import { MediaChatState } from '../../../../store/customerChats/types'
import { getTranslation } from '../../../../store/selectors'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import useElapsedTime from '../../../../hooks/useElapsedTime'

type Props = {
  mediachat: MediaChatState
}
const ChatBodyMediaInfo: React.FC<Props> = ({ mediachat }) => {
  const translation = useSelector(getTranslation)
  const { elapsedTimeString } = useElapsedTime(mediachat?.startTime)
  return (
    <Container>
      {translation.conversationInProgressMini} {elapsedTimeString}
    </Container>
  )
}

export default ChatBodyMediaInfo

//region Style

const Container = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

//endregion
