import { DefaultTheme } from 'styled-components'
import components from './components'

const darkTheme = (fontSize: number): DefaultTheme => ({
  palette: {
    mode: 'dark' as 'dark',
    primary: {
      dark: '#77d5ff',
      main: '#3AA4DD',
      light: '#0075ab'
    },
    secondary: {
      dark: '#ff9800',
      main: '#ffb74d',
      light: '#ffe97d'
    },
    background: {
      paper: '#1e1e1e',
      default: '#303030'
    },
    text: {
      primary: '#DFDFDF',
      secondary: '#ffffff',
      disabled: '#eceff1'
    },
    action: {
      active: '#DFDFDF',
      hover: 'rgba(2, 136, 209, 0.2)',
      disabled: '#c7cbcc'
    }
  },
  typography: {
    fontFamily: `"Open Sans", sans-serif`
  },

  components: components(fontSize)
})

export default darkTheme
