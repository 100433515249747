import {ElectronProxy, JanusPool} from '..'
import {getNewToken} from '../authentication'
import {MessengerUtils} from './Messenger'
import {OCClientEvent, OCClientSettings, Payload} from './types'
import {LanguageCode} from '../rest'
import {getTranslation} from '../../helpers/getTranslation'
import isElectron from '../../helpers/isElectron'
import initialState from '../../store/initialState'
import {LoginPayload} from '../../store/login/types'
import {getIsAuthenticated, getPath, getSelectedLanguage} from '../../store/selectors'
import {RootState} from '../../store/types'
import {EventEmitter} from 'events'
import {Dispatch} from 'redux'
import {ThunkDispatch} from 'redux-thunk'
import {mini, smaller} from '../../constants'
import logger, {LevelsLabels} from '../../helpers/logger'
import * as actions from '../../store/actions'
import {
  changeRoute,
  handleLogout,
  login,
  loginWithPayload,
  setTranslation,
  tokenRenewed
} from '../../store/actions'
import {PhoneUtils} from './Phone'
import {ChatUtils} from './Chat'
import history from "../../constants/history"

const defaultSettings: OCClientSettings = {
  baseUrl: '',
  basePath: 'client',
  width: smaller.width,
  height: smaller.height,
  visible: true,
  delayMount: false,
  tokenRenew: true,
  minified: {
    version: 'floating',
    width: mini.width,
    height: mini.height
  },
  inboundDisplayInfo: {
    customer: {},
    service: {},
    queue: {},
    inbound: {}
  },
  outboundDisplayInfo: {
    customer: {},
    service: {},
    outbound: {}
  },
  showChooseTranslations: true,
  showCloseOption: !isElectron(),
  showThemeButton: true,
  showLogoutButtonOption: true,
  isSalesforce: false
}

/**
 * Interfaccia esposta nella pagina che ospita il client per comandarne alcune funzioni ed
 * intercettare degli eventi d'interesse
 */
export class OCClient {
  private _settings: OCClientSettings
  /** @internal */
  private _dispatch: ThunkDispatch<any, any, any> | Function
  /** @internal */
  private _getState: () => RootState
  private _mounted: boolean
  private readonly _phone: PhoneUtils
  private readonly _chat: ChatUtils
  private readonly _messenger: MessengerUtils
  /** @internal */
  private _events = new EventEmitter()

  /**
   * Constructs a new instance of the class.
   *
   * @param {ThunkDispatch<any, any, any> | undefined} dispatch - The dispatch function to be used.
   * @param {() => RootState | undefined} getState - The function to get the current state.
   * @param {OCClientSettings | undefined} settings - The settings for the OCClient.
   */
  constructor(dispatch?: ThunkDispatch<any, any, any>, getState?: () => RootState, settings?: OCClientSettings) {
    const settingsFromWindow = window.occlientSettings
    if (settings) {
      this._settings = settings ? { ...defaultSettings, ...settings } : defaultSettings
    } else {
      this._settings = settingsFromWindow ? { ...defaultSettings, ...settingsFromWindow } : defaultSettings
    }
    this._mounted = Boolean(sessionStorage.getItem('@occlient/delayMount')) || !this._settings.delayMount
    this._dispatch = dispatch || function () {}
    this._getState =
      getState ||
      function () {
        return initialState
      }
    this._phone = new PhoneUtils(dispatch || function () {}, this._getState)
    this._chat = new ChatUtils(dispatch || function () {}, this._getState)
    this._messenger = new MessengerUtils(dispatch || function () {}, this._getState)
  }

  //region Getter e setter
  get settings(): OCClientSettings {
    return this._settings
  }

  set settings(settings) {
    this._settings = settings
  }

  /** @internal */
  get dispatch(): ThunkDispatch<any, any, any> | Function {
    return this._dispatch
  }

  /** @internal */
  set dispatch(value: Dispatch | Function) {
    this._dispatch = value
    this._phone.dispatch = value
    this._chat.dispatch = value
    this._messenger.dispatch = value
  }

  /** @internal */
  set getState(value: () => RootState) {
    this._getState = value
    this._phone.getState = value
    this._chat.getState = value
    this._messenger.getState = value
  }

  get mounted(): boolean {
    return this._mounted
  }

  get phone(): PhoneUtils {
    return this._phone
  }

  get chat(): ChatUtils {
    return this._chat
  }

  get messenger(): MessengerUtils {
    return this._messenger
  }

  /** @internal */
  get state(): RootState {
    return this._getState()
  }

  //endregion

  /**
   * Permette la registrazione ad uno degli eventi gestiti da occlient
   * @param ev {OCClientEvent} nome dell'evento
   * @param listener {(...args: any[]) => void} funzione da eseguire quando viene intercettato l'evento
   */
  public on(ev: OCClientEvent, listener: (...args: any[]) => void): EventEmitter {
    return this._events.on(ev, listener)
  }

  public once(ev: OCClientEvent, listener: (...args: any[]) => void): EventEmitter {
    return this._events.once(ev, listener)
  }

  /**
   * Permette la disiscrizione ad uno degli eventi gestiti da occlient
   * @param ev {OCClientEvent} nome dell'evento
   * @param listener {(...args: any[]) => void} funzione da eseguire quando viene intercettato l'evento
   */
  public off(ev: OCClientEvent, listener: (...args: any[]) => void): EventEmitter {
    return this._events.off(ev, listener)
  }

  /** @internal */
  public emit(ev: OCClientEvent, ...args: any[]): boolean {
    return this._events.emit(ev, ...args)
  }

  //region Logger
  /**
   * Cambia il loglevel in tempo reale
   * @param lvl
   */
  public setLogLevel(lvl: LevelsLabels) {
    logger.setLogLevel(lvl)
  }

  /**
   * Imposta width ed height del client esteso
   * @param opts
   */
  public setDimensions(opts: { width: number; height: number }) {
    const { width, height } = opts
    this.dispatch(actions.setDimensions({ width, height }))
  }

  //endregion

  //region Visualizzazione
  /** Imposta il client in versione estesa */
  public show() {
    this.dispatch(actions.setVisibility(true))
  }

  /** Imposta il client in versione minificata */
  public hide() {
    this.dispatch(actions.setVisibility(false))
  }

  /** Emette l'evento per permettere il mount dell'app */
  public mount() {
    if (this._mounted) return
    this._mounted = true
    this.emit('mount')
    this.dispatch(changeRoute({ current: '/phone' }))
  }

  /** Emette l'evento per permettere l'unmount dell'app */
  public unmount() {
    if (!this._mounted) return
    history.push('/loading')
    this._mounted = false
    let destroyCount = 0
    const handleDestroy = () => {
      if (++destroyCount === 3) {
        JanusPool.events.off('destroyed', handleDestroy)
      }
    }
    JanusPool.events.on('destroyed', handleDestroy)
    this.emit('unmount')
    this.logout()
  }

  /**
   * Imposta il tema chiaro o scuro
   * @param type
   */
  public setTheme(type: 'light' | 'dark') {
    if (type !== 'light' && type !== 'dark') {
      throw new Error('Argument must be "light" or "dark"')
    }
    this.dispatch(actions.setTheme(type))
  }

  /**
   * Imposta la lingua del client
   * @param language (it_IT o en_EN)
   */
  public setLanguage(language: LanguageCode) {
    const currentLanguage = getSelectedLanguage(this.state)
    if (language === currentLanguage) {
      console.info(`Language "${language}" was already set`)
    } else {
      getTranslation(language)
        .then((translation) => {
          this.dispatch(setTranslation(language, translation))
          ElectronProxy.changeLanguage(language)
          console.info(`Correctly set current language to "${language}"`)
        })
        .catch((err) => console.error('Could not load translation', err))
    }
  }
  //endregion

  //region Autenticazione

  /**
   * Effettua il login con i diversi metodo esposti da OC
   * @param loginMethodId identificativo del metodo di autenticazione
   * @param payload oggetto che varia per i diversi servizi di autenticazione
   * @param payload.token token ottenuto in qualche modo dal particolare servizio di auth (sempre presente)
   */
  public authenticateByPayload(loginMethodId: number, { token, ...rest }: Payload): void {
    this.dispatch(loginWithPayload(loginMethodId, token, rest))
  }

  public logout() {
    this.dispatch(handleLogout())
  }

  /** esegue il login fidandosi di ciò che viene passato
   * si decreta che verrà anche aggiornato se si sceglie questo metodo */
  public login(payload: LoginPayload, janusInit: boolean = true) {
    this.dispatch(login(payload))
    janusInit && JanusPool.initialize(payload.config.turn).catch((e) => logger.error(e))
    const isAuthenticated = getIsAuthenticated(this.state)
    if (isAuthenticated) {
      const { current } = getPath(this.state)
      const pathToGo = ['/login/otp', '/login', '/error'].includes(current) ? '/phone' : current || '/phone'
      this.dispatch(changeRoute({ current: pathToGo }))
    }
  }

  public popupLogin(payload: LoginPayload) {
    sessionStorage.setItem('@occlient/token', payload.token)
    sessionStorage.setItem('@occlient/user', JSON.stringify(payload.user))
    sessionStorage.removeItem('@occlient/selectedLoginMethod')
    window.occlient.setLanguage(payload.language?.code || 'it_IT')
  }

  /** esegue il renew fidandosi di ciò che viene passato
   * si decreta che verrà anche aggiornato se si sceglie questo metodo */
  public renewToken(payload: LoginPayload) {
    this.dispatch(tokenRenewed(payload))
  }

  /**
   * esegue il login effettuando un token renew del token passato come parametro verso il core
   * in modo da riottenere il giusto payload e verificare la validità del token
   */
  public loginWithToken(token: string) {
    getNewToken(token)
      .then((payload) => this.login(payload))
      .catch((err) => logger.error(`Could not login with token: ${err.message}`))
  }

  //endregion

  executeActionFromExternal(action: string, payload: any) {
    if (action === 'call') {
      this.phone.call(payload)
    }
    if (action === 'spy') {
      const { extension, pbxId, type } = payload
      if (!extension || !pbxId || !type) {
        logger.warn('Could not start spying because extension, pbxId and type must be provided')
        return
      }
      this.phone.spy({ extension: parseInt(extension), pbxId: parseInt(pbxId), type })
    }
    if (action === 'spy-chat') {
      const { userId, chatId } = payload
      if (!userId || !chatId) {
        logger.warn('Could not start spying the chat because chatId and userId must be provided')
        return
      }
      this.chat.spy(payload)
    }
    if (action === 'start-proactive-chat') {
      const { departmentId, guestId } = payload
      if (!departmentId || !guestId) {
        logger.warn('Could not start a new proactive chat because departmentId and guestId must be provided')
        return
      }
      this.chat.startProactiveChat(payload)
    }
    if (action === 'go-to-conversation') {
      const { username } = payload
      if (!username) {
        logger.warn('Could not go to conversation because username must be provided')
        return
      }
      this.messenger.goToConversation(username)
    }
  }
}

window.occlient = new OCClient()
