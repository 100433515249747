import {rem} from '../../helpers/style'
import HomeIcon from '@mui/icons-material/Home'
import {ContactsRounded, Groups, HistoryRounded, LogoutRounded, StarBorderRounded} from '@mui/icons-material'
import {ReactComponent as MessageIcon} from '../../images/icons/business_messages.svg'
import {ReactComponent as ChatIcon} from '../../images/icons/chat_apps_script.svg'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'
import {Badge, SvgIcon} from '@mui/material'
import {getDimensions, getLostCalls, getTranslation, getUnreadEvents, getUnreadMessages} from '../../store/selectors'
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'

import {smaller} from '../../constants'
import {MenuButton} from '../common'
import {handleLogout} from "../../store/login/actions"

type NavBarProps = {
  showMenu: boolean
  setShowMenu: (menu: boolean) => void
}

const NavBar: React.FC<NavBarProps> = ({ showMenu, setShowMenu }) => {
  const { width } = useSelector(getDimensions)
  const lostCalls = useSelector(getLostCalls)
  const unreadMessages = useSelector(getUnreadMessages)
  const unreadEvents = useSelector(getUnreadEvents)
  const translation = useSelector(getTranslation)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!showMenu) {
      document.getElementById('bytewise-phone')?.focus()
    }
  }, [showMenu])

  const onClickButton = () => {
    if (width <= smaller.width + 40) setShowMenu(false)
  }

  return (
    <Menu show={showMenu}>
      <ButtonContainer>
        <MenuButton beforeClick={onClickButton} path="/phone" exact tooltip={translation.home}>
          <HomeIcon />
        </MenuButton>
      </ButtonContainer>
      <ButtonContainer>
        <MenuButton beforeClick={onClickButton} path="/phone/address-book" exact tooltip={translation.addressBook}>
          <ContactsRounded />
        </MenuButton>
      </ButtonContainer>
      <ButtonContainer>
        <MenuButton beforeClick={onClickButton} path="/phone/history" exact tooltip={translation.registry}>
          <Badge
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            badgeContent={lostCalls}
            overlap="circular"
            color="secondary"
            variant="dot"
          >
            <HistoryRounded />
          </Badge>
        </MenuButton>
      </ButtonContainer>
      <ButtonContainer>
        <MenuButton beforeClick={onClickButton} path="/phone/favorites" exact tooltip={translation.favorites}>
          <StarBorderRounded />
        </MenuButton>
      </ButtonContainer>
      <ButtonContainer>
        <MenuButton beforeClick={onClickButton} path="/messenger" tooltip={translation.messenger}>
          <Badge
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            badgeContent={unreadMessages}
            overlap="circular"
            color="secondary"
            variant="dot"
          >
            <SvgIcon component={MessageIcon} inheritViewBox />
          </Badge>
        </MenuButton>
      </ButtonContainer>
      <ButtonContainer>
        <MenuButton beforeClick={onClickButton} path="/customer-chat" tooltip={translation.chats}>
          <Badge
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            badgeContent={unreadEvents}
            overlap="circular"
            color="secondary"
            variant="dot"
          >
            <SvgIcon component={ChatIcon} inheritViewBox />
          </Badge>
        </MenuButton>
      </ButtonContainer>
      <ButtonContainer>
        <MenuButton beforeClick={onClickButton} path="/operator-queues" tooltip={translation.operatorQueues}>
          <Badge
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            badgeContent={unreadEvents}
            overlap="circular"
            color="secondary"
            variant="dot"
          >
            <Groups />
          </Badge>
        </MenuButton>
      </ButtonContainer>
      <ButtonContainer>
        <MenuButton beforeClick={onClickButton} path="/settings" tooltip={translation.settings}>
          <SettingsRoundedIcon />
        </MenuButton>
      </ButtonContainer>
      {window.occlient.settings.showLogoutButtonOption && (
        <ButtonContainer isFlexEnd={true}>
          <MenuButton beforeClick={() => dispatch(handleLogout())} path="/login" tooltip={translation.logout}>
            <LogoutRounded />
          </MenuButton>
        </ButtonContainer>
      )}
    </Menu>
  )
}

export default NavBar

//region Style

type MenuProps = {
  show: boolean
}
const Menu = styled.div<MenuProps>`
  grid-area: menu;
  overflow: hidden;
  width: ${({ show }) => (show ? 'var(--navbar-width)' : '0')};
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.background.default};
  padding-bottom: 1rem;
`
const ButtonContainer = styled.div<{ isFlexEnd?: boolean }>`
  ${({ isFlexEnd }) => isFlexEnd && 'margin-top: auto;'}
  height: ${rem(2)};
  display: grid;
  place-self: center;
`

//endregion
