import { Outbound } from '../../../../api/rest'
import { rem } from '../../../../helpers/style'
import { ArrowBackRounded, BackspaceRounded, HighlightOffRounded } from '@mui/icons-material'
import { SvgIcon } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { call, goBack, selectOutbound } from '../../../../store/actions'
import {
  getCanCall,
  getCanICallWithoutOutbound,
  getDimensions,
  getOutbounds,
  getRegistry,
  getSelectedOutbound,
  getTranslation
} from '../../../../store/selectors'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMatch } from 'react-router-dom'
import styled from 'styled-components'
import { smaller } from '../../../../constants'
import { ReactComponent as CallIcon } from '../../../../images/call.svg'
import { ActionButton, NumButton, Select } from '../../../common'

const Keypad: React.FC = () => {
  const [phoneNumber, setPhoneNumber] = useState('')

  const inputRef = useRef<HTMLInputElement>(null)
  const stateRef = useRef(phoneNumber)

  const outbounds = useSelector(getOutbounds)
  const selectedOutbound = useSelector(getSelectedOutbound)
  const translation = useSelector(getTranslation)
  const { width } = useSelector(getDimensions)
  const canCall = useSelector(getCanCall)
  const canICallWithoutOutbound = useSelector(getCanICallWithoutOutbound)
  const dispatch = useDispatch()
  const registry = useSelector(getRegistry)

  const match = useMatch(`/phone/keypad/:number?`)

  const theme = useTheme()

  const getLast = useCallback(() => {
    registry.sort((a, b) => b.timestamp - a.timestamp)
    return registry[0]
  }, [registry])

  // Compone il numero visibile sullo screen
  const changeNumber = useCallback(
    (value: string) => {
      // leva gli spazi se il valore è copiato da fonti esterne
      value = value.replace(/\s/g, '')
      if (value.match(/^\+?[0-9*#]*$/g) || value === '') {
        setPhoneNumber(value)
        stateRef.current = value
      }
      else {
        alert(translation.alerts?.wrongPhoneNumberFormat)
      }
    },
    [setPhoneNumber, translation.alerts?.wrongPhoneNumberFormat]
  )

  // Fa partire la chiamata col numero composto
  const dial = useCallback(
    async (number: string) => {
      if (number && number.length > 0) {
        if (!outbounds?.length) {
          if (canICallWithoutOutbound) {
            dispatch(
              call({
                number: number
              })
            )
          } else {
            alert(translation.noOutboundsAlert)
          }
        } else {
          dispatch(
            call({
              number: number
            })
          )
        }
      } else {
        const recentCall = await getLast()
        if (recentCall) {
          changeNumber(recentCall.telephone)
        }
      }
    },
    [dispatch, getLast, changeNumber, canICallWithoutOutbound, outbounds?.length, translation.noOutboundsAlert]
  )

  // Gestore evento in ascolto sulla pressione dei tasti della tastiera
  const keyPress = useCallback(
    (e: KeyboardEvent) => {
      const number = stateRef.current
      if (e.ctrlKey || e.metaKey) return
      e.preventDefault()
      if (e.key === 'Enter') {
        if (canCall) return dial(number)
      }
      if (e.key === 'Delete') return changeNumber('')
      if (e.key === 'Backspace') return changeNumber(number ? number.substring(0, number.length - 1) : '')
      changeNumber(`${number}` + e.key)
    },
    [dial, canCall, changeNumber]
  )

  useEffect(() => {
    const root = document.getElementById('bytewise-phone')
    root?.addEventListener('keydown', keyPress)
    return () => {
      root?.removeEventListener('keydown', keyPress)
    }
  }, [dispatch, keyPress])

  useEffect(() => {
    inputRef?.current?.focus()
  }, [phoneNumber])

  useEffect(() => {
    if (match?.params.number) changeNumber(match.params.number)
  }, [match?.params.number, changeNumber])

  // Cambia l'outbound selezionato
  const changeSelected = useCallback(
    (outbound: Outbound | null) => {
      dispatch(selectOutbound(outbound))
    },
    [dispatch]
  )

  useEffect(() => {
    changeSelected(selectedOutbound)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeSelected, outbounds])

  return (
    <Container>
      <Screen
        value={phoneNumber}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeNumber(e.target.value)}
        onSubmit={() => canCall && dial(stateRef.current)}
        ref={inputRef}
        autoFocus
      />
      <SelectContainer>
        <Select<Outbound>
          getLabel={(outbound) => outbound.name}
          selected={selectedOutbound}
          getValue={(o) => o.id || ''}
          options={outbounds}
          onChange={changeSelected}
          label={translation.outbound}
        />
      </SelectContainer>
      <NumPad>
        <NumButton label={'1'} onClick={() => changeNumber(phoneNumber + '1')} />
        <NumButton label={'2'} miniLabel={'A B C'} onClick={() => changeNumber(phoneNumber + '2')} />
        <NumButton label={'3'} miniLabel={'D E F'} onClick={() => changeNumber(phoneNumber + '3')} />

        <NumButton label={'4'} miniLabel={'G H I'} onClick={() => changeNumber(phoneNumber + '4')} />
        <NumButton label={'5'} miniLabel={'J K L'} onClick={() => changeNumber(phoneNumber + '5')} />
        <NumButton label={'6'} miniLabel={'M N O'} onClick={() => changeNumber(phoneNumber + '6')} />

        <NumButton label={'7'} miniLabel={'P Q R S'} onClick={() => changeNumber(phoneNumber + '7')} />
        <NumButton label={'8'} miniLabel={'T U V'} onClick={() => changeNumber(phoneNumber + '8')} />
        <NumButton label={'9'} miniLabel={'W X Y Z'} onClick={() => changeNumber(phoneNumber + '9')} />

        <NumButton label={'*'} onClick={() => changeNumber(phoneNumber + '*')} />
        <NumButton label={'0'} miniLabel={'+'} onClick={() => changeNumber(phoneNumber + '0')} />
        <NumButton label={'#'} onClick={() => changeNumber(phoneNumber + '#')} />

        <ActionButton onClick={() => changeNumber('')}>
          <HighlightOffRounded style={{ fontSize: 'var(--font-size)' }} />
        </ActionButton>
        <ActionButton disabled={!canCall} onClick={() => dial(stateRef.current)}>
          <SvgIcon style={{ fontSize: 'calc(var(--font-size) * 1.5)' }}>
            <CallIcon style={{ fill: canCall ? '#1BB21F' : theme.palette?.action?.disabled }} />
          </SvgIcon>
        </ActionButton>
        <ActionButton onClick={() => changeNumber(phoneNumber.substring(0, phoneNumber.length - 1))}>
          <BackspaceRounded style={{ fontSize: 'calc(var(--font-size) * 0.9)' }} />
        </ActionButton>
      </NumPad>

      {width < smaller.width * 2 && (
        <ActionButton onClick={() => dispatch(goBack())} style={{ position: 'absolute', top: 10, left: 10 }}>
          <ArrowBackRounded />
        </ActionButton>
      )}
    </Container>
  )
}

export default Keypad

//region Style

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr auto auto;
  height: 100%;
  max-width: 450px;
  max-height: 500px;
  overflow: hidden;
  place-self: start center;
`
const Screen = styled.input`
  place-self: center;
  outline: none;
  border: none;
  text-align: center;
  font-size: calc(var(--font-size) * 1.2);
  background: transparent;
  color: ${({ theme }) => theme.palette.primary?.main};
`
const SelectContainer = styled.div`
  justify-self: center;
  width: 80%;
`
const NumPad = styled.div`
  place-self: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: min(calc(var(--font-size) * 1.1), 10px);
  grid-row-gap: 0;
  height: 100%;
  max-width: 450px;
  padding: ${rem(0.6)} 0;
`

//endregion
