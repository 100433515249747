import { Chat, Guest } from '../../../../api/rest'
import GuestInfo from '../../../pages/CustomerChat/common/GuestInfo'
import React, { useEffect, useState } from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import styled from 'styled-components'

type Props = {
  chat: Chat | null
  guest: Guest | null
}
const UserInfoModal: React.FC<Props> = ({ chat, guest }) => {
  const [guestBackup, setGuestBackup] = useState<Guest | null>(null)

  useEffect(() => {
    if (!guest && chat) {
      setGuestBackup(chat.guest)
    }
  }, [chat, guest])

  return (
    guestBackup && (
      <Container>
        <GuestInfo guest={guestBackup} chat={chat} />
      </Container>
    )
  )
}

export default UserInfoModal

//region Style

const Container = styled(ScrollBar)`
  display: grid;
  place-items: start;
  grid-template-rows: auto 1fr;
  margin-top: 10px;
  height: fit-content;
`

//endregion
