import { Messenger } from '../../../../api'
import { rem } from '../../../../helpers/style'
import { RemoveCircleRounded } from '@mui/icons-material'
import { Button } from '@mui/material'
import { changeRoute } from '../../../../store/applicationState/actions'
import { getConversationsArray, getDimensions, getMe, getPath, getTranslation, getUsersArray } from '../../../../store/selectors'
import React, { useCallback, useMemo, useState } from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import messenger from '../../../../api/messenger'
import { Conversation, Member, User } from '../../../../api/rest'
import { ActionButton, StatusCircle, UserSelection } from '../../../common'

type Props = {
  conversation: Conversation
  closeModal: Function
}
const GroupControl: React.FC<Props> = ({ conversation, closeModal }) => {
  const [showAdd, setShowAdd] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState<Map<number, User>>(new Map())

  const users = useSelector(getUsersArray)
  const conversations = useSelector(getConversationsArray)
  const me = useSelector(getMe)
  const translation = useSelector(getTranslation)
  const { fontSize } = useSelector(getDimensions)
  const { current } = useSelector(getPath)

  const dispatch = useDispatch()

  const isAdmin = useCallback(
    (m: Member | User): boolean =>
      conversation.admins ? !!conversation.admins.find((a) => a.username === m.username) : false,
    [conversation]
  )

  const amIAdmin = useMemo<boolean>(() => {
    return isAdmin(me)
  }, [me, isAdmin])

  const goToConversation = (m: Member) => {
    if (m.username === me.username) return
    closeModal()
    const myMember = Messenger.getMemberFromUser(me)
    const gotConv = Messenger.getConversationByMembers(conversations, [myMember, m])
    if (gotConv) {
      dispatch(changeRoute({ current: `/messenger/${gotConv.id}`, previous: current }))
    } else {
      dispatch(changeRoute({ current: '/messenger/new', state: { otherMember: m } }))
    }
  }

  const addMembers = () => {
    const members = [...selectedUsers.values()].map(Messenger.getMemberFromUser)
    messenger.addMembersToGroup(conversation.id, members, ({ done }) => {
      if (done) {
        setShowAdd(false)
        setSelectedUsers(new Map())
      }
    })
  }

  const removeMember = (m: Member) => {
    messenger.removeMemberFromGroup(conversation.id, m, () => {
      if (m.username === me.username) {
        // ho abbandonato il gruppo
        closeModal()
        dispatch(changeRoute({ current: '/messenger', previous: '/messenger' }))
      }
    })
  }

  const getMemberStatus = (m: Member) => {
    if (m.username === me.username) {
      return me.state
    } else {
      const userFromMember = users.find((u) => u.username === m.username)
      if (!userFromMember) return undefined
      else return userFromMember.state
    }
  }

  return (
    <Container showAdd={showAdd} amIAdmin={amIAdmin}>
      <Title fontSize={fontSize}>{conversation.name}</Title>
      {showAdd ? (
        <>
          <UserSelection
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
            exclude={conversation.members.map((m) => m.username)}
          />
          <Button color="primary" onClick={addMembers} disabled={selectedUsers.size === 0}>
            {translation.next}
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              setSelectedUsers(new Map())
              setShowAdd(false)
            }}
          >
            {translation.cancel}
          </Button>
        </>
      ) : (
        <>
          <UsersList>
            {conversation.members.map((m) => (
              <UserRow key={m.username} fontSize={fontSize}>
                <StatusCircle size="12px" style={{ placeSelf: 'center' }} status={getMemberStatus(m)} />
                <UserRowInfo onClick={() => goToConversation(m)}>
                  {m.name} {m.surname} {isAdmin(m) && <Admin fontSize={fontSize}>{translation.admin}</Admin>}
                </UserRowInfo>
                {amIAdmin && m.username !== me.username ? (
                  <ActionButton onClick={() => removeMember(m)} size="small">
                    <RemoveCircleRounded />
                  </ActionButton>
                ) : (
                  <div />
                )}
              </UserRow>
            ))}
          </UsersList>
          {amIAdmin && (
            <Button
              color="primary"
              onClick={() => {
                setShowAdd(true)
              }}
            >
              {translation.add}
            </Button>
          )}
          <Button
            color="secondary"
            onClick={() => {
              removeMember(Messenger.getMemberFromUser(me))
            }}
          >
            {translation.leave}
          </Button>
        </>
      )}
    </Container>
  )
}

export default GroupControl

//region Style

const Container = styled.div<{ showAdd: boolean; amIAdmin: boolean }>`
  display: grid;
  grid-template-rows: ${({ showAdd, amIAdmin }) =>
    showAdd ? '30px 30px auto 40px 40px' : `30px auto ${amIAdmin ? '40px' : ''} 40px`};
  grid-template-columns: 1fr;
  row-gap: ${rem(0.4)};
  width: 100%;
  height: 100%;
`

const Title = styled.div<{ fontSize: number }>`
  font-size: ${({ fontSize }) => rem(1.1, fontSize)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`

const UsersList = styled(ScrollBar)`
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-rows: repeat(auto-fill, minmax(40px, 1fr));
  grid-template-columns: 1fr;
  box-sizing: border-box;
  max-width: 500px;
  place-self: center;
`

const Admin = styled.span<{ fontSize: number }>`
  font-size: ${({ fontSize }) => rem(0.6, fontSize)};
  border-radius: 15px;
  color: ${({ theme }) => theme.palette.success.main};
  border: 1px ${({ theme }) => theme.palette.success.main} solid;
  padding: 1px 8px;
  width: fit-content;
  align-self: center;
`

const UserRow = styled.div<{ fontSize: number }>`
  display: grid;
  width: 90%;
  place-self: center;
  grid-template-columns: 20px 1fr 0.1fr;
  padding: ${({ fontSize }) => `${rem(0.5, fontSize)} ${rem(0.3, fontSize)}`};
  border-bottom: 1px ${({ theme }) => theme.palette.action?.hover} solid;
  font-size: ${({ fontSize }) => rem(0.8, fontSize)};
`

const UserRowInfo = styled.div`
  width: fit-content;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`
//endregion
