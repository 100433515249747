import { getDimensions } from '../../store/selectors'
import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

interface ButtonProps {
  center?: boolean
  radius?: string
  selected?: boolean
  disabled: boolean
  icon?: {
    height: number
    width: number
    active?: boolean
    fill?: string
  }
  children?: React.ReactNode
}

export const Button: React.FC<ButtonProps> = (props) => {
  const { children, center, radius = '50%', selected = false, icon, disabled } = props
  const circle = useRef<HTMLDivElement>(null)

  const { fontSize } = useSelector(getDimensions)

  const createRipple = (event: any) => {
    if (disabled) return
    const button = event.currentTarget

    const diameter = Math.max(button.clientWidth, button.clientHeight)
    const radius = diameter / 2

    if (circle.current) {
      circle.current.style.width = `${diameter}px`
      circle.current.style.height = `${diameter}px`
      circle.current.style.left = `${center ? '0' : event.clientX - button.offsetLeft - radius}px`
      circle.current.style.top = `${center ? '0' : event.clientY - button.offsetTop - radius}px`
    }
    const ripple = button.getElementsByClassName('ripple')[0]

    if (ripple) {
      ripple.remove()
    }

    button.appendChild(circle.current)
  }

  return (
    <Container
      fontSize={fontSize}
      onClick={createRipple}
      height={icon && `${icon.height * fontSize * Math.sqrt(2)}px`}
      width={icon && `${icon.width * fontSize * Math.sqrt(2)}px`}
      radius={radius}
      selected={selected}
      icon={icon}
      disabled={disabled}>
      <Circle ref={circle} className="ripple" />
      {children}
    </Container>
  )
}

interface ContainerProps {
  radius: string
  selected: boolean
  width?: string
  height?: string
  disabled: boolean
  fontSize: number
  icon?: {
    height: number
    width: number
    active?: boolean
    fill?: string
  }
}

const Container = styled.div<ContainerProps>`
  place-self: center;
  position: relative;
  overflow: hidden;
  transition: background 400ms;
  background-color: transparent;
  min-width: 20px;
  min-height: 20px;
  ${({ width }) => width && 'width:' + width};
  ${({ height }) => height && 'height:' + height};
  outline: 0;
  border: 0;
  border-radius: ${({ radius }) => radius};
  cursor: ${({ disabled }) => (disabled ? 'context-menu' : 'pointer')};
  display: grid;

  & svg {
    place-self: center;
    width: ${({ icon, fontSize }) => icon && icon.width * fontSize + 'px'};
    height: ${({ icon, fontSize }) => icon && icon.height * fontSize + 'px'};
  }

  }
`

const Circle = styled.div`
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
`
