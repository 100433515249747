import React from 'react'

const CancelTransfer: React.FC<{ disabled: boolean }> = ({ disabled }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="-3 -3 29 29" width="24">
      <circle cx="12" cy="12" r="12" fill={disabled ? '#797979' : '#C90303'} />
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path
        id="cancel-transfer-path"
        fill="#FFFFFF"
        d="M12.5 8c-2.65 0-5.05.99-6.9 2.6L3.71 8.71C3.08 8.08 2 8.52 2 9.41V15c0 .55.45 1 1 1h5.59c.89 0 1.34-1.08.71-1.71l-1.91-1.91c1.39-1.16 3.16-1.88 5.12-1.88 3.16 0 5.89 1.84 7.19 4.5.27.56.91.84 1.5.64.71-.23 1.07-1.04.75-1.72C20.23 10.42 16.65 8 12.5 8z"
      />
    </svg>
  )
}

export default CancelTransfer
