import {Idle} from './Idle'
import {InCall} from './InCall'
import {IncomingChat} from './IncomingChat'
import {rem} from '../../../helpers/style'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import {IconButton} from '@mui/material'
import {getBookedChats, getCallInProgress, getDimensions, getVisibility} from '../../../store/selectors'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'
import {mini, smaller} from '../../../constants'
import {ElectronProxy} from "../../../api";
import {setVisibility} from "../../../store/applicationState/actions"

const Floating: React.FC = () => {
  const [width, setWidth] = useState(mini.width)
  const [height, setHeight] = useState(mini.height)

  const isVisibile = useSelector(getVisibility)
  const { fontSize } = useSelector(getDimensions)
  const dispatch = useDispatch()

  useEffect(() => {
    const newWidth = window.occlient.settings.minified?.width || mini.width
    const newHeight = window.occlient.settings.minified?.height || mini.height
    setWidth(newWidth)
    setHeight(newHeight)
  }, [])

  const callInProgress = !!useSelector(getCallInProgress)
  const chatIncoming = [...useSelector(getBookedChats)].length > 0

  const idle = !callInProgress && !chatIncoming

  return (
    <>
      {!isVisibile && (
        <Container width={width} height={height} fontSize={fontSize} withoutShadow={true}>
          {idle && <Idle />}
          {callInProgress && <InCall />}
          {chatIncoming && !callInProgress && <IncomingChat />}
          {/*Bottone per allargare il client*/}
          <IconContainer
            onClick={() => {
              ElectronProxy.minifyClient(false)
              dispatch(setVisibility(true) as any)
                const lsWidth = localStorage.getItem('@occlient/appWidth')
                const lsWidthInt = lsWidth ? parseInt(lsWidth) : null
                const lsHeight = localStorage.getItem('@occlient/appHeight')
                const lsHeightInt = lsHeight ? parseInt(lsHeight) : null
                const width = lsWidthInt || window.occlient.settings.height || smaller.width
                const browserHeadingHeight = window.outerHeight - window.innerHeight
                const height = (lsHeightInt || window.occlient.settings.height || smaller.height) + browserHeadingHeight
                window.resizeTo(width, height)
              }
            }
          >
            <FullscreenIcon style={{ fontSize: rem(1.5) }} />
          </IconContainer>
        </Container>
      )}
    </>
  )
}

export default Floating

//region Style

type ContainerProps = {
  $isBrowser?: boolean
  withoutShadow?: boolean
  width: number
  height: number
  fontSize: number
}
const Container = styled.div<ContainerProps>`
  --font-size: ${({ fontSize }) => `${fontSize}px`};
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  background: ${({ theme }) => theme.palette.background?.paper};
  color: ${({ theme }) => theme.palette.text?.primary};
  display: grid;
  position: relative;
  ${({ $isBrowser }) => $isBrowser && 'border-radius: 15px'};
  ${({ withoutShadow }) =>
    !withoutShadow &&
    `-webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.75);`}
`
const IconContainer = styled(IconButton)`
  position: absolute;
  padding: 0 4px;
  bottom: 9px;
  left: 2px;
  width: 30px;
  height: 30px;
`

//endregion
