import React from 'react'
import styled from 'styled-components'
import { NumButton } from '../../../common'

//TODO: implementare qui la logica del keypress per renderlo comune a tutti
type NumPadProps = {
  changeNumber: Function
}
const NumPad: React.FC<NumPadProps> = ({ changeNumber }) => {
  return (
    <Container>
      <NumButton label={'1'} onClick={() => changeNumber('1')} />
      <NumButton label={'2'} miniLabel={'A B C'} onClick={() => changeNumber('2')} />
      <NumButton label={'3'} miniLabel={'D E F'} onClick={() => changeNumber('3')} />

      <NumButton label={'4'} miniLabel={'G H I'} onClick={() => changeNumber('4')} />
      <NumButton label={'5'} miniLabel={'J K L'} onClick={() => changeNumber('5')} />
      <NumButton label={'6'} miniLabel={'M N O'} onClick={() => changeNumber('6')} />

      <NumButton label={'7'} miniLabel={'P Q R S'} onClick={() => changeNumber('7')} />
      <NumButton label={'8'} miniLabel={'T U V'} onClick={() => changeNumber('8')} />
      <NumButton label={'9'} miniLabel={'W X Y Z'} onClick={() => changeNumber('9')} />

      <NumButton label={'*'} onClick={() => changeNumber('*')} />
      <NumButton label={'0'} miniLabel={'+'} onClick={() => changeNumber('0')} />
      <NumButton label={'#'} onClick={() => changeNumber('#')} />
    </Container>
  )
}

export default NumPad

//region Style

const Container = styled.div`
  place-self: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: 100%;
  max-width: 450px;
  max-height: 200px;
`

//endregion
