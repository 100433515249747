import { ArrowBackIosRounded as BackIcon } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { rem } from '../../../../../helpers/style'
import { getCallInProgress, getTranslation } from '../../../../../store/selectors'
import { ActionButton } from '../../../../common'

type CallInfoStatusProps = {
  type: 'add' | 'transfer'
  goBack: Function
  infoFontSize?: number
  disclaimerFontSize?: number
}

const CallInfoTransfer: React.FC<CallInfoStatusProps> = ({ type, goBack, infoFontSize, disclaimerFontSize }) => {
  const [label, setLabel] = useState('')
  const call = useSelector(getCallInProgress)
  const translation = useSelector(getTranslation)

  useEffect(() => {
    if (!call) return
    const user = call.user
    if (user) setLabel(`${user.surname} ${user.name}`)
    else setLabel(String(call.number))
  }, [call])

  return (
    <Container>
      <Back>
        <ActionButton onClick={goBack}>
          <BackIcon />
        </ActionButton>
      </Back>
      <Info fontSize={infoFontSize || 1}>
        {translation.callInfo} {label}
      </Info>
      <Disclaimer fontSize={disclaimerFontSize || (infoFontSize ? infoFontSize * 0.8 : 0.8)}>
        {type === 'add' ? translation.addInCall : translation.transferTo}
      </Disclaimer>
    </Container>
  )
}

export default CallInfoTransfer

//region Style

const Container = styled.div`
  grid-row: call-info-transfer;
  place-self: center;
  align-self: start;
  place-items: center;
  display: grid;
  grid-auto-rows: minmax(20px, 1fr);
  grid-template-columns: 1fr 9fr;
  grid-template-areas:
    'back info'
    'back disclaimer';
  width: 100%;
`

const Info = styled.div<{ fontSize: number }>`
  grid-area: info;
  text-align: center;
  color: ${({ theme }) => theme.palette.primary?.main};
  font-size: ${({ fontSize }) => rem(fontSize)};
`

const Disclaimer = styled.div<{ fontSize: number }>`
  grid-area: disclaimer;
  place-self: center;
  text-align: center;
  font-size: ${({ fontSize }) => rem(fontSize)};
`

const Back = styled.div`
  grid-area: back;
  place-self: center start;
`

//endregion
