import withAuthentication from '../../hoc/withAuthentication'
import {rem} from '../../../helpers/style'
import {Chip, Link as MuiLink, Typography} from '@mui/material'
import {getTheme, getVoips} from '../../../store/selectors'
import React from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import {useSelector} from 'react-redux'
import styled from 'styled-components'
import pkg from '../../../../package.json'
import imgLogoLight from '../../../images/OC_logo_colour.png'
import imgLogoDark from '../../../images/OC_logo_white.png'
import {host} from "../../../configuration"
import {ReactComponent as OC} from "../../../images/OC.svg"

const Info: React.FC = () => {
  const theme = useSelector(getTheme)
  const voips = useSelector(getVoips)

  return (
    <Page>
      <LogoContainer>
        <img src={theme === 'light' ? imgLogoLight : imgLogoDark} alt="logo"/>
      </LogoContainer>
      <Typography fontWeight="bold">OpenCommunication Client v{pkg.version}</Typography>
      <Typography>Interni registrati</Typography>
      {voips.map((v) => (
        <Chip key={v.id} label={v.extension} sx={{width: 100, placeSelf: 'center'}}/>
      ))}
      <ChangelogRow>
        <ChangelogItem href={`${host}/client/CHANGELOG.html`} target={'_blank'}>
          <OC style={{height: '0.7rem', marginTop: '2px'}}/>
          <MuiLink component="span" style={{placeSelf: 'center', fontWeight: 500}}>
            Changelog
          </MuiLink>
        </ChangelogItem>
      </ChangelogRow>
    </Page>
  )
}

export default withAuthentication(Info)

//region Style

const Page = styled(ScrollBar)`
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    gap: ${rem(1)};
    padding-top: 10px;
    font-size: ${rem(0.8)};
    font-weight: bold;
    text-align: center;
`

const ChangelogRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`

const ChangelogItem = styled.a`
    width: min-content;
    margin-top: 16px;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    column-gap: 10px;
    text-decoration: none;

    &:hover {
        color: inherit;
    }
`

const LogoContainer = styled.div`
    place-self: center;
    width: 90%;
    max-width: 300px;
    display: flex;
    place-items: center;

    img {
        width: 50%;
        height: auto;
        margin: auto;
    }
`

//endregion
