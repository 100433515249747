import { rem } from '../../../../helpers/style'
import { DialpadRounded } from '@mui/icons-material'
import { IconButton, SvgIcon } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { dmtf, hangup } from '../../../../store/phone/actions'
import { getDimensions, getTheme } from '../../../../store/selectors'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMatch } from 'react-router-dom'
import styled from 'styled-components'
import { smaller } from '../../../../constants'
import { ReactComponent as HungIcon } from '../../../../images/hang.svg'
import { ActionButton } from '../../../common'
import AddOrTransfer from './AddOrTransfer'
import CallButtons from './CallButtons'
import CallInfo from './CallInfo/CallInfo'
import NumPad from './NumPad'

const CallPage: React.FC = () => {
  const [number, setNumber] = useState('')
  const [showKey, setShowKey] = useState(false)
  const [showTransfer, setShowTransfer] = useState<'add' | 'transfer' | null>(null)

  const inputRef = useRef<HTMLInputElement>(null)
  const stateRef = useRef(number)

  const match = useMatch('/phone/call/:transfer?')
  const transfer = match?.params?.transfer

  const theme = useSelector(getTheme)
  const { height } = useSelector(getDimensions)

  const dispatch = useDispatch()

  const muiTheme = useTheme()

  const changeNumber = useCallback(
    (value: string) => {
      if (value.match(/^\+?[0-9*#]*$/g) || value === '') {
        stateRef.current = value
        setNumber(value)
        dispatch(dmtf(value))
      }
    },
    [dispatch]
  )

  const changeNumberForKeypad = (value: string) => {
    const number = stateRef.current
    if (value.match(/^\+?[0-9*#]*$/g) || value === '') {
      stateRef.current = number + value
      setNumber(number + value)
      dispatch(dmtf(value))
    }
  }

  const keyPress = useCallback(
    (e: KeyboardEvent) => {
      const number = stateRef.current
      if (e.ctrlKey || e.metaKey) return
      e.preventDefault()
      if (e.key === 'Delete') return changeNumber('')
      if (e.key === 'Backspace') return changeNumber(number ? number.substring(0, number.length - 1) : '')
      changeNumber(`${number}` + e.key)
    },
    [changeNumber]
  )

  useEffect(() => {
    const root = document.getElementById('bytewise-phone')
    if (showTransfer) {
      root?.removeEventListener('keydown', keyPress)
      return
    }
    root?.addEventListener('keydown', keyPress)
    return () => {
      root?.removeEventListener('keydown', keyPress)
    }
  }, [showTransfer, keyPress])

  useEffect(() => {
    transfer && setShowTransfer(transfer as 'add' | 'transfer')
  }, [transfer])

  const changePage = (page: 'add' | 'transfer' | null) => {
    setShowTransfer(page)
  }

  const KeyPad: React.FC = () => (
    <>
      <KeypadContainer>
        <Screen value={number} onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeNumber(e.target.value)} ref={inputRef} autoFocus />
        <NumPad changeNumber={changeNumberForKeypad} />
      </KeypadContainer>
      <BottomBar />
    </>
  )

  //TODO:dopo aver attaccato una chiamata provare con tasto indietro del browser anche su electron
  const BottomBar: React.FC = () => {
    return (
      <FunctionalButton>
        <HungContainer>
          <ActionButton onClick={() => dispatch(hangup())}>
            <SvgIcon style={{ fontSize: rem(1.8) }}>
              <HungIcon />
            </SvgIcon>
          </ActionButton>
        </HungContainer>
        <KeyContainer>
          <IconButton
            onClick={() => setShowKey(!showKey)}
            style={{
              backgroundColor: theme === 'light' ? '#115B72' : '#37C6F2',
              width: 26,
              height: 26,
              placeSelf: 'center'
            }}
          >
            <DialpadRounded htmlColor={muiTheme.palette.background.paper} />
          </IconButton>
        </KeyContainer>
      </FunctionalButton>
    )
  }

  const shouldHideInfo = useMemo(() => {
    if (height < smaller.height) {
      return showKey
    }
    return false
  }, [height, showKey])

  return (
    <Container addOrTransfer={!!showTransfer}>
      {!shouldHideInfo && !showTransfer && (
        <CallInfo counterFont={0.8} hideInboundInfo={showKey} hideOutboundInfo={showKey} />
      )}
      {showKey && <KeyPad />}
      {!showKey && showTransfer && <AddOrTransfer page={showTransfer} goBack={() => setShowTransfer(null)} />}
      {!showKey && !showTransfer && (
        <>
          <CallButtons changePage={changePage} />
          <BottomBar />
        </>
      )}
    </Container>
  )
}

export default CallPage

//region Style

interface ContainerProps {
  addOrTransfer: boolean
}

//grid-auto-rows: minmax(40px, 1fr);
const Container = styled.div<ContainerProps>`
  display: grid;
  place-self: center;
  grid-template-rows: ${({ addOrTransfer }) => (addOrTransfer ? '1fr' : '1fr auto auto')};
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  padding: ${rem(0.5)} ${rem(1.5)};
  max-height: 500px;
  max-width: 300px;
`
const HungContainer = styled.div`
  grid-area: hung;
  display: grid;
`
const KeyContainer = styled.div`
  grid-area: keypad;
  height: 100%;
  display: grid;
`
const KeypadContainer = styled.div`
  display: grid;
  grid-template-rows: auto;
  height: 100%;
  overflow: hidden;
`

const Screen = styled.input`
  place-self: center;
  outline: none;
  border: none;
  text-align: center;
  font-size: calc(var(--font-size) * 1.2);
  background: transparent;
  color: ${({ theme }) => theme.palette.text?.primary};
`

const FunctionalButton = styled.div`
  place-self: center;
  display: grid;
  grid-template-columns: 1fr [hung]1fr [keypad]1fr;
  width: 70%;
  height: 100%;
  padding: 10px 0;
`

//endregion
