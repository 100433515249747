// Dimensioni vecchio client
export const smaller = {
  width: 280,
  height: 525
}

// Dimensioni mockup nuovo client
export const small = {
  width: 414,
  height: 736
}

//Dimensioni Miniclient
export const mini = {
  width: 280,
  height: 125
}

export const minimumDimensions = {
  width: 280,
  height: 340
}
