import ElectronProxy from '../../../electron'
import {
  addressbooksList,
  addressbooksUpdate,
  departmentsList,
  departmentsUpdate,
  pausesList,
  pausesUpdate,
  statusesList,
  statusesUpdate,
  usersList,
  usersUpdate
} from '../../../../store/entities/actions'
import * as phoneActions from '../../../../store/phone/actions'
import {autoAnswer, incomingCallInfo} from '../../../../store/phone/actions'
import {getSelectedLanguage} from '../../../../store/selectors'
import {RootState} from '../../../../store/types'
import {ThunkDispatch} from 'redux-thunk'
import {Socket} from 'socket.io-client'
import isElectron from '../../../../helpers/isElectron'
import log from '../../../../helpers/logger'
import {AddressBook, Department, InboundCallInfo, Pause, Status, User} from '../../../rest'
import * as t from '../eventsTypes'
import {setTranslation} from '../../../../store/language/actions'
import {getTranslation} from '../../../../helpers/getTranslation'
import {setTheme} from '../../../../store/theme/actions'
import {handleLogout} from '../../../../store/login/actions'

export const handleCoreEvents = (socket: Socket, dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
  log.debug('Adding event listeners for core socket')
  socket.on(t.status_change, (payload: Status | Pause) => {
    log.info(`Received status change: ${payload.name}`)
    dispatch(phoneActions.setStatus(payload))
    ElectronProxy.changeState(payload)
    window.occlient.emit('phone:status-change', payload)
  })
  // per le chiamate inbound possono arrivare info aggiuntive dal core come cliente servizio ecc...
  // inoltre se su quella coda c'è la risposta automatica viene notificato qui
  socket.on(t.incoming_call, (payload: InboundCallInfo & { autoAnswer: number | null }) => {
    dispatch(incomingCallInfo(payload))
    payload.autoAnswer && dispatch(autoAnswer(payload.autoAnswer))
  })
  socket.on(t.queue_call_answered, (payload: any) => {
    log.info(`Received core event ${t.queue_call_answered}`, payload)
    dispatch(phoneActions.removeIncomingCall(payload))
  })
  socket.on(t.users_list, (users: User[]) => {
    log.info(`Received core event ${t.users_list}`)
    const usersMap = new Map(users.map((u) => [u.id, u]))
    dispatch(usersList(usersMap))
  })
  socket.on(t.users_update, (user: User) => {
    const me = sessionStorage.getItem('@occlient/user')
    if (me && user.id === JSON.parse(me).id) {
      const currentTheme = sessionStorage.getItem('@occlient/theme')
      const currentLang = sessionStorage.getItem('@occlient/selectedLanguage')
      if (currentTheme !== user.theme) {
        dispatch(setTheme(user.theme))
      }
      if (currentLang !== user.language?.code) {
        getTranslation(user.language!.code).then((data: any) => {
          dispatch(setTranslation(user.language!.code, { ...data }))
        })
      }
    }
    dispatch(usersUpdate(user))
  })
  socket.on(t.addressbooks_list, (addressbooks: AddressBook[]) => {
    const addressbooksMap = new Map(addressbooks.map((a) => [a.id, a]))
    dispatch(addressbooksList(addressbooksMap))
  })
  socket.on(t.addressbooks_update, (addressbook: AddressBook) => {
    dispatch(addressbooksUpdate(addressbook))
  })
  socket.on(t.pauses_list, (pauses: Pause[]) => {
    if (isElectron()) {
      const electron = window.require('electron')
      const selectedLanguage = getSelectedLanguage(getState())
      electron.ipcRenderer.send('pauses', pauses, selectedLanguage)
    }
    dispatch(pausesList(pauses))
  })
  socket.on(t.pauses_update, (pause: Pause) => {
    dispatch(pausesUpdate(pause))
  })
  socket.on(t.departments_list, (departments: Department[]) => {
    const departmentsMap = new Map(departments.map((d) => [d.id, d]))
    dispatch(departmentsList(departmentsMap))
  })
  socket.on(t.departments_update, (department: Department) => {
    dispatch(departmentsUpdate(department))
  })
  socket.on(t.statuses_list, (statuses: Status[]) => {
    dispatch(statusesList(statuses))
  })
  socket.on(t.statuses_update, (status: Status) => {
    dispatch(statusesUpdate(status))
  })
  socket.on(t.force_logout, () => {
    dispatch(handleLogout(true) as any)
  })
}
