import ElectronProxy from '../../api/electron'
import Phone, {CallOptions, DeviceSettings, SpyOptions} from '../../api/phone'
import {JanusJS} from '@bytewise/janus'
import {coreApi} from '../../configuration'
import {getUserByVoipExtension, isInternalCall} from '../../helpers/calls'
import {makeHeader} from '../../hooks/helpers'
import axios from 'axios'
import {DateTime} from 'luxon'
import {ThunkDispatch} from 'redux-thunk'
import {v1 as uuid} from 'uuid'
import {Inbound, InboundCallInfo, Outbound, Pause, Status, Statuses, User, VoipInfo} from '../../api/rest'
import {can_close_transfer, cancel_transfer, transfer_closed} from '../../api/sockets/core/eventsTypes'
import {smaller} from '../../constants'
import isElectron from '../../helpers/isElectron'
import log from '../../helpers/logger'
import logger from '../../helpers/logger'
import ringtone from '../../sounds/ringtone.mp3'
import otherRingtone from '../../sounds/other-ringtone.mp3'
import {changeRoute, setVisibility} from '../applicationState/actions'
import {
  getCallInProgress,
  getCanICallWithoutOutbound,
  getDimensions,
  getLostCalls,
  getNotifications,
  getOutbounds,
  getPath,
  getPeers,
  getRingerVolume,
  getSelectedDevices,
  getSelectedOutbound,
  getStatus,
  getToken,
  getTranslation as getStoreTranslation,
  getTranslation,
  getUsers,
  getUsersArray,
  getVoips
} from '../selectors'
import {wsAddEventListener, wsRemoveEventListener} from '../sockets/actions'
import {RootState} from '../types'
import * as t from './types'
import {Bounds, Call, CallStatus, HangupOptions, TransferOption} from './types'
import {appendRegistryCall, removeCallFromRegistry, updateRegistryCall} from '../../helpers/registry'

type TD = ThunkDispatch<RootState, null, any>

export const setStatus = (payload: Status | Pause) => ({
  type: t.STATUS_CHANGE,
  payload
})

export const setReconnecting = (payload: boolean) => ({
  type: t.SET_RECONNECTING,
  payload
})

export const setCoreConnected = (payload: boolean) => ({
  type: t.SET_CORE_CONNECTED,
  payload
})

export const changeStatus = (payload: Status | Pause) => async (dispatch: TD, getState: () => RootState) => {
  const currentState = getStatus(getState())
  const token = getToken(getState())

  if (currentState.isStatus && currentState.id !== Statuses.idle) {
    // non puoi cambiare stato se non sei idle e sei in uno stato (in call ecc...)
    return
  }
  if (payload.id === Statuses.idle) {
    axios.delete(`${coreApi}/users/pause`, {headers: {Authorization: 'Bearer ' + token}}).catch((e) => {
      log.error('Could not remove user status', e)
    })
  } else {
    axios
      .put(`${coreApi}/users/pause/${payload.id}`, {}, {headers: {Authorization: 'Bearer ' + token}})
      .catch((e) => {
        log.error('Could not change user status', e)
      })
  }
}

export const notifyDisconnection = () => async (_dispatch: TD, getState: () => RootState) => {
  const notifications = getNotifications(getState())
  const translation = getStoreTranslation(getState())
  if (notifications) {
    if (isElectron()) {
      ElectronProxy.notifyDisconnection()
    } else {
      const notification = new Notification(translation.disconnected, {
        body: translation.disconnectedNotificationBody,
        dir: 'ltr'
      })
      const clickHandler = () => {
        notification.close()
        window.focus()
        takeDownListener()
      }
      const takeDownListener = () => notification.removeEventListener('click', clickHandler)
      notification.addEventListener('click', clickHandler)
    }
  }
}
export const selectOutbound = (payload: Outbound | null) => ({
  type: t.SELECT_OUTBOUND,
  payload
})

export const addVoip = (payload: VoipInfo) => ({type: t.ADD_VOIP, payload})

export const setCallInProgress = (payload: t.Call | null) => ({
  type: t.SET_CALL_IN_PROGRESS,
  payload
})

export const editCallInProgress = (payload: Partial<t.Call>) => (dispatch: TD, getState: () => RootState) => {
  const callInProgress = getCallInProgress(getState())
  if (!callInProgress) return
  dispatch(setCallInProgress({...callInProgress, ...payload}))
}

export const callStatusChange = (payload: CallStatus) => ({
  type: t.CALL_STATUS_CHANGE,
  payload
})

export const addLostCall = () => (dispatch: TD, getState: () => RootState) => {
  const lostCalls = getLostCalls(getState())
  ElectronProxy.setBadge(lostCalls + 1, 'phone')
  dispatch({type: t.SET_LOST_CALLS, payload: lostCalls + 1})
}

export const resetLostCalls = () => ({type: t.SET_LOST_CALLS, payload: 0})

export const setBitrate = (payload: string | null) => ({type: t.SET_BITRATE, payload})

//region Azioni janus

/** gestione di una chiamata in arrivo */
export const incomingCall =
  (opts: {
    number: string
    jsep: JanusJS.JSEP
    voip: VoipInfo
    callId: string | null
    predictiveCampaignId?: string
    outboundId?: string
  }) =>
    async (dispatch: TD, getState: () => RootState) => {
      const {number, jsep, voip, callId, predictiveCampaignId, outboundId} = opts
      // Riproduco la suoneria
      const audioEl: HTMLMediaElement | null = document.getElementById('occlient-ringtone') as HTMLMediaElement
      const phonePeers = getPeers(getState())
      const ringVolume = getRingerVolume(getState())
      if (audioEl) {
        // Riproduco una suoneria diversa se la chiamata è interna
        if (isInternalCall(number, phonePeers)) {
          audioEl.src = otherRingtone
        } else {
          audioEl.src = ringtone
        }
        audioEl.volume = ringVolume
        audioEl.play().catch((err) => logger.error(err))
      }

      const notifications = getNotifications(getState())
      const users = getUsersArray(getState())
      const translation = getTranslation(getState())
      const {current} = getPath(getState())
      const {width} = getDimensions(getState())
      const token = getToken(getState())
      // Se la chimaata è call back come numero sorgente viene dato l'alias o l'iip di asterisk
      if (opts?.number === 'asterisk' || opts?.number.includes('.')) opts.number = 'Open Communication'

      let type: 'inbound' | 'predictive' | 'callback' | 'outbound' = 'inbound'

      let predictiveCampaign
      if (predictiveCampaignId) {
        type = 'predictive'
        const response = await axios
          .get(`${coreApi}/predictive-campaigns/${predictiveCampaignId}`, {
            headers: {Authorization: `Bearer ${token}`}
          })
          .catch((e) => logger.error(e))
        const fullPredictiveCampaign = response?.data?.payload
        predictiveCampaign = {
          id: fullPredictiveCampaign.id,
          name: fullPredictiveCampaign.name,
          prefix: fullPredictiveCampaign.outbound.prefix
        }
      }

      let callBackOutbound
      if (outboundId) {
        type = 'callback'
        const response = await axios
          .get(`${coreApi}/outbounds/${outboundId}`, {
            headers: {Authorization: `Bearer ${token}`}
          })
          .catch((e) => logger.error(e))
        const fullCallBackOutbound = response?.data?.payload
        callBackOutbound = {
          id: fullCallBackOutbound.id,
          name: fullCallBackOutbound.name,
          prefix: fullCallBackOutbound.prefix
        }
      }

      const isInternal = isInternalCall(number, phonePeers)
      log.debug(`Incoming call from ${number}, ocuuid: ${callId}`)
      let userFrom: any = null
      if (isInternal) {
        // Se la chiama è interna cerco l'utente con quel numero (voip.extension)
        userFrom = users.find((u) => u.userVoips.find((v) => v.voip.extension === parseInt(number)))
        log.silly('Call is internal, from user: ', userFrom)
      } else {
        log.silly('Call is not internal')
      }

      // Inizializza l'oggetto chiamata
      const callInProgress: Call = {
        isInternal,
        prefix: '',
        status: CallStatus.incoming,
        number,
        type,
        boundness: Bounds.in,
        voip,
        jsep
      }

      if (isInternal && userFrom) callInProgress.user = userFrom

      const callIdFromState = callId
      appendRegistryCall({
        id: callId,
        userName: userFrom ? `${userFrom.surname} ${userFrom.name}` : number,
        userId: userFrom ? userFrom.id : null,
        timestamp: DateTime.now().toMillis(),
        inbound: userFrom?.inbounds?.map((inbound: Inbound) => inbound.name).join(', '),
        outbound: callBackOutbound || predictiveCampaign || null,
        telephone: number,
        outcome: 'notAnswered',
        type: t.Bounds.in
      })
      callInProgress.id = callId || callIdFromState
      log.debug('Call in progress', callInProgress)
      dispatch(setCallInProgress(callInProgress))
      //Controllare che il prossimo path deve essere incoming-call
      if (width <= smaller.width * 2) {
        dispatch(
          changeRoute({
            previous: current,
            current: '/phone/incoming-call'
          })
        )
      }
      if (notifications) {
        if (isElectron()) {
          ElectronProxy.incomingCall(callInProgress)
        } else {
          const notification = new Notification(translation.incomingCallNotification, {
            body: userFrom ? `${userFrom.surname} ${userFrom.name}` : number,
            silent: true,
            dir: 'ltr'
          })
          const clickHandler = () => {
            dispatch(
              changeRoute({
                previous: current,
                current: '/phone/incoming-call'
              })
            )
            notification.close()
            window.focus()
            takeDownListener()
          }
          const takeDownListener = () => notification.removeEventListener('click', clickHandler)
          notification.addEventListener('click', clickHandler)
          window.occlient.once('phone:call-in-progress', () => notification && notification.close())
          window.occlient.once('phone:call-end', () => notification && notification.close())
        }
      }
    }

/** gestione della risposta ad una chiamata in arrivo */
export const answer = () => async (dispatch: TD, getState: () => RootState) => {
  log.debug('Answering call')
  //Stoppo la suoneria
  const audioEl: HTMLMediaElement | null = document.getElementById('occlient-ringtone') as HTMLMediaElement
  if (audioEl) {
    audioEl.pause()
    audioEl.currentTime = 0
    audioEl.src = ''
  }

  const call = getCallInProgress(getState())
  const {previous, current} = getPath(getState())

  if (!call || !call.voip.sip || !call.jsep || call.status !== CallStatus.incoming) {
    log.error('Could not answer, wrong informations')
    return
  }
  try {
    await call.voip.sip.answer(call.jsep)
    const newCall = {...call}
    if (call.id) {
      updateRegistryCall(call.id, {
        outcome: 'answered'
      })
    }

    newCall.startTime = DateTime.now().toMillis()
    if (newCall.autoAnswerTimeout) window.clearTimeout(newCall.autoAnswerTimeout)
    newCall.autoAnswerTimeout = undefined
    newCall.autoAnswerTime = undefined
    dispatch(setCallInProgress(newCall))

    // redirect
    if (current.includes('call')) {
      dispatch(
        changeRoute({
          current: '/phone/call',
          previous
        })
      )
    }
  } catch (e) {
    window.occlient.emit('phone:input-device-error')
    log.error('Could not answer call', e)
  }
}

/** gestione delle chiamate outbound effettuate dal client */
export const call = (opts: CallOptions) => async (dispatch: TD, getState: () => RootState) => {
  log.debug(`Starting call to ${opts.number}`)
  const oldCallInProgress = getCallInProgress(getState())
  const {width} = getDimensions(getState())

  if (oldCallInProgress) {
    log.debug('Another call is in progress')
    return
  }
  const {voip, number, outbound, path, user} = opts
  const voips = getVoips(getState())
  const users = getUsers(getState())
  const selectedOutbound = getSelectedOutbound(getState())
  const phonePeers = getPeers(getState())

  const voipToUse = voip && voip.sip ? voip : voips[0]
  if (!voipToUse) {
    //TODO: gestire la situazione anomala
    logger.error('Could not find a voip to use')
    return
  }

  // Controllo che il numero da chiamare non sia quello di un mio interno
  if (voips.find((v) => String(v.extension) === number)) {
    // Non puoi chiamarti da solo
    logger.error('Could not call yourself')
    return
  }
  const isInternal = isInternalCall(number, phonePeers)

  let outboundService = outbound || selectedOutbound

  const callInProgress: Call = {
    number,
    prefix: isInternal ? '' : outboundService ? String(outboundService.prefix) : '',
    boundness: t.Bounds.out,
    voip: voipToUse,
    status: CallStatus.pending,
    isInternal
  }

  let userTo
  if (callInProgress.isInternal) {
    userTo = getUserByVoipExtension([...users.values()], number)
    callInProgress.user = userTo
  } else if (user) {
    callInProgress.user = user
  }
  const devices: DeviceSettings = {
    audioinput: getSelectedDevices(getState()).microphone
  }
  const callId = uuid()
  const currentOutbound = outbound || selectedOutbound || undefined
  try {
    await Phone.call({
      ...opts,
      voip: voipToUse,
      devices,
      isInternal,
      outbound: currentOutbound,
      callId
    })
    //inserisco sul db le informazioni utili all analisi
    const callIdFromIndexDB = callId
    appendRegistryCall({
        id: callId,
        userName: userTo ? `${userTo.surname} ${userTo.name}` : number,
        userId: userTo?.id!,
        timestamp: DateTime.now().toMillis(),
        telephone: number,
        outcome: 'notAnswered',
        type: t.Bounds.out,
        inbound: '',
        outbound:
          outboundService && !isInternal
            ? {
              id: outboundService.id!,
              prefix: outboundService.prefix!,
              name: outboundService.name!
            }
            : null
      }
    )
    callInProgress.id = callId || callIdFromIndexDB
    callInProgress.outbound = currentOutbound?.name
    callInProgress.customer = currentOutbound?.customer
    callInProgress.service = currentOutbound?.service as string
    dispatch(setCallInProgress(callInProgress))

    const newPath = path || {
      current: '/phone/call'
    }
    if (width <= smaller.width * 2) dispatch(changeRoute(newPath))
  } catch (e: any) {
    //TODO: gestire errore
    dispatch(setCallInProgress(null))
    log.error('errore')
    log.error(e.message)
  }
}

/** manda l'hangup a janus, quindi è a seguito della pressione del bottone dall'utente */
export const hangup =
  (opts: HangupOptions = {}) =>
    async (dispatch: TD, getState: () => RootState) => {
      const {voip} = opts
      const callInProgress = getCallInProgress(getState())
      const {previous} = getPath(getState())

      if (!callInProgress) {
        return
      }
      const voipToUse =
        callInProgress.voip && callInProgress.voip.sip ? callInProgress.voip : voip || getVoips(getState())[0]

      log.silly('User is hanging up', callInProgress)
      if (!voipToUse || !voipToUse.sip) {
        logger.error('HANGUP: no voip to use, or sip')
        dispatch(setCallInProgress(null))
        dispatch(
          changeRoute({
            current: '/phone',
            previous
          })
        )
        return
      }

      // l'utente sta attaccando
      try {
        if (callInProgress.status === CallStatus.incoming) {
          log.silly('User has declined')
          await Phone.decline(voipToUse.sip)
        } else {
          log.silly('User has hanged up')
          await Phone.hang(voipToUse.sip)
        }
      } catch (e: any) {
        log.error(e.message)
        dispatch(setCallInProgress(null))
        dispatch(
          changeRoute({
            current: '/phone',
            previous
          })
        )
        return
      }
    }

/** viene ricevuto da janus sia quando attacca l'interlocutore sia l'utente del client,
 *  quindi viene gestito qui in entrambi i casi */
export const hangupReceived = () => (dispatch: TD, getState: () => RootState) => {
  log.silly('Hangup received')
  //Stoppo la suoneria
  const audioEl: HTMLMediaElement | null = document.getElementById('occlient-ringtone') as HTMLMediaElement
  if (audioEl) {
    audioEl.pause()
    audioEl.currentTime = 0
    audioEl.src = ''
  }
  const callInProgress = getCallInProgress(getState())
  const {previous, current} = getPath(getState())
  if (!callInProgress) {
    return
  }
  log.silly('Received hangup from janus', callInProgress)
  if (callInProgress.boundness === Bounds.in && callInProgress.status === CallStatus.incoming) {
    log.verbose('Adding call to lost calls')
    dispatch(addLostCall())
  }
  // calculate duration and update registry
  if (callInProgress && callInProgress.id && callInProgress?.startTime) {
    const now = DateTime.now().toMillis()
    let duration = now - callInProgress.startTime
    updateRegistryCall(callInProgress?.id, {
      duration: duration
    })
  }
  dispatch(setCallInProgress(null))
  if (current.includes('call')) {
    // Se l'ultima pagina visitata è una pagina relativa alle chiamate in corso (/call) oppure la pagina di login (/login)
    // faccio redirect sulla home page (/phone)
    const goTo = previous.includes('call') || previous.includes('login') ? '/phone' : previous
    dispatch(
      changeRoute({
        current: goTo,
        previous
      })
    )
  }
}

export const hold = () => (dispatch: TD, getState: () => RootState) => {
  log.debug('Hold action')
  const call = getCallInProgress(getState())
  if (!call || !call.voip.sip) return
  if (call.voip.sip.isHold) {
    log.debug('Removing hold')
    call.voip.sip
      .unhold()
      .then(() => {
        dispatch(callStatusChange(CallStatus.ongoing))
        window.occlient.emit('phone:call-hold', false)
      })
      .catch((e) => {
        log.error('Could not unhould', e)
      })
  } else {
    log.debug('Setting hold')
    call.voip.sip
      .hold()
      .then(() => {
        dispatch(callStatusChange(CallStatus.hold))
        window.occlient.emit('phone:call-hold', true)
      })
      .catch((e) => {
        log.error('Could not unhould', e)
      })
  }
}

export const mute = () => async (dispatch: TD, getState: () => RootState) => {
  log.debug('Mute action')
  const call = getCallInProgress(getState())
  if (!call || !call.voip.sip) return
  const isMuted = call.status === CallStatus.muted
  try {
    await call.voip.sip.mute(!isMuted)
    if (isMuted) dispatch(callStatusChange(CallStatus.ongoing))
    else dispatch(callStatusChange(CallStatus.muted))
  } catch (e) {
    log.error('Could not mute', e)
    // TODO: notifica errore
  }
}

export const dmtf = (tone: string) => async (dispatch: TD, getState: () => RootState) => {
  log.debug('Sending dmtf')
  const call = getCallInProgress(getState())
  if (!call || !call.voip.sip) return
  try {
    await call.voip.sip.sendDTMF(tone)
  } catch (e) {
    log.error('Could not send dmtf', e)
  }
}

export const transfer =
  (opts: TransferOption) => async (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
    const {phoneNumber, to, blind = false, onCancel, type} = opts
    const call = getCallInProgress(getState())
    const {previous} = getPath(getState())
    const {width} = getDimensions(getState())

    log.debug('Transferring call to', phoneNumber)
    if (!call || !call.voip.sip) return
    try {
      if (blind) {
        await call.voip.sip.sendDTMF(`##${phoneNumber}`)
      } else {
        await call.voip.sip.sendDTMF(`*2${phoneNumber}`)
        dispatch(
          wsAddEventListener({
            name: 'core',
            event: transfer_closed,
            listener: () => {
              dispatch(transferClosed(onCancel))
            }
          })
        )
        dispatch(
          wsAddEventListener({
            name: 'core',
            event: cancel_transfer,
            listener: () => {
              logger.silly('cancel_transfer', 'telefono secondo operatore squilla')
              dispatch(canCancelTransfer())
            }
          })
        )
        dispatch(
          wsAddEventListener({
            name: 'core',
            event: can_close_transfer,
            listener: () => dispatch(canCloseTransfer())
          })
        )
      }
      const newCall = {...call}
      newCall.status = CallStatus.transferring
      newCall.transferringTo = to || phoneNumber
      newCall.transferringToExtension = phoneNumber
      if (width <= smaller.width * 2) dispatch(changeRoute({current: `/phone/transferring-call/${type}`, previous}))
      dispatch(setCallInProgress(newCall))
    } catch (e) {
      log.error('Could not start transfer', e)
    }
  }

export const completeTransfer = () => async (dispatch: TD, getState: () => RootState) => {
  log.debug('Completing transfer')
  dispatch(
    wsRemoveEventListener([
      {name: 'core', event: transfer_closed},
      {name: 'core', event: cancel_transfer},
      {name: 'core', event: can_close_transfer}
    ])
  )
  const call = getCallInProgress(getState())
  if (call && call.id && call?.startTime) {
    const now = DateTime.now().toMillis()
    let duration = now - call.startTime
    updateRegistryCall(call?.id, {
      duration: duration
    })
  }
  const {previous, current} = getPath(getState())
  if (!call || !call.voip.sip || call.status !== CallStatus.transferring) return
  try {
    await call.voip.sip.sendDTMF('*4')
    // TODO: aggiorna la chiamata nel registro
    dispatch(setCallInProgress(null))
    if (current.includes('call'))
      dispatch(
        changeRoute({
          current: previous,
          previous
        })
      )
  } catch (e) {
    log.error('Could not complete transfer', e)
  }
}

export const joinCalls = () => async (dispatch: TD, getState: () => RootState) => {
  log.debug('Joining calls')
  dispatch(
    wsRemoveEventListener([
      {name: 'core', event: transfer_closed},
      {name: 'core', event: cancel_transfer},
      {name: 'core', event: can_close_transfer}
    ])
  )
  const call = getCallInProgress(getState())
  const {previous, current} = getPath(getState())
  if (!call || !call.voip.sip || call.status !== CallStatus.transferring) return
  try {
    await call.voip.sip.sendDTMF('*5')
    const newCall = {...call}
    newCall.status = CallStatus.ongoing
    if (!newCall.others) newCall.others = []
    call.transferringTo && newCall.others.push(call.transferringTo)
    delete newCall.transferringTo
    dispatch(setCallInProgress(newCall))
    if (current.includes('call')) {
      dispatch(
        changeRoute({
          current: '/phone/call',
          previous
        })
      )
    }
  } catch (e) {
    log.error('Could not join calls', e)
  }
}

export const cancelTransfer = () => async (dispatch: TD, getState: () => RootState) => {
  log.debug('Cancelling transfer')
  dispatch(
    wsRemoveEventListener([
      {name: 'core', event: transfer_closed},
      {name: 'core', event: cancel_transfer},
      {name: 'core', event: can_close_transfer}
    ])
  )
  const call = getCallInProgress(getState())
  const {previous, current} = getPath(getState())
  if (!call || !call.voip.sip || call.status !== CallStatus.transferring) return
  try {
    await call.voip.sip.sendDTMF('*3')
    const newCall = {...call}
    const isMutedBeforeTransfer = localStorage.getItem('isMutedBeforeTransfer')

    if (isMutedBeforeTransfer) {
      await new Promise<void>((resolve) => {
        dispatch(mute())
        resolve()
      })
      newCall.status = CallStatus.muted
    } else {
      newCall.status = CallStatus.ongoing
    }

    newCall.canCancelTransfer = false
    newCall.canCloseTransfer = false
    delete newCall.transferringTo
    dispatch(setCallInProgress(newCall))
    if (current.includes('call'))
      dispatch(
        changeRoute({
          current: '/phone/call',
          previous
        })
      )
  } catch (e) {
    log.error('Could not send dmtf', e)
  }
}

export const acceptedCall = () => (dispatch: TD, getState: () => RootState) => {
  log.debug('Accept call')
  const call = getCallInProgress(getState())
  if (call && call.id) {
    updateRegistryCall(call.id, {
      outcome: 'answered'
    })
    const newCall = {...call, answered: true}
    newCall.startTime = DateTime.now().toMillis()
    dispatch(setCallInProgress(newCall))
  }
  dispatch(callStatusChange(CallStatus.ongoing))
}

export const requestCall =
  (payload: { number: string; outbound?: string; info?: any; user?: User; from?: string }) =>
    (dispatch: TD, getState: () => RootState) => {
      if (!payload.number) {
        log.error('missing field "number" in payload')
        return
      }
      const oldCallInProgress = getCallInProgress(getState())
      const phonePeers = getPeers(getState())

      if (oldCallInProgress) {
        log.error('Another call is in progress')
        return
      }

      const previousPath = payload.from || getPath(getState()).current
      const previous = previousPath.match(/(\/)?login/g) || !previousPath ? '/phone' : previousPath

      // se la chiamata non è interna fa delle verifiche sull'outbound
      if (!isInternalCall(payload.number, phonePeers)) {
        const outbounds = getOutbounds(getState())

        // Se non è specificato l'outbound e c'è più di una scelta va sul tastierino col numero composto
        if (!payload.outbound && outbounds.length > 1) {
          dispatch(changeRoute({current: `/phone/keypad/${payload.number}`, previous}))
          // serve inoltre mostrare il client se fosse nascosto oppure non si capisce che è successo qualcosa
          dispatch(setVisibility(true))
          return
        }

        if (!outbounds.length) {
          if (getCanICallWithoutOutbound(getState())) {
            // L'utente può chiamare anche senza servizio outbound
            dispatch(
              call({
                number: payload.number,
                info: payload.info,
                user: payload.user,
                path: {current: '/phone/call', previous}
              })
            ).catch((e) => logger.error(e))
            return
          } else {
            const translation = getTranslation(getState())
            alert(translation.noOutboundsAlert)
            return
          }
        }

        // seleziono l'outbound prescelto oppure il primo della lista (in questo caso ce n'è uno)
        const outbound = payload.outbound ? outbounds.find((o) => o.id === payload.outbound) : outbounds[0]

        if (!outbound) {
          log.error(`Requested call to ${payload.number} but specified outbound ${payload.outbound} is unknown`)
          return
        }

        dispatch(
          call({
            number: payload.number,
            info: payload.info,
            outbound,
            user: payload.user,
            path: {current: '/phone/call', previous}
          })
        ).catch((e) => logger.error(e))
      } else {
        //altrimenti chiama direttamente il numero interno
        dispatch(
          call({
            number: payload.number,
            info: payload.info,
            user: payload.user,
            path: {current: '/phone/call', previous}
          })
        ).catch((e) => logger.error(e))
      }
    }

//endregion

/** inserisce nella chiamata le informazioni aggiuntive note al core sulla stessa */
export const incomingCallInfo = (payload: InboundCallInfo) => async (dispatch: TD, getState: () => RootState) => {
  let callInProgress: any
  let attempts = 0
  while (!callInProgress && attempts < 10) {
    callInProgress = getCallInProgress(getState())
    attempts++
    !callInProgress && (await new Promise((resolve) => setTimeout(resolve, 500)))
  }
  if (!callInProgress) {
    logger.error('Could not find call in progress for callInfo')
    return
  }
  // Il cliente può personalizzare le info che vengono visualizzate
  const infoToShow = window.occlient.settings.inboundDisplayInfo
  const infoToSet: InboundCallInfo = {}
  log.debug('Got additional info for call', payload)
  Object.keys(infoToShow).forEach((k) => {
    const key = k as keyof InboundCallInfo
    if (payload[key] && !callInProgress[key]) infoToSet[key] = payload[key]
  })
  if (payload.inbound) {
    updateRegistryCall(callInProgress.id as string, {
      inbound: payload.inbound
    })
  }

  dispatch(editCallInProgress({...infoToSet}))
}
/** se sulla coda è previsto un tempo di risposta automatico, viene settato un timeout all'arrivo
 *  dell'event incomig-call per permettere al client di rispondere automaticamente */
export const autoAnswer = (seconds: number) => (dispatch: TD, getState: () => RootState) => {
  log.silly(`Auto answer set to ${seconds}`)
  const callInProgress = getCallInProgress(getState())

  if (callInProgress?.autoAnswerTimeout) {
    // era già stato settato il timeout, non devo fare nulla
    return
  }

  const timeoutId = window.setTimeout(() => {
    dispatch(answer())
  }, seconds * 1000)

  dispatch(editCallInProgress({autoAnswerTimeout: timeoutId, autoAnswerTime: seconds}))
}

export const transferClosed = (onCancel?: Function) => (dispatch: TD, getState: () => RootState) => {
  dispatch(
    wsRemoveEventListener([
      {name: 'core', event: transfer_closed},
      {name: 'core', event: cancel_transfer},
      {name: 'core', event: can_close_transfer}
    ])
  )
  const call = getCallInProgress(getState())
  if (!call || call.status !== CallStatus.transferring) return
  const newCall = {...call}
  delete newCall.transferringTo
  logger.silly('The transferee hanged up')
  newCall.status = CallStatus.ongoing
  dispatch(setCallInProgress(newCall))
  onCancel && onCancel()
}
export const canCloseTransfer = (onCancel?: Function) => (dispatch: TD, getState: () => RootState) => {
  dispatch(wsRemoveEventListener([{name: 'core', event: can_close_transfer}]))
  logger.silly('User can now close the transfer')
  const call = getCallInProgress(getState())
  if (!call || call.status !== CallStatus.transferring) return
  const newCall = {...call}
  newCall.canCloseTransfer = true
  dispatch(setCallInProgress(newCall))
  onCancel && onCancel()
}
export const canCancelTransfer = (onCancel?: Function) => (dispatch: TD, getState: () => RootState) => {
  dispatch(wsRemoveEventListener([{name: 'core', event: cancel_transfer}]))
  logger.silly('User can now cancel the transfer')
  const call = getCallInProgress(getState())
  if (!call || call.status !== CallStatus.transferring) return
  const newCall = {...call}
  newCall.canCancelTransfer = true
  dispatch(setCallInProgress(newCall))
  onCancel && onCancel()
}

/** intrusione in una chiamata in corso di un altro interno */
export const spy = (opts: SpyOptions) => async (dispatch: TD, getState: () => RootState) => {
  log.debug(`Trying to start spying ${opts.extension}`)
  const oldCallInProgress = getCallInProgress(getState())
  const {width} = getDimensions(getState())

  if (oldCallInProgress) {
    log.debug('Another call is in progress')
    return
  }
  const {extension, pbxId, type} = opts
  const voips = getVoips(getState())
  const users = getUsers(getState())

  // codici da inserire prima del numero per far partire lo spy in base al tipo voluto
  let prefix = ''
  switch (type) {
    // nessuno dei due può sentirti
    case 'anonymous':
      prefix = '*40'
      break
    // solo l'operatore legato al voip ti sente
    case 'support':
      prefix = '*41'
      break
    // vienia scoltato da entrambi
    case 'complete':
      prefix = '*42'
  }
  // seleziona uno dei voip dell'utente sullo stesso pbx dell'interno da spiare
  const voipToUse = voips.find((v) => v.pbx.id === pbxId)
  if (!voipToUse) {
    // l'utente non ha voip sullo stesso pbx, non può eseguire lo spy
    log.error(`User does not have any voip in pbx ${pbxId}`)
    return
  }

  // Controllo che il numero da chiamare non sia quello di un mio interno
  if (voips.find((v) => v.extension === extension)) {
    // Non puoi spiarti da solo
    logger.error('Could not spy yourself')
    return
  }
  const isInternal = true

  const callInProgress: Call = {
    number: String(extension),
    prefix: '',
    boundness: t.Bounds.out,
    voip: voipToUse,
    status: CallStatus.pending,
    isInternal,
    spying: true
  }

  const userTo = getUserByVoipExtension([...users.values()], String(extension))
  if (userTo) callInProgress.user = userTo

  const devices: DeviceSettings = {
    audioinput: getSelectedDevices(getState()).microphone
  }
  const callId = uuid()
  try {
    await Phone.call({
      number: `${prefix}${extension}`,
      voip: voipToUse,
      devices,
      isInternal,
      callId
    })
    //inserisco su redux le informazioni utili all analisi
    const callIdFromIndexDB = callId
    appendRegistryCall({
      id: callId,
      userName: userTo ? `${userTo.surname} ${userTo.name}` : String(extension),
      userId: userTo?.id!,
      timestamp: DateTime.now().toMillis(),
      inbound: '',
      outbound: null,
      telephone: String(extension),
      outcome: 'notAnswered',
      type: t.Bounds.out
    })
    callInProgress.id = callId || callIdFromIndexDB
    dispatch(setCallInProgress(callInProgress))

    const newPath = {
      current: '/phone/call'
    }
    if (width <= smaller.width * 2) dispatch(changeRoute(newPath))
  } catch (e: any) {
    //TODO: gestire errore
    dispatch(setCallInProgress(null))
    log.error('errore')
    log.error(e.message)
  }
}

/** fa partire la registrazione della chiamata su janus */
export const startRecording = () => async (dispatch: TD, getState: () => RootState) => {
  log.debug('Starting recording')
  const callInProgress = getCallInProgress(getState())

  if (!callInProgress) {
    log.error('No call in progress')
    return
  }

  if (!callInProgress.voip.sip) {
    log.error('Call in progress has no voip account set')
    return
  }

  const filename = `${callInProgress.id}-${DateTime.now().toMillis()}`

  try {
    await callInProgress.voip.sip.startRecording(`/recordings/phone/staging/${filename}`)
  } catch (e) {
    log.error('Could not start recording', e)
    return
  }

  dispatch(setCallInProgress({...callInProgress, recordingFilename: filename}))
}

/** termina la registrazione della chiamata su janus */
export const stopRecording = () => async (dispatch: TD, getState: () => RootState) => {
  log.debug('Stopping recording')
  const callInProgress = getCallInProgress(getState())

  if (!callInProgress) {
    log.error('No call in progress')
    return
  }

  if (!callInProgress.voip.sip) {
    log.error('Call in progress has no voip account set')
    return
  }

  try {
    await callInProgress.voip.sip.stopRecording()
  } catch (e) {
    log.error('Could not start recording', e)
  } finally {
    dispatch(setCallInProgress({...callInProgress, recordingFilename: undefined}))
  }
}

/** il client ha richiesto la registrazione e janus ha dato un feedback positivo */
export const recordingStarted = () => async (dispatch: TD, getState: () => RootState) => {
  log.debug('Recording started, got janus feedback')
  const callInProgress = getCallInProgress(getState())

  if (!callInProgress) {
    log.debug('No call in progress')
    return
  }

  // il feedback da janus arriva anche per lo stop recording, se il campo è già stato resettato
  // significa che il feedback era per lo stop
  if (!callInProgress.recordingFilename) return

  try {
    await axios.post(
      `${coreApi}/calls/${callInProgress.id}/recording`,
      {filename: `${callInProgress.recordingFilename}`},
      makeHeader()
    )
  } catch (e: any) {
    alert(`Failed to notify recording to core ${e.response?.data}`)
    log.error('Failed to notify recording to core', e.response?.data)
  }
}

export const removeIncomingCall = (payload: any) => (dispatch: TD, getState: () => RootState) => {
  const {uuid} = payload

  if (!uuid) {
    logger.error('Missing call UUID in queue-call-answered payload', payload)
    return
  }

  if (uuid) {
    removeCallFromRegistry(uuid)
  }

  const lostCalls = getLostCalls(getState())
  if (lostCalls > 0) {
    ElectronProxy.setBadge(Math.max(0, lostCalls - 1), 'phone')
    dispatch({type: t.SET_LOST_CALLS, payload: Math.max(0, lostCalls - 1)})
  }
}
