import CallInfoInbound from '../../../../pages/Phone/Call/CallInfo/CallInfoInbound'
import { Bounds } from '../../../../../store/phone/types'
import { getBitrate, getCallInProgress, getDimensions } from '../../../../../store/selectors'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import Counter from '../../../../common/Counter'
import CallInfoAttendees from './CallinfoAttendees'
import CallInfoStatus from './CallInfoStatus'
import CallInfoOutbound from '../../../../pages/Phone/Call/CallInfo/CallInfoOutbound'
import {rem} from "../../../../../helpers/style"

interface CallInfoProps {
  labelFont?: number
  counterFont?: number
  hideInboundInfo?: boolean
  hideOutboundInfo?: boolean
}

const CallInfo: React.FC<CallInfoProps> = ({
  labelFont,
  counterFont,
  hideInboundInfo = false,
  hideOutboundInfo = false
}) => {
  const call = useSelector(getCallInProgress)
  const bitrate = useSelector(getBitrate)
  const dim = useSelector(getDimensions)

  return (
    <Container>
      <CallInfoStatus font={labelFont} />
      <CallInfoAttendees font={labelFont} />
      {!hideInboundInfo && call?.boundness === Bounds.in && !call.isInternal && <CallInfoInbound />}
      {!hideOutboundInfo && call?.boundness === Bounds.out && !call.isInternal && <CallInfoOutbound />}
      <CounterAndBitrate>
        {call?.startTime && <Counter from={call.startTime} font={counterFont} />}
        {bitrate && <div style={{ fontSize: rem((counterFont || 1) * 0.7, dim.fontSize) }}>{bitrate}</div>}
      </CounterAndBitrate>
    </Container>
  )
}

export default CallInfo

//region Style

const Container = styled.div`
  place-self: start center;
  place-items: start center;
  display: grid;
`

const CounterAndBitrate = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  place-items: center;
  padding: ${rem(0.3)} 0;
`
//endregion
