import { IFavorite } from '../../api/database/types'
import { Contact } from '../../api/rest'
import { requestCall } from '../../store/phone/actions'
import { getCanCall } from '../../store/selectors'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { ReactComponent as ChooseNumber } from '../../images/icons/add_ic_call.svg'
import { ReactComponent as PhoneIcon } from '../../images/icons/phone.svg'
import { ActionButton } from './ActionButton'
import AdressModal from './AddressModal'
import { Modal } from './Modal'

interface ContactButtonProps {
  from: string
  contact: Contact | IFavorite
  beforeClick?: Function
}

//TODO: serve che le rubriche mandino tutto l'oggetto AddressType
// address[0].addressType.name === 'phone' &&

export const ContactButton: React.FC<ContactButtonProps> = (props) => {
  const { from, contact } = props

  const [showModal, setShowModal] = useState(false)
  const canCall = useSelector(getCanCall)
  const dispatch = useDispatch()

  return (
    <Container>
      <ButtonContainer>
        {contact.addresses.length === 1 ? (
          <ActionButton
            disabled={!canCall}
            onClick={() => canCall && dispatch(requestCall({ number: contact.addresses[0].value, from }) as any)}
          >
            <PhoneIcon />
          </ActionButton>
        ) : (
          <ActionButton disabled={false} onClick={() => setShowModal(true)}>
            <ChooseNumber />
          </ActionButton>
        )}
      </ButtonContainer>
      {contact.addresses.length > 1 && (
        <Modal height="75%" width="70%" closeModal={() => setShowModal(false)} isVisible={showModal}>
          <AdressModal contact={contact} from={from} />
        </Modal>
      )}
    </Container>
  )
}
const Container = styled.div``

const ButtonContainer = styled.div`
  grid-area: button;
  place-self: center;
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;
  width: 100%;
`
