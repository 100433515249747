import { RootState } from '../store/types'
import { ThunkDispatch } from 'redux-thunk'
import * as authentication from './authentication'
import { OCClient } from './client'
import CustomerChat from './customerChat'
import ElectronProxy from './electron'
import JanusPool from './janus'
import Messenger from './messenger'
import Phone from './phone'

export const connectRedux = (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
  CustomerChat.reduxDispatcher = dispatch
  ElectronProxy.reduxDispatcher = dispatch
  Messenger.reduxDispatcher = dispatch
  Phone.reduxDispatcher = dispatch
  JanusPool.reduxDispatcher = dispatch

  CustomerChat.reduxGetState = getState
  ElectronProxy.reduxGetState = getState
  Messenger.reduxGetState = getState
  Phone.reduxGetState = getState
  JanusPool.reduxGetState = getState
}

export { CustomerChat, Messenger, ElectronProxy, Phone, authentication, OCClient, JanusPool }
