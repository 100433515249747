import styled, { keyframes } from 'styled-components'

export const pulse = keyframes`
    from {
        stroke-width: 3px;
        stroke-opacity: 1;
        fill-opacity: 1;
        transform: scale(1);
    }
    to {
        stroke-width: 0;
        stroke-opacity: 0;
        fill-opacity: 0;
        transform: scale(2);
    }
`

type CirclePulseProps = {
  delay?: string
  inner: string
  outer: string
}
export const CirclePulse = styled.circle<CirclePulseProps>`
  fill: ${({ inner }) => inner};
  stroke: ${({ outer }) => outer};
  transform-origin: 50% 50%;
  animation: ${pulse} 2s linear infinite;
  animation-delay: ${({ delay }) => delay || '0'};
`
