import { Messenger } from '../../../../api'
import { rem } from '../../../../helpers/style'
import { ArrowBackRounded, GroupRounded, VideocamRounded } from '@mui/icons-material'
import { AppBar, Badge, IconButton, Toolbar } from '@mui/material'
import { changeRoute, conversationsList, sendVideoRoomRequest, setActiveConversation } from '../../../../store/actions'
import { getConversations, getMe, getPath, getTranslation, getUnreadEvents, getUsersArray } from '../../../../store/selectors'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useMatch } from 'react-router'
import styled from 'styled-components'
import { Conversation, User } from '../../../../api/rest'
import { ActionButton, Avatar, AvatarWithStatus, Modal, UserButton } from '../../../common'
import GroupControl from '../modals/GroupControl'
import { DotsButton } from '../../../common/DotsButton'
import axios from 'axios'
import { messengerApi } from '../../../../configuration'
import logger from '../../../../helpers/logger'

type Props = {
  conversation: Conversation
}

const ConversationTopBar: React.FC<Props> = ({ conversation }) => {
  const [otherUserDisplayName, setOtherUserDisplayName] = useState('')
  const [otherUser, setOtherUser] = useState<User | null>(null)
  const [showGroupModal, setShowGroupModal] = useState(false)

  const unreadEvents = useSelector(getUnreadEvents)
  const users = useSelector(getUsersArray)
  const me = useSelector(getMe)
  const { current } = useSelector(getPath)
  const translation = useSelector(getTranslation)
  const allConversations = useSelector(getConversations)

  const dispatch = useDispatch()

  const { params } = useMatch('/messenger/:id')!
  const { state } = useLocation()

  const setConversationInfo = useCallback(() => {
    if (conversation.name) {
      setOtherUserDisplayName(conversation.name)
      setOtherUser(null)
    } else {
      const other = conversation.members.find((m) => m.username !== me.username)
      const otherUserFromState = users.find((u) => other?.username === u.username)
      otherUserFromState && setOtherUser(otherUserFromState)
      setOtherUserDisplayName(`${other?.surname} ${other?.name}`)
    }
  }, [conversation, users, me.username])

  useEffect(() => {
    if (!params.id) {
      return
    }
    if (params.id === 'new') {
      if (state && state.otherMember) {
        setOtherUserDisplayName(`${state.otherMember.name} ${state.otherMember.surname}`)
        setOtherUser(state.otherMember)
      }
    } else {
      setConversationInfo()
      //TODO: gestire notfound
    }
  }, [conversation, params.id, state, setConversationInfo])

  // Quando cambiano gli utenti aggiorno le info sulla conversazione
  // NB: Senza se si aggiorna direttamente sulla pagina della conversazione non si vede l'utente
  useEffect(() => {
    conversation && setConversationInfo()
  }, [conversation, setConversationInfo])

  const goBack = useCallback(() => {
    dispatch(changeRoute({ current: '/messenger', previous: current }) as any)
    dispatch(setActiveConversation(null) as any)
  }, [dispatch, current])

  const handleDelete = useCallback(async () => {
    const token = sessionStorage.getItem('@occlient/token')
    const partialConversation = { id: conversation.id, members: conversation.members }
    try {
      axios
        .post(`${messengerApi}/conversations/delete-conv/${conversation.id}`, partialConversation, {
          headers: { Authorization: 'Bearer ' + token }
        })
        .then((res) => {
          if (res.status !== 200) return
          const updatedConv: Conversation[] = [...allConversations.values()].map((conv) => {
            if (conv.id === conversation.id) conv.messages = []
            return conv
          })
          dispatch(conversationsList(updatedConv) as any)
          goBack()
        })
    } catch (err) {
      logger.error(err)
    }
  }, [conversation, allConversations, dispatch, goBack])

  return (
    <AppBar
      position="sticky"
      color="inherit"
      style={{
        zIndex: 2,
        minWidth: 0,
        boxSizing: 'border-box',
        boxShadow:
          '2px 2px 4px -1px rgb(0 0 0 / 20%), 3px 4px 5px 0px rgb(0 0 0 / 14%), 7px 1px 10px 0px rgb(0 0 0 / 12%)'
      }}
    >
      <Toolbar
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto auto 1fr auto auto',
          gridColumnGap: rem(0.5),
          minWidth: 'unset',
          minHeight: 'unset',
          width: '100%',
          fontSize: rem(0.7),
          padding: `${rem(0.2)} ${rem(0.5)}`
        }}
      >
        <IconButton edge="start" color="inherit" onClick={goBack}>
          <Badge
            badgeContent={unreadEvents}
            color="secondary"
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            variant="dot"
          >
            <ArrowBackRounded />
          </Badge>
        </IconButton>
        {/* caso conversazione a due */}
        {otherUser && (
          <>
            <AvatarWithStatus
              user={otherUser}
              avatarSize="30px"
              statusSize="12px"
              fontSize="16px"
              letterSpacing="-2px"
              statusStrokeWidth="2px"
            />
            {otherUserDisplayName}
            <UserButton from="/messenger" user={otherUser} buttons={{ phone: true, video: true, chat: false }} />
          </>
        )}
        {/* caso conversazione di gruppo */}
        {conversation.name && (
          <>
            <Avatar size="30px" fontSize="14px" letterSpacing="-2px">
              <GroupRounded />
            </Avatar>
            <GroupInfo onClick={() => setShowGroupModal(true)}>
              <span style={{ placeSelf: 'end start', fontSize: rem(0.85) }}>{otherUserDisplayName}</span>
              <span
                style={{
                  maxWidth: '100%',
                  placeSelf: 'start',
                  fontSize: rem(0.6),
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {Messenger.getMembersList(conversation.members, me.username, translation.you)}
              </span>
            </GroupInfo>
            <ActionButton
              onClick={() => {
                dispatch(sendVideoRoomRequest(conversation.members) as any)
              }}
            >
              <VideocamRounded />
            </ActionButton>
          </>
        )}
        <DotsButton
          label={translation.deleteChat}
          disabled={conversation.messages.length === 0}
          onClick={handleDelete}
        />
      </Toolbar>
      <Modal closeModal={() => setShowGroupModal(false)} isVisible={showGroupModal} style={{ maxWidth: '500px' }}>
        <GroupControl conversation={conversation} closeModal={() => setShowGroupModal(false)} />
      </Modal>
    </AppBar>
  )
}

export default ConversationTopBar

//region Style

const GroupInfo = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: 1.5fr 1fr;
  cursor: pointer;
  place-self: center;
`
//endregion
