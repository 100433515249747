import { Messenger } from '../../../../api'
import log from '../../../../helpers/logger'
import { rem } from '../../../../helpers/style'
import { ExpandMoreRounded as GoBottomIcon } from '@mui/icons-material'
import { CircularProgress, Fab, Fade } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { newMessagesLoadedForConversation } from '../../../../store/actions'
import { getTranslation } from '../../../../store/selectors'
import { DateTime } from 'luxon'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Conversation, Message } from '../../../../api/rest'
import usePrevious from '../../../../hooks/usePrevious'
import MessageBaloon from './Message'

type Props = {
  conversation: Conversation
  noMoreMessages: boolean
  setNoMoreMessages: (a0: boolean) => void
  setShowEmoji: (a0: boolean) => void
}
/**
 * Contentore dei messaggi del Messenger con la logica per caricare nuovi messaggi scrollando in alto
 * @param conversation
 * @param noMoreMessages
 * @param setNoMoreMessages
 * @constructor
 */
const Messages: React.FC<Props> = ({ conversation, noMoreMessages, setNoMoreMessages, setShowEmoji }) => {
  const [loadingMoreMessages, setLoadingMoreMessages] = useState(false)
  const [messagesBox, setMessagesBox] = useState<HTMLElement | null>(null)
  const [messagesLength, setMessagesLength] = useState<number>(0)
  const [showGoToBottomButton, setShowGoToBottomButton] = useState<boolean>(false)

  const prevMessagesLength = usePrevious<number>(messagesLength)
  const prevLoadingMoreMessages = usePrevious<boolean>(loadingMoreMessages)

  const translation = useSelector(getTranslation)
  const dispatch = useDispatch()

  const muiTheme = useTheme()

  /** scrolla giù appena il dom è pronto */
  useLayoutEffect(() => {
    if (!messagesBox) return
    messagesBox.scrollTop = messagesBox.scrollHeight
  }, [messagesBox])

  useEffect(() => {
    if (!conversation || !messagesBox) return

    setMessagesLength(conversation.messages.length)
    // Scrolla in modo che sia visibile il primo messaggio non letto:
    // Il messaggio da mostrare sarà l'ultimo la lunghezza dei messaggi non è cambiata, altrimenti
    // prendo l'ultimo tra i nuovi messaggi sottraendo la lunghezza totale attuale alla lunghezza totale precedente
    if (prevLoadingMoreMessages && !loadingMoreMessages) {
      // Appena finito il caricamento
      const messageToShowIndex = conversation.messages.length - (prevMessagesLength || 0)
      const messageToShow = conversation?.messages[messageToShowIndex]
      if (!messageToShow) return
      const messageDiv = document.getElementById(messageToShow.id)
      if (!messageDiv) return
      messageDiv.scrollIntoView(false)
    }

    // se dall'ultimo caricamento non ho ricevuto nuovi messaggi significa che li ho caricati tutti
    setNoMoreMessages(prevMessagesLength === conversation.messages.length)
  }, [conversation, setNoMoreMessages, prevMessagesLength, prevLoadingMoreMessages, loadingMoreMessages, messagesBox])

  useEffect(() => {
    if (!messagesBox) return
    // Se non stava scrollando in alto per vedere vecchi messaggi, scrolla giù alla ricezione di nuovi messaggi
    if (!showGoToBottomButton) messagesBox.scrollTop = messagesBox.scrollHeight
  }, [showGoToBottomButton, conversation, messagesBox])

  const handleScrollUp = (container: HTMLElement) => {
    setShowGoToBottomButton(container.scrollHeight - container.scrollTop - 1 > container.clientHeight)
    if (noMoreMessages) return
    if (!conversation || !conversation.messages || loadingMoreMessages) return
    if (container.scrollTop < 200) {
      const firstMessage = conversation.messages[0]
      if (!firstMessage) return
      setLoadingMoreMessages(true)
      Messenger.searchAfter({
        conversation: conversation.id,
        message: firstMessage.id,
        timestamp: firstMessage.timestamp
      })
        .then((messages) => {
          dispatch(newMessagesLoadedForConversation({ conversation: conversation.id, messages }))
        })
        .catch((e) => log.error(e))
        .finally(() => setLoadingMoreMessages(false))
    }
  }

  const handleScrollDown = (container: HTMLElement) => {
    setShowGoToBottomButton(container.scrollHeight - container.scrollTop - 1 > container.clientHeight)
  }

  const handleFabClick = () => {
    if (messagesBox) {
      messagesBox.scrollTop = messagesBox.scrollHeight
      messagesBox.style.scrollBehavior = ''
    }
  }

  const renderDayInfo = (currentMessage: Message, index: number) => {
    if (!conversation?.messages) return null
    const prevIndex = index - 1
    const now = DateTime.now()
    const mexTime = DateTime.fromMillis(currentMessage.timestamp)
    const isToday = now.hasSame(mexTime, 'day')
    // se è il primo elemento non mostro la data
    if (prevIndex >= 0) {
      // altrimenti verifico se tra questo messaggio e il precedente c'è un cambio di giorno
      const prevMex = conversation.messages[prevIndex]
      const prevMexTime = DateTime.fromMillis(prevMex.timestamp)
      if (!prevMex) return null
      if (mexTime.hasSame(prevMexTime, 'day')) {
        // i due messaggi appartengono allo stesso giorno
        return null
      } else {
        // il giorno è cambiato
        return <DayInfo>{isToday ? translation.today : mexTime.toLocaleString(DateTime.DATE_SHORT)}</DayInfo>
      }
    }
  }

  /**
   * Metodo per capire se renderizzare o meno il nome del sender nel caso in cui sia un gruppo e il messaggio precedente
   * sia dello stesso sender
   * @param index { number } indice del messaggio corrente
   * @returns { boolean } true se fa parte di una sequenza dallo stesso sender
   */
  const isSequence = (index: number): boolean => {
    if (!conversation?.messages) return false
    if (!conversation.name) return false
    const prevIndex = index - 1
    if (index >= 0) {
      const prevMex = conversation.messages[prevIndex]
      const currMex = conversation.messages[index]
      if (!currMex || !prevMex || !currMex.sender || !prevMex.sender) return false
      return currMex.sender.username === prevMex.sender.username
    } else {
      return false
    }
  }

  return (
    <Container
      id="occlient-messenger-messages"
      containerRef={(ref) => setMessagesBox(ref)}
      onScrollUp={handleScrollUp}
      onScrollDown={handleScrollDown}
      options={{ suppressScrollX: true }}
      onClick={() => setShowEmoji(false)}
    >
      <Fade
        in={loadingMoreMessages}
        style={{
          transitionDelay: loadingMoreMessages ? '800ms' : '0ms'
        }}
        unmountOnExit
      >
        <CircularProgress style={{ placeSelf: 'center' }} />
      </Fade>
      {(!conversation || !conversation.messages || !conversation.messages.length) && <div />}
      {conversation?.messages?.map((message, i) => (
        <React.Fragment key={`${message.id}-${i}`}>
          {renderDayInfo(message, i)}
          {message.type === 'system' ? (
            <SystemMessage>{Messenger.parseSystemMessage(message.body, translation)}</SystemMessage>
          ) : (
            <MessageBaloon message={message} isGroup={!!conversation.name} isSequence={isSequence(i)} />
          )}
        </React.Fragment>
      ))}
      <Fade in={showGoToBottomButton} style={fadeStyle} unmountOnExit>
        <Fab size={'small'} onClick={handleFabClick} style={{ backgroundColor: muiTheme.palette.action.active }}>
          <StyledGoToBottomIcon />
        </Fab>
      </Fade>
    </Container>
  )
}

export default Messages

//region Style

const fadeStyle: React.CSSProperties = {
  transitionDelay: '200ms',
  position: 'fixed',
  bottom: '60px',
  right: '17px',
  height: rem(1.4),
  width: rem(1.4)
}

const StyledGoToBottomIcon = styled(GoBottomIcon)`
  color: ${({ theme }) => (theme.palette.mode === 'dark' ? 'black' : 'white')};
`

const Container = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${rem(0.5)};
  overflow-y: auto;
`

const DayInfo = styled.div`
  width: fit-content;
  color: #ffffff;
  place-self: center;
  border-radius: 5px;
  background: ${({ theme }) => theme.palette.primary.main};
  padding: ${rem(0.3)} ${rem(0.6)};
  font-size: ${rem(0.7)};
  opacity: 0.8;
  text-transform: capitalize;
  font-weight: bold;
  margin: ${rem(0.3)} 0;
`

const SystemMessage = styled.div`
  place-self: center;
  border-radius: 15px;
  background: ${({ theme }) => (theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900])};
  color: ${({ theme }) => theme.palette.text?.primary};
  padding: ${rem(0.2)} ${rem(0.6)};
  font-size: ${rem(0.7)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: min(85%, 500px);
  margin: ${rem(0.4)} 0;
  min-height: 25px;
`

//endregion
