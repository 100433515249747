import { User } from './api/rest'
import { IFavorite } from './api/database/types'

/**
 * The function formats a list of favorites by adding user information and voip addresses if available, otherwise it sets
 * the type to "contact".
 * @param {IFavorite[]} [favorites] - `favorites` is an optional array of objects representing favorite contacts or users.
 * Each object has properties such as `favoriteId`, `type`, `firstName`, `secondName`, and `addresses`.
 * @param [users] - The `users` parameter is a `Map` object that maps user IDs to `User` objects. It is used to retrieve
 * user information for each favorite in the `favorites` array.
 * @returns The function `formatFavorites` returns an array of objects that represent the formatted favorites. If either
 * the `favorites` or `users` arguments are falsy, an empty array is returned. Otherwise, the function maps over the
 * `favorites` array and formats each favorite object based on whether it corresponds to a user or a contact. The formatted
 * favorites are returned as an array.
 */
const formatFavorites = (favorites?: IFavorite[], users?: Map<number, User>) => {
  if (!favorites || !users) return []
  return favorites.map((favorite) => {
    const user = users.get(favorite.favoriteId)
    if (!user) {
      favorite.type = 'contact'
      favorite.firstName = ''
      favorite.secondName = ''
      favorite.addresses = []
    } else {
      favorite.type = 'user'
      favorite.firstName = user.name
      favorite.secondName = user.surname
      favorite.addresses = user.userVoips.map((userVoip, index) => ({
        id: index,
        value: userVoip.voip.id!,
        addressType: { id: userVoip.type.id, name: userVoip.type.name, actions: '' }
      }))
    }

    return favorite
  })
}

export { formatFavorites }
