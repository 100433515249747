import {AddIcCallRounded, AddRounded, PhoneForwardedRounded, PhoneRounded} from '@mui/icons-material'
import {IconButton, Menu, MenuItem, Tooltip} from '@mui/material'
import {mute, requestCall, transfer} from '../../store/phone/actions'
import {Call, CallStatus} from '../../store/phone/types'
import {getCallInProgress, getCanCall, getDimensions, getTranslation} from '../../store/selectors'
import React, {useCallback, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Statuses, User, UserVoip, Voip} from '../../api/rest'
import {smaller} from '../../constants'
import {changeRoute} from '../../store/applicationState/actions'

type Props = {
  user: User
  beforeClick?: Function
  transferType?: 'add' | 'transfer'
}

type InnerProps = {
  userVoips: UserVoip[]
  isDisabled: boolean
  callInProgress: Call | null
  transferType: 'add' | 'transfer'
  callFunction: Function
}
export const UserVoipsMenu: React.FC<Props> = ({ user, transferType = 'add' }) => {
  const canCall = useSelector(getCanCall)
  const callInProgress = useSelector(getCallInProgress)
  const dispatch = useDispatch()
  const { width } = useSelector(getDimensions)

  // Deve essere disabled quando:
  // se c'è una chiamata e quindi si potrebbe fare un trasferimento, è disabled se:
  //    - o l'utente in questione non è idle
  //    - o l'utente già partecipa alla chiamata
  //    - o la chiamata non è in stato ongoing
  // se invece non c'è una chiamata in corso c'è il flag can call e lo status dell'utente
  const isDisabled = useMemo(() => {
    return callInProgress
      ? callInProgress.user?.id === user.id ||
      ![CallStatus.ongoing, CallStatus.muted].includes(callInProgress.status) ||
      user.state?.id !== Statuses.idle
      : !canCall || user.state?.id !== Statuses.idle
  }, [callInProgress, canCall, user])

  const notDeletedVoips = (user.userVoips || []).filter(({ deleted }) => !deleted)

  const isMuted = useMemo(()=> callInProgress?.status === CallStatus.muted,[callInProgress])

  const callOrTransfer = useCallback(
    async (voip: Voip) => {
      if (isDisabled) return
      if (callInProgress) {
        const onCancelTransfer = async () => {
          if(isMuted) {
            await new Promise<void>((resolve) => {
              dispatch(mute() as any)
              resolve()
            })
          }
          if (width <= smaller.width * 2) dispatch(changeRoute({ current: '/phone/call' }) as any)
        }

        if(isMuted) {
          await new Promise<void>((resolve) => {
            localStorage.setItem('isMutedBeforeTransfer', 'true')
            dispatch(mute() as any)
            resolve()
          })
        }

        dispatch(
          transfer({ phoneNumber: String(voip.extension), to: user, onCancel: onCancelTransfer, type: transferType }) as any
        )
      } else {
        dispatch(requestCall({ number: String(voip.extension) }) as any)
      }
    },
    [isDisabled, callInProgress, dispatch, transferType, width, user]
  )

  return user.userVoips ? (
    notDeletedVoips.length > 1 ? (
      <MultiVoip
        userVoips={notDeletedVoips}
        isDisabled={isDisabled}
        callInProgress={callInProgress}
        transferType={transferType}
        callFunction={callOrTransfer}
      />
    ) : (
      <SingleVoip
        userVoips={notDeletedVoips}
        isDisabled={isDisabled}
        callInProgress={callInProgress}
        transferType={transferType}
        callFunction={callOrTransfer}
      />
    )
  ) : null
}

const MultiVoip: React.FC<InnerProps> = ({ userVoips, isDisabled, callInProgress, transferType, callFunction }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const translation = useSelector(getTranslation)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.target as any)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className={'do-not-close-search'}>
      <IconButton
        id="dial-button"
        size="small"
        color="default"
        disabled={isDisabled}
        className={'do-not-close-search'}
        onClick={(e) => !isDisabled && handleClick(e)}
      >
        <Tooltip title={translation?.tooltips?.call} disableInteractive className={'do-not-close-search'}>
          {callInProgress ? (
            transferType === 'transfer' ? (
              <PhoneForwardedRounded className={'do-not-close-search'}/>
            ) : (
              <AddRounded className={'do-not-close-search'}/>
            )
          ) : (
            <AddIcCallRounded className={'do-not-close-search'}/>
          )}
        </Tooltip>
      </IconButton>

      <Menu
        id="dial-button"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {userVoips.map(({ voip, type }: any) => (
          <MenuItem
            key={voip.id}
            disabled={voip.state.id !== Statuses.idle}
            onClick={() => {
              callFunction(voip)
              handleClose()
            }}
          >
            {voip.extension} - {type.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

const SingleVoip: React.FC<InnerProps> = ({ userVoips, isDisabled, callInProgress, transferType, callFunction }) => {
  const translation = useSelector(getTranslation)

  return (
    <IconButton size="small" color="default" disabled={isDisabled} onClick={() => callFunction(userVoips[0].voip)}>
      <Tooltip title={translation?.tooltips?.call} disableInteractive>
        {callInProgress ? transferType === 'transfer' ? <PhoneForwardedRounded /> : <AddRounded /> : <PhoneRounded />}
      </Tooltip>
    </IconButton>
  )
}
