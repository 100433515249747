import MuiAvatar from '@mui/material/Avatar'
import { getTheme } from '../../store/selectors'
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { AVATAR_BACKGROUND_DARK, AVATAR_BACKGROUND_LIGHT } from '../../constants/custom-colors'

type AvatarProps = {
  name?: string
  surname?: string
  fullname?: string
  width?: string
  height?: string
  size?: string
  fontSize?: string
  letterSpacing?: string
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  style?: React.CSSProperties
  children?: React.ReactNode
}

export const Avatar: React.FC<AvatarProps> = (props) => {
  const {
    name = 'U',
    surname = 'U',
    fullname,
    width,
    height,
    size,
    fontSize,
    letterSpacing,
    onClick,
    style = {},
    children
  } = props

  const theme = useSelector(getTheme)

  const getInitials = useCallback(() => {
    if (fullname) {
      const [n, s] = fullname.split(' ')

      if (n && !s) return n[0].toUpperCase()
      if (!n && s) return s[0].toUpperCase()
      if (!n && !s) return ''

      return `${s[0].toUpperCase()} ${n[0].toUpperCase()}`
    }
    return `${surname[0]?.toUpperCase() || ''} ${name[0]?.toUpperCase() || ''}`
  }, [name, surname, fullname])

  return (
    <MuiAvatar
      style={{
        background: theme === 'light' ? AVATAR_BACKGROUND_LIGHT : AVATAR_BACKGROUND_DARK,
        width: size || width || 55,
        height: size || height || 55,
        fontSize: fontSize || 23,
        letterSpacing: letterSpacing || -3,
        cursor: onClick ? 'pointer' : 'default',
        fontWeight: 'bold',
        placeSelf: 'center',
        ...style
      }}
      {...(onClick ? { onClick } : {})}
    >
      {children || getInitials()}
    </MuiAvatar>
  )
}
