import { User } from '../api/rest'

export const isInternalCall = (number: string, phonePeers: RegExp[]) => {
  return phonePeers.some((p) => {
    return p.test(number)
  })
}

export const getUserByVoipExtension = (users: User[], number: string) => {
  return users.find((u) => {
    return u.userVoips.some(({ voip }) => String(voip.extension) === number)
  })
}
