import React, { useState } from 'react'
import styled from 'styled-components'
import { rem } from '../../../../helpers/style'
import { User } from '../../../../api/rest'
import { UserSelection } from '../../../common'
import { Button } from '@mui/material'
import { useSelector } from 'react-redux'
import { getTranslation } from '../../../../store/selectors'

type Props = {
  addFavorites: (...args: any) => any
  exclude?: string[]
}

const AddFavorites: React.FC<Props> = ({ addFavorites, exclude }) => {
  const [selectedUsers, setSelectedUsers] = useState<Map<number, User>>(new Map())

  const translation = useSelector(getTranslation)

  return (
    <Container>
      <Label>{translation.addToFavorites}</Label>
      <UserSelection selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} exclude={exclude} />
      <Button
        color="primary"
        type="submit"
        disabled={!selectedUsers.size}
        onClick={() => addFavorites([...selectedUsers.keys()])}
      >
        {translation.add}
      </Button>
    </Container>
  )
}

// region Style
const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 8fr 1fr;
  grid-template-columns: 1fr;
  width: 100%;
  height: 100%;
  place-content: start center;
`
const Label = styled.div`
  font-size: ${rem(0.8)};
  color: ${({ theme }) => theme.palette.primary.main};
`
// endregion

export default AddFavorites
