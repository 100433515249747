import {ApiWithRedux} from '../ApiWithRedux'
import Janus, {JanusLib, SIP} from '@bytewise/janus'
import {coreApi} from '../../configuration'
import {BW_CAMPAIGN_ID, BW_OUTBOUND_ID, OC_UUID_HEADER} from '../../constants/utilities'
import {
  acceptedCall,
  addVoip,
  callStatusChange,
  hangupReceived,
  incomingCall,
  recordingStarted,
  setBitrate
} from '../../store/phone/actions'
import {CallStatus, REMOVE_VOIPS} from '../../store/phone/types'
import {getAudioVolume, getCallInProgress, getPath, getTranslation, getVoips} from '../../store/selectors'
import axios from 'axios'
import pkg from '../../../package.json'
import log from '../../helpers/logger'
import logger from '../../helpers/logger'
import {User, VoipInfo} from '../rest'
import {CallOptions} from './types'
import {ElectronProxy} from '..'

class Phone extends ApiWithRedux {
  private _janus: Janus = new Janus()
  token: string | null = sessionStorage.getItem('@occlient/token')
  private monitoringInterval: number | null = null

  set janus(janus: Janus) {
    this._janus = janus
  }

  //region Voips

  makeAccount(voipInfo: VoipInfo): Promise<SIP> {
    log.verbose(`Configuring account ${voipInfo.extension}`)
    try {
      return this._janus.getSIP()
    } catch (e) {
      log.error(e)
      throw e
    }
  }

  async registerUserVoips() {
    const userFromSessionStorage = sessionStorage.getItem('@occlient/user')
    const user = userFromSessionStorage ? JSON.parse(userFromSessionStorage) as User : null
    const alreadyRegisteredVoips = getVoips(this.state)

    if (user && Array.isArray(user.userVoips)) {
      const userVoipsString = `Registering user voips: ${user.userVoips.map(({voip}) => voip.extension).join()}`
      const registeredVoipsString = alreadyRegisteredVoips.length
        ? `: already registered: ${alreadyRegisteredVoips.map((v) => v.extension).join()}`
        : ''
      logger.silly(userVoipsString + registeredVoipsString)
      try {
        // recupera i voip dell'utente non ancora registrati e
        // controllo che i voip da registrare siano di tipo OC Client
        const voipsInfo: VoipInfo[] = user.userVoips
          .filter(({type}) => type.name === 'Client OC' && type.id === 1)
          .filter(({voip}) => !alreadyRegisteredVoips.find((vr) => vr.id === voip.id))
          .map(({voip}) => ({
            id: voip.id,
            extension: voip.extension,
            host: voip.pbx.host,
            password: voip.password,
            port: voip.port || '5060',
            pbx: voip.pbx
          }))
        const voipsWithSip: VoipInfo[] = await Promise.all(
          voipsInfo.map(async (voip) => {
            const sip = await this.makeAccount(voip)
            const voipWithSip = {...voip, sip}
            this.addVoipEventListeners(voipWithSip)
            return voipWithSip
          })
        )

        // registra i voip che non erano stati precedentemente registrati
        await Promise.all(
          voipsWithSip.map((voip) => {
            return voip.sip?.register({
              username: `sip:${voip.extension}@${voip.host}`,
              password: voip.password,
              server: `sip:${voip.host}:${voip.port}`,
              user_agent: `OpenCommunication Client v.${pkg.version}`,
              incoming_header_prefixes: [OC_UUID_HEADER, BW_CAMPAIGN_ID, BW_OUTBOUND_ID]
            })
          })
        )
      } catch (e) {
        log.error(e)
      }
    } else {
      logger.error('Could not register user voips: user is not defined or has invalid voips')
    }
  }

  addVoipEventListeners(voip: VoipInfo) {
    if (!voip.sip) {
      log.error('Did not make sip account for voip', voip.id)
      return
    }
    voip.sip.on('registered', () => {
      log.info(`Voip account ${voip.extension} registered`)
      // Se non era già noto viene aggiunto nello state di redux
      if (!getVoips(this.state).find((vi) => vi.id === voip.id)) this.dispatch(addVoip(voip))
      window.occlient.emit('phone:registered', voip)
    })
    voip.sip.on('registration-failed', () => {
      log.error(`Registration for account ${voip.extension} failed`)
      this._janus.emit('janus-error')
    })
    voip.sip.on('incoming-call', ({msg, jsep}: { msg: any; jsep: any }) => {
      log.silly(`VOIP ${voip.id}: incoming call`, msg)
      const displayName = msg.result.displayname.replace(/"/g, '')
      const number = displayName.match(/^\d+$/g) ? displayName : msg.result.username.split(':')[1].split('@')[0]
      const callId =
        msg.result.headers && msg.result.headers[OC_UUID_HEADER] ? msg.result.headers[OC_UUID_HEADER] : null
      const predictiveCampaignId =
        msg.result.headers && msg.result.headers[BW_CAMPAIGN_ID] ? msg.result.headers[BW_CAMPAIGN_ID] : null
      const outboundId =
        msg.result.headers && msg.result.headers[BW_OUTBOUND_ID] ? msg.result.headers[BW_OUTBOUND_ID] : null
      this.dispatch(incomingCall({voip, number, jsep, callId, predictiveCampaignId, outboundId}))
      window.occlient.emit('phone:call-start', {
        voip,
        number,
        boundness: 'in',
        jsep,
        callId,
        predictiveCampaignId,
        outboundId
      })
      voip.sip && this.startMonitoringBitrate(voip.sip)
    })
    voip.sip.on('remotestream', async (stream: any) => {
      log.silly(`VOIP ${voip.id}: remotestream`)
      const audioEl: HTMLMediaElement | null = document.getElementById('occlient-audio') as HTMLMediaElement
      if (audioEl) {
        audioEl.volume = getAudioVolume(this.state)
        JanusLib.attachMediaStream(audioEl, stream)
      }
    })
    voip.sip.on('accepted', () => {
      log.silly(`VOIP ${voip.id}: accepted`)
      this.dispatch(acceptedCall())
      ElectronProxy.acceptedCall()
      window.occlient.emit('phone:call-in-progress', getCallInProgress(this.state))
    })
    voip.sip.on('hangup', () => {
      const pathToRedirect = getPath(this.state)
      log.silly(`VOIP ${voip.id}: hangup`, pathToRedirect)
      voip.sip && this.stopMonitoringBitrate(voip.sip)
      const callInProgress = getCallInProgress(this.state)
      if (callInProgress?.boundness === 'in' && !callInProgress.answered) {
        ElectronProxy.missedCall(callInProgress)
        const userFrom = callInProgress.user
        const number = callInProgress.number
        const notification = new Notification(getTranslation(this.state).missedCallNotification, {
          body: userFrom ? `${userFrom.surname} ${userFrom.name}` : number,
          dir: 'ltr'
        })
        const clickHandler = () => {
          notification.close()
          window.focus()
          takeDownListener()
        }
        const takeDownListener = () => notification.removeEventListener('click', clickHandler)
        notification.addEventListener('click', clickHandler)
        window.onfocus = () => {
          notification && notification.close()
          window.onfocus = null
        }
      }
      window.occlient.emit('phone:call-end', callInProgress)
      ElectronProxy.callEnd()
      this.dispatch(hangupReceived())
    })
    voip.sip.on('calling', () => {
      log.silly(`VOIP ${voip.id}: calling`)
      this.dispatch(callStatusChange(CallStatus.calling))
      window.occlient.emit('phone:call-start', getCallInProgress(this.state))
      voip.sip && this.startMonitoringBitrate(voip.sip)
    })
    voip.sip.on('recording', () => {
      log.silly(`VOIP ${voip.id}: recording`)
      this.dispatch(recordingStarted())
    })
  }

  /**
   * This function unregisters all registered VoIPs and removes them from the state.
   */
  unregisterUserVoips() {
    const registeredVoips = getVoips(this.state)
    log.debug('Unregistering voips: ' + registeredVoips.map((v) => v.extension).join())
    registeredVoips.forEach((v) => v.sip?.unregister())
    this.dispatch({type: REMOVE_VOIPS})
  }

  //endregion

  //region Phone
  // Metodo per far partire una chiamata outbound
  async call({number, outbound, voip, devices = {}, isInternal, callId}: CallOptions) {
    if (!voip) {
      logger.error('Could not call because voip is falsy', voip)
      return
    }
    if (!voip.sip) {
      logger.error('Could not call because voip.sip is falsy', voip.sip)
      return
    }
    try {
      const outPrefix = isInternal ? '' : outbound ? outbound.prefix : ''
      const realNumber = number.replace(/\+/g, '00')
      const headers: any = {}
      if (callId) headers[OC_UUID_HEADER] = callId

      await voip.sip.call({number: `sip:${outPrefix}${realNumber}@${voip.host}:${voip.port}`, devices, headers})
    } catch (e: any) {
      log.error(e.message)
      throw e
    }
  }

  // Metodo per chiudere una chiamata in corso
  async hang(sip: SIP) {
    await sip.hang()
  }

  async decline(sip: SIP) {
    await sip.decline()
  }

  async requestPauseChange(pauseId?: number): Promise<void> {
    if (!this.token) return
    if (!pauseId) {
      await axios
        .delete(`${coreApi}/users/pause`, {
          headers: {
            Authorization: 'Bearer ' + this.token
          }
        })
        .catch((e) => {
          log.error('CANNOT UNPAUSE ', e)
        })
    } else {
      await axios
        .put(
          `${coreApi}/users/pause/${pauseId}`,
          {},
          {
            headers: {
              Authorization: 'Bearer ' + this.token
            }
          }
        )
        .catch((e) => {
          log.error('CANNOT PAUSE ', e)
        })
    }
  }

  startMonitoringBitrate(sip: SIP) {
    this.monitoringInterval = window.setInterval(() => {
      const bitrate = sip.monitorBitrate()
      if (bitrate !== 'Invalid handle' && bitrate !== null && bitrate !== undefined) {
        window.occlient.emit('phone:monitor-bitrate', bitrate)
        this.dispatch(setBitrate(bitrate.replace('kbits/sec', 'kbps')))
      }
    }, 1000)
  }

  stopMonitoringBitrate(sip: SIP) {
    sip.stopMonitorBitrate()
    this.dispatch(setBitrate(null))
    this.monitoringInterval && clearInterval(this.monitoringInterval)
  }

  //endregion

  //region Helpers
}

export * from './types'

const phone = new Phone()
export default phone
