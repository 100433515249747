import withAuthentication from '../../hoc/withAuthentication'
import {rem} from '../../../helpers/style'
import {getDimensions, getTranslation, getVoips} from '../../../store/selectors'
import React, {useEffect, useMemo, useState} from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import {useSelector} from 'react-redux'
import styled from 'styled-components'
import {Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow} from '@mui/material'
import {useMonitoringCallsInQueue} from "./MonitoringCallsInQueue";
import {useRest} from "../../../hooks";
import {Pbx, Queue, Voip} from "../../../api/rest"

const OperatorQueues: React.FC = () => {
  const translation = useSelector(getTranslation)
  const calls = useMonitoringCallsInQueue()
  const voips = useSelector(getVoips)
  const { width } = useSelector(getDimensions)
  const [queuesMap, setQueuesMap ]= useState<Map<string, { id: string, descr: string, callsInQueue: number, extension: string, pbx: Partial<Pbx> }>>(new Map())

  const {results: queuesFromAPI} = useRest<Queue>('queues', {lazy: false})

  const callsInQueue = useMemo(() => [...calls.values()].filter(call => call.type === 'inbound' && call.inbounds.length && !call.users.length), [calls])

  useEffect(() => {
    setQueuesMap((oldQueuesMap) => {
      if(oldQueuesMap.values().next().done) {
        const queuesMap = new Map<string, { id: string, descr: string, callsInQueue: number, extension: string, pbx: Partial<Pbx> }>()

        queuesFromAPI
          .filter((queue) => queue.members?.map((member) => (member.voip as Voip).id)
            .find((voipFromQueueMember) => voips.map((voip) => voip.id)
              .includes(voipFromQueueMember)
            )
          )
          .forEach((queue) => {
            queuesMap.set(queue.id!, {
              id: queue.id!,
              descr: queue.descr,
              callsInQueue: 0,
              extension: queue.id!.split('@')[0],
              pbx: { id: parseInt(queue.id!.split('@')[1]) }
            })
          })

        return queuesMap
      }
      else {
        return oldQueuesMap
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queuesFromAPI, setQueuesMap])

  const queues = useMemo(() => {

    const queuesMapWithCalls: Map<string, Queue> = new Map(JSON.parse(JSON.stringify([...queuesMap])))

    callsInQueue.forEach((call) => {
      call.queues.forEach((queueId) => {
        const queue = queuesMapWithCalls.get(queueId)
        if (queue) {
          queue.callsInQueue!++
          queuesMapWithCalls.set(queueId, queue)
        }
      })
    })

    return Array.from(queuesMapWithCalls.values())
  }, [queuesMap, callsInQueue])

  return (
    <Page>
      <StyledTableContainer>
        <Table stickyHeader data-testid="queues-table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="right">{translation.name}</StyledTableCell>
              <StyledTableCell align="right">{translation.callsInQueue}</StyledTableCell>
              {width > 400 && <StyledTableCell align="right">{translation.extension}</StyledTableCell>}
              {width > 500 && <StyledTableCell align="right">{translation.pbxId}</StyledTableCell>}
            </TableRow>
          </TableHead>
          <TableBody data-testid={'queues-table-body'}>
            {queues.map((queue) => {
              return (
                <StyledTableRow key={queue.id}>
                  <StyledTableCell align="right">{queue.descr}</StyledTableCell>
                  <StyledTableCell align="right" data-testid={`${queue.id}-calls`}>{queue.callsInQueue}</StyledTableCell>
                  {width > 400 && <StyledTableCell align="right">{queue.extension}</StyledTableCell>}
                  {width > 500 && <StyledTableCell align="right">{(queue.pbx as Pbx).id}</StyledTableCell>}
                </StyledTableRow>
              )
            })}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Page>
  )
}

export default withAuthentication(OperatorQueues)

//region Style

const Page = styled(ScrollBar)`
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    gap: ${rem(1)};
    padding: ${({ theme }) => theme.spacing(1)};
    font-size: ${rem(0.8)};
    font-weight: bold;
    text-align: center;
`

const StyledTableContainer = styled(TableContainer)`
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
`

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '0.7rem',
    color: theme.palette.text.primary
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.background.paper
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

//endregion
