import { LOGIN } from '../login/types'
import { Reducer } from 'redux'
import * as t from './types'
import { Outbound } from '../../api/rest'

export const initialState: t.EntitiesState = {
  users: new Map(),
  addressbooks: new Map(),
  queues: [],
  pauses: [],
  statuses: [],
  departments: new Map(),
  outbounds: []
}

type EntitiesReducer = Reducer<t.EntitiesState, t.EntitiesStateActionTypes>

const entities: EntitiesReducer = (state = initialState, action: t.EntitiesStateActionTypes): t.EntitiesState => {
  switch (action.type) {
    case t.USERS_LIST:
      return { ...state, users: action.payload }
    case t.ADDRESSBOOKS_LIST:
      return { ...state, addressbooks: action.payload }
    case t.QUEUES_LIST:
      return { ...state, queues: action.payload }
    case t.PAUSES_LIST:
      return { ...state, pauses: action.payload }
    case t.DEPARTMENTS_LIST:
      return { ...state, departments: action.payload }
    case t.STATUSES_LIST:
      return { ...state, statuses: action.payload }
    case LOGIN:
      action.payload.user.outbounds?.sort((firstOutbound: Outbound, secondOutbound: Outbound) =>
        firstOutbound.name < secondOutbound.name ? -1 : 1
      )
      return { ...state, outbounds: action.payload.user.outbounds || [] }
    default:
      return state
  }
}

export default entities
