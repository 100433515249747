import { rem } from '../../../../helpers/style'
import { useRest } from '../../../../hooks'
import {
  AddRounded as AddIcon,
  ContactsRounded as AddressBookIcon,
  DialpadRounded as NumPadIcon,
  PhoneForwardedRounded as TransferIcon
} from '@mui/icons-material'
import { SvgIcon, Tab, Tabs } from '@mui/material'
import { withStyles } from '@mui/styles'
import { changeRoute } from '../../../../store/applicationState/actions'
import { transfer } from '../../../../store/phone/actions'
import { getDimensions, getTranslation, getUsers } from '../../../../store/selectors'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Queue, Statuses, User } from '../../../../api/rest'
import { small, smaller } from '../../../../constants'
import { ReactComponent as CallTransferIcon } from '../../../../images/call_transfer.svg'
import { ReactComponent as PersonSearchIcon } from '../../../../images/icons/person_search.svg'
import { ActionButton, Address, SearchBar, UserVoipsMenu } from '../../../common'
import CallInfoTransfer from './CallInfo/CallInfoTransfer'
import NumPad from './NumPad'

enum TabsEnum {
  operators = 0,
  queues,
  numpad
}

type AddOrTransferProps = {
  page: 'add' | 'transfer'
  goBack: Function
}

const AddOrTransfer: React.FC<AddOrTransferProps> = ({ page, goBack }) => {
  const [tab, setTab] = useState<TabsEnum>(TabsEnum.operators)
  const [number, setNumber] = useState<string>('')
  const [search, setSearch] = useState<string>('')

  const { height, width } = useSelector(getDimensions)

  const inputRef = useRef<HTMLInputElement>(null)
  const stateRef = useRef(number)

  const users = useSelector(getUsers)
  const translation = useSelector(getTranslation)
  const dispatch = useDispatch()

  const { results: queues } = useRest<Queue>('queues', { lazy: false })

  const keyPress = useCallback((e: KeyboardEvent) => {
    const number = stateRef.current
    if (e.ctrlKey || e.metaKey) {
      return
    }
    e.preventDefault()
    if (e.key === 'Delete') return changeNumber('')
    if (e.key === 'Backspace') return changeNumber(number ? number.substring(0, number.length - 1) : '')
    changeNumber(number + e.key)
  }, [])

  useEffect(() => {
    const root = document.getElementById('bytewise-phone')
    if (tab !== 2) {
      root?.removeEventListener('keydown', keyPress, true)
      return
    }
    root?.addEventListener('keydown', keyPress)
    return () => {
      root?.removeEventListener('keydown', keyPress)
    }
  }, [tab, keyPress])

  const onCancelTransfer = () => {
    if (width <= smaller.width * 2) dispatch(changeRoute({ current: '/phone/call' }))
  }

  const changeNumber = (value: string) => {
    if (value.match(/^\+?[0-9*#]*$/g) || value === '') {
      stateRef.current = value
      setNumber(value)
    }
  }

  const changeNumberForKeypad = (value: string) => {
    const number = stateRef.current
    if (value.match(/^\+?[0-9*#]*$/g) || value === '') {
      stateRef.current = number + value
      setNumber(number + value)
    }
  }

  // Servirà quando aggiungeremo i contatti dalle rubriche
  // const transferToContact = (contact: Contact) => {
  //   if (contact.addresses.length === 0) return
  //   dispatch(
  //     transfer({
  //       phoneNumber: contact.addresses[0]?.value,
  //       to: contact,
  //       onCancel: onCancelTransfer,
  //       type: page
  //     })
  //   )
  // }
  // const filteredContacts = (contacts: Contact[]): Contact[] => {
  //   return contacts.filter((contact) => {
  //     if (search === '') return true
  //     const lowerCaseSearch = search.toLowerCase()
  //     if (contact.firstName?.toLowerCase().includes(lowerCaseSearch)) return true
  //     return !!contact.secondName?.toLowerCase().includes(lowerCaseSearch)
  //   })
  // }

  const transferToQueue = (queue: Queue) => {
    dispatch(
      transfer({
        phoneNumber: queue.extension,
        to: queue,
        onCancel: onCancelTransfer,
        type: page,
        blind: true
      })
    )
  }

  const transferToNumber = () => {
    if (number.length > 0) {
      dispatch(transfer({ phoneNumber: number, onCancel: onCancelTransfer, type: page }))
    }
  }

  const filteredUsers = (users: User[]): User[] => {
    return users.filter((user) => {
      if (user.state?.id === Statuses.disconnected) return false
      if (search === '') return true
      const lowerCaseSearch = search.toLowerCase()
      if (user.name?.toLowerCase().includes(lowerCaseSearch)) return true
      if (user.surname?.toLowerCase().includes(lowerCaseSearch)) return true
      return !!user.email?.toLowerCase().includes(lowerCaseSearch)
    })
  }

  const filteredQueues: Queue[] = useMemo(() => {
    if (search === '') return queues
    return queues.filter((queue) => {
      if (search === '') return true
      return queue.descr.includes(search) || queue.extension.includes(search)
    })
  }, [queues, search])

  const chooseTabToShow = () => {
    switch (tab) {
      case TabsEnum.operators:
        return filteredUsers([...users.values()]).map((user) => (
          <Address key={user.id} user={user} name={user.name} surname={user.surname} type="user">
            <UserVoipsMenu user={user} transferType={page} />
          </Address>
        ))
      case TabsEnum.queues:
        return filteredQueues.map((queue) => (
          <Address key={queue.id} type="contact" name={queue.descr} surname={queue.extension}>
            <ActionButton disabled={false} onClick={() => transferToQueue(queue)}>
              {page === 'add' ? <AddIcon /> : <TransferIcon />}
            </ActionButton>
          </Address>
        ))
      case TabsEnum.numpad:
        return (
          <KeypadContainer>
            <NumPad changeNumber={changeNumberForKeypad} />
            <ActionButton onClick={transferToNumber}>
              <SvgIcon style={{ fontSize: rem(1.5) }}>
                <CallTransferIcon />
              </SvgIcon>
            </ActionButton>
          </KeypadContainer>
        )
    }
  }

  const mustReduceInfoSize = tab === TabsEnum.numpad && height < small.height

  const TabStyled = withStyles(() => ({
    root: {
      minWidth: rem(4)
    }
  }))(Tab)

  return (
    <Container>
      <CallInfoTransfer type={page} goBack={goBack} infoFontSize={mustReduceInfoSize ? 0.7 : 1} />
      <Screen>
        {tab === TabsEnum.numpad ? (
          <PhoneScreen value={number} onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeNumber(e.target.value)} ref={inputRef} autoFocus />
        ) : (
          <SearchBar
            value={search}
            input={{
              autoFocus: true,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.currentTarget.value),
              placeholder: translation.search + '...',
              style: {
                height: rem(1.3)
              }
            }}
            setValue={setSearch}
          />
        )}
      </Screen>
      <Tabs
        value={tab}
        onChange={(_, newValue) => setTab(newValue)}
        indicatorColor="primary"
        textColor="primary"
        centered
        style={{ width: '100%', gridRow: 'menu' }}
      >
        <TabStyled icon={<AddressBookIcon />} />
        <TabStyled
          icon={
            <SvgIcon>
              <PersonSearchIcon />
            </SvgIcon>
          }
        />
        <TabStyled icon={<NumPadIcon />} />
      </Tabs>
      <List>{chooseTabToShow()}</List>
    </Container>
  )
}

export default AddOrTransfer

//region Style

const Container = styled.div`
  display: grid;
  grid-template-rows: [call-info-transfer] auto [screen] auto [menu] auto [list] 1fr;
  place-content: start center;
  width: 100%;
  height: 100%;
`

const PhoneScreen = styled.input`
  place-self: center;
  outline: none;
  border: none;
  text-align: center;
  font-size: calc(var(--font-size) * 1.2);
  background: transparent;
  color: ${({ theme }) => theme.palette.text?.primary};
  height: ${rem(1.8)};
`

const Screen = styled.div`
  grid-row: screen;
  width: 100%;
  display: grid;
  place-content: start center;
  height: ${rem(1.8)};
`

const List = styled(ScrollBar)`
  grid-row: list;
  height: fit-content;
  padding: ${rem(0.5)};
`

const KeypadContainer = styled.div`
  display: grid;
  grid-template-rows: 3fr 1fr;
  height: 100%;
  overflow: hidden;
`
//endregion
