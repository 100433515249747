import Call from '../../../pages/Phone/Call/Call'
import IncomingCall from '../../../pages/Phone/Call/IncomingCall'
import TransferringCall from '../../../pages/Phone/Call/TransferringCall'
import Keypad from '../../../pages/Phone/Keypad/Keypad'
import { CallStatus } from '../../../../store/phone/types'
import { getCallInProgress } from '../../../../store/selectors'
import React, {useCallback} from 'react'
import { useSelector } from 'react-redux'

/**
 * Componente renderizzato sempre quando il client ha width minima * 2
 * Può mostrare o il tastierino o se si è in conversazione la pagina contestuale
 */
const KeyPadOrCall: React.FC = () => {
  const callInProgress = useSelector(getCallInProgress)

  const chooseCallComponent = useCallback(() => {
    if(!callInProgress ) return <Keypad />
    switch (callInProgress?.status) {
      case CallStatus.incoming:
        return <IncomingCall />
      case CallStatus.transferring:
        return <TransferringCall />
      default:
        return <Call />
    }
  }, [callInProgress])

  return chooseCallComponent()
}

export default KeyPadOrCall
