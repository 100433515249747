import { getDimensions, getMe, getRegistry, getTheme, getUsers } from '../../store/selectors'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import log from '../../helpers/logger'
import { changeRoute, setDimensions, setLoginMethods, setRegistry } from '../../store/actions'
import { useElastic } from '../../hooks'
import logger from '../../helpers/logger'
import useRest from '../../hooks/useRest'
import { DateTime } from 'luxon'
import { DefaultTheme } from 'styled-components'
import { createTheme } from '@mui/material/styles'
import themes from '../../themes'
import axios from 'axios'
import { coreUrl } from '../../configuration'
import isElectron from '../../helpers/isElectron'
import { smaller } from '../../constants'
import { ElectronProxy } from '../../api'
import store from '../../store'

const electron = isElectron() ? window.require('electron') : null

const AppStateManager: React.FC<{ setCurrentTheme: (theme: DefaultTheme) => void }> = ({ setCurrentTheme }): null => {
  const dimensions = useSelector(getDimensions)
  const theme = useSelector(getTheme)
  const me = useSelector(getMe)
  const users = useSelector(getUsers)
  const registry = useSelector(getRegistry)
  const [registryRequired, setRegistryRequired] = useState(false)
  const dispatch = useDispatch()

  // Carica le traduzioni e imposta le dimensioni iniziali
  useEffect(() => {
    let timedout = false
    // recupero i metodi di autenticazione
    const timeout = window.setTimeout(() => {
      logger.error('Auth methods request timed out')
      dispatch(changeRoute({ current: '/error' }))
      timedout = true
    }, 5000)
    axios
      .get(`${coreUrl}/authentication/methods`)
      .then(({ data }) => {
        if (timedout) return
        log.verbose('Got login methods from core')
        dispatch(setLoginMethods(data.payload))
      })
      .catch((e) => {
        log.error(e)
        dispatch(changeRoute({ current: '/error' }))
      })
      .finally(() => clearTimeout(timeout))

    const initialWidthFromLS = localStorage.getItem('@occlient/appWidth')
    const initialHeightFromLS = localStorage.getItem('@occlient/appHeight')

    if (isElectron()) {
      const initialWidth = initialWidthFromLS
        ? parseInt(initialWidthFromLS)
        : window.occlient?.settings?.width || smaller.width
      const initialHeight = initialHeightFromLS
        ? parseInt(initialHeightFromLS)
        : window.occlient?.settings?.height || smaller.height
      ElectronProxy.resizeWindow(initialWidth, initialHeight)
    } else {
      dispatch(
        setDimensions({
          width: window.innerWidth,
          height: window.innerHeight
        })
      )
    }

    return () => {
      if (electron) {
        electron.ipcRenderer.removeAllListeners('')
      }
    }
  }, [dispatch])

  useEffect(() => {
    const newTheme =
      theme === 'light' ? createTheme(themes.light(dimensions.fontSize)) : createTheme(themes.dark(dimensions.fontSize))

    const { width, height, fontSize } = dimensions
    setCurrentTheme({
      ...newTheme,
      width: `${width}px`,
      height: `${height}px`,
      fontSize: `${fontSize}px`
    })
  }, [dimensions, theme, setCurrentTheme])

  useEffect(() => {
    const handler = () => {
      dispatch(changeRoute({ current: '/phone/incoming-call' }))
    }
    window.addEventListener('click-notify', handler)
    return () => window.removeEventListener('click-notify', handler)
  }, [dispatch])

  useEffect(() => {
    const handler = () => {
      store.dispatch(setDimensions({ width: window.innerWidth, height: window.innerHeight }))
    }
    window.addEventListener('resize', handler)
    return () => window.removeEventListener('resize', handler)
  }, [])

  const { get: getHistoryCalls } = useElastic('calls-registry')
  const { results: voips, get: getVoips } = useRest('voips', { lazy: true })
  const { results: predictiveCampaigns, get: getPredictiveCampaigns } = useRest('predictive-campaigns', { lazy: true })

  useEffect(() => {
    if (me?.id && users?.size) {
      getVoips().catch((e) => logger.error(e))
    }
  }, [me?.id, getVoips, users?.size])
  useEffect(() => {
    if (me?.id) {
      getPredictiveCampaigns().catch((e) => logger.error(e))
    }
  }, [me?.id, getPredictiveCampaigns])

  const searchForCalls = useCallback(() => {
    if (!me?.id || !voips?.length || !users?.size) return
    if (registry?.length) return
    if (registryRequired) return
    const filters = {
      users: me.id,
      from:
        me.callRegistryStart === '0'
          ? 1
          : DateTime.fromFormat(me.callRegistryStart, 'yyyy-MM-dd HH:mm:ss.SSS').toMillis(),
      sortby: 'startTime',
      sortorder: 'desc'
    }
    setRegistryRequired(true)
    getHistoryCalls({ filters })
      .then((data: any) => {
        const rawResults = data?.hits?.hits ? data.hits.hits.map((d: any) => ({ elasticId: d._id, ...d._source })) : []
        dispatch(
          setRegistry(
            rawResults.map((rawResult: any) => {
              const userIdFrom =
                rawResult.from === '<unknown>'
                  ? me.id
                  : voips.find((voip) => voip.extension === parseInt(rawResult.from))?.userId
              const userIdTo = voips.find((voip) => voip.extension === parseInt(rawResult.to))?.userId
              const user = userIdFrom && userIdFrom !== me.id ? users.get(userIdFrom) : users.get(userIdTo)
              const predictiveCampaign =
                rawResult.type === 'predictive' &&
                predictiveCampaigns.find((campaign) => campaign.id === rawResult.campaignId)

              return {
                id: rawResult.calluid,
                userName: user
                  ? `${user.surname} ${user.name}`
                  : ['predictive', 'outbound'].includes(rawResult.type)
                  ? rawResult.to
                  : rawResult.from,
                userId: user?.id || '',
                timestamp: rawResult.startTime,
                telephone: userIdFrom && userIdFrom === me.id ? rawResult.to : rawResult.from,
                outcome: rawResult.outcome === 'ANSWER' ? 'answered' : 'notAnswered',
                type: rawResult.type === 'outbound' ? 'out' : 'in',
                outbound: !!predictiveCampaign
                  ? {
                      id: predictiveCampaign.id,
                      name: predictiveCampaign.name,
                      prefix: predictiveCampaign.outbound?.prefix || 0
                    }
                  : rawResult.outbound,
                inbound: rawResult.inbounds?.length
                  ? rawResult.inbounds.map((inbound: any) => inbound.name).join(', ')
                  : '',
                duration: rawResult.endTime - rawResult.startTime
              }
            })
          )
        )
      })
      .catch((e) => logger.error(e))
  }, [
    getHistoryCalls,
    users,
    voips,
    dispatch,
    registry?.length,
    predictiveCampaigns,
    registryRequired,
    setRegistryRequired,
    me
  ])
  useEffect(() => {
    searchForCalls()
  }, [searchForCalls])

  return null
}

export default AppStateManager
