import { User } from '../../api/rest'
import { rem } from '../../helpers/style'
import { AddCircleRounded, CheckCircleRounded } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { getDimensions, getTranslation, getUsersArray } from '../../store/selectors'
import React, { useCallback, useEffect, useState } from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { AvatarWithStatus } from './AvatarWithStatus'
import { SearchBar } from './SearchBar'

type Props = {
  selectedUsers: Map<number, User>
  setSelectedUsers: (a0: Map<number, User>) => void
  exclude?: string[]
}
export const UserSelection: React.FC<Props> = ({ selectedUsers, setSelectedUsers, exclude = [] }) => {
  const [search, setSearch] = useState('')
  const [usersToShow, setUsersToShow] = useState<User[]>([])

  const translation = useSelector(getTranslation)
  const { fontSize } = useSelector(getDimensions)
  const users = useSelector(getUsersArray)

  const doSearch = useCallback(() => {
    if (!search) {
      setUsersToShow(users.filter((u) => !exclude?.includes(u.username)))
      return
    }

    const s = search.toLowerCase()
    const newUsersToShow = users.filter((u: User) => {
      if (exclude?.includes(u.username)) return false
      if (u.name.toLowerCase().includes(s)) return true
      return u.surname.toLowerCase().includes(s)
    })

    newUsersToShow.sort((a, b) => a.name.localeCompare(b.name) || a.surname.localeCompare(b.surname))
    setUsersToShow(newUsersToShow)
  }, [search, exclude, users])

  useEffect(() => {
    doSearch()
  }, [doSearch])

  const selectUser = (u: User) => {
    const wasSelected = selectedUsers.get(u.id)
    const newSelectedMap = new Map(selectedUsers)
    if (wasSelected) {
      newSelectedMap.delete(u.id)
    } else {
      newSelectedMap.set(u.id, u)
    }
    setSelectedUsers(newSelectedMap)
  }

  return (
    <>
      <SearchBar
        value={search}
        setValue={setSearch}
        input={{
          onChange: (event) => setSearch(event.target.value),
          style: { width: '100%', height: rem(1.3, fontSize), placeSelf: 'center', padding: rem(0.2, fontSize) }
        }}
        style={{
          width: 'fit-content',
          placeContent: 'center',
          placeSelf: 'start center',
          height: rem(1.3, fontSize),
          maxWidth: 600
        }}
      />
      <List>
        {usersToShow.length === 0 && (
          <Typography style={{ placeSelf: 'center' }}>{translation.zeroAvailableUsers}</Typography>
        )}
        {usersToShow.map((u) => (
          <UserCard key={u.id} onClick={() => selectUser(u)}>
            <AvatarWithStatus user={u} />
            <UserName>
              {u.surname} {u.name}
            </UserName>
            {selectedUsers.get(u.id) ? (
              <CheckCircleRounded color="primary" style={{ placeSelf: 'center' }} />
            ) : (
              <AddCircleRounded color="action" style={{ placeSelf: 'center' }} />
            )}
          </UserCard>
        ))}
      </List>
    </>
  )
}

//region Style

const List = styled(ScrollBar)`
  box-sizing: border-box;
  display: grid;
  gap: 5px;
  width: 100%;
  max-width: 600px;
`

const UserCard = styled.div`
  display: grid;
  grid-template-columns: 40px auto 40px;
  width: 100%;
  grid-column-gap: 10px;
  border-radius: 15px;
  place-content: center stretch;
  max-width: 500px;
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.palette.action.hover};
  }
`

const UserName = styled.div`
  place-self: center start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`

//endregion
