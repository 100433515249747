import { rem } from '../../../../helpers/style'
import { ContactsRounded } from '@mui/icons-material'
import { getDimensions, getTranslation, getUsersArray } from '../../../../store/selectors'
import React, { useEffect, useState } from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import { useSelector } from 'react-redux'
import { useMatch } from 'react-router-dom'
import styled from 'styled-components'
import { AddressBook, Contact, User } from '../../../../api/rest'
import useRest from '../../../../hooks/useRest'
import { Select, Title } from '../../../common'
import AddressBookEntry from './AddressBookEntry'

interface SelectValue {
  value: number
  label: string
  type: 'contact' | 'user'
}

//TODO: controllare perche faccio la richiesta ogni volta che seleziono una rubrica e non uso i dati in results
const AddressBookPage: React.FC = () => {
  const { results, getById } = useRest<AddressBook>('addressbooks')
  const [options, setOptions] = useState<SelectValue[]>([])
  const [selected, setSelected] = useState<SelectValue | null>()
  const [addressBook, setAddressBook] = useState<AddressBook | User[]>()
  const { fontSize } = useSelector(getDimensions)

  const match = useMatch(`/phone/address-book/:id?`)
  const id = match?.params?.id

  const translation = useSelector(getTranslation)

  const users: User[] = useSelector(getUsersArray)

  useEffect(() => {
    const selectValue: SelectValue[] = [{ value: 0, label: 'interni', type: 'user' }]
    selectValue.push(
      ...results.map<SelectValue>((a) => ({
        value: a.id,
        label: a.name,
        type: 'contact'
      }))
    )
    setOptions(selectValue)

    if (id && results && results.length >= 0) {
      const selectedaddressBook = selectValue.filter((sv) => sv.value === parseInt(id))
      if (selectedaddressBook?.length === 1) {
        setSelected(selectedaddressBook[0])
      } else {
        setSelected({ value: 0, label: 'Interni', type: 'user' })
      }
    } else setSelected({ value: 0, label: 'Interni', type: 'user' })
  }, [results, id])

  useEffect(() => {
    if (!selected) return setAddressBook(users)
    if (selected.type === 'contact')
      getById({ id: selected.value }).then((res) => {
        setAddressBook(res)
      })
    else setAddressBook(users)
  }, [selected, getById, users])

  const getContacts = (): Contact[] | undefined => {
    if (!addressBook) return undefined
    if (!selected || selected.type === 'user') return undefined

    if ('contacts' in addressBook && addressBook.contacts) return addressBook.contacts as Contact[]
    return undefined
  }

  return (
    <Container>
      <TitleContainer>
        <Title label={translation.addressBook} icon={<ContactsRounded />} style={{ padding: '3px 5px' }} />
        <SelectContainer>
          <Select<SelectValue>
            options={options}
            selected={selected || null}
            onChange={(t) => {
              setSelected(t || null)
            }}
            getLabel={(t) => t.label}
            getValue={(t) => t.value}
            style={{ placeSelf: 'center start', maxWidth: 200, width: '100%', fontSize: rem(0.7), minHeight: 0 }}
            required
          />
        </SelectContainer>
      </TitleContainer>
      <ScrollBar style={{ paddingBottom: fontSize * 2 + 8 }}>
        {addressBook ? (
          <AddressBookEntry
            from={`/phone/address-book/${selected?.value || 0}`}
            contacts={getContacts()}
            users={selected?.type === 'user' ? users : undefined}
          />
        ) : (
          <div>Nessuna rubrica selezionata</div>
        )}
      </ScrollBar>
    </Container>
  )
}

// @ts-ignore
export default AddressBookPage

//region Style

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  padding-top: ${rem(0.5)};
  overflow: hidden;
  gap: 5px;
`
const TitleContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
`
const SelectContainer = styled.div`
  place-self: center;
  width: 80%;
`
//endregion
