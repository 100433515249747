import { ElectronProxy } from '../../../api'
import { LanguageCode } from '../../../api/rest'
import { coreUrl } from '../../../configuration'
import { getTranslation } from '../../../helpers/getTranslation'
import log from '../../../helpers/logger'
import { rem } from '../../../helpers/style'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { changeRoute } from '../../../store/applicationState/actions'
import { setTranslation } from '../../../store/language/actions'
import { setLoginMethods } from '../../../store/login/actions'
import { getDimensions, getTheme, getTranslation as getTranslationFromStore } from '../../../store/selectors'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import imgBackground from '../../../images/login-background.jpeg'
import { ReactComponent as LogoIcon } from '../../../images/OC.svg'
import { ReactComponent as Logo } from '../../../images/OC_full_white.svg'
import { ReactComponent as LogoIconDark } from '../../../images/OC_white.svg'

const LANGUAGE = sessionStorage.getItem('@occlient/selectedTranslation') || 'it_IT'

const Error: React.FC = () => {
  const [counter, setCounter] = useState(10)
  const [loading, setLoading] = useState(false)
  const theme = useSelector(getTheme)
  const translation = useSelector(getTranslationFromStore)

  const { width } = useSelector(getDimensions)

  const dispatch = useDispatch()

  useEffect(() => {
    if (loading) return
    const interval = window.setInterval(() => setCounter((old) => old - 1), 1000)
    return () => clearInterval(interval)
  }, [loading])

  useEffect(() => {
    if (counter !== 0) return
    setLoading(true)
    Promise.all([
      new Promise((resolve, reject) => {
        getTranslation(LANGUAGE)
          .then((translation) => {
            dispatch(setTranslation(LANGUAGE as LanguageCode, translation))
            ElectronProxy.changeLanguage(LANGUAGE as LanguageCode)
            resolve(true)
          })
          .catch(() => {
            reject()
          })
      }),
      new Promise((resolve, reject) => {
        let timedout = false
        const timeout = window.setTimeout(() => {
          timedout = true
          reject()
        }, 5000)
        axios
          .get(`${coreUrl}/authentication/methods`)
          .then(({ data }) => {
            if (timedout) return
            log.verbose('Got login methods from core')
            dispatch(setLoginMethods(data.payload))
            resolve(true)
          })
          .catch((e) => {
            log.error(e)
            reject()
          })
          .finally(() => {
            clearTimeout(timeout)
          })
      })
    ])
      .then(() => {
        if (sessionStorage.getItem('@occlient/token')) {
          dispatch(changeRoute({ current: '/phone', previous: '/phone' }))
        } else {
          dispatch(changeRoute({ current: '/login', previous: '/login' }))
        }
      })
      .catch(() => {
        setCounter(10)
      })
      .finally(() => setLoading(false))
  }, [counter, dispatch])

  return (
    <Container templateColumns={width >= 1280 ? '1.5fr 2fr' : '1fr'}>
      <LeftContainer>
        <LogoContainer>
          {theme === 'light' ? <LogoIcon /> : <LogoIconDark />}
          <LogoDisclaimer>OpenCommunication</LogoDisclaimer>
        </LogoContainer>
        <MethodsContainer>
          <Typography color="error" sx={{ placeSelf: 'center' }}>
            {translation.serverNotReachable || 'Server not reachable'}
          </Typography>
          <Box sx={{ placeSelf: 'center' }}>
            {!loading ? (
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography color="action" sx={{ placeSelf: 'center' }}>
                  {translation.newAttempt || 'New attempt in'}: {counter} {translation.seconds || 'seconds'}
                </Typography>
                <Button
                  onClick={() => {
                    setLoading(true)
                    setCounter(0)
                  }}
                  sx={{ maxWidth: 300, placeSelf: 'center' }}
                  variant="outlined"
                >
                  Riprova
                </Button>
              </Box>
            ) : (
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography>{translation.reconnecting || 'Reconnecting...'}</Typography>
                <CircularProgress sx={{ placeSelf: 'center' }} />
              </Box>
            )}
          </Box>
        </MethodsContainer>
      </LeftContainer>
      {width >= 1280 && (
        <RightContainer>
          <FullLogoContainer>
            <Logo />
          </FullLogoContainer>
          <DarkeningEffect />
        </RightContainer>
      )}
    </Container>
  )
}

export default Error

//region Style
const Container = styled.div<{ templateColumns: '1.5fr 2fr' | '1fr' }>`
  display: grid;
  grid-template-columns: ${(props) => props.templateColumns};
  width: var(--width);
  height: var(--height);
`

const LeftContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 1.5fr;
  width: 100%;
  height: var(--height);
  background: ${(props) => props.theme.palette.background.paper};
`

const LogoContainer = styled.div`
  place-self: center start;
  text-align: center;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-row-gap: 5px;
  place-items: center;
  & > svg {
    width: auto;
    height: 20%;
    max-height: 180px;
    min-height: 80px;
    place-self: end center;
  }
`

const LogoDisclaimer = styled.div`
  color: ${(props) => props.theme.palette.primary.main};
  font-size: ${rem(0.8)};
  width: 80%;
  max-width: 300px;
`

const MethodsContainer = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 450px;
  place-self: start center;
  padding-top: ${rem(0.5)};
`

const RightContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 2fr;

  width: 100%;
  height: 100%;
  background-image: url('${imgBackground}');
  background-repeat: no-repeat;
  background-size: auto var(--height);
  position: relative;
`

const DarkeningEffect = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to bottom, rgb(17, 91, 114), rgba(17, 91, 114, 0));
  mix-blend-mode: multiply;
`

const FullLogoContainer = styled.div`
  place-self: center start;
  text-align: center;
  width: 100%;
  display: grid;
  place-items: center;
  z-index: 1000;
  & > svg {
    width: auto;
    height: 20%;
    max-height: 180px;
    min-height: 80px;
    place-self: end center;
  }
`

//endregion
