import { getCallInProgress, getTranslation } from '../../../../../store/selectors'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

const CallInfoAutoAnswer: React.FC = () => {
  const call = useSelector(getCallInProgress)
  const translation = useSelector(getTranslation)

  const [currTime, setCurrTime] = useState(100)
  const [intervalId, setIntervalId] = useState<number | null>(null)

  useEffect(() => {
    if (!call?.autoAnswerTime) return
    setCurrTime(call.autoAnswerTime)
    const _intervalId = window.setInterval(() => {
      setCurrTime((prev) => prev - 1)
    }, 1000)
    setIntervalId(_intervalId)
    return () => {
      window.clearTimeout(_intervalId)
    }
  }, [call?.autoAnswerTime])

  useEffect(() => {
    if (currTime === 0 && intervalId) {
      window.clearInterval(intervalId)
    }
  }, [currTime, intervalId])

  return call && call.autoAnswerTime ? (
    <Container>
      {translation.autoAnswerIn} {currTime} {translation.seconds}
    </Container>
  ) : null
}

export default CallInfoAutoAnswer

//region Style

const Container = styled.div``

//endregion
