import { useElapsedTime } from '../../hooks'
import { Tooltip } from '@mui/material'
import { getSelectedLanguage } from '../../store/selectors'
import React, { MouseEvent, useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Pause, Status } from '../../api/rest'
import statusesColors from '../../constants/states-colors'

type Props = {
  width?: string
  height?: string
  size?: string
  color?: string
  style?: React.CSSProperties
  stroke?: string
  strokeWidth?: string
  status?: Partial<Pause> | Partial<Status>
  onClick?: (e: MouseEvent) => any
  forcedColor?: string
}

export const StatusCircle = React.forwardRef<SVGSVGElement, Props>((props, ref) => {
  const { width, height, style, stroke, strokeWidth, size, status, onClick, forcedColor } = props

  const language = useSelector(getSelectedLanguage)
  const { elapsedTimeString } = useElapsedTime(status?.lastchange)
  const color: string = useMemo(() => {
    if (forcedColor) return forcedColor
    if (!status) return '#000'
    if (status.isStatus) {
      return statusesColors[(status as Status).name || 'NOT_FOUND']
    } else {
      return (status as Pause).color
    }
  }, [status, forcedColor])

  const tooltip: string = useMemo(() => {
    if (!status || forcedColor) return ''
    if (!status.translations) return status.name || status.label || ''
    return `${status.translations[language]} (${elapsedTimeString})`
  }, [status, elapsedTimeString, forcedColor, language])

  return (
    <Tooltip title={tooltip}>
      <Svg
        ref={ref}
        viewBox="0 0 24 24"
        widthCustom={size || width || '20px'}
        heightCustom={size || height || '20px'}
        style={
          style ? { cursor: onClick ? 'pointer' : 'default', ...style } : { cursor: onClick ? 'pointer' : 'default' }
        }
        {...(onClick ? { onClick } : {})}
      >
        <circle
          cx="12"
          cy="12"
          r="10"
          fill={color}
          {...(stroke ? { stroke } : {})}
          {...(strokeWidth ? { strokeWidth } : {})}
        />
      </Svg>
    </Tooltip>
  )
})
type SvgProps = {
  widthCustom: string
  heightCustom: string
}
const Svg = styled.svg<SvgProps>`
  width: ${({ widthCustom }) => widthCustom};
  height: ${({ heightCustom }) => heightCustom};
`
