import logger from '../../helpers/logger'

const DEFAULT_REQUESTS_LIMIT = 50

interface Entities {
  [key: string]: Entity<any>
}
interface Entity<T> {
  requests: number
  payload: T[]
  requestsLimit: number
}
class CacheManager {
  private readonly _entities: Entities
  constructor() {
    this._entities = {}
  }

  getEntity<T>(name: string): T[] | null {
    const entity = this._entities[name]
    if (entity) {
      entity.requests += 1
      if (entity.requests >= entity.requestsLimit) {
        delete this._entities[name]
        return null
      } else {
        return entity.payload as T[]
      }
    } else {
      return null
    }
  }

  setEntity<T>(name: string, payload: T[], requestsLimit: number = DEFAULT_REQUESTS_LIMIT): void {
    logger.info('Setting entity', name, 'in cache manager', payload)
    this._entities[name] = { requests: 0, requestsLimit, payload }
  }
}

const cacheManager = new CacheManager()

export default cacheManager
