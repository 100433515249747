import { rem } from '../../../../helpers/style'
import {
  ChatBubbleRounded,
  MicOffRounded as MuteIcon,
  MicRounded as UnmuteIcon,
  PhoneForwardedRounded,
  PhoneInTalkRounded as UnpauseIcon,
  PhonePausedRounded as PauseIcon
} from '@mui/icons-material'
import { SvgIcon } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { hold, mute, startRecording, stopRecording } from '../../../../store/phone/actions'
import { CallStatus } from '../../../../store/phone/types'
import { getCallInProgress, getConversationsArray, getDimensions, getMe, getTranslation } from '../../../../store/selectors'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { ReactComponent as RecordIcon } from '../../../../images/icons/record.svg'
import { ActionButton } from '../../../common'
import { PhoneButton } from '../../../common/PhoneButton'
import { Messenger } from '../../../../api'
import { changeRoute } from '../../../../store/applicationState/actions'

type CallButtonsProps = {
  changePage: Function
}
const CallButtons: React.FC<CallButtonsProps> = ({ changePage }) => {
  const translation = useSelector(getTranslation)
  const call = useSelector(getCallInProgress)
  const { fontSize } = useSelector(getDimensions)
  const dispatch = useDispatch()
  const theme = useTheme()
  const conversations = useSelector(getConversationsArray)
  const me = useSelector(getMe)

  const chatInfo = useMemo(() => {
    if (!call?.user)
      return {
        path: '/messenger',
        state: undefined
      }
    const conv = Messenger.getConversationByMembers(conversations, [
      Messenger.getMemberFromUser(me),
      Messenger.getMemberFromUser(call?.user)
    ])
    if (conv) return { path: `/messenger/${conv.id}`, state: undefined }
    else return { path: '/messenger/new', state: { otherMember: call?.user } }
  }, [call, me, conversations])

  return (
    <Container>
      <ButtonContainer>
        <PhoneButton
          onClick={() => dispatch(hold() as any)}
          tooltip={translation.hold}
          disabled={call?.status === CallStatus.calling}
          isActive={call?.status === CallStatus.hold}
          icon={<PauseIcon style={{ width: rem(1.4, fontSize), height: rem(1.4, fontSize) }} />}
          activeIcon={<UnpauseIcon style={{ width: rem(1.4, fontSize), height: rem(1.4, fontSize) }} />}
        />
        <ActionButton
          onClick={() => changePage('add')}
          tooltip={translation.addOrTransfer}
          disabled={!!call?.status && [CallStatus.hold, CallStatus.calling].includes(call?.status)}
        >
          <PhoneForwardedRounded
            style={{
              fill: call?.status === CallStatus.calling ? theme.palette.action.disabled : theme.palette.action.active,
              width: rem(1.4, fontSize),
              height: rem(1.4, fontSize)
            }}
          />
        </ActionButton>
        <PhoneButton
          onClick={() => dispatch(mute() as any)}
          tooltip={translation.mute}
          disabled={call?.status === CallStatus.calling}
          isActive={call?.status === CallStatus.muted}
          icon={<UnmuteIcon style={{ width: rem(1.4, fontSize), height: rem(1.4, fontSize) }} />}
          activeIcon={<MuteIcon style={{ width: rem(1.4, fontSize), height: rem(1.4, fontSize) }} />}
        />
        <PhoneButton
          onClick={() => dispatch(!call?.recordingFilename ? startRecording() : stopRecording() as any)}
          tooltip={translation.record}
          disabled={!call?.id}
          isActive={!!call?.recordingFilename}
          icon={
            <SvgIcon
              style={{
                fill: call?.status === CallStatus.calling ? theme.palette.action.disabled : theme.palette.action.active,
                width: rem(1.4, fontSize),
                height: rem(1.4, fontSize)
              }}
            >
              <RecordIcon />
            </SvgIcon>
          }
          activeIcon={
            <RecordIcon
              style={{ fill: theme.palette.text.primary, width: rem(1.4, fontSize), height: rem(1.4, fontSize) }}
            />
          }
        />
        {call?.isInternal && (
          <ActionButton
            tooltip={translation?.tooltips?.chat}
            disabled={!call?.user}
            size="small"
            onClick={() => dispatch(changeRoute({ current: chatInfo.path }) as any)}
          >
            <ChatBubbleRounded style={{ width: rem(1.6, fontSize), height: rem(1.4, fontSize) }} />
          </ActionButton>
        )}
      </ButtonContainer>
    </Container>
  )
}

export default CallButtons

//region Style

const Container = styled.div`
  display: grid;
  place-self: end center;
  grid-template-rows: 1fr;
`

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  place-self: center;
  place-content: space-between;
`

//endregion
