import { VolumeDownRounded, VolumeMuteRounded, VolumeOffRounded, VolumeUpRounded } from '@mui/icons-material'
import React from 'react'

type Props = {
  volume: number
  muted?: boolean
}
export const DynamicVolumeIcon: React.FC<Props> = ({ volume, muted = false }) => {
  if (muted) return <VolumeOffRounded />
  if (volume === 0) return <VolumeMuteRounded />

  if (volume <= 0.5) return <VolumeDownRounded />

  return <VolumeUpRounded />
}
