import {Divider} from '@mui/material'
import {getDimensions} from '../../store/selectors'
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'
import {smaller} from '../../constants'
import {setRenderedPages} from "../../store/applicationState/actions";

interface WithMorePages {
  style?: React.CSSProperties
}

type Options = {
  maxPages?: number
  pageMinWidth?: number
  stretchFirst?: boolean
}
const withMorePages = <T extends WithMorePages = WithMorePages>(
  WrappedComponent: React.ComponentType<T>,
  components: React.ComponentType<any>[],
  opts: Options = {}
) => {
  return (props: T) => {
    const { width, renderedPages } = useSelector(getDimensions)
    const dispatch = useDispatch()

    const { maxPages = 3, pageMinWidth = smaller.width, stretchFirst = false } = opts

    useEffect(() => {
      const currentPagesToRender = Math.min(maxPages, Math.floor(width / pageMinWidth))
      if(renderedPages !== currentPagesToRender) dispatch(setRenderedPages(currentPagesToRender) as any)
    }, [dispatch, maxPages, renderedPages, pageMinWidth, width])

    const renderLastChild = () => {
      if (width >= pageMinWidth * 2) {
        const LastChild = components[components.length - 1]
        return (
          <>
            <Divider orientation="vertical" style={{ placeSelf: 'center', height: '70%' }} />
            <LastChild />
          </>
        )
      } else {
        return null
      }
    }

    return (
      <Container columns={renderedPages || 1} stretchFirst={stretchFirst}>
        <WrappedComponent {...(props as T)} />
        {!!renderedPages && components.slice(0, renderedPages - 2 < 0 ? 0 : renderedPages - 2).map((Component, i) => (
          <React.Fragment key={i}>
            <Divider orientation="vertical" style={{ placeSelf: 'center', height: '70%' }} />
            <Component />
          </React.Fragment>
        ))}
        {renderLastChild()}
      </Container>
    )
  }
}

export default withMorePages

//region style

const getTemplate = (n: number, stretchFirst: boolean) => {
  const range = [...new Array(n).keys()]
  if (n <= 1) return '1fr'
  if (n > 1) {
    let result = stretchFirst ? '2fr' : '1fr'
    for (let i = 0; i < range.length - 1; i++) {
      result += ' 10px 1fr'
    }
    return result
  }
}

const Container = styled.div<{ columns: number; stretchFirst: boolean }>`
  display: grid;
  grid-template-columns: ${({ columns, stretchFirst }) => getTemplate(columns, stretchFirst)};
  overflow: hidden;
  height: 100%;
`

//endregion
