import { CustomerChat } from '../../../../api'
import {Chat, DeviceTypes} from '../../../../api/rest'
import { Avatar } from '../../../common'
import { rem } from '../../../../helpers/style'
import { ArrowBackRounded } from '@mui/icons-material'
import { AppBar, Badge, IconButton, Toolbar } from '@mui/material'
import { changeRoute } from '../../../../store/applicationState/actions'
import { getUnreadEvents } from '../../../../store/selectors'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import devicesIcons from '../common/devicesIcons'

type Props = {
  chat: Chat
}

const SpyChatBodyTopBar: React.FC<Props> = ({ chat }) => {
  const unreadMessages = useSelector(getUnreadEvents)

  const dispatch = useDispatch()

  const goBack = useCallback(() => {
    dispatch(changeRoute({ current: '/customer-chat' }) as any)
  }, [dispatch])

  return (
    <AppBar
      position="sticky"
      color="inherit"
      style={{
        height: 'fit-content',
        minWidth: 0,
        minHeight: 0,
        boxSizing: 'border-box',
        boxShadow:
          '2px 2px 4px -1px rgb(0 0 0 / 20%), 3px 4px 5px 0px rgb(0 0 0 / 14%), 7px 1px 10px 0px rgb(0 0 0 / 12%)',
        width: '100%'
      }}
    >
      <Toolbar
        style={{
          display: 'flex',
          gap: rem(0.5),
          minWidth: 'unset',
          minHeight: 'unset',
          width: '100%',
          fontSize: rem(0.7),
          padding: '8px 0'
        }}
      >
        <IconButton edge="start" color="inherit" sx={{ ml: 2 }} onClick={goBack} size="small">
          <Badge
            badgeContent={unreadMessages}
            color="secondary"
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            variant="dot"
          >
            <ArrowBackRounded />
          </Badge>
        </IconButton>
        <Avatar size="30px" fontSize="14px" letterSpacing="-2px" fullname={CustomerChat.getGuestFullname(chat.guest)} >
          {devicesIcons[(chat.guest.info.client_info.device as DeviceTypes)]}
        </Avatar>
        <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', flexGrow: 1 }}>
          {CustomerChat.getGuestFullname(chat.guest)}
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default SpyChatBodyTopBar
