import { IRegistryCall } from '../../../api/database/types'
import { getAddressBooksArray, getPath, getRegistry, getTranslation, getUsersArray } from '../../../store/selectors'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Contact, User } from '../../../api/rest'
import { high } from '../../../constants'
import { Address, ContactButton, RegistryCall, SearchBar, UserButton } from '../../common'

type SearchProps = {
  from?: string
  close: Function
}
const SearchPage = React.forwardRef<HTMLDivElement, SearchProps>((props, ref) => {
  const [search, setSearch] = useState('')
  const [usersResults, setUsersResults] = useState<User[]>([])
  const [contactsResults, setContactsResults] = useState<Contact[]>([])
  const [historyResults, setHistoryResults] = useState<IRegistryCall[]>([])
  const [messengerResults, setMessengerResults] = useState<IRegistryCall[]>([])
  const [chatsResults, setChatsResults] = useState<IRegistryCall[]>([])

  const users = useSelector(getUsersArray)
  const addressbooks = useSelector(getAddressBooksArray)
  const registry = useSelector(getRegistry)
  const { current } = useSelector(getPath)
  const translation = useSelector(getTranslation)

  const inputRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null)

  const { from = current } = props

  const searchInUsers = useCallback(
    (term: string) => {
      const matching = users.filter((u) => {
        if (u.name && u.name.toLowerCase().includes(term)) return true
        if (u.surname && u.surname.toLowerCase().includes(term)) return true
        if (u.email && u.email.toLowerCase().includes(term)) return true
        if (u.username && u.username.toLowerCase().includes(term)) return true
        return u.userVoips.some(({ voip }) => voip.extension && `${voip.extension}`.includes(term))
      })
      setUsersResults(matching)
    },
    [users]
  )

  const searchInAddressBooks = useCallback(
    (term: string) => {
      const contacts: Contact[] = addressbooks.map((a) => a.contacts).flat()
      const matching = contacts.filter((c) => {
        if (c.firstName && c.firstName.toLowerCase().includes(term)) return true
        if (c.secondName && c.secondName.toLowerCase().includes(term)) return true
        return c.addresses.some((a) => a.value.toLowerCase().includes(term))
      })
      setContactsResults(matching)
    },
    [addressbooks]
  )

  const searchInRegistry = useCallback(
    (term: string) => {
      const matching = registry.filter((r) => {
        if (!r.telephone || !r.userName) return false
        return (
          r.telephone.toLowerCase().includes(term) ||
          r.userName.toLowerCase().includes(term) ||
          (r.inbound && r.inbound.toLowerCase().includes(term)) ||
          (r.outbound && r.outbound.name.toLowerCase().includes(term))
        )
      })
      setHistoryResults(matching)
    },
    [registry]
  )

  useEffect(() => {
    if (search.length === 0) {
      setUsersResults([])
      setContactsResults([])
      setHistoryResults([])
      setMessengerResults([])
      setChatsResults([])
      return
    }
    const term = search.toLowerCase()
    searchInUsers(term)
    searchInAddressBooks(term)
    searchInRegistry(term)
  }, [search, searchInUsers, searchInAddressBooks, searchInRegistry])

  const noResults = () => {
    return (
      usersResults.length === 0 &&
      contactsResults.length === 0 &&
      historyResults.length === 0 &&
      messengerResults.length === 0 &&
      chatsResults.length === 0
    )
  }
  useEffect(() => {
    // gestione click fuori dalla input per chiudere la modale di ricerca
    const handler = (event: any) => {
      if (!inputRef.current) return
      if (!inputRef?.current?.contains(event.target)) {
        props.close()
      }
    }
    document.addEventListener('click', handler, true)
    return () => {
      document.removeEventListener('click', handler)
    }
  }, [props])

  return (
    <Container ref={ref}>
      <Search>
        <div ref={inputRef}>
          <SearchBar
            value={search}
            style={searchBarStyle}
            input={{
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.currentTarget.value),
              placeholder: translation.search + '...',
              autoFocus: true,
              style: { height: 30 }
            }}
            setValue={setSearch}
          />
        </div>
      </Search>
      <Results>
        {search.length > 0 && noResults() && <Title>{translation.noResults}</Title>}
        {usersResults.length > 0 && (
          <>
            <Title>{translation.users}</Title>
            <Section>
              {usersResults.map((u, index) => (
                <Address type={'user'} user={u} key={`${u.username}-${index}`}>
                  {u?.userVoips && u.userVoips.length > 0 && (
                    <UserButton
                      key={`${u.id}${u.userVoips[0].voip.extension}-${index}`}
                      from={from}
                      user={u}
                      beforeClick={() => props.close(true)}
                    />
                  )}
                </Address>
              ))}
            </Section>
          </>
        )}
        {contactsResults.length > 0 && (
          <>
            <Title>{translation.contacts}</Title>
            <Section>
              {contactsResults.map((c, index) => (
                <Address type={'contact'} contact={c} key={`ext${index}${c.secondName}${c.firstName}-${index}`}>
                  {c.addresses && c.addresses.length > 0 && (
                    <ContactButton key={`${c.firstName}${c.addresses[0].value}`} contact={c} from={from} />
                  )}
                </Address>
              ))}
            </Section>
          </>
        )}
        {historyResults.length > 0 && (
          <>
            <Title>{translation.registry}</Title>
            <Section>
              {historyResults.map((r) => (
                <RegistryCall key={r.timestamp} registryCall={r} from={from} beforeClick={props.close} />
              ))}
            </Section>
          </>
        )}
        {messengerResults.length > 0 && (
          <Section>
            <Title>{translation.messenger}</Title>
          </Section>
        )}
        {chatsResults.length > 0 && (
          <Section>
            <Title>{translation.chats}</Title>
          </Section>
        )}
      </Results>
    </Container>
  )
})

export default SearchPage

//region Style

const searchBarStyle = {
  placeSelf: 'center',
  marginRight: 0,
  maxWidth: 150,
  maxHeight: '35px'
}

const Container = styled.div`
  display: grid;
  grid-template-areas: 'search' 'results';
  box-sizing: border-box;
  padding: 10px;
  position: absolute;
  -webkit-backdrop-filter: blur(10px) saturate(125%);
  backdrop-filter: blur(10px) saturate(125%);
  background-color: ${({ theme }) =>
    theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.4)'};
  top: var(--topbar-height);
  right: 0;
  bottom: 0;
  left: 0;
  font-size: calc(var(--font-size) * 0.8);
  z-index: 1200;
  place-content: start center;
  color: ${({ theme }) => theme.palette.text?.primary};
  overflow: hidden;
  & > svg {
    position: absolute;
    width: calc(var(--font-size) * 1.2);
    height: calc(var(--font-size) * 1.2);
    fill: ${({ theme }) => theme.palette.text?.secondary};
    top: 10px;
    right: 10px;
    z-index: ${high + 1};
    cursor: pointer;
  }
`

const Search = styled.div`
  display: grid;
  grid-area: search;
  width: var(--width);
  place-items: center;
  margin-bottom: 10px;
`
const Results = styled(ScrollBar)`
  display: grid;
  place-items: center;
  grid-area: results;
  width: 100%;
  height: 100%;
`
const Title = styled.div`
  font-size: calc(var(--font-size) * 1.2);
  text-align: center;
`

const Section = styled.div`
  padding: calc(var(--font-size) * 0.9);
  background-color: ${({ theme }) =>
    theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)'};
  border-radius: 20px;
  margin: 10px 0;
  color: ${({ theme }) => theme.palette.text?.primary};
`

//endregion
