import { rem } from '../../../../helpers/style'
import { getSneakPeeks } from '../../../../store/selectors'
import React, { useEffect, useState } from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

type Props = {
  chatId: string
}
const ChatBodySneakpeek: React.FC<Props> = ({ chatId }) => {
  const [sneakpeek, setSneakpeek] = useState<string | undefined>(undefined)
  const sneakpeeks = useSelector(getSneakPeeks)

  useEffect(() => {
    setSneakpeek(sneakpeeks.get(chatId))
  }, [chatId, sneakpeeks])

  return sneakpeek ? (
    <Container>
      <ScrollBar>{sneakpeek}...</ScrollBar>
    </Container>
  ) : null
}

export default ChatBodySneakpeek

const Container = styled.div`
  display: grid;
  place-items: start;
  padding: ${rem(0.7)};
  font-size: ${rem(0.7)};
  border-radius: 15px;
  backdrop-filter: blur(20px) saturate(125%);
  position: absolute;
  bottom: 0;
  left: 10px;
  background: ${({ theme }) => theme.palette.background.default};
  opacity: 0.7;
  max-height: calc(var(--height) * 0.3);
  min-height: 0;
`
