import { Socket } from 'socket.io-client'
import log from '../../helpers/logger'
import * as core from './core'
import * as messenger from './messenger'

export type SocketName = 'core' | 'messenger'

export interface Sockets {
  core?: Socket
  messenger?: Socket
}

export const socketsUtils = {
  core,
  messenger
}

export const removeAllListeners = (socket: Socket, events: string[]) => {
  log.warn('Removing event listeners')
  events.forEach((e) => {
    const listeners = socket.listeners(e)
    listeners.forEach((l) => socket.off(e, l))
  })
}

export const removeListeners = (socket: Socket, event: string) => {
  log.silly('Removing listeners on event', event)
  const listeners = socket.listeners(event)
  listeners.forEach((l) => socket.off(event, l))
}
