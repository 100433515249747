import {Phone} from '../../api'
import {Pause, Statuses} from '../../api/rest'
import Counter from './Counter'
import {StatusCircle} from './StatusCircle'
import isElectron from '../../helpers/isElectron'
import {rem} from '../../helpers/style'
import {ArrowBackIosRounded, ArrowForwardIosRounded} from '@mui/icons-material'
import {Box, CircularProgress, Menu, MenuItem, Slide, Typography} from '@mui/material'
import {withStyles} from '@mui/styles'
import {
  getDimensions,
  getPauses,
  getReconnecting,
  getSelectedLanguage,
  getStatus,
  getTranslation
} from '../../store/selectors'
import React, {JSX, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import {useSelector} from 'react-redux'
import styled from 'styled-components'
import logger from '../../helpers/logger'

type StructureItem = {
  key: string
  id: number
  name: string
  label: string
  isStatus: boolean
  color: string
  isGroup: boolean
  subStructure?: StructureItem[]
}
type Props = {
  style?: React.CSSProperties
  anchorParent?: any
  withScrollBar?: boolean
}

const StatusWithCounter: React.FC<Props> = ({style = {}, anchorParent = null, withScrollBar = false}) => {
  const anchor = useRef<HTMLDivElement>(null)

  const [openMenu, setOpenMenu] = useState(false)
  const [statusLabel, setStatusLabel] = useState('')

  const {fontSize: globalFontSize} = useSelector(getDimensions)
  const language = useSelector(getSelectedLanguage)
  const translation = useSelector(getTranslation)
  const pauses = useSelector(getPauses)
  const status = useSelector(getStatus)
  const reconnecting = useSelector(getReconnecting)

  const containerRef = useRef(null)

  /*region Struttura*/

  const [structure, setStructure] = useState<StructureItem[]>([])
  const [expandedGroup, setExpandedGroup] = useState<StructureItem | null>(null)

  /**
   * Crea un elemento della struttura dipendentemente dal fatto che si tratti di un gruppo o di una pausa
   * @param p {Pause} pausa corrente
   */
  const buildStructureFromPause = useCallback(
    (p: Pause): StructureItem => {
      if (p.pauseGroup === null)
        // La key non contiene solo l'id per evitare qualsiasi collisione
        return {
          key: `pause-${p.id}`,
          id: p.id,
          name: p.name,
          isStatus: p.isStatus,
          isGroup: false,
          label: p.translations ? p.translations[language] : p.label || p.name,
          color: p.color
        }
      else
        return {
          key: `pauseGroup-${p.pauseGroup.id}`,
          id: p.pauseGroup.id,
          name: p.pauseGroup.name,
          isStatus: p.isStatus,
          isGroup: true,
          label: p.translations ? p.translations[language] : p.label,
          color: ''
        }
    },
    [language]
  )
  /**
   * Ordina in modo che i valori non espandibili vengano messi in alto
   * @param a {StructureItem}
   * @param b {StructureItem}
   */
  const sortStructure = useCallback((a: StructureItem, b: StructureItem) => {
    if (a.isGroup && !b.isGroup) return 1
    if (b.isGroup && !a.isGroup) return -1
    return a.label.localeCompare(b.label)
  }, [])

  useEffect(() => {
    // Se ancora non sono arrivate le pauses non posso fare nulla
    if (!pauses.length) return
    // Costruisco la struttura delle pause per rendere facile l'iterazione nel render
    const firstLevel: StructureItem[] = pauses
      .filter((p) => !p.busyOther)
      .map(buildStructureFromPause)
      .sort(sortStructure)

    // Aggiunge il secondo livello alla struttura mettendo le pause dentro ai gruppi di competenza
    firstLevel.forEach((si) => {
      // Interessano soltanto i gruppi
      if (!si.key.includes('pauseGroup')) return
      // Prende le pause che appartengono al gruppo in esame a questa iterazione e le aggiunge tutte
      si.subStructure = pauses
        .filter((p) => p.pauseGroup?.id === si.id)
        .map((p) => ({
          key: `pause-${p.id}`,
          id: p.id,
          name: p.name,
          isStatus: p.isStatus,
          isGroup: false,
          label: p.name,
          color: p.color
        }))
    })

    const IDLE_STATUS: StructureItem = {
      id: 0,
      label: translation.statuses.IDLE,
      name: 'IDLE',
      color: '',
      key: 'status-0',
      isStatus: true,
      isGroup: false
    }

    // Al primo posto mettiamo sempre IDLE se lo stato corrente è IDLE, altrimenti
    // viene inserito in testa prima lo stato corrente, poi idle, poi la struttura senza la pausa corrente
    const withCurrentStatus = [{...IDLE_STATUS}, ...firstLevel]

    setStructure(withCurrentStatus)
  }, [pauses, status, translation.statuses, buildStructureFromPause, sortStructure])

  useEffect(() => {
    if (!translation.statuses && !status.translations) return
    setStatusLabel(status.translations ? status.translations[language] : translation.statuses[status.name])
  }, [status, translation.statuses, language])
  /*endregion*/

  const withScroll = (menuList: JSX.Element | JSX.Element[]) => {
    if (withScrollBar) return <ScrollBar>{menuList}</ScrollBar>
    else return menuList
  }

  const changeStatus = (pauseId?: number) => {
    setExpandedGroup(null)
    setOpenMenu(false)
    Phone.requestPauseChange(pauseId).catch((e) => logger.error(e))
  }

  const disconnected = useMemo(() => status.id === 4 || reconnecting, [status.id, reconnecting])

  const StyledMenuItem = withStyles(() => ({
    root: {
      fontSize: rem(0.7, globalFontSize)
    }
  }))(MenuItem)

  return (
    <>
      <Container
        style={style}
        disconnected={disconnected}
        onClick={() => {
          if (disconnected) return
          setOpenMenu(true)
        }}
      >
        <Label ref={anchor}>{reconnecting ? translation.statuses.DISCONNECTED : statusLabel}</Label>
        {reconnecting ? (
          <Box display="flex" gap={1}>
            {reconnecting && <CircularProgress size={10} sx={{placeSelf: 'center'}} color="inherit"/>}
            <Typography sx={{fontStyle: 'italic', fontSize: rem(0.7, globalFontSize)}}>
              {translation.reconnecting}
            </Typography>
          </Box>
        ) : (
          <Counter font={0.6} from={status.lastchange}/>
        )}
      </Container>
      {(status.id === Statuses.idle || !status.isStatus) && (!reconnecting) && (
        <Menu
          ref={containerRef}
          slotProps={{
            paper:
              anchorParent && isElectron()
                ? {
                  style: {
                    width: '100%',
                    height: '100%',
                    minHeight: '75%'
                  }
                }
                : undefined
          }}
          open={openMenu}
          anchorOrigin={anchorParent ? {vertical: 'top', horizontal: 'center'} : undefined}
          transformOrigin={anchorParent ? {vertical: 'top', horizontal: 'center'} : undefined}
          anchorEl={anchorParent ? anchorParent.current : anchor.current}
          onClose={() => {
            setOpenMenu(false)
            setExpandedGroup(null)
          }}
          keepMounted={true}
        >
          {withScroll(
            !expandedGroup
              ? structure.map((s) => (
                <Slide direction={'right'} key={s.key} container={containerRef.current} in={expandedGroup === null}>
                  <StyledMenuItem
                    key={s.key}
                    style={{display: 'grid', gridTemplateColumns: '15px auto 1fr'}}
                    onClick={() => {
                      if (s.isGroup) {
                        setOpenMenu(false)
                        setExpandedGroup(s)
                        setOpenMenu(true)
                      } else changeStatus(s.id)
                    }}
                  >
                    {!s.isGroup ? (
                      <StatusCircle
                        key={s.key}
                        size="10px"
                        style={{marginRight: 5}}
                        strokeWidth="0"
                        status={{id: s.id, name: s.name, isStatus: s.isStatus, color: s.color}}
                        onClick={() => setOpenMenu(!openMenu)}
                      />
                    ) : (
                      <div/>
                    )}
                    <div style={{textTransform: 'capitalize'}}>{s.label}</div>
                    {s.isGroup && <ArrowForwardIosRounded style={{fontSize: rem(0.5), placeSelf: 'center end'}}/>}
                  </StyledMenuItem>
                </Slide>
              ))
              : [
                <Slide direction={'left'} key={'goBack'} container={containerRef.current} in={!!expandedGroup}>
                  <StyledMenuItem
                    key={'goBack'}
                    style={{display: 'grid', gridTemplateColumns: '15px auto'}}
                    onClick={() => {
                      setExpandedGroup(null)
                    }}
                  >
                    <ArrowBackIosRounded style={{fontSize: rem(0.5), placeSelf: 'center start'}}/>
                    <div style={{textTransform: 'capitalize', fontStyle: 'italic'}}>{translation.goBack}</div>
                  </StyledMenuItem>
                </Slide>,
                ...(expandedGroup?.subStructure?.map((sub) => (
                  <Slide direction={'left'} key={sub.name} container={containerRef.current} in={!!expandedGroup}>
                    <StyledMenuItem
                      key={sub.name}
                      style={{display: 'grid', gridTemplateColumns: '15px auto'}}
                      onClick={() => {
                        changeStatus(sub.id)
                      }}
                    >
                      <StatusCircle
                        size="10px"
                        style={{marginRight: 5}}
                        strokeWidth="0"
                        status={{id: sub.id, name: sub.name, isStatus: sub.isStatus, color: sub.color}}
                        onClick={() => setOpenMenu(!openMenu)}
                      />
                      <div style={{textTransform: 'capitalize'}}>{sub.label}</div>
                    </StyledMenuItem>
                  </Slide>
                )) || [])
              ]
          )}
        </Menu>
      )}
    </>
  )
}

export default StatusWithCounter

const Container = styled.div<{ disconnected: boolean }>`
  display: grid;
  grid-template-rows: 1fr 1fr;
  cursor: ${({disconnected}) => (disconnected ? 'auto' : 'pointer')};
`

const Label = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  font-size: ${rem(0.7)};
  align-self: end;
`
