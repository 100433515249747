import { Reducer } from 'redux'
import * as t from './types'

export const initialState: t.ThemeState = (sessionStorage.getItem('@occlient/theme') as t.ThemeState) || 'light'

type ThemeReducer = Reducer<t.ThemeState, t.ThemeActionTypes>

const themeReducer: ThemeReducer = (state = initialState, action: t.ThemeActionTypes): t.ThemeState => {
  switch (action.type) {
    case t.SET_THEME:
      return action.payload
    default:
      return state
  }
}

export default themeReducer
