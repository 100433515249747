import { FormControl, ListSubheader, MenuItem, Select } from '@mui/material'
import { getPauses, getSelectedLanguage, getStatus, getTranslation } from '../../store/selectors'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Pause, PauseGroup, Statuses } from '../../api/rest'
import log from '../../helpers/logger'
import useRest from '../../hooks/useRest'
import { StatusCircle } from './index'

type PauseGroupsArrayEl = PauseGroup & { pauses: Pause[] }
type PauseGroupsStructure = {
  [key: number]: PauseGroupsArrayEl
}

type Props = {
  style?: React.CSSProperties
}
const StateSelect: React.FC<Props> = ({ style = {} }) => {
  const [pauseGroups, setPauseGroups] = useState<PauseGroupsArrayEl[]>([])

  const { put, remove } = useRest('users', { lazy: true })

  const status = useSelector(getStatus)
  const language = useSelector(getSelectedLanguage)
  const translation = useSelector(getTranslation)
  const pauses = useSelector(getPauses)

  useEffect(() => {
    if (!pauses.length) return
    pauses.sort((a, b) => {
      if (a.pauseGroup === null && b.pauseGroup !== null) return -1
      if (a.pauseGroup !== null && b.pauseGroup === null) return 1
      if (a.pauseGroup !== null && b.pauseGroup !== null) {
        if (a.pauseGroup.translations && b.pauseGroup.translations && a.translations && b.translations) {
          return (
            a.pauseGroup.translations[language].localeCompare(b.pauseGroup.translations[language]) ||
            a.translations[language].localeCompare(b.translations[language])
          )
        } else {
          return a.pauseGroup.name.localeCompare(b.pauseGroup.name) || a.name.localeCompare(b.name)
        }
      }
      return a.translations && b.translations
        ? a.translations[language].localeCompare(b.translations[language])
        : a.name.localeCompare(b.name)
    })
    const newPauseGroups: PauseGroupsStructure = {}
    pauses
      .filter((p) => !p.busyOther)
      .forEach((p) => {
        const accessId = p.pauseGroup ? p.pauseGroup.id : 0
        if (newPauseGroups[accessId]) {
          newPauseGroups[accessId].pauses.push(p)
        } else {
          newPauseGroups[accessId] = { id: accessId, name: p.pauseGroup ? p.pauseGroup.name : '', pauses: [p] }
        }
      })
    const arrStructure = [...Object.values(newPauseGroups)]
    setPauseGroups(arrStructure)
  }, [pauses, language])

  const changeStatus = (pauseId?: number) => {
    if (pauseId === undefined) return
    if (pauseId === Statuses.idle) {
      remove(null, { afterPath: '/pause' }).catch((e) => {
        log.error('REMOVE STATUS RES')
        log.error(e)
      })
    } else {
      put(null, {}, { afterPath: `/pause/${pauseId}` }).catch((e) => {
        log.error('PUT STATUS ERROR')
        log.error(e)
      })
    }
  }

  return (
    <FormControl size="small" style={{ width: '100%', ...style }}>
      <Select
        value={status.id}
        disabled={status.isStatus && status.id !== Statuses.idle}
        onChange={(event) => changeStatus(event.target.value as number)}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 60
            }
          }
        }}
      >
        {status.isStatus && status.id !== Statuses.idle && (
          <MenuItem value={status.id}>
            <StatusCircle status={status} size="10px" style={{ marginRight: 10 }} />
            {status.translations ? status.translations[language] : translation.statuses.NOT_FOUND}
          </MenuItem>
        )}
        <MenuItem value={Statuses.idle}>
          <StatusCircle
            status={{ id: Statuses.idle, name: 'IDLE', isStatus: true, lastchange: '' }}
            size="10px"
            style={{ marginRight: 10 }}
          />
          {translation.statuses.IDLE}
        </MenuItem>
        {pauseGroups.map((pg) => {
          if (pg.id === 0) {
            return pg.pauses.map((p) => (
              <MenuItem key={`pause-${p.id}`} value={p.id}>
                <StatusCircle status={p} size="10px" style={{ marginRight: 10 }} />
                {p.translations ? p.translations[language] : p.name}
              </MenuItem>
            ))
          } else {
            return [
              <ListSubheader key={pg.id} style={{ fontWeight: 500 }}>
                {pg.translations ? pg.translations[language] : pg.name}
              </ListSubheader>,
              pg.pauses.map((p) => (
                <MenuItem key={`pause-${p.id}`} value={p.id}>
                  <StatusCircle status={p} size="10px" style={{ marginRight: 10 }} />
                  {p.translations ? p.translations[language] : p.name}
                </MenuItem>
              ))
            ]
          }
        })}
      </Select>
    </FormControl>
  )
}

export default StateSelect
