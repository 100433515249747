import { Phone } from '../../api'
import { SpyType } from '../phone'
import { Outbound, User } from '../rest'
import { answer, hangup, requestCall, spy } from '../../store/actions'
import { getOutbounds } from '../../store/selectors'
import { RootState } from '../../store/types'
import { ThunkDispatch } from 'redux-thunk'
import logger from '../../helpers/logger'

/**
 * Classe di utility per la gestione da cli delle funzioni telefoniche
 */
export class PhoneUtils {
  private _dispatch: ThunkDispatch<any, any, any> | Function
  private _getState: () => RootState

  /**
   * A richiamarlo ci penso solo OCClient una sola volta
   * @param dispatch
   * @param getState
   * @internal
   */
  constructor(dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) {
    this._dispatch = dispatch
    this._getState = getState
  }

  //region Getter e setter
  /** @internal */
  set dispatch(value: ThunkDispatch<any, any, any> | Function) {
    this._dispatch = value
  }
  /** @internal */
  get state(): RootState {
    return this._getState()
  }
  /** @internal */
  set getState(value: () => RootState) {
    this._getState = value
  }
  //endregion

  /**
   * avvio di una chiamata outbound
   * @param data Oggetto contenente le informazioni per la composizione della chiamata
   * @param data.number numero da comporre
   * @param data.outbound ID del servizio di outbound da utilizzare per la chiamata
   * @param data.info oggetto contenente informazioni aggiuntive da allegare alla chiamata.
   *                   Tali informazioni saranno incluse nello storico
   * @param data.user Oggetto contenente informazioni sull’utente chiamato. Deve avere i campi “name” e “surname”
   */
  public call(data: { number: string; outbound: string; info: any; user: User }) {
    this._dispatch(requestCall(data))
  }

  /** ritorna i servizi di outbound disponibili per l'attuale client */
  public outbounds(): Outbound[] {
    return getOutbounds(this.state)
  }

  /** richiede uno stato di pausa */
  public pause(pauseId: number) {
    return Phone.requestPauseChange(pauseId)
  }

  /** richiede di uscire dallo stato di pausa */
  public unpause() {
    return Phone.requestPauseChange()
  }

  /** risponde alla chiamata in arrivo */
  public answer() {
    this._dispatch(answer()).catch((e: Error) => logger.error(e))
  }

  /** termina la chiamata in corso */
  public hang() {
    this._dispatch(hangup()).catch((e: Error) => logger.error(e))
  }

  /** fa partire lo spy del tipo selezionato */
  public spy(opts: { extension: number; pbxId: number; type: SpyType }) {
    this._dispatch(spy(opts)).catch((e: Error) => logger.error(e))
  }
}
