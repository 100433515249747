import { CustomerChat } from '../../../../api'
import { Chat, DeviceTypes, User } from '../../../../api/rest'
import { Avatar } from '../../../common'
import ChatBodyMediaInfo from '../../../pages/CustomerChat/conversation/ChatBodyMediaInfo'
import { rem } from '../../../../helpers/style'
import {
  AddRounded,
  ArrowBackRounded,
  CloseRounded,
  DoneRounded,
  InfoRounded,
  PhoneRounded,
  ScreenShareRounded,
  SwapHorizRounded,
  VideocamRounded
} from '@mui/icons-material'
import { AppBar, Badge, IconButton, SpeedDial, SpeedDialAction, SpeedDialIcon, Toolbar, Tooltip } from '@mui/material'
import { changeRoute } from '../../../../store/applicationState/actions'
import {
  closeMediaChat,
  sendMediaChatRequest,
  transferToOperatorAccept,
  transferToOperatorCancel,
  transferToOperatorReject
} from '../../../../store/customerChats/actions'
import {getMe, getMediaChat, getTranslation, getUnreadEvents, getUsers} from '../../../../store/selectors'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import devicesIcons from '../../../pages/CustomerChat/common/devicesIcons'

type Props = {
  chat: Chat
  openUserInfoModal: Function
  openCloseDialog: Function
  openTransferModal: Function
}

const ChatBodyTopBar: React.FC<Props> = ({ chat, openUserInfoModal, openCloseDialog, openTransferModal }) => {
  const [open, setOpen] = useState(false)
  const [transferringTo, setTrasferringTo] = useState<User | null>(null)

  const unreadMessages = useSelector(getUnreadEvents)
  const users = useSelector(getUsers)
  const me = useSelector(getMe)
  const mediachat = useSelector(getMediaChat)

  const dispatch = useDispatch()
  const translation = useSelector(getTranslation)

  useEffect(() => {
    if (chat.transferer) {
      const user = users.get(chat.transferer)
      if (!user) return
      setTrasferringTo(user)
    } else {
      setTrasferringTo(null)
    }
  }, [chat, users])

  const goBack = () => {
    dispatch(changeRoute({ current: '/customer-chat' }))
  }

  const TransferContextualButton: React.FC = () => {
    if (mediachat) {
      return (
        <Tooltip title={'Termina'}>
          <IconButton
            size="small"
            onClick={() => {
              CustomerChat.stopMedia(chat.id)
              dispatch(closeMediaChat())
            }}
          >
            <CloseRounded />
          </IconButton>
        </Tooltip>
      )
    }

    if (!chat.transferer || !chat.transferee) return null

    // sei quello che sta trasferendo
    if (chat.transferer === me.id) {
      return (
        <Tooltip title={'Annulla trasferimento'}>
          <IconButton
            size="small"
            onClick={() => {
              CustomerChat.cancelTransfer(chat.id, (response) => {
                if (!response) {
                  // TODO: handle error
                  return
                }
                dispatch(transferToOperatorCancel(chat.id))
              })
            }}
          >
            <CloseRounded />
          </IconButton>
        </Tooltip>
      )
    } else {
      // sei quello che ha ricevuto il trasferimento
      return (
        <ButtonsContainer>
          <Tooltip title={'Rifiuta trasferimento'}>
            <IconButton
              size="small"
              onClick={() => {
                CustomerChat.transferReject(chat.id)
                dispatch(transferToOperatorReject(chat.id))
              }}
            >
              <CloseRounded />
            </IconButton>
          </Tooltip>
          <Tooltip title={'Accetta trasferimento'}>
            <IconButton
              size="small"
              onClick={() => {
                CustomerChat.transferAccept(chat.id, (response) => {
                  if (!response) {
                    // TODO: handle error
                    return
                  }
                  dispatch(transferToOperatorAccept(chat.id))
                })
              }}
            >
              <DoneRounded />
            </IconButton>
          </Tooltip>
        </ButtonsContainer>
      )
    }
  }

  const allActions = useMemo(() => [
    {
      icon: <InfoRounded />,
      tooltipTitle: translation?.tooltips?.info,
      onClick: () => openUserInfoModal(),
      alwaysShow: true
    },
    {
      icon: <SwapHorizRounded />,
      tooltipTitle: translation?.tooltips?.transfer,
      onClick: () => openTransferModal(),
      alwaysShow: true
    },
    {
      icon: <PhoneRounded />,
      tooltipTitle: translation?.tooltips?.call,
      onClick: () => dispatch(sendMediaChatRequest({ type: 'audio', chatId: chat.id })),
      alwaysShow: false
    },
    {
      icon: <VideocamRounded />,
      tooltipTitle: translation?.tooltips?.videocall,
      onClick: () => dispatch(sendMediaChatRequest({ type: 'video', chatId: chat.id })),
      alwaysShow: false
    },
    {
      icon: <ScreenShareRounded />,
      tooltipTitle: translation?.tooltips?.shareScreen,
      onClick: () => dispatch(sendMediaChatRequest({ type: 'cobrowse', chatId: chat.id })),
      alwaysShow: false
    },
    {
      icon: <CloseRounded />,
      tooltipTitle: translation?.tooltips?.close,
      onClick: () => openCloseDialog(),
      alwaysShow: true
    }
  ], [translation, openUserInfoModal, openTransferModal, openCloseDialog, dispatch, chat.id])

  const visibleActions  = useMemo(() => {
    if (!chat) return []
    const isSocialDevice = chat?.guest?.info?.device ? ['FacebookInstance', 'TelegramInstance', 'WhatsappInstance'].includes(chat.guest.info.device) : false
    return allActions.filter(action => action.alwaysShow || !isSocialDevice)
  }, [chat, allActions])

  return (
    <AppBar
      position="sticky"
      color="inherit"
      style={{
        height: 'fit-content',
        minWidth: 0,
        minHeight: 0,
        boxSizing: 'border-box',
        boxShadow:
          '2px 2px 4px -1px rgb(0 0 0 / 20%), 3px 4px 5px 0px rgb(0 0 0 / 14%), 7px 1px 10px 0px rgb(0 0 0 / 12%)'
      }}
    >
      <Toolbar
        style={{
          display: 'flex',
          gap: rem(0.5),
          minWidth: 'unset',
          minHeight: 'unset',
          width: '100%',
          fontSize: rem(0.7),
          padding: '8px'
        }}
      >
        <IconButton edge="start" color="inherit" onClick={goBack} size="small">
          <Badge
            badgeContent={unreadMessages}
            color="secondary"
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            variant="dot"
          >
            <ArrowBackRounded />
          </Badge>
        </IconButton>
        <Avatar size="30px" fontSize="14px" letterSpacing="-2px" fullname={CustomerChat.getGuestFullname(chat.guest)} >
          {devicesIcons[(chat.guest.info.device as DeviceTypes)]}
        </Avatar>
        {mediachat && (
          <Info>
            <div>{CustomerChat.getGuestFullname(chat.guest)}</div>
            <ChatBodyMediaInfo mediachat={mediachat} />
          </Info>
        )}
        {transferringTo && (
          <Info>
            <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
              {CustomerChat.getGuestFullname(chat.guest)}
            </div>
            <TransferInfo>
              Trasferendo a {transferringTo.name} {transferringTo.surname}
            </TransferInfo>
          </Info>
        )}
        {!transferringTo && !mediachat && (
          <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', flexGrow: 1 }}>
            {CustomerChat.getGuestFullname(chat.guest)}
          </div>
        )}
        {chat.transferer || chat.transferee || mediachat ? (
          <TransferContextualButton />
        ) : (
          <SpeedDialWrapper>
            <SpeedDial
              ariaLabel="SpeedDial example"
              icon={
                <SpeedDialIcon
                  icon={<AddRounded htmlColor="#ffffff" />}
                  openIcon={<CloseRounded htmlColor="#ffffff" />}
                />
              }
              onClose={() => setOpen(false)}
              onOpen={() => setOpen(true)}
              open={open}
              style={{ position: 'absolute', top: 'calc(50% - 12.5px)', left: 'calc(50% - 12.5px)' }}
              FabProps={{
                size: 'small',
                style: { width: 25, height: 25, boxShadow: 'none' }
              }}
              direction="down"
            >
              {visibleActions.map((action, index) => (
                <SpeedDialAction
                  key={index}
                  icon={action.icon}
                  tooltipTitle={action.tooltipTitle}
                  FabProps={{
                    size: 'small',
                    style: { width: 30, height: 30, margin: '4px 0' },
                    disabled: chat.closed && !action.alwaysShow
                  }}
                  onClick={action.onClick}
                />
              ))}
            </SpeedDial>
          </SpeedDialWrapper>
        )}
      </Toolbar>
    </AppBar>
  )
}

export default ChatBodyTopBar

//region Style

const Info = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  flex-grow: 1;
`

const TransferInfo = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const SpeedDialWrapper = styled.div`
  position: relative;
  height: 40px;
  width: 40px;
  transform: translateZ(0px);
`

const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`
//endregion
