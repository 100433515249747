import { OutboundCallInfo } from '../../../../../api/rest'
import { rem } from '../../../../../helpers/style'
import { getCallInProgress, getTranslation } from '../../../../../store/selectors'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

const CallInfoOutbound: React.FC = () => {
  const call = useSelector(getCallInProgress)
  const translation = useSelector(getTranslation)
  const getRightInfoToShow = () => {
    if (!call) return null
    const infoToShow = window.occlient.settings.outboundDisplayInfo
    return Object.entries(infoToShow).map(([key, style]) => {
      const k = key as keyof OutboundCallInfo
      if (!call[k]) return null
      else
        return (
          <Info key={key} style={style}>
            <td className="occlient-outbound-call-info-title">{translation.outboundCallInfo[key]}:</td>
            <td className="occlient-outbound-call-info">{call[k]}</td>
          </Info>
        )
    })
  }

  return (
    <Container>
      <tbody>{getRightInfoToShow()}</tbody>
    </Container>
  )
}

export default CallInfoOutbound

//region Style

const Container = styled.table`
  padding: 10px 0;
  text-align: left;
`

const Info = styled.tr`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: ${rem(0.7)};

  .occlient-inbound-call-info-title {
    text-transform: uppercase;
    font-weight: bold;
  }
`

//endregion
