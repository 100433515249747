import { AxiosRequestConfig, ResponseType } from 'axios'
import { AnyObject } from '../types'

export const makeHeader = (responseType?: ResponseType, params?: AnyObject) => {
  const token = sessionStorage.getItem('@occlient/token')
  const header: AxiosRequestConfig = {
    headers: { Authorization: 'Bearer ' + token }
  }
  if (responseType) {
    header['responseType'] = responseType
  }
  if (params) {
    header.params = params
  }
  return header
}
