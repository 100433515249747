import { IconButton, Tooltip } from '@mui/material'
import { getPath } from '../../store/selectors'
import React, { PropsWithChildren } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeRoute } from '../../store/actions'

type MenuButtonProps = {
  path: string
  label?: string
  disabled?: boolean
  exact?: boolean
  from?: string
  beforeClick?: Function
  size?: 'small' | 'medium'
  state?: { [key: string]: any }
  tooltip?: string
}
export const MenuButton: React.FC<PropsWithChildren<MenuButtonProps>> = (props) => {
  const { path, children, exact = false, disabled = false, from, size = 'medium', state, tooltip } = props

  const { current } = useSelector(getPath)
  const dispatch = useDispatch()

  const isSelected = (): boolean => {
    if (path === current) return true
    return !exact && current.includes(path)
  }

  const onClick = () => {
    props.beforeClick && props.beforeClick()
    dispatch(changeRoute({ current: path, previous: from || current, state }))
  }

  return disabled ? (
    <IconButton color={isSelected() ? 'primary' : 'default'} disabled={disabled} onClick={onClick} size={size}>
      {children}
    </IconButton>
  ) : (
    <Tooltip title={tooltip || ''} disableInteractive>
      <IconButton color={isSelected() ? 'primary' : 'default'} disabled={disabled} onClick={onClick} size={size}>
        {children}
      </IconButton>
    </Tooltip>
  )
}
