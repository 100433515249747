import { rem } from '../../helpers/style'
import { changeRoute } from '../../store/applicationState/actions'
import { getPath } from '../../store/selectors'
import React, { PropsWithChildren } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Contact, User } from '../../api/rest'
import { Avatar } from './Avatar'
import { AvatarWithStatus } from './AvatarWithStatus'

type AddressProps = {
  name?: string
  surname?: string
  id?: number
  type: 'user' | 'contact'
  user?: User
  contact?: Contact
  number?: string
}

//TODO:aprire la modale invece che showAddress
export const Address: React.FC<PropsWithChildren<AddressProps>> = (props) => {
  const { children, name, surname, id, type, user, contact, number } = props

  const { current } = useSelector(getPath)

  const dispatch = useDispatch()

  //region info contatto
  const getCompleteName = (): string => {
    if (user) return `${user?.surname} ${user?.name}`
    if (number) return number
    if (contact) return `${contact.secondName} ${contact.firstName}`
    return `${surname} ${name}`
  }
  const getName = (): string => {
    if (name) return name
    if (contact) return contact.firstName
    return 'Unknown'
  }
  const getSurname = (): string => {
    if (surname) return surname
    if (contact) return contact.secondName
    return 'Unknown'
  }
  //endregion

  return (
    <Container>
      <InfoContainer>
        {type === 'user' ? (
          <AvatarWithStatus
            onClick={() => {
              if (id) {
                dispatch(changeRoute({ current: `/phone/address-book/int/${id}`, previous: current }) as any)
                return
              }
              if (user && user.id) {
                dispatch(changeRoute({ current: `/phone/address-book/int/${user.id}`, previous: current }) as any)
              }
            }}
            avatarSize="28px"
            statusSize="12px"
            fontSize="max(min(calc(var(--font-size) * 0.5), 20px), 14px)"
            letterSpacing="max(calc(var(--topbar-height) / -20),-2px)"
            user={user}
          />
        ) : (
          <Avatar name={getName()} surname={getSurname()} size="28px" fontSize="12px" letterSpacing="-2px" />
        )}
        <Info>{getCompleteName()}</Info>
        {children}
      </InfoContainer>
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  width: 100%;
  justify-self: center;
  padding: 4px 10px;
  height: fit-content;
  &:hover {
    background: ${({ theme }) => theme.palette.action.hover};
  }
`

const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr auto;
  grid-column-gap: ${rem(0.4)};
`

const Info = styled.div`
  align-self: center;
  justify-self: start;
  font-size: max(${rem(0.6)}, 12px);
  margin-left: 5px;
`
