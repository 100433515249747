import CustomerChat from '../../../../api/customerChat'
import {Chat, ChatEvent} from '../../../../api/rest'
import ChatBodyMedia from '../../../pages/CustomerChat/conversation/ChatBodyMedia'
import ChatBodyMessages from '../../../pages/CustomerChat/conversation/ChatBodyMessages'
import ChatBodyTopBar from '../../../pages/CustomerChat/conversation/ChatBodyTopBar'
import {rem} from '../../../../helpers/style'
import {SendRounded, SentimentSatisfiedAltRounded} from '@mui/icons-material'
import { ReactComponent as ChatIcon } from '../../../../images/icons/chat_apps_script.svg'
import CloseIcon from '@mui/icons-material/Close'
import {IconButton, Popover, SvgIcon, Tooltip} from '@mui/material'
import {TextareaAutosize} from '@mui/material'
import {useTheme} from '@mui/material/styles'
import {addEvent, readEventsForChat} from '../../../../store/customerChats/actions'
import {getDimensions, getMe, getMediaChat, getTheme, getTranslation, getUnreadEventsByChat} from '../../../../store/selectors'
import Picker, {EmojiStyle, Theme} from 'emoji-picker-react'
import {DateTime} from 'luxon'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import styled, {css} from 'styled-components'

type Props = {
  openUserInfoModal: Function
  openCloseDialog: Function
  openTransferModal: Function
  openCustomizedMessagesModal: Function
  chat: Chat
}
const ChatBody: React.FC<Props> = (props) => {
  const {chat, openUserInfoModal, openCloseDialog, openTransferModal, openCustomizedMessagesModal} = props
  const [showEmoji, setShowEmoji] = useState(false)
  const [message, setMessage] = useState('')
  const [sneakpeekStarted, setSneakpeekStarted] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const unreadEventsByChat = useSelector(getUnreadEventsByChat)
  const me = useSelector(getMe)
  const mediachat = useSelector(getMediaChat)
  const theme = useSelector(getTheme)
  const {height, width} = useSelector(getDimensions)
  const translation = useSelector(getTranslation)

  const dispatch = useDispatch()

  const muiTheme = useTheme()

  useEffect(() => {
    // Rimuovo i messaggi non letti per la chat appena aperta
    const evs = unreadEventsByChat.get(chat.id)
    if (evs) dispatch(readEventsForChat(chat.id) as any)
  }, [chat.id, dispatch, unreadEventsByChat])

  useEffect(() => {
    if (sneakpeekStarted) {
      if (!message) {
        CustomerChat.sneakpeekStop(chat.id)
        setSneakpeekStarted(false)
      }
    } else {
      if (message) {
        CustomerChat.sneakpeekStart(chat.id)
        setSneakpeekStarted(true)
      }
    }
  }, [message, chat.id, sneakpeekStarted])

  const handleTextareaChange = (ev: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (ev.key === 'Enter' && !ev.shiftKey) {
      ev.preventDefault()
      sendMessage()
    }
  }

  const sendMessage = () => {
    if (!message || !chat) return
    const now = DateTime.now()
    const event: ChatEvent = {
      tempID: now.toMillis(),
      date: now.toSQL(),
      type: 'message',
      user: CustomerChat.getUserForChat(me),
      text: message
    }
    CustomerChat.sendEvent({...event, chat: chat.id})
    dispatch(addEvent({chat: chat.id, event}) as any)
    setMessage('')
  }

  return (
    <Container withEmoji={showEmoji} withMedia={!!mediachat}>
      <ChatBodyTopBar
        chat={chat}
        openUserInfoModal={openUserInfoModal}
        openCloseDialog={openCloseDialog}
        openTransferModal={openTransferModal}
      />
      {mediachat && mediachat.chatId === chat.id && <ChatBodyMedia chat={chat} mediachat={mediachat}/>}
      <ChatBodyMessages chat={chat} noMoreMessages={true} setNoMoreMessages={() => {
      }}/>

      <BottomBar>
        <InputContainer>
          <Tooltip title={translation.predefinedMessages} placement={'top'}>
            <IconButton
              size="small"
              disabled={chat.closed}
              onClick={() => openCustomizedMessagesModal()}
              style={{width: 30, height: 30, placeSelf: 'center'}}
            >
              <SvgIcon component={ChatIcon} inheritViewBox />
            </IconButton>
          </Tooltip>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              sendMessage()
            }}
          >
            <Input
              maxRows={5}
              value={message}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setMessage(e.target.value)}
              onKeyPress={handleTextareaChange}
              autoFocus={true}
              disabled={chat.closed}
            />
          </form>
          <div>
            <Tooltip title={translation.emoji} placement={'top'}>
              <IconButton
                size="small"
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  setShowEmoji(!showEmoji)
                  setAnchorEl(e.currentTarget)
                }}
                style={{width: 30, height: 30, placeSelf: 'center'}}
              >
                {!showEmoji ? <SentimentSatisfiedAltRounded/> : <CloseIcon/>}
              </IconButton>
            </Tooltip>
            <Popover anchorEl={anchorEl} anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                     open={showEmoji} onClose={() => {
              setShowEmoji(false)
              setAnchorEl(null)
            }} transformOrigin={{horizontal: 'right', vertical: 'bottom'}} sx={{overflow: 'hidden', opacity: 0.9}} slotProps={{paper: {sx: {height: height / 2, maxWidth: width-100}}}}>
              <Picker
                onEmojiClick={(data) => setMessage(message + data.emoji)}
                emojiStyle={EmojiStyle.NATIVE}
                width={'100%'}
                height={'99%'}
                theme={theme as Theme}
                previewConfig={{showPreview: false}}
              />
            </Popover>
          </div>
        </InputContainer>
        <Tooltip title={translation.send} placement={'top'}>
          <IconButton
            size="small"
            disabled={!message}
            color="primary"
            onClick={sendMessage}
            style={{background: !message ? muiTheme.palette.action.disabled : muiTheme.palette.primary.main, width: '30px', height: '30px'}}
          >
            <SendRounded htmlColor="#ffffff"/>
          </IconButton>
        </Tooltip>
      </BottomBar>
    </Container>
  )
}

export default ChatBody

//region Style

const Container = styled.div<{ withEmoji: boolean; withMedia: boolean }>`
  overflow: hidden;
  display: grid;
  grid-template-rows: ${({withEmoji, withMedia}) => css`auto ${withMedia && 'auto'} 1fr auto ${withEmoji && 'auto'}`};
  grid-template-columns: 1fr;
  width: 100%;
  height: 100%;
`

const BottomBar = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 10px;
  padding: 10px;
`

const InputContainer = styled.div`
  display: grid;
  grid-template-columns: 30px auto 30px;
  border-radius: 20px;
  background: ${({theme}) => (theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900])};
  padding: 0 10px;
`

const Input = styled(TextareaAutosize)`
  resize: none;
  background: transparent;
  border: none;
  outline: none;
  color: ${({theme}) => theme.palette.text.primary};
  font-family: 'Open Sans', sans-serif;
  font-size: ${rem(0.7)};
  width: 100%;
  height: 100%;
  padding-top: 5px;

  &:focus {
    outline: none;
  }
`

//endregion
