import { Badge, IconButton } from '@mui/material'
import { withStyles } from '@mui/styles'
import { changeRoute } from '../../../store/applicationState/actions'
import React from 'react'
import { useDispatch } from 'react-redux'

const StyledBadge = withStyles(() => ({
  badge: {
    fontSize: 8,
    width: 14,
    height: 14,
    minWidth: 0
  }
}))(Badge)

type Props = {
  count: number
  icon: React.ReactElement
  path: string
}
const Notification: React.FC<Props> = ({ count, icon, path }) => {
  const dispatch = useDispatch()

  return (
    <StyledBadge badgeContent={count} overlap="circular" color="secondary">
      <IconButton
        style={{
          placeSelf: 'center',
          width: 18,
          height: 18
        }}
        onClick={() => {
          dispatch(changeRoute({ current: path }))
        }}
      >
        {icon}
      </IconButton>
    </StyledBadge>
  )
}

export default Notification
