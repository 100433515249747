import { DateTime } from 'luxon'
import React from 'react'
import styled from 'styled-components'
import { rem } from '../../helpers/style'
import useElapsedTime from '../../hooks/useElapsedTime'

type CounterProps = {
  from?: number | string | DateTime | null
  font?: number
}

const Counter: React.FC<CounterProps> = ({ from, font }) => {
  const { elapsedTimeString } = useElapsedTime(from || null)

  return <Container font={font || 1}>{elapsedTimeString}</Container>
}

export default Counter

//region Style

const Container = styled.div<{ font: number }>`
  font-size: ${({ font }) => rem(font)};
`

//endregion
