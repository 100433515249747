import { mini } from '../../constants'
import { getFromLSOrDefault } from '../../helpers/common'
import { Settings } from './types'

export const getDefaultValues = () => {
  const widthFromLs = getFromLSOrDefault('@occlient/appWidth', 280) as number
  const heightFromLs = getFromLSOrDefault('@occlient/appHeight', 525) as number

  const width = widthFromLs === mini.width && heightFromLs === mini.height ? 280 : widthFromLs
  const height = widthFromLs === mini.width && heightFromLs === mini.height ? 525 : heightFromLs

  const defaultSettings = {
    selectedDevices: {},
    notifications: localStorage.getItem('@occlient/notifications') === 'yes',
    ringerVolume: 1,
    audioVolume: 1
  }

  const settings = getFromLSOrDefault('@occlient/settings', defaultSettings) as Settings

  const minimized = Boolean(localStorage.getItem('@occlient/minimized'))

  return { width, height, settings, visible: !minimized}
}
