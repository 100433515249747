import { Department, Statuses, User } from '../api/rest'

export const findUser =
  (search: string) =>
  (user: User): boolean => {
    const s = search.toLowerCase()
    if (user.name.toLowerCase().includes(s)) return true
    if (user.surname.toLowerCase().includes(s)) return true
    return user.username.toLowerCase().includes(s)
  }

export const isIdle = (user: User) => {
  return user.state?.id === Statuses.idle
}

export const findDepartment =
  (search: string) =>
  (department: Department): boolean => {
    const s = search.toLowerCase()
    return department.name.toLowerCase().includes(s)
  }

export const sortUsers = (userA: User, userB: User) => {
  if (userA.surname.localeCompare(userB.surname) !== 0) return userA.surname.localeCompare(userB.surname)
  else return userA.name.localeCompare(userB.name) || 1
}
