import { Device, TurnServer } from '@bytewise/janus'
import { LoginAction } from '../login/types'

export const SET_PATH = 'SET_PATH'
export const SET_DEVICES = 'SET_DEVICES'
export const SET_DIMENSIONS = 'SET_DIMENSIONS'
export const SET_VISIBILITY = 'SET_VISIBILITY'
export const SET_SETTINGS = 'SET_SETTINGS'
export const SET_RENDERED_PAGES = 'SET_RENDERED_PAGES'

export interface ApplicationState {
  path: Path
  devices: Device[]
  dimensions: Dimensions
  visible: boolean
  settings: Settings
  turnServer: TurnServer
  peers: RegExp[]
}

export type Path = {
  previous: string
  current: string
}

export type SelectedDevices = {
  ringer?: Device
  audio?: Device
  webcam?: Device
  microphone?: Device
}

export type Dimensions = {
  width: number
  height: number
  fontSize: number
  renderedPages?: number
}

export type Settings = {
  notifications: boolean
  selectedDevices: SelectedDevices
  ringerVolume: number
  audioVolume: number
}

export interface changeRouteAction {
  type: typeof SET_PATH
  payload: Path
}

export interface SetDevicesAction {
  type: typeof SET_DEVICES
  payload: Device[]
}

export interface SetDimensionsAction {
  type: typeof SET_DIMENSIONS
  payload: Dimensions
}

export interface SetVisibilityAction {
  type: typeof SET_VISIBILITY
  payload: boolean
}

export interface SetSettings {
  type: typeof SET_SETTINGS
  payload: Settings
}

export interface SetRenderedPages {
  type: typeof SET_RENDERED_PAGES
  payload: number
}

export type ApplicationStateActionTypes =
  | changeRouteAction
  | SetDevicesAction
  | SetDimensionsAction
  | SetVisibilityAction
  | SetSettings
  | LoginAction
| SetRenderedPages
