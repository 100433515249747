import logger from './logger'

export const askNotificationPermission = async (): Promise<boolean> => {
  if (!('Notification' in window)) {
    logger.info('This browser does not support notifications.')
    return false
  } else if (Notification.permission === 'denied') {
    logger.info('User denied permission to use Notifications')
    return false
  } else {
    const permission = await Notification.requestPermission()
    return !(permission === 'denied' || permission === 'default')
  }
}
