import { Messenger } from '../../../api'
import { getDateInfo } from '../../../helpers/common'
import { rem } from '../../../helpers/style'
import { GroupAddRounded, PersonAddRounded, VideocamRounded } from '@mui/icons-material'
import { Card, Tooltip } from '@mui/material'
import { changeRoute, setActiveConversation } from '../../../store/actions'
import {
  getConversationsArray,
  getMe,
  getMessengerVideoRoom,
  getPath,
  getTranslation,
  getUnreadMessagesByChat
} from '../../../store/selectors'
import React, { useEffect, useState } from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import { useDispatch, useSelector } from 'react-redux'
import { useMatch } from 'react-router-dom'
import styled from 'styled-components'
import { Conversation, Member, Message } from '../../../api/rest'
import { ActionButton, Avatar, ConversationCard, Modal, SearchBar } from '../../common'
import Counter from '../../common/Counter'
import NewChat from './modals/NewChat'
import NewGroup from './modals/NewGroup'

const ConversationsList: React.FC = () => {
  const [search, setSearch] = useState('')
  const [showNewChat, setShowNewChat] = useState(false)
  const [showNewGroup, setShowNewGroup] = useState(false)
  const [conversationsToShow, setConversationsToShow] = useState<Conversation[]>([])

  const conversations = useSelector(getConversationsArray)
  const me = useSelector(getMe)
  const unreadMessagesByChat = useSelector(getUnreadMessagesByChat)
  const translation = useSelector(getTranslation)
  const videoroom = useSelector(getMessengerVideoRoom)
  const { current } = useSelector(getPath)

  const dispatch = useDispatch()

  const { params } = useMatch('/messenger/:id?')!

  useEffect(() => {
    if (!search) {
      setConversationsToShow([...conversations.filter((conv) => conv.messages?.length !== 0)])
    } else {
      const s = search.toLowerCase()
      const matchFullname = (m: Member) => m.name.toLowerCase().includes(s) || m.surname.toLowerCase().includes(s)
      setConversationsToShow(
        conversations.filter((c) => {
          if (c.name) {
            // si tratta di un gruppo
            return c.name.toLowerCase().includes(s) && c.messages?.length !== 0
          } else {
            return c.members.some(matchFullname) && c.messages?.length !== 0
          }
        })
      )
    }
  }, [conversations, search])

  const getOtherUserFullname = (c: Conversation) => {
    if (!c.members) return 'U U'
    const other = c.members.find((m) => m.username !== me.username)
    if (!other) return 'U U'
    return `${other.surname} ${other.name}`
  }

  const goToConversation = (c: Conversation) => {
    dispatch(setActiveConversation(c.id) as any)
    dispatch(changeRoute({ current: `/messenger/${c.id}`, previous: '/messenger' }) as any)
  }

  const goToVideoCall = () => {
    dispatch(setActiveConversation(null) as any)
    dispatch(changeRoute({ current: `/messenger/video-call`, previous: current }) as any)
  }

  const getMessageTimestamp = (m?: Message) => {
    if (!m) return ''
    return getDateInfo(m.timestamp)
  }

  const shouldRenderMessageAck = (messages?: Message[]): boolean => {
    if (!messages || !messages[messages.length - 1]) return false
    const lastMessage = messages[messages.length - 1]
    if (lastMessage.type === 'system') return false
    return lastMessage.sender.username === me.username
  }

  const getLastMessageUser = (c: Conversation) => {
    const lastMessage = c.messages[c.messages.length - 1]
    if (!lastMessage || lastMessage.type === 'system') return ''
    if (lastMessage.sender.username === me.username) return ''
    return lastMessage.sender.name + ':'
  }

  const getLastMessageBody = (c: Conversation) => {
    if (!c.messages) return ''
    const lastMessage = c.messages[c.messages.length - 1]
    if (!lastMessage) return ''

    return lastMessage.type === 'system'
      ? Messenger.parseSystemMessage(lastMessage.body, translation)
      : lastMessage.body
  }

  return (
    <>
      <Conversations elevation={3}>
        <Top>
          <ActionButton
            onClick={() => {
              setShowNewChat(true)
            }}
          >
            <Tooltip title={translation?.tooltips?.newConversation}>
              <PersonAddRounded />
            </Tooltip>
          </ActionButton>
          <ActionButton
            onClick={() => {
              setShowNewGroup(true)
            }}
          >
            <Tooltip title={translation?.tooltips?.newGroup}>
              <GroupAddRounded />
            </Tooltip>
          </ActionButton>
          <SearchBar
            value={search}
            setValue={setSearch}
            style={{ placeSelf: 'center end', marginRight: 0 }}
            input={{
              onChange: (event) => setSearch(event.target.value),
              style: { height: rem(1.2) }
            }}
          />
        </Top>
        <List>
          {conversationsToShow.length === 0 && <Empty>{translation.conversationsNoEntry}</Empty>}
          {videoroom && (
            <VideoroomCard selected={params.id === 'video-call'} onClick={goToVideoCall}>
              <Avatar size={rem(1.7)} fontSize={rem(0.9)} style={{ gridArea: 'avatar', placeSelf: 'center' }}>
                <VideocamRounded />
              </Avatar>
              <Name>Videochiamata</Name>
              <LastMessage withAck={false} withUser={false}>
                {Messenger.getMembersList(videoroom.members || [], me.username, translation.you)}
              </LastMessage>
              {videoroom.startTime && (
                <Timestamp>
                  <Counter from={videoroom.startTime} font={0.65} />
                </Timestamp>
              )}
            </VideoroomCard>
          )}
          {conversationsToShow.map((c) => (
            <ConversationCard
              key={c.id}
              conversation={c}
              goToConversation={(c) => goToConversation(c as Conversation)}
              paramsId={params.id!}
              badgeContent={unreadMessagesByChat.get(c.id)?.length || 0}
              otherFullname={getOtherUserFullname(c)}
              shouldRenderMessageAck={shouldRenderMessageAck(c.messages)}
              lastMessageUser={getLastMessageUser(c)}
              lastMessageBody={getLastMessageBody(c)}
              timestamp={c.messages ? getMessageTimestamp(c.messages[c.messages.length - 1]) : ''}
            />
          ))}
        </List>
      </Conversations>
      <Modal closeModal={() => setShowNewChat(false)} isVisible={showNewChat} width="75%" height="85%">
        <NewChat closeModal={() => setShowNewChat(false)} />
      </Modal>
      <Modal closeModal={() => setShowNewGroup(false)} isVisible={showNewGroup} width="75v%" height="85%">
        <NewGroup closeModal={() => setShowNewGroup(false)} />
      </Modal>
    </>
  )
}

export default ConversationsList

//region Style

const Conversations = styled(Card)`
  display: grid;
  grid-template-rows: [top] ${rem(3)} [list] auto;
  height: calc(var(--height) - var(--topbar-height));
  width: 100%;
  place-self: center;
  border-radius: 0;
`

const Top = styled.div`
  display: grid;
  grid-row: top;
  grid-template-columns: 1fr 1fr 4fr;
  grid-template-areas: 'icon icon searchbar';
  place-content: center;
  padding: ${rem(0.5)};
`

const List = styled(ScrollBar)`
  display: grid;
  grid-row: list;
  height: 100%;
  width: 100%;
  grid-template-rows: repeat(auto-fill, minmax(50px, 1fr));
  grid-template-columns: 1fr;
  place-content: start center;
`

const VideoroomCard = styled.div<{ selected: boolean }>`
  display: grid;
  width: 100%;
  height: 100%;
  padding: ${rem(0.3)};
  grid-template-areas: 'avatar name timestamp' 'avatar lastmessage timestamp';
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 50px auto 60px;
  grid-column-gap: ${rem(0.5)};
  cursor: pointer;
  place-content: center stretch;
  padding: ${rem(0.5)};
  ${({ selected, theme }) => selected && 'background: ' + theme.palette.action.selected};
`

const Name = styled.div`
  grid-area: name;
  font-size: ${rem(0.7)};
  align-self: end;
  height: 100%;
`

const LastMessage = styled.div<{ withAck: boolean; withUser: boolean }>`
  grid-area: lastmessage;
  display: grid;
  grid-template-columns: ${({ withAck }) => (withAck ? '15px auto' : '1fr')};
  grid-column-gap: ${({ withUser }) => (withUser ? rem(0.5) : rem(0.25))};
  font-size: ${rem(0.65)};
  height: 100%;
  width: 100%;
  place-self: center start;
`

const Timestamp = styled.div`
  grid-area: timestamp;
  place-self: center end;
  font-size: ${rem(0.65)};
  color: ${({ theme }) => theme.palette.text?.secondary};
  font-style: italic;
`

const Empty = styled.div`
  width: 100%;
  font-size: ${rem(1.1)};
  text-align: center;
`
//endregion
