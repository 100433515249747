import { rem } from '../../../helpers/style'
import { Button, TextField } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { loginWithPayload, setLoginError } from '../../../store/login/actions'
import { getDimensions, getLogin, getTheme, getTranslation } from '../../../store/selectors'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import styled from 'styled-components'
import pkg from '../../../../package.json'

import footerImage from '../../../images/back_login.png'
import logoImage from '../../../images/OC_full_logo_colour.png'
import logoImageDark from '../../../images/OC_full_logo_white.png'

const OTP: React.FC = () => {
  const [otp, setOtp] = useState('')
  const { selectedMethod, error } = useSelector(getLogin)
  const theme = useSelector(getTheme)
  const { height } = useSelector(getDimensions)
  const translation = useSelector(getTranslation)

  const dispatch = useDispatch()

  const muiTheme = useTheme()
  const { state } = useLocation()

  const handleSubmit = () => {
    const selectedFromLS = sessionStorage.getItem('@occlient/selectedLoginMethod')
    const selected = selectedMethod || (selectedFromLS ? JSON.parse(selectedFromLS) : null)
    selected && dispatch(loginWithPayload(selected.id, state.token, { otp }))
  }

  return (
    <>
      {!sessionStorage.getItem('@occlient/token') && (
        <Container height={height}>
          <LogoContainer>
            <Logo src={theme === 'light' ? logoImage : logoImageDark} alt={'logo'} />
          </LogoContainer>
          <MethodsContainer
            onSubmit={(e: any) => {
              e.preventDefault()
              handleSubmit()
            }}
          >
            <TextField
              variant="standard"
              label="OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              onFocus={() => error && dispatch(setLoginError(''))}
              style={{
                background: muiTheme.palette.background.paper,
                width: '80%',
                maxWidth: 300,
                placeSelf: 'center'
              }}
            />
            {error && <Error>{translation.loginErrors.otp}</Error>}
            <Button
              type="submit"
              variant="contained"
              style={{
                width: '70%',
                maxWidth: 200,
                placeSelf: 'center',
                borderRadius: 0
              }}
            >
              Login
            </Button>
          </MethodsContainer>
          <Footer>
            <Info>Open Communication - V {pkg.version}</Info>
            <Info>Bytewise S.r.l.</Info>
          </Footer>
        </Container>
      )}
    </>
  )
}

export default OTP

//region Style
const Container = styled.div<{ height: number }>`
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header'
    'loginMethods'
    'footer';
  width: 100%;
  height: 100%;
  background-image: url('${footerImage}');
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: right bottom;
`
const LogoContainer = styled.div`
  grid-area: header;
  place-self: center start;
  width: var(--width);
  text-align: center;
`
const Logo = styled.img`
  place-self: center;
  width: 90%;
  max-width: 500px;
  height: auto;
`
const MethodsContainer = styled.form`
  grid-area: loginMethods;
  display: grid;
  width: 100%;
  max-width: 400px;
  place-self: start center;
  grid-row-gap: ${rem(0.8)};
  padding-top: ${rem(0.5)};
  text-align: center;
`

const Footer = styled.div`
  grid-area: footer;
  place-self: center;
  width: 100%;
  height: fit-content;
  overflow: hidden;
  place-items: center start;
  background: transparent;
  text-align: center;
`

const Info = styled.div`
  place-self: center;
  color: white;
  font-size: ${rem(0.7)};
`

const Error = styled.div`
  font-size: ${rem(0.7)};
  color: ${({ theme }) => theme.palette.error.main};
`
//endregion
