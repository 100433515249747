import { LanguageCode } from '../../api/rest'
import { Reducer } from 'redux'
import * as t from './types'

export const initialState: t.LanguageState = {
  translation: {},
  selected: (sessionStorage.getItem('@occlient/selectedTranslation') || 'it_IT') as LanguageCode
}

type TranslationReducer = Reducer<t.LanguageState, t.SetTranslation>

const language: TranslationReducer = (state = initialState, action: t.TranslationActionTypes): t.LanguageState => {
  switch (action.type) {
    case t.SET_LANGUAGE:
      return {
        selected: action.payload.selected,
        translation: action.payload.translation
      }
    default:
      return state
  }
}

export default language
