import { InputAdornment, TextField } from '@mui/material'
import { InputProps } from '@mui/material/Input'
import { BackspaceRounded as BackSpaceIcon, SearchRounded as SearchIcon } from '@mui/icons-material'
import React from 'react'
import { rem } from '../../helpers/style'

type SearchBarProps = {
  value: string
  style?: React.CSSProperties
  setValue?: (arg0: string) => void
  iconStyle?: {
    top?: string
    right?: string
    bottom?: string
    left?: string
  }
  input: InputProps
}

export const SearchBar: React.FC<SearchBarProps> = (props) => {
  return (
    <TextField
      style={{ ...defaultTextFieldStyle, ...props.style }}
      variant={'outlined'}
      value={props.value}
      autoFocus={props.input.autoFocus}
      InputProps={{
        ...props.input,
        style: { ...defaultInputStyle, ...props.input.style },
        endAdornment: (
          <InputAdornment position="end">
            {props.value.length > 0 ? (
              <BackSpaceIcon
                onClick={() => props.setValue && props.setValue('')}
                style={{ ...defaultIconStyle, color: '#FF5433' }}
              />
            ) : (
              <SearchIcon style={{ ...defaultIconStyle, color: '#999' }} />
            )}
          </InputAdornment>
        )
      }}
    />
  )
}

//region Style
const defaultTextFieldStyle = {
  maxWidth: '200px',
  marginRight: '10px',
  marginLeft: '10px'
}
const defaultInputStyle = {
  borderRadius: '8px',
  paddingRight: '8px',
  fontSize: rem(0.8)
}
const defaultIconStyle = {
  cursor: 'pointer'
}
//endregion
