import { coreApi, mediaUrl } from '../configuration'
import axios from 'axios'
import logger from './logger'

export const getAuthorizationTokenForDownload = async (userId: number, filepath: string) => {
  const url = `${coreApi}/media/chats/download/${userId}/${encodeURIComponent(filepath)}`
  try {
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${sessionStorage.getItem('@occlient/token')}` }
    })
    return response.data.payload
  } catch (e) {
    logger.error(e)
    return null
  }
}

export const download = async (token: string, filename?: string) => {
  const url = `${mediaUrl}/resources`
  try {
    const { data } = await axios.get(url, { headers: { Authorization: `Bearer ${token}` }, responseType: 'blob' })
    const objectUrl = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = objectUrl
    link.setAttribute('download', `${filename}`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  } catch (e) {
    logger.error(e)
  }
}
