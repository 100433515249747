import { Reducer } from 'redux'
import * as t from './types'

export const initialState: t.MessengerState = {
  conversations: new Map(),
  activeConversation: null,
  unreadMessages: 0,
  unreadMessagesByChat: new Map(),
  videoroom: null
}

type MessengerReducer = Reducer<t.MessengerState, t.MessengerActionTypes>

const messenger: MessengerReducer = (state = initialState, action: t.MessengerActionTypes) => {
  switch (action.type) {
    case t.CONVERSATIONS_LIST:
      return { ...state, conversations: action.payload }
    case t.SET_ACTIVE_CONVERSATION:
      return { ...state, activeConversation: action.payload }
    case t.SET_UNREAD_MESSAGES:
      return {
        ...state,
        unreadMessagesByChat: action.payload.unreadMessagesByChat,
        unreadMessages: action.payload.unreadMessages
      }
    case t.SET_VIDEOROOM:
      return { ...state, videoroom: action.payload }
    case t.VIDEOROOM_UPDATE:
      return { ...state, videoroom: action.payload }
    case t.ADD_PUBLISHER_SESSION:
      return { ...state, videoroom: state.videoroom ? { ...state.videoroom, publisherSession: action.payload } : null }
    case t.SET_PRIVATE_ID:
      return { ...state, videoroom: state.videoroom ? { ...state.videoroom, private_id: action.payload } : null }
    case t.SET_SUBSCRIBER_SESSIONS:
      return {
        ...state,
        videoroom: state.videoroom ? { ...state.videoroom, subscriberSessions: action.payload } : null
      }
    case t.SET_LOCAL_STREAM:
      return { ...state, videoroom: state.videoroom ? { ...state.videoroom, localStream: action.payload } : null }
    case t.SET_REMOTE_STREAMS:
      return { ...state, videoroom: state.videoroom ? { ...state.videoroom, remoteStreams: action.payload } : null }
    default:
      return state
  }
}

export default messenger
