import { Contact, Queue, User } from '../../../../../api/rest'

export type Info = User | Contact | Queue | string | undefined
export type InfoType = 'user' | 'contact' | 'string' | 'queue' | ''

export const getInfoType = (o: Info): InfoType => {
  if (!o) return ''
  if (typeof o === 'string') return 'string'
  if ('name' in o && 'surname' in o) return 'user'
  if ('extension' in o) return 'queue'
  else return 'contact'
}

export const getContactLabel = (o: Info): string => {
  const type = getInfoType(o)
  switch (type) {
    case 'contact':
      return `${(o as Contact).secondName} ${(o as Contact).firstName}`
    case 'user':
      return `${(o as User).surname} ${(o as User).name}`
    case 'queue':
      return `${(o as Queue).descr} ${(o as Queue).extension}`
    case 'string':
      return o as string
    default:
      return ''
  }
}
