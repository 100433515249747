import { Backdrop, Fade, Modal as MuiModal, Paper, Slide } from '@mui/material'
import { CloseRounded } from '@mui/icons-material'
import React from 'react'
import isElectron from '../../helpers/isElectron'

// Quando il client è embeddato nel browser slide parte dall'inizio della pagina
// quindi non è un bell'effetto
const TransitionComponent = isElectron() ? Slide : Fade

type ModalProps = {
  closeModal: () => void
  isVisible: boolean
  width?: string
  height?: string
  container?: HTMLElement
  children?: React.ReactNode
  style?: React.CSSProperties
}

export const Modal: React.FC<ModalProps> = (props) => {
  const { children, closeModal, isVisible, width, height, container, style = {} } = props

  return (
    <MuiModal
      onClose={closeModal}
      open={isVisible}
      closeAfterTransition
      slots={{backdrop: Backdrop}}
      slotProps={{backdrop: {timeout: 500}}}
      disablePortal
      style={{ display: 'grid', placeItems: 'center' }}
      container={container}>
      <TransitionComponent direction="up" in={isVisible} mountOnEnter unmountOnExit>
        <Paper elevation={10} style={{ ...defaultStyle(width, height), ...style }}>
          <CloseRounded
            onClick={closeModal}
            style={{ width: 24, height: 24, cursor: 'pointer', position: 'absolute', top: 5, right: 5 }}
          />
          {children}
        </Paper>
      </TransitionComponent>
    </MuiModal>
  )
}

const defaultStyle = (width?: string, height?: string): React.CSSProperties => ({
  boxSizing: 'border-box',
  position: 'relative',
  width: width || '80%',
  height: height || '100%',
  placeSelf: 'center',
  overflowY: 'auto',
  outline: 'none',
  padding: 10
})
