import { CustomerChat } from '../../../../api'
import { Chat } from '../../../../api/rest'
import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { getTranslation } from '../../../../store/selectors'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

type Props = {
  chat: Chat | null
  close: Function
}
const CloseChatDialog: React.FC<Props> = ({ chat, close }) => {
  const translation = useSelector(getTranslation)

  const navigate = useNavigate()

  return (
    <DialogContainer>
      <DialogTitle>{translation.closeChatDialog.title}</DialogTitle>
      <DialogContent>{translation.closeChatDialog.content}</DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            close()
            if (!chat) return
            CustomerChat.closeChat(chat.id)
            navigate('/customer-chat')
          }}
        >
          {translation.yes}
        </Button>
        <Button onClick={() => close()}>{translation.no}</Button>
      </DialogActions>
    </DialogContainer>
  )
}

export default CloseChatDialog

//region Style
const DialogContainer = styled.div`
  max-width: 500px;
`

//endregion
