import {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {WS_ADD_EVENT_LISTENER, WS_EMIT, WS_REMOVE_EVENT_LISTENER} from "../../../store/sockets/types"
import {getCoreConnected} from "../../../store/selectors"
import logger from "../../../helpers/logger";

export interface MonitoringCall {
  calluid?: string | null
  type?: string
  customer?: number
  services: number[]
  inbounds: number[]
  outbound?: string | null
  queues: string[]
  users: string []
}

export const useMonitoringCallsInQueue = () => {
  const [calls, setCalls] = useState<Map<string, MonitoringCall>>(new Map())

  const dispatch = useDispatch()
  const coreConnected = useSelector(getCoreConnected)

  useEffect(() => {
    if (coreConnected) {
      const callsListCallback = (payload: any[]) => {
        const callsMap = new Map()

        payload?.forEach((c) => {
          if (c.calluid) {
            callsMap.set(c.calluid, {
              calluid: c.calluid,
              type: c.type,
              customer: c.customer?.id,
              services: c.services.map((s: any) => s.id),
              inbounds: c.inbounds.map((i: any) => i.id),
              outbound: c.outbound?.id,
              queues: c.queues.map((q: any) => q.id),
              users: c.users.map((u: any) => u.id)
            })
          }
        })

        setCalls(callsMap)
      }

      const callsUpdateCallback = (payload: any) => {
        switch (payload.type) {
          case 'call-closed':
            setCalls((prevState) => {
              prevState.delete(payload.call)
              return new Map(prevState)
            })
            break
          case 'new-call':
            setCalls(prevState => new Map(prevState.set(payload.call, {
              calluid: payload.call,
              services: [],
              inbounds: [],
              queues: [],
              users: [],
            })))
            break
          case 'out-call-type':
            setCalls(prevState => {
              const call = prevState.get(payload.call)
              if (call) {
                call.type = payload.callType
                return new Map(prevState.set(payload.call, call))
              }
              return prevState
            })
            break
          case 'set-outbound':
            setCalls(prevState => {
              const call = prevState.get(payload.call)
              if (call) {
                call.type = (call.type !== 'callback' && call.type !== 'predictive') ? 'outbound' : call.type
                call.outbound = payload.outbound.id
                call.services.push(payload.service.id)
                call.customer = payload.customer.id
                return new Map(prevState.set(payload.call, call))
              }
              return prevState
            })
            break
          case 'set-inbound':
            setCalls(prevState => {
              const call = prevState.get(payload.call)
              if (call) {
                call.type = 'inbound'
                call.inbounds.push(payload.inbound.id)
                call.services.push(payload.service.id)
                call.customer = payload.customer.id
                return new Map(prevState.set(payload.call, call))
              }
              return prevState
            })
            break
          case 'agent-connect':
            setCalls(prevState => {
              const call = prevState.get(payload.call)
              if (call) {
                call.users.push(payload.user.id)
                return new Map(prevState.set(payload.call, call))
              }
              return prevState
            })
            break
          case 'queue-enter':
            setCalls(prevState => {
              const call = prevState.get(payload.call)
              if (call) {
                call.queues.push(payload.queue.id)
                call.users = []
                return new Map(prevState.set(payload.call, call))
              }
              return prevState
            })
            break
          case 'queue-exit':
            setCalls(prevState => {
              const call = prevState.get(payload.call)
              if (call) {
                call.queues = call.queues.filter(queueId => queueId === payload.queue.id)
                return new Map(prevState.set(payload.call, call))
              }
              return prevState
            })
            break
          default:
            return
        }
      }

      dispatch({
        type: WS_ADD_EVENT_LISTENER,
        payload: {
          name: 'core',
          event: 'calls-list',
          listener: callsListCallback
        }
      })

      dispatch({
        type: WS_ADD_EVENT_LISTENER,
        payload: {
          name: 'core',
          event: 'calls-update',
          listener: callsUpdateCallback
        }
      })


      logger.info('Joining calls channel')

      dispatch({
        type: WS_EMIT,
        payload: {
          name: 'core',
          eventName: 'join',
          eventPayload: 'calls'
        }
      })
    }

    return () => {
      dispatch({
        type: WS_EMIT,
        payload: {
          name: 'core',
          eventName: 'leave',
          eventPayload: 'calls'
        }
      })

      dispatch({
        type: WS_REMOVE_EVENT_LISTENER,
        payload: {
          name: 'core',
          events: [{event: 'calls-list'}, {event: 'calls-update'}]
        }
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coreConnected])

  return calls
}