import {CustomerChat, Messenger, Phone} from '..'
import {ApiWithRedux} from '../ApiWithRedux'
import Janus, {DebugLevel, TurnServer} from '@bytewise/janus'
import {janusConf} from '../../configuration'
import logger from '../../helpers/logger'
import log from '../../helpers/logger'
import {notifyDisconnection, setCallInProgress, setReconnecting} from '../../store/phone/actions'
import EventEmitter from 'events'
import {changeRoute} from "../../store/applicationState/actions";
import {User} from '../rest'

type ConnectionNames = keyof typeof janusConf

class JanusPool extends ApiWithRedux {
  public voip?: Janus
  public chat?: Janus
  public messenger?: Janus
  public events = new EventEmitter()
  private turn: TurnServer = { host: '', user: '', password: '' }

  /**
   * The function initializes three Janus instances for voip, messenger, and chat and returns them as a Promise.
   * @param {TurnServer} turn - `turn` is an object representing the configuration for a TURN server. It is likely used for
   * establishing a WebRTC connection.
   * @returns The `initialize` function returns a Promise that resolves to an object with three properties: `voip`,
   * `messenger`, and `chat`. These properties are assigned the values of the `janusVoip`, `janusMessenger`, and
   * `janusChat` variables respectively, which are instances of the `Janus` class.
   */
  async initialize(turn: TurnServer): Promise<{ [key in ConnectionNames]?: Janus }> {
    this.turn = turn

    this.voip = this.voip || (await this.initializeInstance('voip'))
    await sleep(0.5)
    this.chat = this.chat || (await this.initializeInstance('chat'))
    await sleep(0.5)
    this.messenger = this.messenger || (await this.initializeInstance('messenger'))
    return { voip: this.voip, messenger: this.messenger, chat: this.chat }
  }

  /**
   * This function initializes a Janus instance and handles connection errors by retrying the connection.
   * @param {ConnectionNames} instanceName - The parameter `instanceName` is a string that represents the name of the Janus
   * connection instance being initialized.
   * @returns The function `initializeInstance` returns a Promise that resolves to a new instance of the `Janus` class.
   */
  async initializeInstance(instanceName: ConnectionNames) {
    // Se non viene ricreato l'oggetto, dopo la disconnessione (per esempio login - logout -login)
    // veniva lanciato l'errore "websocket already in closed state"
    return this.connectWithRetry(instanceName)
  }

  /**
   * The function destroys three different janus instances asynchronously and returns a promise that resolves when all three
   * have been destroyed.
   * @param [shouldRetry=false] - shouldRetry is a boolean parameter that determines whether the destroy method should
   * retry if it fails. If shouldRetry is true, the destroy method will retry until it succeeds or until the maximum number
   * of retries is reached. If shouldRetry is false, the destroy method will only try once and will not retry anymore.
   */
  async destroyAll(shouldRetry = false) {
    await Promise.all([
      this.destroy('voip', shouldRetry),
      this.destroy('chat', shouldRetry),
      this.destroy('messenger', shouldRetry)
    ])

    this.voip = undefined
    this.chat = undefined
    this.messenger = undefined
  }

  /**
   * This function destroys a Janus instance and optionally retries to connect.
   * @param {ConnectionNames} instanceName - The name of the Janus instance to be destroyed. It is of type
   * `ConnectionNames`.
   * @param [shouldRetry=false] - `shouldRetry` is a boolean parameter that indicates whether the code should attempt to
   * reconnect to the Janus instance after destroying it. If `shouldRetry` is `true`, the code will call the
   * `connectWithRetry` method to attempt to reconnect to the Janus instance.
   */
  async destroy(instanceName: ConnectionNames, shouldRetry = false) {
    logger.warn(`Destroying janus ${instanceName}${shouldRetry ? ' and will retry to connect' : ''}`)
    try {
      if (instanceName === 'voip') {
        Phone.unregisterUserVoips()
      }
      await Promise.all([
        this[instanceName]?.destroy(),
        new Promise(async (resolve, reject) => {
          this.events.emit('destroyed', instanceName)
          if (shouldRetry) {
            this.connectWithRetry(instanceName).then(resolve).catch(reject)
          } else {
            this[instanceName]?.removeAllListeners('janus-error')
            resolve(this[instanceName])
          }
        })
      ])
    } catch (err) {
      logger.error(err)
    }
  }

  /** The `connectWithRetry` function is an asynchronous function that attempts to initialize a Janus instance and retries every 3 seconds with a timeout of 5 seconds
   if the connection fails.
   @param instanceName is a string that represents the name of the Janus instance being initialized.
   */
  async connectWithRetry(instanceName: ConnectionNames) {
    const userFromSessionStorage = sessionStorage.getItem('@occlient/user')
    const user = userFromSessionStorage ? JSON.parse(userFromSessionStorage) as User : null
    if(instanceName === 'voip' && user?.userVoips.length === 0) {
      log.warn('Logged user has no voip to register to Janus')
      return
    }
    instanceName === 'voip' && this.dispatch(setReconnecting(true))
    let attempts = 0
    const janusErrorHandler = async (error: any) => {
      log.error(`JANUS CONNECTION ERROR FOR ${instanceName}:`, error)
      if (instanceName === 'voip') {
        window.occlient.emit('phone:disconnected')
        if([2, 8, 51, 52].includes(this.state.phone.status.id)) {
          logger.info('Phone disconnected while it was in call or busy, returning to home')
          const previous = this.state.applicationState.path.previous
          this.dispatch(setCallInProgress(null))
          this.dispatch(
            changeRoute({
              current: '/phone',
              previous
            })
          )
        }
        this.dispatch(setReconnecting(true))
        this.dispatch(notifyDisconnection())
      }
      await this.destroy(instanceName, true)
    }

    const checkForError = () => {
      if (!this[instanceName]!.isConnected()) throw new Error('janus not connected')
    }

    while (true) {
      this[instanceName] = new Janus()
      log.silly(`Trying to init janus ${instanceName}, attempt ${attempts}`)
      try {
        await Promise.race([timeout(5), this[instanceName]!.init(janusConf[instanceName], this.turn, [DebugLevel.Error, DebugLevel.Warning, DebugLevel.Log], {longPollTimeout: 32000})]) // impostato a 32 secondi perchè janus risponde dopo 30 secondi, se impostato a meno la richiesta va in timeout
        checkForError()
        if (instanceName === 'voip') {
          this.dispatch(setReconnecting(false))
        }
        this.events.emit('connected', { janusInstance: this[instanceName], instanceName })
        log.silly(`Janus ${instanceName} initialized, at attempt ${attempts}`)
        this[instanceName]!.once('janus-error', janusErrorHandler)
        if (instanceName === 'voip') {
          Phone.janus = this.voip!
          await Phone.registerUserVoips()
        } else if (instanceName === 'chat') {
          CustomerChat.janus = this[instanceName]!
        } else {
          Messenger.janus = this[instanceName]!
        }
        return this[instanceName]
      } catch (e) {
        log.error(e)
        attempts++
        await sleep(3)
      }
    }
  }
}

const sleep = (seconds: number) => new Promise((resolve) => setTimeout(resolve, seconds * 1000))
const timeout = (seconds: number) =>
  new Promise((_, reject) => setTimeout(() => reject('janus connection timeout'), seconds * 1000))

const janusPool = new JanusPool()
export default janusPool
