import { initialState as applicationStateInitialState } from './applicationState/reducer'
import { initialState as customerChatsInitialState } from './customerChats/reducer'
import { initialState as entitiesInitialState } from './entities/reducer'
import { initialState as languageInitialState } from './language/reducer'
import { initialState as loginInitialState } from './login/reducer'
import { initialState as messengerInitialState } from './messenger/reducer'
import { initialState as phoneInitialState } from './phone/reducer'
import { initialState as themeInitialState } from './theme/reducer'
import { RootState } from './types'

const initialState: RootState = {
  applicationState: applicationStateInitialState,
  customerChats: customerChatsInitialState,
  entities: entitiesInitialState,
  language: languageInitialState,
  login: loginInitialState,
  messenger: messengerInitialState,
  phone: phoneInitialState,
  theme: themeInitialState
}

export default initialState
