import { host } from '../../../configuration'
import { rem } from '../../../helpers/style'
import { CircularProgress } from '@mui/material'
import { login, selectLoginMethod } from '../../../store/login/actions'
import {
  getDimensions,
  getIsAuthenticated,
  getLoginMethods,
  getSelectedMethod,
  getTheme,
  getTranslation
} from '../../../store/selectors'
import qs from 'query-string'
import React, {useCallback, useEffect} from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import isElectron from '../../../helpers/isElectron'
import log from '../../../helpers/logger'
import imgBackground from '../../../images/login-background.jpeg'
import { ReactComponent as LogoIcon } from '../../../images/OC.svg'
import { ReactComponent as Logo } from '../../../images/OC_full_white.svg'
import methodLogo from '../../../images/OC_logo_colour.png'
import methodLogoDark from '../../../images/OC_logo_white.png'
import { ReactComponent as LogoIconDark } from '../../../images/OC_white.svg'
import { changeRoute } from '../../../store/applicationState/actions'
import { setReconnecting } from '../../../store/phone/actions'
import MSAD from '../../../images/MSAD.svg'
import Azure from '../../../images/Azure.svg'
import Okta from '../../../images/Okta.svg'
import {JanusPool} from "../../../api";
import logger from "../../../helpers/logger";

const electron = isElectron() ? window.require('electron') : null

const Login: React.FC = () => {
  const loginMethods = useSelector(getLoginMethods)
  const selectedMethod = useSelector(getSelectedMethod)
  const isAuthenticated = useSelector(getIsAuthenticated)
  const theme = useSelector(getTheme)
  const translation = useSelector(getTranslation)

  const { width } = useSelector(getDimensions)

  const dispatch = useDispatch()

  const selectMethodIcon = useCallback((name: string) => {
    switch (name) {
      case 'OCAuthMSAD':
        return MSAD
      case 'OCAuthAzure':
        return Azure
      case 'OCAuthOkta':
        return Okta
      case 'OCAuth':
        return theme === 'light' ? methodLogo : methodLogoDark
      default:
        return theme === 'light' ? methodLogo : methodLogoDark
    }
  }, [theme])

  useEffect(() => {
    //se presente un solo metodo di autenticazione o ne ho gia uno selezionato faccio un redirect sul SSO
    if (selectedMethod && !isAuthenticated) {
      if (electron) {
        electron.ipcRenderer
          .invoke('login', {
            ...selectedMethod,
            redirect_uri: `${host}/${window.occlientSettings.basePath || 'client'}`
          })
          .then((res: any) => log.debug(res))
          .catch((e: any) => log.error(e))
      } else {
        const body = {
          redirect_uri: `${host}/${window.occlientSettings.basePath || 'client'}`
        }
        const separator = selectedMethod.login_url.includes('?') ? '&' : '?'
        if (window.occlient.settings.isSalesforce) {
          const loginUrlQS = qs.parse(selectedMethod.login_url.split('?')[1])
          const win = window.open(selectedMethod.login_url.split('?')[0] + '?' + qs.stringify({...body, ...loginUrlQS, state: selectedMethod.id}), '_blank')
          window.occlient.once('salesforce-login-success', (payload) => {
            dispatch(login(payload) as any)
            JanusPool.initialize(payload.config.turn).catch((e) => logger.error(e))
            dispatch(setReconnecting(true))
            dispatch(changeRoute({ previous: '/login', current: '/phone' }) as any)
            win?.close()
          })
        } else {
          window.location.href = selectedMethod.login_url + separator + qs.stringify(body)
        }
      }
    }
  }, [dispatch, selectedMethod, isAuthenticated])

  return loginMethods.length === 0 || !sessionStorage.getItem('@occlient/token') ? (
    <Container className="occlient-drag-handle" templateColumns={width >= 1280 ? '1.5fr 2fr' : '1fr'}>
      <LeftContainer>
        <LogoContainer>
          {theme === 'light' ? <LogoIcon /> : <LogoIconDark />}
          <LogoDisclaimer>{translation.loginDisclaimer}</LogoDisclaimer>
        </LogoContainer>
        <MethodsContainer>
          {loginMethods.length > 0 ? (
            loginMethods.map((m) => (
              <MethodButton
                key={m.id}
                onClick={() => {
                  dispatch(selectLoginMethod(m) as any)
                }}
              >
                <ButtonLogo src={selectMethodIcon(m.type!)} />
                <ButtonText>{m.name}</ButtonText>
              </MethodButton>
            ))
          ) : (
            <CircularProgress sx={{ placeSelf: 'center' }} />
          )}
        </MethodsContainer>
      </LeftContainer>
      {width >= 1280 && (
        <RightContainer>
          <FullLogoContainer>
            <Logo />
          </FullLogoContainer>
          <DarkeningEffect />
        </RightContainer>
      )}
    </Container>
  ) : null
}

export default Login

//region Style
const Container = styled.div<{ templateColumns: '1.5fr 2fr' | '1fr' }>`
  display: grid;
  grid-template-columns: ${(props) => props.templateColumns};
  width: var(--width);
  height: var(--height);
`

const LeftContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 1.5fr;
  width: 100%;
  height: var(--height);
  background: ${(props) => props.theme.palette.background.paper};
`

const LogoContainer = styled.div`
  place-self: center start;
  text-align: center;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-row-gap: 5px;
  place-items: center;
  & > svg {
    width: auto;
    height: 20%;
    max-height: 180px;
    min-height: 80px;
    place-self: end center;
  }
`

const LogoDisclaimer = styled.div`
  color: #e24e13;
  font-size: ${rem(0.8)};
  width: 80%;
  max-width: 300px;
`

const MethodsContainer = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 450px;
  place-self: start center;
  padding-top: ${rem(0.5)};
`

const MethodButton = styled.div`
  position: relative;
  place-self: center;
  border: 1px solid #979797;
  height: 45px;
  width: 80%;
  max-width: 450px;
  cursor: pointer;
  font-size: ${rem(0.8)};
  text-align: center;
  vertical-align: middle;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin-bottom: ${rem(0.3)};
  &:hover {
    background: ${({ theme }) => theme.palette.action.hover};
  }

  &:last-child {
    margin-bottom: 0;
  }
`

const ButtonLogo = styled.img`
  grid-area: logo;
  place-self: center;
  width: 20px;
  height: auto;
  position: absolute;
  left: 10px;
  top: calc(50% - 10px);
`

const ButtonText = styled.div`
  grid-area: text;
  place-self: center;
`

const RightContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 2fr;

  width: 100%;
  height: 100%;
  background-image: url('${imgBackground}');
  background-repeat: no-repeat;
  background-size: auto var(--height);
  position: relative;
`

const DarkeningEffect = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to bottom, rgb(17, 91, 114), rgba(17, 91, 114, 0));
  mix-blend-mode: multiply;
`

const FullLogoContainer = styled.div`
  place-self: center start;
  text-align: center;
  width: 100%;
  display: grid;
  place-items: center;
  z-index: 1000;
  & > svg {
    width: auto;
    height: 20%;
    max-height: 180px;
    min-height: 80px;
    place-self: end center;
  }
`

//endregion
