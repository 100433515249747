import { User } from '../../api/rest'
import { useTheme } from '@mui/material/styles'
import React, { MouseEvent, useLayoutEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Avatar } from './Avatar'
import { StatusCircle } from './StatusCircle'

type AvatarWithStatusProps = {
  onClick?: (e: MouseEvent) => any
  avatarSize?: string
  statusSize?: string
  statusStroke?: string
  statusStrokeWidth?: string
  statusForcedColor?: string
  fontSize?: string
  letterSpacing?: string
  user?: User
  avatarStyle?: React.CSSProperties
  style?: React.CSSProperties
}

export const AvatarWithStatus: React.FC<AvatarWithStatusProps> = (props) => {
  const {
    onClick,
    avatarSize = '30px',
    statusSize = '12px',
    statusStroke,
    statusStrokeWidth = '2px',
    fontSize = '16px',
    letterSpacing = '-2px',
    user,
    avatarStyle = {},
    style = {},
    statusForcedColor
  } = props

  const [statusX, setStatusX] = useState(0)
  const [statusY, setStatusY] = useState(0)

  const containerRef = useRef<HTMLDivElement>(null)
  const statusRef = useRef<SVGSVGElement>(null)

  const muiTheme = useTheme()

  /** calcola la corretta posizione dello status circle (in basso a destra) */
  useLayoutEffect(() => {
    if (!containerRef.current || !statusRef.current) return
    if (!containerRef.current || !statusRef.current) return
    const r = containerRef.current.offsetWidth / 2
    const statusR = statusRef.current.getBoundingClientRect().width / 2
    const x = r + r * Math.abs(Math.cos((-45 * Math.PI) / 180)) - statusR
    const y = r + r * Math.abs(Math.sin((-45 * Math.PI) / 180)) - statusR
    setStatusX(x)
    setStatusY(y)
  }, [])

  // TODO: rendere questa prop di Select passabile PaperProps={{ style: { maxHeight: 75 } }}
  return (
    <Container ref={containerRef} avatarSize={avatarSize || '55px'} style={style}>
      <Avatar
        name={user?.name || 'Unknown'}
        surname={user?.surname || 'Unknown'}
        size={avatarSize}
        fontSize={fontSize}
        letterSpacing={letterSpacing}
        style={avatarStyle}
        onClick={onClick}
      />
      <StatusCircle
        ref={statusRef}
        size={statusSize}
        style={{ position: 'absolute', top: statusY, left: statusX }}
        stroke={statusStroke || muiTheme.palette.background.default}
        strokeWidth={statusStrokeWidth || '2px'}
        status={user?.state}
        forcedColor={statusForcedColor}
      />
    </Container>
  )
}

//region Style

type ContainerProps = {
  onClick?: any
  avatarSize: string
}
const Container = styled.div<ContainerProps>`
  position: relative;
  width: ${({ avatarSize }) => avatarSize};
  height: ${({ avatarSize }) => avatarSize};
  place-self: center;
  ${({ onClick }) => onClick && 'cursor: pointer'};
`

//endregion
