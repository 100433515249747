import { rem } from '../helpers/style'

const getComponents = (fontSize: number) => ({
  MuiIcon: {
    styleOverrides: {
      root: {
        fontSize
      }
    }
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        fontSize: rem(0.7, fontSize),
        minHeight: 0
      }
    }
  },
  MuiListSubheader: {
    styleOverrides: {
      root: {
        fontSize: rem(0.8, fontSize),
        lineHeight: 'unset'
      }
    }
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        fontSize,
        width: 'auto'
      }
    }
  },
  MuiOutlinedInput: {
    styleOverrides: {
      input: {
        padding: 0,
        paddingLeft: rem(0.3, fontSize)
      }
    }
  },
  MuiFab: {
    styleOverrides: {
      root: {
        minHeight: 0
      }
    }
  },
  MuiSelect: {
    styleOverrides: {
      select: {
        minHeight: 0,
        fontSize: rem(0.8, fontSize),
        padding: `${rem(0.1, fontSize)} 24px ${rem(0.1, fontSize)} ${rem(0.2, fontSize)}`
      }
    }
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontSize: rem(0.8, fontSize)
      }
    }
  },
  MuiSpeedDial: {
    styleOverrides: {
      root: {
        minWidth: 0,
        minHeight: 0
      },
      fab: {
        root: {
          minHeight: 0,
          minWidth: 0
        }
      }
    }
  },
  MuiSpeedDialIcon: {
    styleOverrides: {
      root: {
        display: 'flex',
        alignItems: 'center'
      }
    }
  }
})

export default getComponents
