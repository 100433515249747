import { Menu, MenuItem } from '@mui/material'
import React, { useRef, useState } from 'react'
import { ActionButton } from './ActionButton'
import { MoreHorizRounded } from '@mui/icons-material'

type ActionButtonProps = {
  onClick: Function
  label?: string
  disabled?: boolean
}

export const DotsButton: React.FC<ActionButtonProps> = (props) => {
  const { onClick, label, disabled } = props

  const [dropDown, setDropDown] = useState(false)
  const ref = useRef(null)
  return (
    <div ref={ref}>
      <ActionButton
        style={{ zIndex: 1050 }}
        onClick={() => {
          setDropDown((prev) => !prev)
        }}
      >
        <MoreHorizRounded />
      </ActionButton>
      <Menu
        onBlur={() => setDropDown(false)}
        sx={{ zIndex: 'modal' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        open={dropDown}
        anchorEl={ref.current}
      >
        <MenuItem disabled={disabled} onClick={() => onClick()}>
          {label}
        </MenuItem>
      </Menu>
    </div>
  )
}
