import { rem } from '../../../../../helpers/style'
import { CallStatus } from '../../../../../store/phone/types'
import { getCallInProgress, getTranslation } from '../../../../../store/selectors'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

interface CallInfoStatusProps {
  font?: number
}

const CallInfoStatus: React.FC<CallInfoStatusProps> = ({ font }) => {
  const [label, setLabel] = useState('')
  const call = useSelector(getCallInProgress)
  const translation = useSelector(getTranslation)

  useEffect(() => {
    if (!call || !call.status) return
    switch (call.status) {
      case CallStatus.ongoing:
        setLabel(
          call.others && call.others.length > 0 ? translation.conferenceInProgress : translation.conversationInProgress
        )
        break
      case CallStatus.transferring:
        setLabel(translation.callTransferring)
        break
      case CallStatus.calling:
        setLabel(translation.callInProgress)
        break
      case CallStatus.hold:
        setLabel(translation.callInHold)
        break
      case CallStatus.muted:
        setLabel(translation.microphoneOff)
        break
      default:
        setLabel('')
    }
  }, [call, translation])

  return <Info font={font || 0.9}>{label}</Info>
}

export default CallInfoStatus

//region Style

interface InfoProps {
  font: number
}

const Info = styled.div<InfoProps>`
  text-align: center;
  color: ${({ theme }) => theme.palette.primary?.main};
  font-size: ${({ font }) => rem(font)};
  padding: ${rem(0.3)} 0;
`

//endregion
