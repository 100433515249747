import isElectron from './helpers/isElectron'

const isDev = process.env.NODE_ENV === 'development'
const isTest = process.env.NODE_ENV === 'test'
const isDesktop = isElectron()

let host: string

if (!isDev && !isTest) {
  if (isDesktop && process.env.REACT_APP_HOST) host = process.env.REACT_APP_HOST
  else {
    host = window.occlientSettings.baseUrl
  }
} else {
  host = `http://localhost:${process.env.REACT_APP_PORT}`
}

if (!host) {
  alert('OC Client necessita che venga impostato un indirizzo verso le api')
}

let reverseProxyUrl: string,
  coreUrl: string,
  coreApi: string,
  statsUrl: string,
  coreSocket: string,
  messengerUrl: string,
  messengerApi: string,
  messengerSocket: string,
  authUrl: string,
  mediaUrl: string,
  translations: string,
  sockets: {
    core: { url: string; path: string }
    messenger: { url: string; path: string }
  },
  janusConf: {
    voip: string
    chat: string
    messenger: string
  },
  recordPath: string

if (isDev) {
  reverseProxyUrl = process.env.REACT_APP_HOST!.replace(/https+:\/\//g, '')

  // Core
  coreUrl = process.env.REACT_APP_CORE_URL! as string
  coreApi = `${coreUrl}/api`
  coreSocket = '/socket'

  // Messenger
  messengerUrl = process.env.REACT_APP_MESSENGER_URL!
  messengerApi = `${messengerUrl}/messengerapi`
  messengerSocket = '/messengersocket'

  // Authentication
  authUrl = process.env.REACT_APP_AUTH_URL!

  // Stats
  statsUrl = process.env.REACT_APP_STATS_URL!

  // Media
  mediaUrl = process.env.REACT_APP_MEDIA_URL!

  // Tranlations
  translations = isDev ? `http://localhost:${process.env.REACT_APP_PORT}/translations` : `${host}/client/translations`

  // Sockets
  sockets = {
    core: { url: coreUrl, path: coreSocket },
    messenger: { url: messengerUrl, path: messengerSocket }
  }

  janusConf = {
    voip: `https://${reverseProxyUrl}/phone`,
    chat: `https://${reverseProxyUrl}/mediachat`,
    messenger: `https://${reverseProxyUrl}/mediamessenger`
  }
  recordPath = process.env.REACT_APP_RECORD_PATH || '/recordings/chat/staging/'
} else {
  reverseProxyUrl = host?.replace(/https+:\/\//g, '')

  // Core
  coreUrl = host
  coreApi = `${coreUrl}/api`
  coreSocket = '/socket'

  // Messenger
  messengerUrl = `${host}`
  messengerApi = `${messengerUrl}/messengerapi`
  messengerSocket = '/messengersocket'

  // Stats
  statsUrl = `${host}/history`

  // Authentication
  authUrl = `${host}/auth`

  // Media
  mediaUrl = `${host}/media`

  // Tranlations
  translations = `${host}/client/translations`

  // Sockets
  sockets = {
    core: { url: coreUrl, path: coreSocket },
    messenger: { url: messengerUrl, path: messengerSocket }
  }

  janusConf = {
    voip: `https://${reverseProxyUrl}/phone`,
    chat: `https://${reverseProxyUrl}/mediachat`,
    messenger: `https://${reverseProxyUrl}/mediamessenger`
  }
  recordPath = process.env.REACT_APP_RECORD_PATH || '/recordings/chat/staging/'
}

export { host }
export { reverseProxyUrl }
export { coreUrl }
export { coreApi }
export { statsUrl }
export { coreSocket }
export { messengerUrl }
export { messengerApi }
export { messengerSocket }
export { authUrl }
export { mediaUrl }
export { translations }
export { sockets }
export { janusConf }
export { recordPath }
