import { LanguageCode } from '../../api/rest'

export const SET_LANGUAGE = 'SET_LANGUAGE'

export interface Translation {
  [k: string]: any
}

export interface LanguageState {
  selected: LanguageCode
  translation: Translation
}

export interface SetTranslation {
  type: typeof SET_LANGUAGE
  payload: LanguageState
}

export type TranslationActionTypes = SetTranslation
