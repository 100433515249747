import { ElectronProxy } from '../../api'
import Messenger from '../../api/messenger'
import { Videoroom } from '@bytewise/janus'
import { DateTime } from 'luxon'
import { Conversation, Member, Message, Statuses } from '../../api/rest'
import logger from '../../helpers/logger'
import messageTone from '../../sounds/message_tone.mp3'
import videoTone from '../../sounds/video_tone.mp3'
import { changeRoute } from '../applicationState/actions'
import {
  getCallInProgress,
  getConversations,
  getMe,
  getMessengerVideoRoom,
  getNotifications,
  getPath,
  getUnreadMessagesByChat,
  getUsersArray
} from '../selectors'
import * as t from './types'

const MessageTone = new Audio()
const VideoTone = new Audio()

VideoTone.loop = true

// region Chat

export const conversationsList =
  (conversations: Conversation[]): t.ConversationsListThunkAction =>
  (dispatch, getState) => {
    const me = getMe(getState())
    const conversationsMap = new Map(conversations.map((c) => [c.id, c]))

    Messenger.lookForMessagesToAck(conversations, me.username)
    dispatch({
      type: t.CONVERSATIONS_LIST,
      payload: conversationsMap
    })
  }

export const setActiveConversation = (payload: string | null): t.MessengerActionTypes => ({
  type: t.SET_ACTIVE_CONVERSATION,
  payload
})

export const addMessage =
  (payload: Message): t.AddMessageThunkAction =>
  (dispatch, getState) => {
    if (!payload.conversation) return
    const conversations = getConversations(getState())
    const conv = conversations.get(payload.conversation)
    if (!conv) return
    const oldMessages = conv.messages || []
    conv.messages = [...oldMessages, payload]
    conv.lastMessage = payload.timestamp
    const newConversations = new Map(conversations)

    dispatch({
      type: t.CONVERSATIONS_LIST,
      payload: newConversations
    })
  }

export const newConversation =
  (payload: Conversation): t.NewConversationThunkAction =>
  (dispatch, getState) => {
    const conversations = getConversations(getState())
    const newConversations = new Map(conversations)
    newConversations.set(payload.id, payload)
    dispatch({ type: t.CONVERSATIONS_LIST, payload: newConversations })
  }

export const messageReceived =
  (payload: Message): t.CanDispatchAnyAction =>
  async (dispatch, getState) => {
    if (!payload.conversation) return
    const notifications = getNotifications(getState())
    const conversations = getConversations(getState())
    const me = getMe(getState())
    const { current } = getPath(getState())
    const conversationFromState = conversations.get(payload.conversation)
    // salvo un riferimento alla conversazione da usare nello step successivo
    let conv: Conversation
    if (!conversationFromState) {
      // provo a vedere se c'è sul server
      const conversationFromServer = await Messenger.getConversationById(payload.conversation)
      if (conversationFromServer) {
        conversationFromServer.lastMessage = payload.timestamp
        conversationFromServer.messages = conversationFromServer.messages
          ? conversationFromServer.messages.concat([payload])
          : [payload]
        dispatch(newConversation(conversationFromServer))
        conv = conversationFromServer
      } else {
        // la creo senza colpo ferire con le info che ho
        const conversation: Conversation = {
          id: payload.conversation,
          members: [payload.sender, Messenger.getMemberFromUser(me)],
          messages: [payload],
          lastMessage: payload.timestamp
        }
        dispatch(newConversation(conversation))
        conv = conversation
      }
    } else {
      const oldMessages = conversationFromState.messages || []
      conversationFromState.messages = [...oldMessages, payload]
      conversationFromState.lastMessage = payload.timestamp
      const newConversations = new Map(conversations)
      dispatch({
        type: t.CONVERSATIONS_LIST,
        payload: newConversations
      })
      conv = conversationFromState
    }
    // se sono già sulla pagina, mando direttamente l'ack di lettura
    const [, , currConversationId] = current.split('/')
    // Invio dell'ack di ricezione (e anche lettura se sono sulla pagina giusta)
    Messenger.sendReceiveAck(
      payload.conversation,
      [payload.id],
      conv.members.map((m) => m.username),
      payload.conversation === currConversationId
    )
    if (notifications) {
      // Se l'utente si trova sulla conversazione riproduci solo il suono senza inviare la notifica
      if (payload.conversation !== currConversationId) {
        // @ts-ignore
        const messageNotification = new Notification(`${payload.sender.surname} ${payload.sender.name}`, {
          body: payload.body,
          silent: true
        })
        messageNotification.onclick = () => {
          dispatch(changeRoute({current: `/messenger/${payload.conversation}`, previous: current}))
          messageNotification.close()
        }
        window.onfocus = () => {
          messageNotification && messageNotification.close()
        }
      }
      // Riproduce il suono solo se non è electron perchè le notifiche di sistema hanno già un audio
      MessageTone.src = messageTone
      MessageTone.play().catch((e) => logger.error(e))
    }
    // Verifico se aggiungere info per i badge di notifica
    dispatch(addUnreadMessage(payload as Required<Message>))
  }

export const readMessagesForConversation =
  (conversation: string): t.ReadMessagesForConversationThunkAction =>
  (dispatch, getState) => {
    const unreadMessagesByChat = getUnreadMessagesByChat(getState())
    const unreadMessages = unreadMessagesByChat.get(conversation)

    if (!unreadMessages) return

    Messenger.sendReadAck(conversation, unreadMessages)

    const newUnreadMessages = new Map(unreadMessagesByChat)
    newUnreadMessages.delete(conversation)
    dispatch(setUnreadMessages(newUnreadMessages))
  }

export const setUnreadMessages = (payload: Map<string, string[]>): t.SetUnreadMessagesWithoutTotal => {
  const total = [...payload.values()].reduce((acc, curr) => acc + curr.length, 0)
  ElectronProxy.setBadge(total, 'messenger')
  return {
    type: t.SET_UNREAD_MESSAGES,
    payload: {
      unreadMessagesByChat: payload,
      unreadMessages: total
    }
  }
}

export const addUnreadMessage =
  (payload: Required<Message>): t.AddUnreadMessageThunkAction =>
  (dispatch, getState) => {
    const { current } = getPath(getState())
    const [, , currConversationId] = current.split('/')
    if (payload.conversation === currConversationId) {
      // Sono sulla conversazione aperta, non devo fare nulla
      return
    }
    const unreadMessages = getUnreadMessagesByChat(getState())
    const newUnreadMessages = new Map(unreadMessages)
    const unreadMessagesForChat = unreadMessages.get(payload.conversation)
    if (!unreadMessagesForChat) {
      newUnreadMessages.set(payload.conversation, [payload.id])
    } else {
      unreadMessagesForChat.push(payload.id)
    }
    dispatch(setUnreadMessages(newUnreadMessages))
  }

export const ackReceived =
  (payload: {
    conversation: string
    message: string
    user: Member
    timestamp: number
    type: 'received' | 'read'
  }): t.AckReceivedThunkAction =>
  (dispatch, getState) => {
    const conversations = getConversations(getState())
    const conversation = conversations.get(payload.conversation)

    if (!conversation) return

    const newConversations = new Map(conversations)
    const messages = conversation.messages

    if (!messages) return

    // cerco partendo dai più recenti il messaggio (dovrebbe sempre essere uno degli ultimi)
    for (let i = messages.length - 1; i >= 0; i--) {
      if (messages[i].id === payload.message) {
        const currMex = messages[i]
        // cambia l'oggetto per scatenerare un rerender
        messages[i] = {
          ...currMex,
          acks: currMex.acks.map((a) => {
            if (a.username === payload.user.username) return { ...a, [payload.type]: payload.timestamp }
            else return a
          })
        }
        break
      }
    }

    dispatch({
      type: t.CONVERSATIONS_LIST,
      payload: newConversations
    })
  }

export const newMessagesLoadedForConversation =
  (payload: { conversation: string; messages: Message[] }): t.SearchAfterThunkAction =>
  async (dispatch, getState) => {
    const conversations = getConversations(getState())
    const conversation = conversations.get(payload.conversation)
    if (!conversation) return

    const newConversations = new Map(conversations)
    conversation.messages = payload.messages.concat(conversation.messages)

    dispatch({ type: t.CONVERSATIONS_LIST, payload: newConversations })
  }

export const addUsersToGroup =
  (payload: { conversation: string; members: Member[]; messages: Message[] }): t.AddRemoveUsersToGroupThunkAction =>
  async (dispatch, getState) => {
    const conversations = getConversations(getState())
    let conversation = conversations.get(payload.conversation) || null

    if (!conversation) {
      // provo a vedere se la trovo sul server, potrei essere stato io ad essere stato aggiunto ad un gruppo
      conversation = await Messenger.getConversationById(payload.conversation, payload.members)
      if (!conversation) return
    }

    const newConversations = new Map(conversations)
    const newConversation = { ...conversation }

    newConversation.messages = newConversation.messages.concat(payload.messages)

    const memberSet = new Set([...newConversation.members.map((user) => user.username)])

    payload.members.forEach((member) => !memberSet.has(member.username) && newConversation.members.push(member))

    newConversations.set(newConversation.id, newConversation)

    dispatch({
      type: t.CONVERSATIONS_LIST,
      payload: newConversations
    })
  }

export const removeUserFromGroup =
  (payload: { conversation: string; member: Member; messages: Message[] }): t.AddRemoveUsersToGroupThunkAction =>
  (dispatch, getState) => {
    const me = getMe(getState())
    const conversations = getConversations(getState())
    const conversation = conversations.get(payload.conversation)
    if (!conversation) return

    const newConversations = new Map(conversations)

    if (payload.member.username === me.username) {
      // l'user attuale è stato cacciato / ha abbandonato il gruppo, comunque l'effetto è che la chat sparisce
      const { current } = getPath(getState())
      if (current.includes(conversation.id)) dispatch(changeRoute({ current: '/messenger', previous: current }))
      newConversations.delete(conversation.id)
    } else {
      const newConversation = { ...conversation }
      newConversation.messages = newConversation.messages.concat(payload.messages)
      newConversation.members = newConversation.members.filter((m) => m.username !== payload.member.username)

      newConversations.set(newConversation.id, newConversation)
    }

    dispatch({
      type: t.CONVERSATIONS_LIST,
      payload: newConversations
    })
  }

// handler event add_admin_to_group, può essere sparato anche se l'unico admin esce dal gruppo
export const addAdminToGroup =
  (payload: { conversation: string; admin: Member }): t.AddRemoveUsersToGroupThunkAction =>
  (dispatch, getState) => {
    const conversations = getConversations(getState())
    const conversation = conversations.get(payload.conversation)

    if (!conversation) return

    const newConversations = new Map(conversations)
    const newConversation = { ...conversation }

    // Rimuovo dagli admin quelli che non sono più tra i members e aggiungo il nuovo
    newConversation.admins = newConversation.admins || []
    newConversation.admins = newConversation.admins
      .filter((a) => !!newConversation.members.find((m) => m.username === a.username))
      .concat([payload.admin])

    newConversations.set(newConversation.id, newConversation)

    dispatch({
      type: t.CONVERSATIONS_LIST,
      payload: newConversations
    })
  }

//endregion

//region Video chiamate

export const updateVideoRoom =
  (payload: t.VideoRoomState): t.UpdateVideoRoomAction =>
  (dispatch, getState) => {
    const videoroom = getMessengerVideoRoom(getState())
    let newVideoRoom: t.VideoRoomState
    if (videoroom === null) {
      newVideoRoom = { ...payload }
    } else {
      newVideoRoom = { ...videoroom, ...payload }
    }
    dispatch({
      type: t.SET_VIDEOROOM,
      payload: newVideoRoom
    })
  }

export const setPrivateId = (payload: number): t.MessengerActionTypes => ({
  type: t.SET_PRIVATE_ID,
  payload
})

export const addSubscriberSession =
  (payload: { id: number; session: Videoroom }): t.AddRemovePublisherSessionThunkAction =>
  (dispatch, getState) => {
    const videoroom = getMessengerVideoRoom(getState())

    if (!videoroom) return

    const newSessions = videoroom.subscriberSessions ? new Map(videoroom.subscriberSessions) : new Map()

    newSessions.set(payload.id, payload.session)

    dispatch({
      type: t.SET_SUBSCRIBER_SESSIONS,
      payload: newSessions
    })
  }

export const setLocalStream = (payload: MediaStream): t.MessengerActionTypes => ({
  type: t.SET_LOCAL_STREAM,
  payload: new MediaStream(payload)
})

export const addRemoteStream =
  (payload: { id: number; stream: MediaStream }): t.AddRemoveRemoteStreamsThunkAction =>
  (dispatch, getState) => {
    const videoroom = getMessengerVideoRoom(getState())

    if (!videoroom) return

    const newStreams = videoroom.remoteStreams ? new Map(videoroom.remoteStreams) : new Map()

    newStreams.set(payload.id, payload.stream)

    dispatch({
      type: t.SET_REMOTE_STREAMS,
      payload: newStreams
    })
  }

export const removeRemoteStream =
  (payload: number): t.AddRemoveRemoteStreamsThunkAction =>
  (dispatch, getState) => {
    const videoroom = getMessengerVideoRoom(getState())

    if (!videoroom) return

    const newStreams = videoroom.remoteStreams ? new Map(videoroom.remoteStreams) : new Map()

    newStreams.delete(payload)

    if (newStreams.size === 0) {
      dispatch(closeVideoRoom(true))
    } else {
      dispatch({
        type: t.SET_REMOTE_STREAMS,
        payload: newStreams
      })
    }
  }

// quando arriva una richiesta di videochiamata da un altro client
export const videoRequestReceived =
  (payload: { sender: Member; pin: string; room: number; members: Member[] }): t.CanDispatchAnyAction =>
  (dispatch, getState) => {
    const actualVideoRoom = getMessengerVideoRoom(getState())
    const callInProgress = getCallInProgress(getState())
    const { current } = getPath(getState())
    if (!!actualVideoRoom || !!callInProgress) {
      // se c'è già qualcosa non posso ricevere nuove richieste
      return
    }
    Messenger.prepareVideoRoom(payload)
    dispatch(changeRoute({ current: '/messenger/video-request', previous: current }))
    VideoTone.src = videoTone
    VideoTone.play().catch((err) => logger.error(err))
  }

// quando rispondi ad una richiesta di videochiamata arrivata da un altro client
export const videoRequestResponse =
  (payload: { response: boolean; members: Member[] }): t.CanDispatchAnyAction =>
  async (dispatch, getState) => {
    const videoroom = getMessengerVideoRoom(getState())
    const { previous } = getPath(getState())

    if (!videoroom || !videoroom.room || !videoroom.pin) {
      // se già non c'è più per qualche motivo non devo fare nulla
      return
    }

    VideoTone.pause()
    VideoTone.currentTime = 0
    VideoTone.src = ''

    // intanto notifica al server la decisione
    Messenger.sendVideoResponse(payload.response, payload.members)

    if (payload.response) {
      await Messenger.joinVideoRoom()
      dispatch(changeRoute({ current: '/messenger/video-call', previous }))
    } else {
      // la richiesta è stata rifiutata
      Messenger.resetVideoRoom()
      dispatch(changeRoute({ current: previous, previous }))
    }
  }

// quando mandi la richiesta di videochiamata ad un altro client
export const sendVideoRoomRequest =
  (members: Member[]): t.CanDispatchAnyAction =>
  async (dispatch, getState) => {
    const me = getMe(getState())
    const users = getUsersArray(getState())
    const { current } = getPath(getState())

    logger.debug('Sending video request', members)
    if (members.length > 3) {
      // sono supportate le chiamate fino a 4 persone
      return
    }

    const myMember = Messenger.getMemberFromUser(me)

    const membersAvailable = members.filter((m) => {
      const user = users.find((u) => u.username === m.username)
      if (!user) return false
      else return user.state?.id === Statuses.idle
    })

    logger.debug('Members available', membersAvailable)

    const totalMembers = membersAvailable.concat([myMember])

    if (totalMembers.length === 1) {
      // c'è solo l'utente che ha chiamato
      return
    }

    try {
      await Messenger.initializeVideoRoom(membersAvailable)
      Messenger.sendVideoRequest()
      dispatch(changeRoute({ current: '/messenger/video-call', previous: current }))
    } catch (err) {
      logger.error('Could not create videoroom for messenger')
    }
  }

// quando qualcuno risponde alla richiesta di videochiamata
export const videoRequestResponseReceived =
  (payload: { response: boolean; sender: Member }): t.CanDispatchAnyAction =>
  (dispatch, getState) => {
    logger.silly('Video Request Response received', payload)
    const videoroom = getMessengerVideoRoom(getState())
    if (!videoroom) {
      // se già non c'è più per qualche motivo non devo fare nulla
      return
    }

    if (!videoroom.invites) {
      // situazione anomala a questo punto
      return
    }

    const newInvites = [...videoroom.invites]
    let accepted = 0
    for (let invite of newInvites) {
      if (invite.username === payload.sender.username) {
        invite.pending = false
        invite.response = payload.response
        if (payload.response) accepted += 1
      } else {
        if (invite.response) accepted += 1
      }
    }

    if (accepted === 1) {
      // se è il primo che accetta imposto lo startTime
      dispatch(updateVideoRoom({ startTime: DateTime.now().toMillis(), invites: newInvites }))
    } else {
      dispatch(updateVideoRoom({ invites: newInvites }))
    }
    if (newInvites.every((i) => !i.pending && !i.response)) {
      // se tutti hanno risposto negativamente allora chiudo tutto
      dispatch(closeVideoRoom(true))
      return
    }
    if (payload.response && !videoroom.members?.find((m) => m.username === payload.sender.username)) {
      // se ha accettato e non era tra i membri, devo aggiungerlo
      const newMembers = (videoroom.members || []).concat([payload.sender])
      dispatch(updateVideoRoom({ members: newMembers }))
    }
  }

// quando qualcuno lascia la stanza
export const userLeftVideoRoom =
  (payload: { user: Member }): t.CanDispatchAnyAction =>
  (dispatch, getState) => {
    const videoroom = getMessengerVideoRoom(getState())
    if (!videoroom || !videoroom.members) return

    const newMembers = [...videoroom.members].filter((m) => m.username !== payload.user.username)

    if (newMembers.length <= 1) {
      // è rimasto solo l'attuale client
      dispatch(closeVideoRoom(true))
    } else {
      // rimuovo user dai membri, al resto ci penseranno gli eventi da janus
      // per rimuovere il suo remote stream ecc...
      dispatch(updateVideoRoom({ members: newMembers }))
    }
  }

// quando lasci volontariamente la room
export const leaveVideoRoom = (): t.CanDispatchAnyAction => (dispatch, getState) => {
  const videoroom = getMessengerVideoRoom(getState())
  if (!videoroom) return

  if (videoroom.members) Messenger.leaveVideoRoom(videoroom.members)

  if (videoroom.publisherSession) {
    //videoroom.publisherSession.unpublish().catch((err) => logger.error(err))
    videoroom.publisherSession.leave().catch((err) => logger.error(err))
  }

  dispatch(closeVideoRoom(false))
}

// quando esci prima che qualcuno abbia risposto
export const cancelVideoRoomRequest = (): t.CanDispatchAnyAction => (dispatch, getState) => {
  const videoroom = getMessengerVideoRoom(getState())
  if (!videoroom) return

  if (videoroom.members) Messenger.cancelVideoRequest(videoroom.members)

  dispatch(closeVideoRoom(true))
}

// quando qualcuno ha attaccato prima che qualcuno abbia risposto
export const videoRoomRequestCanceled = (): t.CanDispatchAnyAction => (dispatch, getState) => {
  const videoroom = getMessengerVideoRoom(getState())
  const { previous } = getPath(getState())

  VideoTone.pause()
  VideoTone.currentTime = 0
  VideoTone.src = ''

  if (!videoroom) return

  dispatch(changeRoute({ current: previous, previous: previous }))
  dispatch({ type: t.SET_VIDEOROOM, payload: null })
}

export const addUsersToVideoRoom =
  (invited: Member[]): t.CanDispatchAnyAction =>
  (dispatch, getState) => {
    const videoroom = getMessengerVideoRoom(getState())
    if (!videoroom || !videoroom.pin || !videoroom.room || !videoroom.members) return

    Messenger.addMembersToVideoRoom({
      room: videoroom.room,
      pin: videoroom.pin,
      members: videoroom.members,
      invited
    })

    const newInvites = videoroom.invites ? [...videoroom.invites] : []

    invited.forEach((m) =>
      newInvites.push({
        username: m.username,
        fullname: `${m.surname} ${m.name}`,
        pending: true
      })
    )

    dispatch(updateVideoRoom({ invites: newInvites }))
  }

// quando va chiusa la videoroom in diversi casi
export const closeVideoRoom =
  (destroy: boolean): t.CanDispatchAnyAction =>
  async (dispatch, getState) => {
    const videoroom = getMessengerVideoRoom(getState())
    const { previous } = getPath(getState())
    if (!videoroom) return

    await Messenger.closeVideoroom(destroy)
    dispatch(changeRoute({ current: previous, previous: previous }))
  }

//endregion
