import ElectronProxy from '../../../api/electron'
import { AvatarWithStatus } from '../../common'
import StatusWithCounter from '../../common/StatusWithCounter'
import log from '../../../helpers/logger'
import { rem } from '../../../helpers/style'
import { ReactComponent as MessageIcon } from '../../../images/icons/business_messages.svg'
import { ReactComponent as ChatIcon } from '../../../images/icons/chat_apps_script.svg'
import RestoreIcon from '@mui/icons-material/Restore'
import { Badge, IconButton, SvgIcon } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { withStyles } from '@mui/styles'
import { changeRoute, setVisibility } from '../../../store/applicationState/actions'
import { getLostCalls, getMe, getStatus, getUnreadEvents, getUnreadMessages } from '../../../store/selectors'
import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import useRest from '../../../hooks/useRest'
import { ReactComponent as PauseIcon } from '../../../images/icons/motion_photos_pause.svg'

/**
 * Componente per la visualizzazzione del mini client desktop nel caso nessuna chiamata in arrivo o nessuna chiamata in corso
 */
export const Idle: React.FC = () => {
  const user = useSelector(getMe)
  const status = useSelector(getStatus)
  const dispatch = useDispatch()
  const { remove } = useRest('users', { lazy: true })

  const unreadMessages = useSelector(getUnreadMessages)
  const lostCall = useSelector(getLostCalls)
  const unreadChats = useSelector(getUnreadEvents)

  const theme = useTheme()

  const anchor = useRef<HTMLDivElement>(null)

  /**
   * funzione per l'ingrandimento del client con successivo cambio di pagina
   */
  const changePage = (page?: string) => {
    ElectronProxy.minifyClient(false)
    dispatch(setVisibility(true) as any)
    page && dispatch(changeRoute({ current: page }) as any)
  }
  /**
   * funzione per far tornale lo stato del operatore a disponibile
   */
  const returnIdle = () => {
    remove(null, { afterPath: '/pause' }).catch((e) => {
      log.error('REMOVE STATUS RES')
      log.error(e)
    })
  }

  const StyledBadge = withStyles(() => ({
    badge: {
      fontSize: '10px',
      width: '18px',
      height: '18px',
      minWidth: '14px'
    }
  }))(Badge)

  return (
    <Container ref={anchor}>
      {/* prima riga contiene avatar select stato e count*/}
      <AvatarContainer>
        <AvatarWithStatus
          avatarSize="35px"
          fontSize="20px"
          letterSpacing="-3px"
          user={user}
          statusSize={'14px'}
          onClick={() => {
            changePage()
          }}
        />
        {/*select e count*/}
        <div style={{ display: 'grid' }}>
          <StatusWithCounter anchorParent={anchor} withScrollBar={true} />
        </div>
        {!status.isStatus && (
          <IconButton
            style={{
              width: 30,
              height: 30,
              padding: 5
            }}
            onClick={() => {
              returnIdle()
            }}
          >
            <PauseIcon fill={theme.palette.primary.main} style={{ placeSelf: 'center' }} />
          </IconButton>
        )}
      </AvatarContainer>
      {/*Bottoni azioni rapide con notifiche*/}
      <ButtonContainer>
        <StyledBadge badgeContent={lostCall} overlap="circular" color="secondary">
          <IconButton
            onClick={() => {
              changePage(`/phone/history`)
            }}
          >
            <RestoreIcon style={{ fontSize: rem(1.6) }} />
          </IconButton>
        </StyledBadge>
        <StyledBadge badgeContent={unreadMessages} overlap="circular" color="secondary">
          <IconButton
            onClick={() => {
              changePage('/messenger')
            }}
          >
            <SvgIcon component={MessageIcon} style={{ fontSize: rem(1.6) }} inheritViewBox />
          </IconButton>
        </StyledBadge>
        <StyledBadge badgeContent={unreadChats} overlap="circular" color="secondary">
          <IconButton
            onClick={() => {
              changePage('/customer-chat')
            }}
          >
            <SvgIcon component={ChatIcon} style={{ fontSize: rem(1.6) }} inheritViewBox />
          </IconButton>
        </StyledBadge>
      </ButtonContainer>
    </Container>
  )
}

//region Style

const Container = styled.div`
  display: grid;
  grid-template-rows: max-content auto;
  gap: 5px;
`

const AvatarContainer = styled.div`
  display: grid;
  place-content: start;
  padding: 10px 5px 0;
  grid-template-columns: 40px 3fr 1fr;
  grid-column-gap: 10px;
`

const ButtonContainer = styled.div`
  place-self: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`
//endregion
