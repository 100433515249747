import {getDimensions} from '../../../store/selectors'
import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {Outlet, Route, Routes, useMatch} from 'react-router-dom'
import styled from 'styled-components'
import {small} from '../../../constants'
import withAuthentication from '../../hoc/withAuthentication'
import ConversationsList from './ConversationsList'
import VideoRequest from './VideoRequest'
import {MessengerContent} from "./MessengerContent";

const Messenger: React.FC = () => {
  const [showChats, setShowChats] = useState(true)
  const {width} = useSelector(getDimensions)
  const {params} = useMatch("/messenger/:id?")!

  useEffect(() => {
    // Se c'è una conversazione attiva o ne sto creando una
    if (params.id) {
      setShowChats(width > small.width * 1.5)
    } else {
      // altrimenti è sempre visibile
      setShowChats(true)
    }
  }, [params, width])

  return (
    <>
      <Routes>
        <Route path="video-request" element={<VideoRequest/>}/>
        <Route path="/" element={<Container hasList={showChats}
                                            hasConversation={width > small.width * 1.5}>
          {showChats && <ConversationsList/>}
          <Outlet/>
        </Container>}>
          <Route path={':id?'} element={<MessengerContent id={params.id} showChats={showChats} width={width}/>}/>
        </Route>
      </Routes>
    </>
  )
}

export default withAuthentication(Messenger)

//region Style

type ContainerProps = {
  hasList: boolean
  hasConversation: boolean
}
const Container = styled.div<ContainerProps>`
  display: grid;
  width: 100%;
  height: calc(var(--height) - var(--topbar-height));
  overflow: hidden;
  outline: none;
  grid-template-columns: ${({hasList, hasConversation}) =>
          hasList ? (hasConversation ? '300px auto' : '1fr') : '1fr'};
`
//endregion
