import { Messenger } from '../../../../api'
import { rem } from '../../../../helpers/style'
import { ArrowBackRounded, CancelRounded, GroupAddRounded } from '@mui/icons-material'
import { AppBar, IconButton, Toolbar } from '@mui/material'
import { cancelVideoRoomRequest, changeRoute, leaveVideoRoom, setActiveConversation } from '../../../../store/actions'
import { getMe, getMessengerVideoRoom, getPath, getTranslation } from '../../../../store/selectors'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { ActionButton, Modal } from '../../../common'
import AddInVideoCall from '../modals/AddInVideoCall'

const VideoCallTopBar: React.FC = () => {
  const [showAddUsers, setShowAddUsers] = useState(false)

  const videoroom = useSelector(getMessengerVideoRoom)
  const me = useSelector(getMe)
  const { current } = useSelector(getPath)
  const translation = useSelector(getTranslation)

  const dispatch = useDispatch()

  const goBack = () => {
    dispatch(changeRoute({ current: '/messenger', previous: current }) as any)
    dispatch(setActiveConversation(null) as any)
  }

  const handleCancelClick = () => {
    if (!videoroom) {
      return
    }

    if (videoroom.sender?.username === me.username) {
      if (!videoroom.invites) {
        // caso anomalo se sei tu che hai fatto la richiesta
        dispatch(leaveVideoRoom() as any)
        return
      }
      const acceptedInvites = videoroom.invites.reduce((acc, curr) => {
        if (curr.response) return acc + 1
        else return acc
      }, 0)
      if (acceptedInvites === 0) {
        dispatch(cancelVideoRoomRequest() as any)
      } else {
        dispatch(leaveVideoRoom() as any)
      }
    } else {
      dispatch(leaveVideoRoom() as any)
    }
  }

  return (
    <AppBar
      position="sticky"
      color="inherit"
      style={{
        minWidth: 0,
        boxSizing: 'border-box',
        boxShadow:
          '2px 2px 4px -1px rgb(0 0 0 / 20%), 3px 4px 5px 0px rgb(0 0 0 / 14%), 7px 1px 10px 0px rgb(0 0 0 / 12%)'
      }}>
      <Toolbar
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 12fr 1fr 1fr',
          gridColumnGap: rem(0.5),
          minWidth: 'unset',
          width: '100%',
          fontSize: rem(0.7),
          padding: `${rem(0.2)} ${rem(0.4)}`
        }}>
        <IconButton edge="start" color="inherit" onClick={goBack}>
          <ArrowBackRounded />
        </IconButton>
        <GroupInfo>
          <span style={{ placeSelf: 'end start', fontSize: rem(0.85) }}>VideoChiamata</span>
          <span
            style={{
              placeSelf: 'start',
              fontSize: rem(0.6),
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
            {Messenger.getMembersList(videoroom?.members || [], me.username, translation.you)}
          </span>
        </GroupInfo>
        <ActionButton disabled={videoroom?.members?.length === 4} onClick={() => setShowAddUsers(true)}>
          <GroupAddRounded />
        </ActionButton>
        <ActionButton onClick={handleCancelClick}>
          <CancelRounded color="error" />
        </ActionButton>
      </Toolbar>
      <Modal closeModal={() => setShowAddUsers(false)} isVisible={showAddUsers}>
        <AddInVideoCall closeModal={() => setShowAddUsers(false)} />
      </Modal>
    </AppBar>
  )
}

export default VideoCallTopBar

//region Style

const GroupInfo = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: 1.5fr 1fr;
  cursor: pointer;
  place-self: center;
`

//endregion
