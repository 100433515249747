import {LoggedUser} from '../../store/login/types'
import {getLogin} from '../../store/selectors'
import qs from 'query-string'
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import log from '../../helpers/logger'
import {changeRoute, gotAuthCode, gotAuthToken, gotTokenAndUser, handleLogout} from '../../store/actions'

const AuthManager: React.FC = (): null => {
  const navigate = useNavigate()
  const { methods, isAuthenticated } = useSelector(getLogin)
  const dispatch = useDispatch()

  // Gestore della logica di autenticazione
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!methods.length || isAuthenticated) return
    const tokenToUse = sessionStorage.getItem('@occlient/token') || ''
    const userFromLS = sessionStorage.getItem('@occlient/user')
    const user: LoggedUser = userFromLS ? JSON.parse(userFromLS) : null
    const { code, token, state: qsState } = qs.parse(window.location.search)

    // ancora non sono state fatte azioni per il login
    if (!tokenToUse && !code && !token) {
      log.silly('Did not have token in memory nor code and token in query string')
      dispatch(handleLogout())
      dispatch(changeRoute({ current: '/login', previous: '/login' }))
    } else if (!tokenToUse && typeof token === 'string') {
      // se il servizio di auth ci manda direttamente il token,
      // salto la parte di autorizzazione verso il servizio di auth
      log.silly('Found a token in query string')
      dispatch(gotAuthToken(token, qsState as string))
    } else if (!tokenToUse && typeof code === 'string') {
      // abbiamo ottenuto il codice di autorizzazione tramite redirect
      log.silly('Got code but not token, must be from redirect')
      dispatch(gotAuthCode(code, qsState as string))
    } else if (tokenToUse && user) {
      // token e utente presenti
      log.silly('Got token and user')
      dispatch(gotTokenAndUser(tokenToUse))
    } else {
      log.warn('Logging out because of unknown login state')
      dispatch(handleLogout() as any)
      navigate('/login')
    }
  }, [methods, isAuthenticated])
  return null
}

export default AuthManager
