import { Statuses } from '../../api/rest'
import { DateTime } from 'luxon'
import { Reducer } from 'redux'
import * as phoneActions from '../phone/types'
import * as t from './types'

export const initialState: t.LoginState = {
  token: null,
  user: null,
  error: '',
  methods: [],
  selectedMethod: null,
  isAuthenticated: false,
  me: {
    id: 0,
    name: '',
    surname: '',
    userVoips: [],
    email: '',
    favorites: [],
    username: '',
    state: {
      id: Statuses.disconnected,
      isStatus: true,
      lastchange: DateTime.now().toSQL(),
      name: 'DISCONNECTED'
    },
    theme: 'light',
    callRegistryStart: '0',
    departments: []
  },
  otpRequired: true,
  renewTimeout: null
}

type LoginReducer = Reducer<t.LoginState, t.LoginActionTypes>

const loginReducer: LoginReducer = (state = initialState, action: t.LoginActionTypes): t.LoginState => {
  switch (action.type) {
    case t.LOGIN:
      return {
        ...state,
        user: action.payload.user,
        me: action.payload.user,
        token: action.payload.token,
        isAuthenticated: true,
        error: '',
        selectedMethod: null
      }
    case t.TOKEN_RENEWED:
      return {
        ...state,
        token: action.payload.token
      }
    case t.LOGOUT:
      return { ...state, user: null, token: null, selectedMethod: null, isAuthenticated: false }
    case t.LOGIN_ERROR:
      return { ...state, error: action.payload }
    case t.SET_LOGIN_METHODS:
      return { ...state, methods: action.payload.methods, otpRequired: action.payload.otp }
    case t.SELECT_LOGIN_METHOD:
      return { ...state, selectedMethod: action.payload }
    case t.RENEW_TOKEN_TIMEOUT:
      return { ...state, renewTimeout: action.payload }
    case t.SET_ME:
      return { ...state, me: action.payload }
    case phoneActions.STATUS_CHANGE:
      return { ...state, me: { ...state.me, state: action.payload } }
    default:
      return state
  }
}

export default loginReducer
