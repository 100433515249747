import { DeviceTypes } from '../../../../api/rest'
import {
  DesktopWindowsRounded,
  PhoneAndroidRounded,
  TabletAndroidRounded,
} from '@mui/icons-material'
import React from 'react'
import FacebookIcon from '@mui/icons-material/Facebook';
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const devicesIcons: { [key in DeviceTypes]: React.ReactNode } = {
  mobile: <PhoneAndroidRounded />,
  tablet: <TabletAndroidRounded />,
  browser: <DesktopWindowsRounded />,
  TelegramInstance: <TelegramIcon style={{ color: '#0088cc' }}/>,
  FacebookInstance: <FacebookIcon style={{ color: '#1877F2' }}/>,
  WhatsappInstance: <WhatsAppIcon style={{ color: '#25D366' }}/>
}

export default devicesIcons
