import { Device } from '@bytewise/janus'
import { askNotificationPermission } from '../../helpers/notifications'
import { editSettings, setDevices } from '../../store/applicationState/actions'
import { SelectedDevices, Settings } from '../../store/applicationState/types'
import { getAudioVolume, getCallInProgress, getDevices, getRingerVolume, getSelectedDevices } from '../../store/selectors'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import logger from '../../helpers/logger'

type HTMLMediaElementEnhanced = HTMLMediaElement & {
  setSinkId: (arg0: string) => Promise<void>
}

const DevicesManager: React.FC = () => {
  const ringtoneEl = useRef<HTMLMediaElementEnhanced>(null)
  const audioEl = useRef<HTMLMediaElementEnhanced>(null)

  const devices = useSelector(getDevices)
  const selectedDevices = useSelector(getSelectedDevices)
  const ringerVolume = useSelector(getRingerVolume)
  const audioVolume = useSelector(getAudioVolume)
  const call = useSelector(getCallInProgress)

  //Variabile di stato che serve a controllare se i device sono cambiati e, in caso, effettuare il renegotiate della connessione webrtc
  const setCustomMicId = useState<string | null>(null)[1]
  const setSelectedMicDevice = useState<string | null>()[1]
  const callInProgress = useSelector(getCallInProgress)

  const dispatch = useDispatch()

  /** appena montato il componente si richiede il permesso per il microfono e videocamera,
   * e per le notifiche e vengono inoltre caricati tutti i device a disposizione */
  useEffect(() => {
    const handleChangeDevices = async () => {
      if (!navigator.mediaDevices) return
      const devices: Device[] = await navigator.mediaDevices.enumerateDevices()
      dispatch(setDevices(devices) as any)
    }
    getUserMedia()
      .then(() => handleChangeDevices())
      .catch((e) => logger.error(e))
    if (!navigator.mediaDevices) return
    navigator.mediaDevices.ondevicechange = () => {
      handleChangeDevices().catch((e) => logger.error(e))
    }

    askNotificationPermission().then((hasPermission) => {
      dispatch(editSettings({ notifications: hasPermission }) as any)
    })
    return () => {
      navigator.mediaDevices.ondevicechange = null
    }
  }, [dispatch])

  /** quando vengono ricevuti i device, si cercano tra essi eventuali selected devices,
   * se non se ne trovano si ricorre alla scelta di default (dettata dal SO) */
  useEffect(() => {
    if (!devices.length) return

    let selectedDevicesFromLS: SelectedDevices | null
    let settingsString = localStorage.getItem('@occlient/settings')
    if (settingsString) {
      const settings = JSON.parse(settingsString) as Settings
      if (settings.selectedDevices) selectedDevicesFromLS = settings.selectedDevices
      else selectedDevicesFromLS = null
    }

    const getFromLSOrDefault = (name: keyof SelectedDevices, kind: MediaDeviceKind) => {
      const fromLS = selectedDevicesFromLS ? selectedDevicesFromLS[name] : null
      const isDefault = (old: Device) => old.deviceId === 'default'
      const isStillThere = (old: Device) => devices.find((d) => d.label === old.label)
      if (fromLS) {
        if (isDefault(fromLS)) return devices.find((d) => d.kind === kind && d.deviceId === 'default') || fromLS
        if (isStillThere(fromLS)) return fromLS
        else return devices.find((d) => d.kind === kind && d.deviceId === 'default')
      }
      if (kind === 'videoinput') {
        const hasDefault = devices.find(getDefaultForKind(kind))
        if (hasDefault) return hasDefault
        return devices.find((d) => d.kind === 'videoinput')
      } else return devices.find(getDefaultForKind(kind))
    }

    const ringerToBind = getFromLSOrDefault('ringer', 'audiooutput')
    const audioToBind = getFromLSOrDefault('audio', 'audiooutput')
    const microphoneToBind = getFromLSOrDefault('microphone', 'audioinput')
    const webcamToBind = getFromLSOrDefault('webcam', 'videoinput')
    dispatch(
      editSettings({
        selectedDevices: {
          ringer: ringerToBind,
          audio: audioToBind,
          microphone: microphoneToBind,
          webcam: webcamToBind
        }
      }) as any
    )
  }, [devices, dispatch])

  /** lega i tag audio ai device selezionati */
  useEffect(() => {
    if (selectedDevices.ringer && (ringtoneEl.current as any)?.sinkId !== selectedDevices.ringer?.deviceId) {
      ringtoneEl.current
        ?.setSinkId(selectedDevices.ringer.deviceId)
        .then(() => {
          logger.silly(`Device ${selectedDevices.ringer?.label} set as ringer device`)
        })
        .catch((e: Error) => {
          logger.error('SettingController - Could not change output audio device')
          logger.error(e.message)
        })
    }
    if (selectedDevices.audio && (audioEl.current as any)?.sinkId !== selectedDevices.audio?.deviceId) {
      audioEl.current?.load()
      audioEl.current
        ?.setSinkId(selectedDevices.audio.deviceId)
        .then(() => {
          logger.silly(`Device ${selectedDevices.audio?.label} set as audio output device`)
        })
        .catch((e: Error) => {
          logger.error('SettingController - Could not change output audio device')
          logger.error(e.message)
        })
    }
    setSelectedMicDevice((prev) => {
      if (selectedDevices.microphone && prev !== selectedDevices.microphone?.deviceId) {
        logger.silly(`Device ${selectedDevices.microphone?.label} set as audio input device`)
        return selectedDevices.microphone?.deviceId
      }
      return prev
    })
  }, [selectedDevices, call, setSelectedMicDevice])

  /** gestione del volume */
  useLayoutEffect(() => {
    if (ringtoneEl.current) {
      ringtoneEl.current.volume = ringerVolume
    }

    if (audioEl.current) {
      audioEl.current.volume = audioVolume
    }
  }, [ringerVolume, audioVolume])

  const getDefaultForKind = (kind: MediaDeviceKind) => (d: Device) => d.deviceId === 'default' && d.kind === kind

  const getUserMedia = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      logger.error('getUserMedia not supported on this browser')
      return
    }
    // Richiedo i permessi audio
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      // Chiudo lo stream
      stream.getTracks().forEach((track) => track.stop())
      logger.debug('Microphone permissions granted')
    } catch (e: any) {
      logger.error(e.message)
      alert("L'applicazione necessita del permesso di accesso al microfono")
    }

    // Richiedo i permessi video
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true })
      // Chiudo lo stream
      stream.getTracks().forEach((track) => track.stop())
      logger.debug('Camera permissions granted')
    } catch (e: any) {
      logger.error(e.message)
    }
  }

  useEffect(() => {
    setCustomMicId((oldCustomDeviceId) => {
      // Creo un id custom in questo modo perchè il deviceId per i dispositivi di default ha sempre valore "default"
      // quindi se durante una conversazione, ad esempio, stacco le cuffie, il browser sceglie un altro dispositivo come default
      // e quel dispositivo avrà come deviceId sempre la stringa "default"
      const newCustomDeviceId = `${selectedDevices.microphone?.deviceId}-${selectedDevices.microphone?.groupId}`

      // Se i device non sono cambiati, non devo fare nulla quindi ritorno il vecchio id custom del microfono
      if (newCustomDeviceId === oldCustomDeviceId) return oldCustomDeviceId

      // Se non c'è call in progress devo solo aggiornare il device quindi ritorno il nuovo id custom
      if (!callInProgress) return newCustomDeviceId

      // Se ho call in progress e i device sono cambiati, devo fare il renegotiate della connessione webrtc e aggiornare l'id custom del microfono
      const sip = callInProgress.voip?.sip
      const newDeviceId = selectedDevices.microphone?.deviceId
      callInProgress && !callInProgress.answered && ringtoneEl?.current?.play()
      newDeviceId && sip?.renegotiate(newDeviceId)

      return newCustomDeviceId
    })
  }, [selectedDevices, callInProgress, setCustomMicId])

  return (
    <>
      <audio ref={ringtoneEl} id="occlient-ringtone" src={''} autoPlay={false} loop={true} />
      <audio ref={audioEl} id="occlient-audio" autoPlay />
    </>
  )
}

export default DevicesManager
