import { Middleware } from 'redux'
import { io, Socket } from 'socket.io-client'
import { removeAllListeners, removeListeners, SocketName, Sockets, socketsUtils } from '../../api/sockets'
import { sockets } from '../../configuration'
import logger from '../../helpers/logger'
import { getToken } from '../selectors'
import { RootState } from '../types'
import * as actions from './actions'
import * as t from './types'
import { WsRemoveEventListenerPayload } from './types'
import {setCoreConnected} from "../phone/actions"

const socketsUrls = { ...sockets }

const socketMiddleware = (): Middleware<{}, RootState> => {
  // oggetto che mantiene le 3 possibili socket da usare
  const sockets: Sockets = {}

  return (store) => (next) => (action) => {
    // ogni azione prevede il campo name, che identifica quale socket si intende usare
    const serviceName: SocketName = (action as any)?.payload?.name as SocketName

    switch ((action as any).type) {
      case t.WS_CONNECT:
        logger.silly(`Trying to connect to socket ${serviceName}`)
        // è richiesta la connessione alla websocket, controllo se non ne avevo già una attiva per quel servizio
        const oldSocket: Socket | undefined = sockets[serviceName]

        if (oldSocket) {
          logger.silly(`Socket for ${serviceName} already found, closing the old one`)
          oldSocket.close()
        }

        // recupero dalla configurazione le coordinate per effettuare la connessione
        const socketCoordinates = socketsUrls[serviceName]

        const socket = io(socketCoordinates.url!, {
          path: socketCoordinates.path,
          reconnection: true,
          query: {
            token: sessionStorage.getItem('@occlient/token') || ''
          }
        })

        // aggiungo i dovuti handler uguali per tutte le socket
        socket
          .on('connect', () => {
            logger.info(`Socket ${serviceName} connected`)
            // assegno la nuova websocket all'oggetto che mantiene i riferimenti
            sockets[serviceName] = socket
            socketsUtils[serviceName].addEventListeners(socket, store.dispatch, store.getState)
          })
          .on('disconnect', async () => {
            logger.warn(`Socket ${serviceName} disconnected`)
            store.dispatch(actions.wsDisconnect({ name: serviceName }) as any)
          })
          .on('ready', async () => {
            logger.info(`Socket ${serviceName} ready`)
            store.dispatch(actions.wsConnected({ name: serviceName }) as any)
            const utils = socketsUtils[serviceName]
            utils.subscribeToChannels(socket, store.dispatch)
            serviceName === 'core' && store.dispatch(setCoreConnected(true))
          })
          .on('reconnect_attempt', () => {
            if (socket !== undefined) {
              logger.warn(`Webscoket ${serviceName} reconnection attempt`)
              //@ts-ignore
              socket.io.opts.query = `token=${sessionStorage.getItem('@occlient/token')}`
            }
          })
          .on('error', (e: Error) => {
            logger.error(`Websocket ${serviceName} error`, e)
          })
        // aggiungo gli handler specifici per la socket richiesta (core, chat o messenger)
        break
      case t.WS_DISCONNECT:
        logger.silly(`Disconnection requested for socket ${serviceName}`)
        // chiudo la connessione
        const s = sockets[serviceName]
        if (!s) break
        const utils = socketsUtils[serviceName]
        removeAllListeners(s, utils.events)
        if ((action as any)?.payload?.logout) {
          removeListeners(s, 'disconnect')
          logger.warn(`Logout, ${serviceName} socket closed`)
          s.close()
          // cancello il campo dell'oggetto per quel servizio
          delete sockets[serviceName]
        }
        if(serviceName === 'core') store.dispatch(setCoreConnected(false))
        break
      case t.WS_EMIT:
        // costruisco gli argomenti da passare all'emit in modo condizionale
        if (serviceName === 'messenger') (action as any).payload.eventPayload.token = getToken(store.getState())
        const args = [(action as any).payload.eventPayload]
        if ((action as any).payload.eventAckFunction) {
          args.push((action as any).payload.eventAckFunction)
        }
        sockets[serviceName]?.emit((action as any).payload.eventName, ...args)
        break
      case t.WS_ADD_EVENT_LISTENER:
        const socketToAdd = sockets[serviceName]
        if (!socketToAdd) break
        socketToAdd.on((action as any).payload.event, (action as any).payload.listener)
        break
      case t.WS_REMOVE_EVENT_LISTENER:
        const socketToRemove = sockets[serviceName]
        if (!socketToRemove) break
        (action as any).payload.events.forEach((e: WsRemoveEventListenerPayload) => {
          removeListeners(socketToRemove, e.event)
        })

        break
      default:
        return next(action)
    }
  }
}

export default socketMiddleware()
