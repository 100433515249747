import { CustomerChat } from '../../../../api'
import { Chat } from '../../../../api/rest'
import { Avatar } from '../../../common'
import { Video } from '../../../common/Video'
import { getMaxVideoWidthAndMargin } from '../../../../helpers/style'
import { MediaChatState } from '../../../../store/customerChats/types'
import { getChatLocalStream, getGuestStream } from '../../../../store/selectors'
import elementResizeEvent, { unbind } from 'element-resize-event'
import React, { useLayoutEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

type Props = {
  chat: Chat
  mediachat: MediaChatState
}
const ChatBodyMedia: React.FC<Props> = ({ chat, mediachat }) => {
  const videosContainerRef = useRef<HTMLDivElement>(null)

  const [videoWidth, setVideoWidth] = useState(0)
  const [videoMargin, setVideoMargin] = useState(0)

  const localStream = useSelector(getChatLocalStream)
  const guestStream = useSelector(getGuestStream)

  useLayoutEffect(() => {
    if (!videosContainerRef.current || !mediachat) return
    const videosContainer = videosContainerRef.current
    elementResizeEvent(videosContainerRef.current, () => {
      setDimensions()
    })
    setDimensions()
    return () => {
      if (!videosContainer) return
      unbind(videosContainer)
    }
  }, [localStream, guestStream, mediachat])

  const setDimensions = () => {
    if (!videosContainerRef.current) return

    const { width, margin } = getMaxVideoWidthAndMargin(videosContainerRef.current, 1)
    setVideoWidth(width)
    setVideoMargin(margin)
  }

  return (
    <Container ref={videosContainerRef}>
      <Video
        stream={guestStream}
        autoPlay
        width={`${videoWidth}px`}
        style={{ margin: videoMargin }}
        noVideoComponent={() => <Avatar fullname={CustomerChat.getGuestFullname(chat.guest)} />}
      />
      {mediachat.type === 'video' && (
        <LocalVideoContainer>
          <Video stream={localStream} width="100px" autoPlay withoutSettings muted />
        </LocalVideoContainer>
      )}
    </Container>
  )
}

export default ChatBodyMedia

//region Style

const Container = styled.div`
  display: grid;
  place-content: center;
  position: relative;
  background-image: linear-gradient(to right bottom, #000000, #1c1c1c, #323232, #494949, #626262);
  padding: 10px;
  height: calc(var(--height) / 1.85);
`

const LocalVideoContainer = styled.div`
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 10000;
`

//endregion
