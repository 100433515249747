import { Chat, Guest } from '../../../../api/rest'
import { rem } from '../../../../helpers/style'
import { getDepartments, getDimensions, getTranslation } from '../../../../store/selectors'
import { DateTime } from 'luxon'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

const GuestInfo: React.FC<{ guest: Guest; chat?: Chat | null }> = ({ guest, chat }) => {
  const translation = useSelector(getTranslation)
  const { fontSize } = useSelector(getDimensions)
  const departments = useSelector(getDepartments)

  const DepartmentInfo: React.FC = () => {
    if (chat) {
      return (
        <>
          {chat?.departments && chat.departments.length === 1 && (
            <div>
              <Subtitle>{translation.department}: </Subtitle>
              <span>{chat.departments[0].name}</span>
            </div>
          )}
          {chat?.departments && chat.departments.length > 1 && (
            <div>
              <Subtitle>{translation.departments}:</Subtitle>
              <ul>
                {chat.departments.map((d) => (
                  <li>{d.name}</li>
                ))}
              </ul>
            </div>
          )}
        </>
      )
    } else {
      let depName = translation.unknown
      const dep = departments.get(guest.info.client_info.department)
      if (dep) depName = dep.name
      return (
        <>
          {guest.info.client_info.department && (
            <div>
              <Subtitle>{translation.department}: </Subtitle>
              <span>{depName}</span>
            </div>
          )}
        </>
      )
    }
  }

  return (
    <Container style={{ fontSize: rem(0.8, fontSize) }}>
      <DepartmentInfo />
      <br />
      {Object.entries(guest.info).map(([key, value]) => (
        <React.Fragment key={key}>
          {key !== 'client_info' && (
            <div>
              <Subtitle>{translation.guestCustomInfo[key] || key}: </Subtitle>
              <span>{value ? String(value) : translation.unknown}</span>
            </div>
          )}
        </React.Fragment>
      ))}
      <br />
      {guest.info.client_info?.pageTitle && (
        <div>
          <Subtitle>{translation.pageTitle}: </Subtitle>
          <span>{guest.info.client_info?.pageTitle}</span>
        </div>
      )}
      {guest.info.client_info?.url && (
        <div>
          <Subtitle>URL: </Subtitle>
          <span>{guest.info.client_info?.url}</span>
        </div>
      )}
      <br />
      {guest.info.client_info?.timestamp && (
        <div>
          <Subtitle>{translation.onlineFrom}: </Subtitle>
          <span>{DateTime.fromISO(guest.info.client_info.timestamp).toLocaleString(DateTime.DATETIME_SHORT)}</span>
        </div>
      )}
      <br />
      {guest.info.client_info?.browser && (
        <div>
          <Subtitle disabled={true}>Browser: </Subtitle>
          <Disabled>{guest.info.client_info.browser}</Disabled>
        </div>
      )}
      {guest.info.client_info?.ip && (
        <div>
          <Subtitle disabled={true}>IP: </Subtitle>
          <Disabled>{guest.info.client_info.ip}</Disabled>
        </div>
      )}
    </Container>
  )
}

export default GuestInfo

//region Style

const Container = styled.div`
  display: grid;
  place-items: start;
  grid-auto-flow: row dense;
  height: fit-content;
`

const Subtitle = styled.span<{ disabled?: boolean }>`
  color: ${({ theme, disabled }) => (disabled ? theme.palette.action.disabled : theme.palette.primary.main)};
  font-weight: 600;
  width: fit-content;
  text-transform: capitalize;
`

const Disabled = styled.span`
  color: ${({ theme }) => theme.palette.action.disabled};
`

//endregion
