export const new_message = 'messenger:new-message'
export const new_group = 'messenger:new-group'
export const receive_ack = 'messenger:receive-ack'
export const read_ack = 'messenger:read-ack'

export const add_users_to_group = 'messenger:add-users-to-group'
export const add_admin_to_group = 'messenger:add-admin-to-group'
export const remove_user_from_group = 'messenger:remove-user-from-group'

export const conversations_list = 'messenger:conversations-list'

export const video_request = 'messenger:video-request'
export const video_response = 'messenger:video-response'
export const video_request_cancel = 'messenger:video-request-cancel'
export const video_add_users = 'messenger:video-add-users'
export const video_user_left = 'messenger:video-user-left'
