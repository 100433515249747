import { ApiWithRedux } from '../ApiWithRedux'
import Janus, { GetVideoRoomOptions, JanusJS, Videoroom } from '@bytewise/janus'
import { messengerApi } from '../../configuration'
import {
  addRemoteStream,
  addSubscriberSession,
  removeChatSubscriberSession,
  removeRemoteStream,
  setLocalStream,
  setPrivateId,
  setUnreadMessages,
  updateVideoRoom
} from '../../store/actions'
import { Translation } from '../../store/language/types'
import * as t from '../../store/messenger/types'
import { SET_VIDEOROOM, VideoRoomState } from '../../store/messenger/types'
import { getMe, getSelectedDevices } from '../../store/selectors'
import { WS_EMIT } from '../../store/sockets/types'
import axios from 'axios'
import { DateTime } from 'luxon'
import logger from '../../helpers/logger'
import { Conversation, Member, Message, SystemMessage, User } from '../rest'
import * as evs from '../sockets/messenger/eventsTypes'

class Messenger extends ApiWithRedux {
  private _janus: Janus = new Janus()
  private _videoroom: VideoRoomState | null = null
  token: string | null

  constructor() {
    super()
    this.token = sessionStorage.getItem('@occlient/token')
    window.addEventListener('beforeunload', async () => await this.handleLogout())
  }

  set janus(janus: Janus) {
    this._janus = janus
  }

  //region Videoroom Plugin

  getVideoRoom(opts?: GetVideoRoomOptions): Promise<Videoroom> {
    return this._janus.getVideoroom(opts)
  }

  getRandomString(): string {
    return this._janus.randomString(16)
  }
  // metodo per inizializzare la videochiamata iniziata da questo client
  async initializeVideoRoom(membersAvailable: Member[]): Promise<void> {
    const me = getMe(this.state)
    const myMember = this.getMemberFromUser(me)

    let room
    let pin = this.getRandomString()

    let publisherSession
    try {
      publisherSession = await this.getVideoRoom()
    } catch (err) {
      logger.error('Could not initialize publisher session', err)
      return
    }

    if (!publisherSession) return
    logger.debug('Got publisher session', publisherSession)

    this.addPublisherSessionEventListeners(publisherSession)

    logger.debug('Room initialazied in state', {
      pin,
      sender: myMember,
      members: membersAvailable.concat(myMember),
      publisherSession
    })

    // Crea la videoroom su janus per ottenere l'id
    try {
      room = await publisherSession.create({
        publishers: 4,
        pin,
        bitrate: 256000
      })
    } catch (err) {
      logger.error('Could not create videoroom', err)
      return
    }

    if (!room) return
    logger.debug('Got videoroom', room)

    // esegue il join come publisher nella room appena creata

    try {
      await publisherSession.join('publisher', {
        room,
        pin,
        id: me.id,
        displayName: `${me.surname} ${me.name}`
      })
    } catch (err) {
      logger.error('Could not join videoroom', err)
      return
    }

    logger.debug('Joined room successfully')
    const invites = membersAvailable.map((m) => ({
      username: m.username,
      pending: true,
      fullname: `${m.surname} ${m.name}`
    }))

    // La videoroom è inizializzata, si può iniziare ad aggiornare lo stato di redux
    this._videoroom = {
      room,
      pin,
      sender: myMember,
      members: membersAvailable.concat(myMember),
      publisherSession,
      invites
    }
    this.dispatch(updateVideoRoom(this._videoroom))
  }

  // metodo per inizializzare le info sulla videochiamata in entrata
  prepareVideoRoom(payload: { sender: Member; pin: string; room: number; members: Member[] }) {
    this._videoroom = { ...payload }
    this.dispatch(updateVideoRoom(this._videoroom))
  }

  // se si accetta la videochiamata si procede col join alla videoroom specificata dal payload dell'evento
  async joinVideoRoom(): Promise<void> {
    if (!this._videoroom) return
    // la richiesta è stata accettata
    // creo la publisher session per far si che JanusManager aggiunga i listener agli eventi
    let publisherSession
    try {
      publisherSession = await this.getVideoRoom()
    } catch (err) {
      logger.error('Could not initialize publisher session', err)
      return
    }

    if (!publisherSession) return
    const me = getMe(this.state)
    this._videoroom.publisherSession = publisherSession
    this.addPublisherSessionEventListeners(publisherSession)
    // posso dunque passare ad effettuare il join nella videoroom
    try {
      await publisherSession.join('publisher', {
        room: this._videoroom.room!,
        pin: this._videoroom.pin!,
        id: me.id,
        displayName: `${me.surname} ${me.name}`
      })
    } catch (err) {
      logger.error('Could not join videoroom', err)
      return
    }
    logger.debug('Joined room successfully')
    this.dispatch(updateVideoRoom({ publisherSession, startTime: DateTime.now().toMillis() }))
  }

  // se si rifiuta la videochiamata si annulla la preparazione fatta in precedenza
  resetVideoRoom() {
    this._videoroom = null
    this.dispatch({ type: SET_VIDEOROOM, payload: null })
  }

  addPublisherSessionEventListeners(session: Videoroom) {
    // Quando il client stesso esegue il join con successo
    session.on('joined', async (info: { private_id: number; publishers: JanusJS.Publisher[] }) => {
      logger.silly('VIDEOROOM EVENT: joined', info)
      const selectedDevices = getSelectedDevices(this.state)
      // Appena entro mando audio e video nella room con i device selezionati
      const devices = {
        audioinput: selectedDevices.microphone,
        videoinput: selectedDevices.webcam,
        audiooutput: selectedDevices.audio
      }
      try {
        await session.sendStream({ audio: true, video: true, devices })
      } catch (err) {
        logger.error('Could not send stream', err)
        return
      }
      if (this._videoroom) this._videoroom.private_id = info.private_id
      this.dispatch(setPrivateId(info.private_id))
      // e mi iscrivo come subscriber per ogni publisher già presente nella stanza
      info.publishers.forEach((p) => this.createSubscriberSession(p))
    })
    // Quando uno dei publisher esce dalla room
    session.on('leaved', (publisherId: number) => {
      logger.silly('VIDEOROOM EVENT: leaved', publisherId)
      this.dispatch(removeChatSubscriberSession(publisherId))
      this.dispatch(removeRemoteStream(publisherId))
    })
    // Quando la libreria janus è riuscita a recuperare il proprio stream locale
    session.on('localstream', (stream: MediaStream) => {
      logger.silly('VIDEOROOM EVENT: localstream', stream)
      this.dispatch(setLocalStream(stream))
    })
    // Quando si aggiungono dei publishers
    session.on('publishers', (publishers: JanusJS.Publisher[]) => {
      logger.silly('VIDEOROOM EVENT: publishers', publishers)
      publishers.forEach((p) => this.createSubscriberSession(p))
    })
  }

  async createSubscriberSession(publisher: JanusJS.Publisher) {
    const vr = this._videoroom
    if (!vr) return
    logger.debug('Adding subscriber session for', publisher, vr)
    if (!vr || !vr.pin || !vr.room || !vr.private_id) return
    const subscriberSessions = vr.subscriberSessions || new Map()
    if (subscriberSessions.get(publisher.id)) return

    let subscriberSession: Videoroom
    try {
      subscriberSession = await this.getVideoRoom()
    } catch (err) {
      logger.error('Could not get subscriber session', err)
      return
    }

    if (!subscriberSession) return

    logger.debug('Got subscriber session', subscriberSession)
    subscriberSession.on('remotestream', (stream: MediaStream) => {
      logger.silly('VIDEOROOM EVENT: remotestream', stream)
      this.dispatch(addRemoteStream({ id: publisher.id, stream }))
    })

    this.dispatch(addSubscriberSession({ id: publisher.id, session: subscriberSession }))

    try {
      await subscriberSession.join('subscriber', {
        room: vr.room,
        pin: vr.pin,
        publisher: publisher.id,
        subscriber: vr.private_id
      })
    } catch (err) {
      logger.error('Could not join videoroom as a subscriber', err)
      return
    }
    logger.debug('Correctly joined as subscriber')
  }

  async closeVideoroom(destroy: boolean) {
    if (!this._videoroom) return

    if (this._videoroom.room && this._videoroom.publisherSession && destroy) {
      // Distrugge la videroom su janus
      await this._videoroom.publisherSession.destroy(this._videoroom.room).catch((err: any) => logger.error(err))
    }
    this._videoroom = null
    this.dispatch({
      type: t.SET_VIDEOROOM,
      payload: null
    })
  }
  //endregion

  async handleLogout() {
    this.token = null
    if (this._videoroom && this._videoroom.publisherSession) {
      try {
        await this._videoroom.publisherSession.leave()
      } catch (err) {
        logger.error('Could not leave videoroom on logout')
      }
    }
  }

  sendMessage(payload: SendMessagePayload, callback: SendMessageFeedback) {
    if (!payload.conversation && !payload.members) {
      logger.error('Cannot send message if "conversation" and "members" are undefined')
      return
    }
    this.dispatch({
      type: WS_EMIT,
      payload: {
        name: 'messenger',
        eventName: evs.new_message,
        eventPayload: {
          conversation: payload.conversation,
          body: payload.body,
          user: 'id' in payload.user ? this.getMemberFromUser(payload.user) : payload.user,
          members: !payload.conversation ? payload.members : undefined
        },
        eventAckFunction: callback
      }
    })
  }

  createGroup(name: string, users: User[], callback: (payload: { conversation: Conversation }) => void) {
    const members: Member[] = users.map(this.getMemberFromUser)
    this.dispatch({
      type: WS_EMIT,
      payload: {
        name: 'messenger',
        eventName: evs.new_group,
        eventPayload: { name, members },
        eventAckFunction: callback
      }
    })
  }

  sendReceiveAck(conversation: string, messages: string[], members?: string[], read = false) {
    this.dispatch({
      type: WS_EMIT,
      payload: {
        name: 'messenger',
        eventName: evs.receive_ack,
        eventPayload: {
          conversation,
          messages,
          members,
          read
        }
      }
    })
  }

  sendReadAck(conversation: string, messages: string[], members?: string[]) {
    this.dispatch({
      type: WS_EMIT,
      payload: {
        name: 'messenger',
        eventName: evs.read_ack,
        eventPayload: {
          conversation,
          messages,
          members
        }
      }
    })
  }

  /**
   * aggiunge n membri al gruppo, il feedback viene in realtà gestito nell'event
   * handler dell'evento add_users_to_group
   * @param conversation
   * @param members
   * @param callback
   */
  addMembersToGroup(
    conversation: string,
    members: Member[],
    callback: (feedback: { done?: boolean; error?: string }) => void
  ) {
    this.dispatch({
      type: WS_EMIT,
      payload: {
        name: 'messenger',
        eventName: evs.add_users_to_group,
        eventPayload: { conversation, members },
        eventAckFunction: callback
      }
    })
  }

  /**
   * rimuove un membro dal gruppo, il feedback viene in realtà gestito nell'event
   * handler dell'evento remove_user_from_group
   * @param conversation
   * @param member
   * @param callback
   */
  removeMemberFromGroup(
    conversation: string,
    member: Member,
    callback: (feedback: { done?: boolean; error?: string }) => void
  ) {
    this.dispatch({
      type: WS_EMIT,
      payload: {
        name: 'messenger',
        eventName: evs.remove_user_from_group,
        eventPayload: { conversation, member },
        eventAckFunction: callback
      }
    })
  }

  sendVideoRequest() {
    if (!this._videoroom) return
    this.dispatch({
      type: WS_EMIT,
      payload: {
        name: 'messenger',
        eventName: evs.video_request,
        eventPayload: {
          room: this._videoroom.room,
          pin: this._videoroom.pin,
          members: this._videoroom.members
        }
      }
    })
  }

  sendVideoResponse(response: boolean, members: Member[]) {
    this.dispatch({
      type: WS_EMIT,
      payload: {
        name: 'messenger',
        eventName: evs.video_response,
        eventPayload: { response, members }
      }
    })
  }

  leaveVideoRoom(members: Member[]) {
    this.dispatch({
      type: WS_EMIT,
      payload: {
        name: 'messenger',
        eventName: evs.video_user_left,
        eventPayload: { members }
      }
    })
  }

  cancelVideoRequest(members: Member[]) {
    this.dispatch({
      type: WS_EMIT,
      payload: {
        name: 'messenger',
        eventName: evs.video_request_cancel,
        eventPayload: { members }
      }
    })
  }

  addMembersToVideoRoom(payload: { pin: string; room: number; members: Member[]; invited: Member[] }) {
    this.dispatch({
      type: WS_EMIT,
      payload: {
        name: 'messenger',
        eventName: evs.video_add_users,
        eventPayload: payload
      }
    })
  }
  /**
   * Look for messages to acknowledge.
   *
   * @param {Conversation[] | Conversation} conversations - the conversations to search for messages to acknowledge
   * @param {string} myUsername - the username of the current user
   */
  lookForMessagesToAck(conversations: Conversation[] | Conversation, myUsername: string) {
    let convs: Conversation[]
    if (!Array.isArray(conversations)) {
      convs = [conversations]
    } else {
      convs = conversations
    }
    const receiveAcks: Map<string, string[]> = new Map()
    const readAcks: Map<string, string[]> = new Map()

    // Fase di ricerca
    for (let conv of convs) {
      if (!conv.messages) continue
      for (let message of [...conv.messages].reverse()) {
        const myAck = message.acks.find((a) => a.username === myUsername)
        if (!myAck) continue // non dovrebbe essere possibile
        if (myAck.received && myAck.read) {
          // smetto di ciclare, do per scontato che dal primo che trovo con
          // tutti e due in poi tutti i messaggi hanno entrambi gli ack
          break
        }
        if (!myAck.received) {
          const acksForConv = receiveAcks.get(conv.id)
          if (acksForConv) acksForConv.push(message.id)
          else receiveAcks.set(conv.id, [message.id])
        }
        if (!myAck.read) {
          const acksForConv = readAcks.get(conv.id)
          if (acksForConv) acksForConv.push(message.id)
          else readAcks.set(conv.id, [message.id])
        }
      }
    }

    // Invio gli ack
    receiveAcks.forEach((messages, conversation) => this.sendReceiveAck(conversation, messages))
    this.dispatch(setUnreadMessages(readAcks))
  }

  async getConversationById(id: string, members?: Member[]): Promise<Conversation | null> {
    if (!this.token) {
      logger.error('Could not make the request for conversation by id because token is missing')
      return null
    }
    let key = ''
    if (members && members.length) {
      key = '/' + this.getConversationKey(members)
    }
    try {
      const { data } = await axios.get(`${messengerApi}/conversations/${id}${key}`, {
        headers: { Authorization: 'Bearer ' + this.token }
      })
      return data as Conversation
    } catch (err) {
      logger.error(err)
      return null
    }
  }

  async searchAfter(payload: { conversation: string; message: string; timestamp: number }): Promise<Message[]> {
    try {
      // @ts-ignore
      const qs = new URLSearchParams(payload).toString()
      // FIXME: l'url deve divenire lo stesso del core, ci penserà nginx a rigirare
      const { data } = await axios.get(`${messengerApi}/messages/get_after?` + qs, {
        headers: { Authorization: 'Bearer ' + this.token }
      })
      return data
    } catch (err) {
      logger.error('Could not load more messages', err)
      return []
    }
  }

  //region Helpers

  getMemberFromUser = (user: User | null): Member => ({
    username: user?.username!,
    name: user?.name!,
    surname: user?.surname!,
    email: user?.email || 'nomail'
  })

  getConversationByMembers = (conversations: Conversation[], members?: Member[]): Conversation | undefined => {
    if (!members) return undefined
    const mems = members.map((m) => m.username)
    return conversations.find((c) => {
      return c.members.length === 2 && !c.name && c.members.every((m) => mems.includes(m.username))
    })
  }

  parseSystemMessage = (text: string, translation: Translation) => {
    const textSplits = text.split(':')
    const type = textSplits[0] as SystemMessage
    const args = textSplits[1]
    if (type === 'group-add') {
      return `${args} ${translation.groupAdd}`
    }
    if (type === 'group-leave') {
      return `${args} ${translation.groupLeave}`
    }
    if (type === 'group-remove') {
      const [admin, user] = args.split('|')
      return `${admin} ${translation.groupRemove} ${user}`
    }
    if (type === 'group-new-admin') {
      const [, user] = args.split('|')
      return `${user} ${translation.isNow} ${translation.admin}`
    }

    return ''
  }

  getMembersList = (members: Member[], exclude: string, you: string) => {
    const added: string[] = []
    return (
      you +
      ', ' +
      members
        .filter((m) => m.username !== exclude)
        .map((m) => {
          if (added.includes(m.name)) {
            return `${m.name} ${m.surname[0]}.`
          } else {
            added.push(m.name)
            return m.name
          }
        })
        .join(', ')
    )
  }

  getConversationKey = (members: Member[]): string =>
    members
      .map((m) => m.username)
      .sort()
      .join('-')
  //endregion
}

const messenger = new Messenger()
export default messenger

//region Types

type SendMessagePayload = {
  conversation?: string
  body: string
  user: User | Member
  members?: Member[]
}
// in base a se è il primo messaggio di una nuova conversazione o meno
type SendMessageFeedback =
  | ((fb: { message: Message }) => void)
  | ((fb: { conversation: Conversation & { message: Message } }) => void)

//endregion
