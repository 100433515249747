import { DateTime } from 'luxon'
import { useCallback, useEffect, useRef, useState } from 'react'
import { getTranslation } from '../store/selectors'
import { useSelector } from 'react-redux'

function useElapsedTime(reference?: DateTime | string | number | null) {
  const [elapsedTime, _setElapsedTime] = useState(0)
  const elapsedTimeRef = useRef(0)
  const translation = useSelector(getTranslation)

  const setElapsedTime = useCallback(
    (secs: number) => {
      elapsedTimeRef.current = secs
      _setElapsedTime(secs)
    },
    [_setElapsedTime]
  )

  const pad = useCallback((num: any) => {
    return ('0' + num).slice(-2)
  }, [])

  const hhmmss = useCallback(
    (secs: number) => {
      if (secs > 3600 * 24) return translation.moreThanOneDay
      let minutes = Math.floor(secs / 60)
      secs = secs % 60
      const hours = Math.floor(minutes / 60)
      minutes = minutes % 60
      return `${hours ? pad(hours) + ':' : ''}${pad(minutes)}:${pad(secs)}`
    },
    [translation, pad]
  )

  useEffect(() => {
    let realReference
    // se viene passato null utilizza il tempo corrente
    if (reference === null || reference === undefined) {
      realReference = DateTime.now()
    } else {
      // Altrimenti, nel caso in cui venga passato un DateTime creo una copia per via dei riferimenti
      if (DateTime.isDateTime(reference as object))
        realReference = DateTime.fromMillis((reference as DateTime).toMillis())
      // se è un tipo base è un valore
      realReference = reference
    }

    const startTime = DateTime.isDateTime(realReference as object)
      ? realReference
      : typeof realReference === 'string'
      ? DateTime.fromSQL(realReference)
      : DateTime.fromMillis(realReference as number)

    const now = DateTime.now()
    const secsElapsed = now.diff(startTime as DateTime, 'seconds')

    setElapsedTime(Math.floor(secsElapsed.seconds))

    const interval = setInterval(() => {
      setElapsedTime(elapsedTimeRef.current + 1)
    }, 1000)

    return () => clearInterval(interval)
  }, [setElapsedTime, reference])

  return { elapsedTime, elapsedTimeString: hhmmss(elapsedTime) }
}

export default useElapsedTime
