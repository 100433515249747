import { rem } from '../../../../../helpers/style'
import { getCallInProgress, getTranslation } from '../../../../../store/selectors'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import Counter from '../../../../common/Counter'
import { getContactLabel, getInfoType, Info } from './helpers'

type CallInfoStatusProps = {
  type?: 'add' | 'transfer'
  labelFont?: number
  counterFont?: number
}
const CallInTransferring: React.FC<CallInfoStatusProps> = ({ type, labelFont, counterFont }) => {
  const [label, setLabel] = useState('')
  const call = useSelector(getCallInProgress)
  const translation = useSelector(getTranslation)

  useEffect(() => {
    if (!call) return
    const user = call.user
    let lbl = user ? `${user.surname} ${user.name}` : `${call.number}`
    if (call.others?.length) lbl += ` ${translation.others} ${call.others.length}`
    setLabel(lbl)
  }, [call, translation])

  const getNumber = (o: Info) => {
    const type = getInfoType(o)
    switch (type) {
      case 'user':
        return `${translation.voip} ${call?.transferringToExtension || ''}`
      case 'contact':
        return `${call?.transferringToExtension || ''}`
    }
  }

  return (
    <Container font={labelFont || 1}>
      <InfoContainer font={labelFont || 1}>
        {translation.callPause} {label}
      </InfoContainer>
      {type && <Disclaimer>{type === 'add' ? translation.addInCall : translation.transferTo}</Disclaimer>}
      <InfoContainer font={labelFont || 1}>{translation.conversationInProgress}</InfoContainer>
      <div>{getContactLabel(call?.transferringTo)}</div>
      <div>{call?.transferringTo && ``}</div>
      <div>{getNumber(call?.transferringTo)}</div>
      <Counter font={counterFont} />
    </Container>
  )
}

export default CallInTransferring

//region Style

interface Options {
  font: number
}

const Container = styled.div<Options>`
  padding: 5px;
  justify-self: center;
  align-self: start;
  place-items: center;
  display: grid;
  grid-template-rows: repeat(auto-fill, minmax(20px, 1fr));
  grid-row-gap: 3px;
  font-size: ${({ font }) => rem(font)};
`

const InfoContainer = styled.div<Options>`
  text-align: center;
  color: ${({ theme }) => theme.palette.primary?.main};
  font-size: ${({ font }) => rem(font)};
`

const Disclaimer = styled.div`
  place-self: center;
  text-align: center;
`

//endregion
