import { CustomerChat } from '../../../../api'
import { Action, Chat, Department, Role, User } from '../../../../api/rest'
import { AvatarWithStatus, SearchBar } from '../../../common'
import { findDepartment, findUser, isIdle } from '../../../../helpers/filters'
import { rem } from '../../../../helpers/style'
import { ArrowForwardIosRounded } from '@mui/icons-material'
import { TabContext, TabPanel } from '@mui/lab'
import {Tab, Tabs} from '@mui/material'
import { changeRoute } from '../../../../store/applicationState/actions'
import { removeChat, transferToOperatorResponse } from '../../../../store/customerChats/actions'
import { getDepartmentsArray, getDimensions, getTranslation, getUser, getUsersArray } from '../../../../store/selectors'
import React, { useEffect, useState } from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import logger from '../../../../helpers/logger'

type Props = {
  chat: Chat | null
  close: Function
}

const TransferModal: React.FC<Props> = ({ chat, close }) => {
  const [tab, setTab] = useState<'operator' | 'department'>('operator')
  const [search, setSearch] = useState('')
  const [usersToShow, setUsersToShow] = useState<User[]>([])
  const [departmentsToShow, setDepartmentsToShow] = useState<Department[]>([])

  const user = useSelector(getUser)
  const users = useSelector(getUsersArray)
  const departments = useSelector(getDepartmentsArray)
  const { fontSize } = useSelector(getDimensions)
  const translation = useSelector(getTranslation)

  const dispatch = useDispatch()

  useEffect(() => {
    setSearch('')
  }, [tab])

  useEffect(() => {
    const operators = [...users].filter((u) => u.userRoles?.find((ur) => (ur.role as Role).licenseType === 'operator'))
    if (!search) {
      setUsersToShow(operators.filter((u) => isIdle(u) && canTransferTo(u, 'chats-transfer', 'retrieve')))
    } else {
      setUsersToShow(
        operators.filter(findUser(search)).filter((u) => isIdle(u) && canTransferTo(u, 'chats-transfer', 'retrieve'))
      )
    }
  }, [users, search])

  useEffect(() => {
    // Non posso trasferire agli stessi dipartimenti ai quali l'utente è assegnato
    const validDepartments = departments.filter((department) => !user?.departments.find((d) => d.id === department.id))
    if (!search) {
      setDepartmentsToShow([...validDepartments])
    } else {
      setDepartmentsToShow(validDepartments.filter(findDepartment(search)))
    }
  }, [departments, search, user?.departments])

  const transferToOperator = (operatorId: number) => {
    if (!chat || chat.closed) {
      close()
      alert(translation.alerts.chatClosedOrNotFound)
      return
    }
    CustomerChat.transferToOperator(chat.id, operatorId, (response) => {
      if(!response) {
        logger.error(`Could not transfer chat with id ${chat.id} to operator ${operatorId}`)
        alert(translation.alerts.chatClosedOrNotFound)
        return
      }
      dispatch(transferToOperatorResponse({ chatId: chat.id, operatorId, response }) as any)
      close()
    })
  }

  const transferToDepartment = (departmentId: number) => {
    if (!chat || chat.closed) {
      close()
      alert(translation.alerts.cannotTransferChatToOperator)
      return
    }
    CustomerChat.transferToDepartment(chat.id, departmentId, (fb) => {
      if (!fb) {
        logger.error(`Could not transfer chat with id ${chat.id} to department ${departmentId}`)
        alert(translation.alerts.cannotTransferChatToQueue)
        return
      }
      dispatch(removeChat(chat.id) as any)
      dispatch(changeRoute({ current: '/customer-chat', previous: '/customer-chat' }) as any)
      close()
    })
  }

  return (
    <Container>
      <TabContext value={tab}>
        <Tabs value={tab} onChange={(_, newValue) => setTab(newValue)}>
          <Tab label={translation.operator} value="operator" style={{ fontSize: rem(0.7) }} />
          <Tab label={translation.department} value="department" style={{ fontSize: rem(0.7) }} />
        </Tabs>
        <TabPanel value="operator">
          <SearchBar
            style={{ marginBottom: '10px' }}
            value={search}
            input={{ onChange: (ev) => setSearch(ev.target.value) }}
            setValue={setSearch}
          />
          <ScrollBar>
            {usersToShow.map((u) => (
              <Card key={u.id} fontSize={fontSize} onClick={() => transferToOperator(u.id)}>
                <AvatarWithStatus
                  user={u}
                  avatarSize={rem(1.5, fontSize)}
                  fontSize={rem(0.9, fontSize)}
                  statusSize={rem(0.8, fontSize)}
                  letterSpacing={'-2px'}
                  style={{ placeSelf: 'center' }}
                />
                <span style={{ justifySelf: 'start' }}>
                  {u.name} {u.surname}
                </span>
                <ArrowForwardIosRounded />
              </Card>
            ))}
          </ScrollBar>
        </TabPanel>
        <TabPanel value="department">
          <SearchBar
            style={{ marginBottom: '10px' }}
            value={search}
            input={{ onChange: (ev) => setSearch(ev.target.value) }}
            setValue={setSearch}
          />
          <ScrollBar>
            {departmentsToShow.map((d) => (
              <Card key={d.id} fontSize={fontSize} onClick={() => transferToDepartment(d.id)}>
                <div />
                <span style={{ justifySelf: 'start' }}>{d.name}</span> <ArrowForwardIosRounded />
              </Card>
            ))}
          </ScrollBar>
        </TabPanel>
      </TabContext>
    </Container>
  )
}

export default TransferModal

//region Style

const Container = styled(ScrollBar)`
  display: grid;
  place-items: start;
  grid-template-rows: auto 1fr;
  margin-top: 10px;
  height: fit-content;
`

const Card = styled.div<{ fontSize: number }>`
  display: grid;
  grid-template-columns: auto 1fr auto;
  place-items: center;
  grid-column-gap: 5px;
  border-radius: 15px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: ${({ fontSize }) => rem(0.8, fontSize)};
  &:hover {
    background: ${({ theme }) => theme.palette.action?.hover};
  }
`
//endregion

const canTransferTo = (user: User, entity: string, action: string) => {
  let userCan = false
  user.userRoles?.forEach((ur) =>
    ((ur.role as Role)?.actions as Action[])?.forEach((a: any) => {
      if ((a as Action).entity === entity && (a as Action).name === action) return (userCan = true)
    })
  )
  return userCan
}
