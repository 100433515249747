import {Router} from "react-router-dom"
import React, {useLayoutEffect, useState} from "react"

export const CustomRouter: React.FC<{history: any, children: React.ReactNode, props?: any}> = ({history, ...props}: any) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location
  })

  useLayoutEffect(() => history.listen(setState), [history])

  return (
    <Router
      {...props}
      navigator={history}
      location={state?.location}
      navigationType={state?.action}
    />
  )
}