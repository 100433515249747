import CustomerChat from '../../../../api/customerChat'
import { Chat } from '../../../../api/rest'
import { Avatar, ConversationCard } from '../../../common'
import { getDateInfo } from '../../../../helpers/common'
import { rem } from '../../../../helpers/style'
import { CheckRounded, CloseRounded } from '@mui/icons-material'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { addChat, removeChat, unbookChat } from '../../../../store/customerChats/actions'
import { getBookedChatsArray, getMe, getTranslation, getUnreadEventsByChat } from '../../../../store/selectors'
import React, { useMemo, useState } from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import { useDispatch, useSelector } from 'react-redux'
import { useMatch } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { Role } from '../../../../api/rest'
import { WS_EMIT } from '../../../../store/sockets/types'
import * as evs from '../../../../api/sockets/core/eventsTypes/chatEventsTypes'

type Props = {
  active: Chat[]
  closed: Chat[]
  spied: Chat[]
  inTransfer: Chat[]
  goToChat: (a0: Chat) => void
  getLastMessageBody: (a0: Chat) => string
}

const ChatListActive: React.FC<Props> = (props) => {
  const { active, closed, spied, inTransfer, goToChat, getLastMessageBody } = props

  const [showCloseDialog, setShowCloseDialog] = useState<string | null>(null)

  const translation = useSelector(getTranslation)
  const unreadMessages = useSelector(getUnreadEventsByChat)
  const bookedChats = useSelector(getBookedChatsArray)
  const me = useSelector(getMe)

  const dispatch = useDispatch()

  const muiTheme = useTheme()
  const { params } = useMatch('/customer-chat/:id?')!

  const showSpiedList = useMemo(
    () => !me.userRoles?.some((r) => (r.role as Role).licenseType === 'operator'),
    [me.userRoles]
  )

  const bookedChatResponse = (chatId: string, response: boolean, supervisor?: number) => {
    if (!response) {
      dispatch(unbookChat(chatId) as any)
    }
    CustomerChat.bookedChatResponse({ chat: chatId, response, supervisor }, ({ error, chat }) => {
      if (error) {
        //TODO: gestire errore
        dispatch(unbookChat(chatId) as any)
        return
      }
      if (chat) {
        dispatch(addChat(chat) as any)
        goToChat(chat)
      }
    })
  }

  return (
    <Container $withBooked={bookedChats.length > 0} $withTransfer={inTransfer.length > 0} $withSpied={showSpiedList}>
      {inTransfer.length > 0 && <Title>In trasferimento</Title>}
      {inTransfer.length > 0 && (
        <List>
          {inTransfer.map((c) => (
            <ConversationCard
              key={c.id}
              conversation={c}
              goToConversation={(c) => goToChat(c as Chat)}
              paramsId={params.id!}
              badgeContent={unreadMessages.get(c.id) || 0}
              otherFullname={CustomerChat.getGuestFullname(c.guest)}
              shouldRenderMessageAck={false}
              lastMessageBody={getLastMessageBody(c)}
              timestamp={c.events?.length ? getDateInfo(c.events[c.events.length - 1].date) : ''}
            />
          ))}
        </List>
      )}
      {bookedChats.length > 0 && <Title>In arrivo</Title>}
      {bookedChats.length > 0 && (
        <List>
          {bookedChats.map((bookedChat) => (
            <BookedChatContainer key={bookedChat.id}>
              <Avatar size={rem(1.7)} fontSize={rem(0.9)} style={{ gridArea: 'avatar', placeSelf: 'center' }}>
                <CircularProgress size={10} thickness={10} color="inherit" />
              </Avatar>
              <Name>
                {bookedChat.guest.info.name || bookedChat.guest.info.surname
                  ? `${bookedChat.guest.info.name} ${bookedChat.guest.info.surname}`
                  : ' Nome sconosciuto'}
              </Name>
              <DepartmentName>
                {bookedChat.supervisor && (
                  <b>
                    Assegnata da {bookedChat.supervisor.name} {bookedChat.supervisor.surname} sul
                  </b>
                )}
                {bookedChat.departments[0] && bookedChat.departments[0].name}
              </DepartmentName>
              <ButtonsContainer>
                <IconButton
                  style={{ backgroundColor: muiTheme.palette.success.main, width: 20, height: 20 }}
                  onClick={() => bookedChatResponse(bookedChat.id, true, bookedChat.supervisor?.id)}
                >
                  <CheckRounded htmlColor="#FFFFFF" />
                </IconButton>
                <IconButton
                  style={{ backgroundColor: muiTheme.palette.error.main, width: 20, height: 20 }}
                  onClick={() => bookedChatResponse(bookedChat.id, false, bookedChat.supervisor?.id)}
                >
                  <CloseRounded htmlColor="#FFFFFF" />
                </IconButton>
              </ButtonsContainer>
            </BookedChatContainer>
          ))}
        </List>
      )}
      <Title>{translation.activeChats}</Title>
      <List>
        {active.length === 0 && <Empty>{translation.activeChatsNoEntry}</Empty>}
        {active.map((c) => (
          <ConversationCard
            key={c.id}
            conversation={c}
            goToConversation={(c) => goToChat(c as Chat)}
            paramsId={params.id!}
            badgeContent={unreadMessages.get(c.id) || 0}
            otherFullname={CustomerChat.getGuestFullname(c.guest)}
            shouldRenderMessageAck={false}
            lastMessageBody={getLastMessageBody(c)}
            timestamp={c.events?.length ? getDateInfo(c.events[c.events.length - 1].date) : ''}
          />
        ))}
      </List>
      <Title>{translation.closedChats}</Title>
      <List>
        {closed.length === 0 && <Empty>{translation.closedChatsNoEntry}</Empty>}
        {closed.map((c) => (
          <ConversationCard
            key={c.id}
            conversation={c}
            goToConversation={(c) => goToChat(c as Chat)}
            paramsId={params.id!}
            badgeContent={unreadMessages.get(c.id) || 0}
            otherFullname={CustomerChat.getGuestFullname(c.guest)}
            shouldRenderMessageAck={false}
            lastMessageBody={getLastMessageBody(c)}
            timestamp={c.events?.length ? getDateInfo(c.events[c.events.length - 1].date) : ''}
            withDelete={true}
            deleteChat={(chatId: string) => {
              setShowCloseDialog(chatId)
            }}
          />
        ))}
      </List>
      {showSpiedList && <Title>{translation.spiedChats}</Title>}
      {showSpiedList && (
        <List>
          {spied.length === 0 && <Empty>{translation.activeChatsNoEntry}</Empty>}
          {spied.map((c) => (
            <ConversationCard
              key={c.id}
              conversation={c}
              goToConversation={(c) => goToChat(c as Chat)}
              paramsId={params.id!}
              badgeContent={unreadMessages.get(c.id) || 0}
              otherFullname={CustomerChat.getGuestFullname(c.guest)}
              shouldRenderMessageAck={false}
              lastMessageBody={getLastMessageBody(c)}
              timestamp={c.events?.length ? getDateInfo(c.events[c.events.length - 1].date) : ''}
              withDelete={true}
              deleteChat={(chatId: string) => {
                setShowCloseDialog(chatId)
                dispatch({
                  type: WS_EMIT,
                  payload: {
                    name: 'core',
                    eventName: evs.stop_spy,
                    eventPayload: { chatId, userId: me?.id },
                    eventAckFunction: function () {}
                  }
                })
              }}
            />
          ))}
        </List>
      )}
      <Dialog open={!!showCloseDialog}>
        <DialogTitle>Rimuovere la chat?</DialogTitle>
        <DialogContent>Non avrai più modo di leggere questa conversazione.</DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              showCloseDialog && dispatch(removeChat(showCloseDialog) as any)
              setShowCloseDialog(null)
            }}
          >
            Si
          </Button>
          <Button onClick={() => setShowCloseDialog(null)}>No</Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}

export default ChatListActive

//region Style

const Container = styled(ScrollBar as any)<{ $withBooked: boolean; $withTransfer: boolean; $withSpied: boolean }>`
  display: grid;
  grid-row: content;
  grid-template-rows: ${({ $withBooked, $withTransfer, $withSpied }) =>
    css`
      ${$withTransfer && 'auto auto'} ${$withBooked && 'auto auto'} ${$withSpied && 'auto auto'} auto auto auto auto 1fr
    `};
  grid-row-gap: ${rem(0.7)};
`

const List = styled.div`
  display: grid;
  height: 99%;
  width: 100%;
  grid-template-rows: repeat(auto-fill, minmax(50px, 1fr));
  grid-template-columns: 1fr;
  place-content: start center;
`

const Empty = styled.div`
  width: 100%;
  font-size: ${rem(0.7)};
  padding: 0 ${rem(0.8)};
`

const Title = styled.div`
  font-size: ${rem(0.75)};
  font-weight: 600;
  color: ${({ theme }) => theme.palette.primary.main};
  padding: 0 ${rem(0.8)};
`

const BookedChatContainer = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  padding: ${rem(0.3)};
  grid-template-areas: 'avatar name buttons' 'avatar lastmessage buttons';
  grid-template-rows: 1fr 1fr;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: ${rem(0.5)};
  place-content: center stretch;
  padding: ${rem(0.5)};
  cursor: pointer;
`

const Name = styled.div`
  grid-area: name;
  font-size: ${rem(0.7)};
  align-self: end;
  height: 100%;
`

const DepartmentName = styled.div`
  grid-area: lastmessage;
  display: grid;
  grid-template-columns: 1fr;
  font-size: ${rem(0.65)};
  height: 100%;
  width: 100%;
  place-self: center start;
`

const ButtonsContainer = styled.div`
  grid-area: buttons;
  place-self: center end;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${rem(0.5)};
`

//endregion
