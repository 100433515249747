import { SIP } from '@bytewise/janus'
import { IFavorite } from '../database/types'

export type StatusesLabels =
  | 'ON_HOLD'
  | 'IN_CALL_IN'
  | 'NOT_FOUND'
  | 'IDLE'
  | 'BUSY_OUT'
  | 'IN_CALL_OUT'
  | 'IN_CALL'
  | 'DISCONNECTED'
  | 'BUSY_IN'

export enum Statuses {
  idle = 0,
  disconnected = 4
}

export interface Action {
  id?: number
  name: string
  entity: string
  description: string
}

export interface Address {
  id?: number
  value: string
  addressType: AddressType
}

export interface AddressBook {
  id: number
  name: string
  contacts: Contact[]
  services?: Service[] | number[]
}

export interface AddressType {
  id?: number
  name: string
  actions: string
}

export interface CallsChatsEvent {
  name: string
  type: string
  label: string
  description: string
}

export interface Company {
  id?: number
  name: string
  offices?: Office[] | number[]
}

export interface Contact {
  id?: number
  firstName: string
  secondName: string
  addresses: Address[]
  addressBooks?: AddressBook[]
}

export interface CRM {
  id?: number
  name: string
  baseurl: string
  callsChatsEvents?: CRMCallsChatsEvent[]
}

export interface CRMCallsChatsEvent {
  callsChatsEvent: CallsChatsEvent | string
  callType?: string
  path: string
}

export interface Customer {
  id?: number
  name: string
  deleted?: boolean
  services?: Service[] | number[]
  callsInQueue?: number
  callsInProgress?: number
}

export interface CustomizedMessage {
  id: number
  shortDescription: string
  fullText: string
  timeout: number
}

export interface Division {
  id?: number
  name: string
  company: Company | number
  office: Office | number
  users?: User[] | number[]
}

export interface Inbound {
  id?: number
  service: Service | number
  name: string
  externalNumbers?: string[]
  weight?: number
  deleted?: boolean
  crm?: CRM | number
  members?: InboundMember[] | number[]
  callsInQueue?: number
  callsInProgress?: number
}

export interface InboundCallInfo {
  customer?: string
  inbound?: string
  queue?: string
  service?: string
}

export interface OutboundCallInfo {
  customer?: string
  outbound?: string
  service?: string
}

interface InboundMember {
  queue: Queue | string
  deleted?: boolean
}

export type LanguageCode = 'it_IT' | 'en_EN'

export type TranslatedLabel = {
  [key in LanguageCode]: string
}

export interface Office {
  id?: number
  name: string
  companies?: Company[] | number[]
}

export interface Outbound {
  id: string
  prefix: number
  pbx: Pbx | number
  service?: Service | number | string
  customer?: string
  name: string
  externalNumbers?: string[]
  isRecordable?: boolean
  hidden?: boolean
  autoPause?: Pause | number
  provider: Provider | number
  deleted?: boolean
  crm?: CRM | number
  members?: OutboundMember[] | number[]
}

export interface OutboundMember {
  voip: Voip | string
  deleted?: boolean
}

export interface Queue {
  id?: string
  extension: string
  descr: string
  pbx: Pbx | number
  name: string
  autoAnswerTime?: number
  hidden?: boolean
  isServiceQueue?: boolean
  autoPause?: Pause | number
  strategy: string
  weight: string
  warningThreshold?: number
  deleted?: boolean
  members?: QueueMember[]
  callsInQueue?: number
  callsInProgress?: number
}

export interface QueueMember {
  voip: Voip | string
  pause?: Pause | number
  penalty: number
  deleted?: boolean
}

export interface Pause {
  busyOther: boolean
  callsEnabled: boolean
  calluid: null
  color: string
  cssClass: string
  hidden: boolean
  id: number
  isStatus: boolean
  label: string
  lastchange: string | null
  name: string
  pauseGroup: PauseGroup | null
  translations?: TranslatedLabel
}

export interface PauseGroup {
  id: number
  name: string
  translations?: TranslatedLabel
}

export interface Provider {
  id?: number
  pbx: Pbx | number
  name: string
  channels: number
  prefix: number
  isDefault?: boolean
}

export interface Pbx {
  id?: number
  name: string
  shortname?: string
  host: string
  channels?: number
}

export interface Role {
  id?: number
  name: string
  label: string
  hasVoip?: boolean
  hasService?: boolean
  isUnique?: boolean
  licenseType: string
  isAdmin?: boolean
  isSuperAdmin?: boolean
  numbersVisibility?: boolean
  actions?: Action[] | number[]
}

export interface Service {
  id?: number
  customer: Customer
  name: string
  deleted?: boolean
  addressBooks?: AddressBook[] | number[]
  callsInQueue?: number
  callsInProgress?: number
}

export interface Status {
  id: number
  isStatus: boolean
  lastchange: string
  name: StatusesLabels
  label?: string
  translations?: TranslatedLabel
}

export interface User {
  id: number
  username: string
  name: string
  surname: string
  operatorCode?: string
  email?: string
  language?: { code: LanguageCode; name: string }
  deleted?: boolean
  favorites: IFavorite[]
  externalNumber?: string
  canCall?: boolean
  provider?: Provider | number
  company?: Company | number
  office?: Office | number
  divisions?: Division[] | number[]
  userVoips: UserVoip[]
  state: Status | Pause
  userRoles?: UserRole[]
  outbounds?: Outbound[]
  departments: Pick<Department, 'id' | 'name'>[]
  theme: 'light' | 'dark'
  callRegistryStart: string
}

interface UserRole {
  role: Role | number
  services?: Service[] | number[]
  inbounds?: Inbound[] | number[]
  outbounds?: Outbound[] | string[]
}

export interface UserVoip {
  type: VoipType
  voip: Voip
  addressVisible?: boolean
  deleted?: boolean
}

export interface Voip {
  id?: string
  extension: number
  pbx: Pbx
  defaultUser?: string
  host: string
  client?: string
  deleted?: boolean
  status?: Status
  pause?: Pause
  state?: Status | Pause
  userid?: number
  password: string
  sip?: SIP
  port?: string
}

export interface VoipInfo {
  id?: string
  extension: number
  host: string
  password: string
  port: string
  sip?: SIP
  pbx: Pbx
}

export interface VoipType {
  id?: number
  name: string
  softvoip?: boolean
  defaultPenalty?: number
}

//region Messenger

export interface Conversation {
  id: string
  name?: string
  members: Member[]
  admins?: Member[]
  membersNumber?: number
  lastMessage: number
  messages: Message[]
  toDelete?: number
}

export interface Member {
  username: string
  name: string
  surname: string
  email: string
}
export interface Message {
  id: string
  conversation?: string
  sender: Member
  timestamp: number
  body: string
  acks: Ack[]
  type?: 'text' | 'media' | 'system'
  filepath?: string
  deleted: boolean
}

export type Ack = Member & {
  received: number
  read: number
}

export type SystemMessage = 'group-add' | 'group-leave' | 'group-remove'
//endregion

//region Chat

export interface OpeningTime {
  schedule: OpeningTimeSchedule
  extra: OpeningTimeExtra
}

export interface OpeningTimeRule {
  from: number | string
  to: number | string
  open?: boolean
}

export interface OpeningTimeSchedule {
  0?: OpeningTimeRule[] // dom
  1?: OpeningTimeRule[] // lun
  2?: OpeningTimeRule[] // mar
  3?: OpeningTimeRule[] // mer
  4?: OpeningTimeRule[] // gio
  5?: OpeningTimeRule[] // ven
  6?: OpeningTimeRule[] // sab
}

interface MonthlySchedule {
  1?: OpeningTimeRule[]
  2?: OpeningTimeRule[]
  3?: OpeningTimeRule[]
  4?: OpeningTimeRule[]
  5?: OpeningTimeRule[]
  6?: OpeningTimeRule[]
  7?: OpeningTimeRule[]
  8?: OpeningTimeRule[]
  9?: OpeningTimeRule[]
  10?: OpeningTimeRule[]
  11?: OpeningTimeRule[]
  12?: OpeningTimeRule[]
  13?: OpeningTimeRule[]
  14?: OpeningTimeRule[]
  15?: OpeningTimeRule[]
  16?: OpeningTimeRule[]
  17?: OpeningTimeRule[]
  18?: OpeningTimeRule[]
  19?: OpeningTimeRule[]
  20?: OpeningTimeRule[]
  21?: OpeningTimeRule[]
  22?: OpeningTimeRule[]
  23?: OpeningTimeRule[]
  24?: OpeningTimeRule[]
  25?: OpeningTimeRule[]
  26?: OpeningTimeRule[]
  27?: OpeningTimeRule[]
  28?: OpeningTimeRule[]
  29?: OpeningTimeRule[]
  30?: OpeningTimeRule[]
  31?: OpeningTimeRule[]
}

export interface OpeningTimeExtra {
  1?: MonthlySchedule
  2?: MonthlySchedule
  3?: MonthlySchedule
  4?: MonthlySchedule
  5?: MonthlySchedule
  6?: MonthlySchedule
  7?: MonthlySchedule
  8?: MonthlySchedule
  9?: MonthlySchedule
  10?: MonthlySchedule
  11?: MonthlySchedule
  12?: MonthlySchedule
}

export interface DepartmentMember {
  user: User | number
  penalty: number
  connectedClients?: string[]
}

export interface Department {
  id: number
  name: string
  isActive: boolean
  service: Service | number
  weight: number
  deleted?: boolean
  openingTimes?: OpeningTime
  backupPolicy?: string
  backupEmail?: string
  backupDepartmentId?: number
  members?: DepartmentMember[]
}

export type BrowserTypes =
  | 'Chrome'
  | 'Firefox'
  | 'Opera'
  | 'Yandex'
  | 'Safari'
  | 'Internet Explorer'
  | 'Edge'
  | 'Chromium'
  | 'IE'
  | 'Mobile Safari'
  | 'Edge Chromium'

export type DeviceTypes = 'mobile' | 'tablet' | 'browser' | 'TelegramInstance' | 'FacebookInstance' | 'WhatsappInstance'

export interface GuestInfo {
  client_info: {
    department: number
    ip: string
    url: string
    isProactive: boolean
    browser: BrowserTypes
    timestamp: string
    pageTitle: string
    device: DeviceTypes
  }
  name?: string
  surname?: string
  phone?: string
  email?: string
  [key: string]: any
}

export interface Guest {
  id: string
  info: GuestInfo
  chat?: string
  lastActivity?: number
}

export interface Chat {
  id: string
  guest: Guest
  startDate: string
  endDate?: string
  departments: Department[]
  operators?: User[] | number[]
  users?: User[] | number[]
  watchers?: User[] | number[]
  closed?: boolean
  transferee?: number
  transferer?: number
  typingOperators?: User[] | number[]
  events: ChatEvent[]
  supervisor?: { id: number; name: string; surname: string }
  spied?: boolean
}

export type UserForChat = Pick<User, 'id' | 'username' | 'name' | 'surname'>

export type ChatEventType = 'system' | 'message' | 'link'

export interface ChatEvent {
  date: string
  type: ChatEventType
  subtype?: string
  user: UserForChat | Guest | any
  text: string
  title?: string
  link?: string
  filename?: string
  file?: string
  tempID?: number
  chat?: string
  transferee?: User
}

//endregion
