import React from "react"
import {useSelector} from "react-redux"
import {getTranslation} from "../../../store/selectors"
import VideoCall from "../../pages/Messenger/videocall/VideoCall"
import {small} from "../../../constants"
import Conversation from "../../pages/Messenger/conversation/Conversation"
import styled from "styled-components"

export const MessengerContent: React.FC<{ id?: string, showChats: boolean, width: number }> = ({id, showChats, width}) => {
  const translation = useSelector(getTranslation)

  return (
    id === 'video-call' ? (
      <>

        <VideoCall hide={false} showChats={showChats}/>
      </>
    ) : <>
      {id && <Conversation/>}
      {!id && width > small.width * 1.5 && <NoSelected>{translation.noConversationSelected}</NoSelected>}
    </>
  )
}

// region style
const NoSelected = styled.div`
  display: grid;
  place-self: center;
  place-content: center;
  width: 100%;
  height: 100%;
`
// endregion