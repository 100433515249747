import ElectronProxy from '../../../api/electron'
import Counter from '../../common/Counter'
import CallInfoAttendees from '../../pages/Phone/Call/CallInfo/CallinfoAttendees'
import { rem } from '../../../helpers/style'
import {
  AddIcCallRounded,
  MicOffRounded as MuteIcon,
  MicRounded as UnmuteIcon,
  PhoneForwardedRounded as TransferIcon,
  PhoneInTalkRounded as UnpauseIcon,
  PhonePausedRounded as PauseIcon
} from '@mui/icons-material'
import { IconButton, SvgIcon } from '@mui/material'
import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialAction from '@mui/material/SpeedDialAction'
import SpeedDialIcon from '@mui/material/SpeedDialIcon'
import { useTheme } from '@mui/material/styles'
import { setVisibility } from '../../../store/applicationState/actions'
import { answer, hangup, hold, mute } from '../../../store/phone/actions'
import { CallStatus } from '../../../store/phone/types'
import { getBitrate, getCallInProgress, getTranslation } from '../../../store/selectors'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { ReactComponent as AnswerIcon } from '../../../images/call.svg'
import { ReactComponent as HangIcon } from '../../../images/hang.svg'

/** Componente che viene mostrato quando arriva una chiamata o quando si risponde a una chiamata */
export const InCall: React.FC = () => {
  const [open, setOpen] = useState(false)
  const translation = useSelector(getTranslation)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const theme = useTheme()

  const [incomingCall, setIncomingCall] = useState(false)
  const call = useSelector(getCallInProgress)
  const bitrate = useSelector(getBitrate)

  useEffect(() => {
    if (!call?.status) return
    setIncomingCall(call.status === CallStatus.incoming)
  }, [call?.status])

  const changePage = (page: 'add' | 'transfer' | null) => {
    ElectronProxy.minifyClient(false)
    dispatch(setVisibility(true) as any)
    navigate(`/phone/call/${page}`)
  }

  return (
    <Container>
      {/*chiamata in arrivo , info chiamata*/}
      <InfoContainer>
        <TitleContainer>
          <div style={{ placeSelf: 'center start' }}>
            {incomingCall ? translation.incomingCall : translation.conversationInProgressMini}
          </div>
          <div style={{ placeSelf: 'center start' }}>
            {!incomingCall && <Counter font={0.7} from={call?.startTime} />}
          </div>
          {bitrate && <BitRate style={{ placeSelf: 'center end' }}>{bitrate}</BitRate>}
        </TitleContainer>

        <CallerInfo>
          <CallInfoAttendees font={0.7} />
        </CallerInfo>
      </InfoContainer>
      {/*Bottoni per accettare/rifiutare la chiamata*/}
      <ButtonContainer>
        <IconButton
          onClick={() => {
            dispatch(hangup() as any)
          }}
          style={{ width: 30, height: 30, placeSelf: 'start center' }}
        >
          <SvgIcon style={{ fontSize: 'calc(var(--font-size) * 1.5)' }}>
            <HangIcon />
          </SvgIcon>
        </IconButton>

        {incomingCall ? (
          <IconButton
            style={{ width: 30, height: 30, placeSelf: 'center' }}
            onClick={() => {
              dispatch(answer() as any)
              setIncomingCall(false)
            }}
          >
            <SvgIcon style={{ fontSize: 'calc(var(--font-size) * 1.5)' }}>
              <AnswerIcon />
            </SvgIcon>
          </IconButton>
        ) : (
          <IconContainer place={'center'}>
            <SpeedDial
              ariaLabel="More Option"
              icon={<SpeedDialIcon style={{ width: 16, height: 16 }} />}
              onClose={() => setOpen(false)}
              onOpen={() => setOpen(true)}
              open={open}
              style={{ position: 'absolute', top: 0, right: 0 }}
              FabProps={{
                size: 'small',
                style: { width: 30, height: 30, boxShadow: 'none' }
              }}
              direction="left"
            >
              <SpeedDialAction
                key={'Pause'}
                icon={call?.status === CallStatus.hold ? <UnpauseIcon /> : <PauseIcon />}
                tooltipTitle={translation.hold}
                tooltipPlacement={'top'}
                FabProps={{
                  size: 'small',
                  style: {
                    width: 30,
                    height: 30,
                    margin: 5,
                    background: call?.status === CallStatus.hold ? theme.palette.text.disabled : ''
                  }
                }}
                onClick={() => dispatch(hold() as any)}
              />
              <SpeedDialAction
                key={'Add'}
                icon={<AddIcCallRounded />}
                tooltipTitle={translation.add}
                tooltipPlacement={'top'}
                FabProps={{ size: 'small', style: { width: 30, height: 30, margin: 5 } }}
                onClick={() => changePage('add')}
              />
              <SpeedDialAction
                key={'Transfer'}
                icon={<TransferIcon style={{ width: 'var(--font-size)', height: 'var(--font-size)' }} />}
                tooltipTitle={translation.transfer}
                tooltipPlacement={'top'}
                FabProps={{ size: 'small', style: { width: 30, height: 30, margin: 5 } }}
                onClick={() => changePage('transfer')}
              />
              <SpeedDialAction
                key={'Mute'}
                icon={call?.status === CallStatus.muted ? <UnmuteIcon /> : <MuteIcon />}
                tooltipTitle={translation.mute}
                tooltipPlacement={'top'}
                FabProps={{
                  size: 'small',
                  style: {
                    width: 30,
                    height: 30,
                    margin: 5,
                    background: call?.status === CallStatus.muted ? theme.palette.text.disabled : ''
                  }
                }}
                onClick={() => dispatch(mute() as any)}
              />
            </SpeedDial>
          </IconContainer>
        )}
      </ButtonContainer>
    </Container>
  )
}

//region STYLE
const Container = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
`
const InfoContainer = styled.div`
  display: grid;
  gap: 5px;
  grid-template-rows: 1.2fr 3fr;
`
const TitleContainer = styled.div`
  padding-left: 10px;
  padding-top: 10px;
  font-size: ${rem(0.75)};
  color: ${({ theme }) => theme.palette.primary.main};
  display: grid;
  grid-template-columns: max-content auto auto;
  gap: 5px;
`

const CallerInfo = styled.div`
  padding-left: 20px;
`

const ButtonContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  padding-top: 15px;
`

const IconContainer = styled.div<{ place: string }>`
  width: 30px;
  height: 30px;
  place-self: ${({ place }) => place};
  position: relative;
  transform: translateZ(0px);
  display: grid;
`

const BitRate = styled.div`
  font-size: calc(var(--font-size) * 0.6);
  place-self: center end;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
//endregion
