import { IconButton, SvgIcon } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

interface NumButtonProps {
  label: string
  miniLabel?: string
  onClick: () => void
}

export const NumButton: React.FC<NumButtonProps> = ({ label, onClick }) => {
  return (
    <Container>
      <IconButton onClick={onClick} size="small">
        <SvgIcon viewBox="0 0 50 50" style={{ fontSize: 'calc(var(--font-size) * 1.7)' }}>
          <Number x={'50%'} y={'65%'}>
            {label}
          </Number>
        </SvgIcon>
      </IconButton>
    </Container>
  )
}

// export const NumButton: React.FC<NumButtonProps> = ({ label, miniLabel, onClick }) => {
//   return (
//     <Container>
//       <IconButton onClick={onClick}>
//         <SvgIcon viewBox="0 0 50 50" style={{ fontSize: 'calc(var(--font-size) * 1.7)' }}>
//           <Number x={'50%'} y={30}>
//             {label}
//           </Number>
//           <Letters x={'50%'} y={47}>
//             {miniLabel}
//           </Letters>
//         </SvgIcon>
//       </IconButton>
//     </Container>
//   )
// }

const Container = styled.div`
  display: grid;
  place-content: center;
  width: fit-content;
`
const Number = styled.text`
  place-self: center;
  font-size: min(calc(var(--font-size) * 1.8), 40px);
  font-weight: normal;
  color: ${({ theme }) => theme.palette.text.primary};
  text-anchor: middle;
  font-family: 'Open Sans', sans-serif;
  vertical-align: central;
`
// const Letters = styled.text`
//   font-size: min(calc(var(--font-size) * 0.8), 14px);
//   min-height: 14px;
//   place-self: center;
//   white-space: nowrap;
//   width: fit-content;
//   color: ${({ theme }) => theme.palette.text.primary};
//   text-anchor: middle;
// `
