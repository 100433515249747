import {IRegistryCall} from '../api/database/types'
import {Call} from '../store/phone/types'

export const getRegistry = (): IRegistryCall[] => {
  return sessionStorage.getItem('@occlient/registry') ? JSON.parse(sessionStorage.getItem('@occlient/registry') as string) : []
}

export const removeCallFromRegistry = (callId: string) => {
  const registry = sessionStorage.getItem('@occlient/registry') ? JSON.parse(sessionStorage.getItem('@occlient/registry') as string) : []
  const callToRemoveIdx = registry.findIndex((call: Call) => call.id === callId)
  if (callToRemoveIdx < 0) return
  registry.splice(callToRemoveIdx, 1);
  sessionStorage.setItem('@occlient/registry', JSON.stringify(registry))
}

export const setRegistry = (payload: IRegistryCall[]) => {
  sessionStorage.setItem('@occlient/registry', JSON.stringify(payload))
}

export const appendRegistryCall = (payload: IRegistryCall) => {
  const registry = sessionStorage.getItem('@occlient/registry') ? JSON.parse(sessionStorage.getItem('@occlient/registry') as string) : []
  sessionStorage.setItem('@occlient/registry', JSON.stringify([...registry, payload]))
}

export const updateRegistryCall = (callId: string, callFieldsToUpdate: Partial<IRegistryCall>) => {
  const registry = sessionStorage.getItem('@occlient/registry') ? JSON.parse(sessionStorage.getItem('@occlient/registry') as string) : []
  const callToUpdateIdx = registry.findIndex((call: Call) => call.id === callId)
  if (callToUpdateIdx < 0) return
  const updatedCall = {...registry[callToUpdateIdx], ...callFieldsToUpdate}
  const updatedRegistry = [...registry]
  updatedRegistry[callToUpdateIdx] = updatedCall
  sessionStorage.setItem('@occlient/registry', JSON.stringify(updatedRegistry))
}