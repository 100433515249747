import { IFavorite } from '../../../../api/database/types'
import { rem } from '../../../../helpers/style'
import { CheckCircleOutlineRounded, EditRounded, GroupAddRounded, StarBorderRounded } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import { getDimensions, getMe, getTranslation, getUsers } from '../../../../store/selectors'
import React, { useCallback, useMemo, useState } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Title, Modal } from '../../../common'
import { Favorite } from './Favorite'
import { useRest } from '../../../../hooks'
import logger from '../../../../helpers/logger'
import { SET_ME } from '../../../../store/login/types'
import { formatFavorites } from '../../../../helpers'
import ScrollBar from 'react-perfect-scrollbar'
import AddFavorites from '../../../pages/Phone/Favorites/AddFavorites'

const Favorites: React.FC = () => {
  const [enableEdit, setEnableEdit] = useState(false)
  const [showAddFavorites, setShowAddFavorites] = useState(false)

  const translation = useSelector(getTranslation)
  const me = useSelector(getMe)
  const users = useSelector(getUsers)
  const { fontSize } = useSelector(getDimensions)
  const dispatch = useDispatch()
  const {
    put: reorder,
    remove,
    post: createFavorites
  } = useRest<Partial<IFavorite> | { favoritesIds: number[] }>('my-favorites', { lazy: true })
  const getListStyle = useCallback(
    () => ({
      background: 'inherit',
      width: '100%'
    }),
    []
  )

  const onDragEnd = useCallback(
    (result: any) => {
      if (!me.favorites) return
      // dropped outside the list
      if (!result.destination) {
        return
      }

      const favList = Array.from(me.favorites)
      const startIndex = result.source.index
      const endIndex = result.destination.index
      const [removed] = favList.splice(startIndex, 1)
      favList.splice(endIndex, 0, removed)
      reorder('', {
        favorites: favList.map((favorite, index) => {
          favorite.orderNumber = index + 1
          return favorite
        })
      } as any)
        .then((payload) => {
          dispatch({ type: SET_ME, payload })
        })
        .catch((e) => logger.error(e))
    },
    [me.favorites, reorder, dispatch]
  )

  const removeFavorite = (favoriteId?: number) => {
    if (!favoriteId) return
    remove(favoriteId)
      .then((payload) => {
        dispatch({ type: SET_ME, payload })
      })
      .catch((e) => logger.error(e))
  }

  const addFavorites = async (favoritesIds: number[]): Promise<void> => {
    setShowAddFavorites(false)
    createFavorites({ favoritesIds })
      .then((payload) => {
        dispatch({ type: SET_ME, payload })
      })
      .catch((e) => logger.error(e))
  }

  const favorites = useMemo(() => {
    return formatFavorites(me.favorites, users)
  }, [me.favorites, users])

  const getExcludedFromSelection = (): string[] => {
    return [...users.values()]
      .filter((user) => favorites.find((favorite) => favorite.favoriteId === user.id))
      .map((user) => user.username)
  }

  return (
    <Container>
      <TitleContainer>
        <Title label={translation.favorites} icon={<StarBorderRounded />} style={{ padding: '3px 5px' }} />
        <Modal closeModal={() => setShowAddFavorites(false)} isVisible={showAddFavorites} width="75v%" height="85%">
          <AddFavorites addFavorites={addFavorites} exclude={getExcludedFromSelection()} />
        </Modal>
        <IconButton onClick={() => setShowAddFavorites(true)}>
          <Tooltip title={translation.addToFavorites}>
            <GroupAddRounded />
          </Tooltip>
        </IconButton>
        <IconButton onClick={() => setEnableEdit(!enableEdit)} disabled={!favorites.length}>
          {enableEdit ? (
            <Tooltip title={translation?.tooltips?.apply}>
              <CheckCircleOutlineRounded />
            </Tooltip>
          ) : (
            <Tooltip title={translation?.tooltips?.edit}>
              <EditRounded />
            </Tooltip>
          )}
        </IconButton>
      </TitleContainer>
      <ScrollBar>
        <div style={{ paddingBottom: fontSize * 2 + 8, height: 'min-content' }}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" isDropDisabled={!enableEdit}>
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle()}>
                  {favorites
                    ?.filter((f) => f.firstName)
                    .sort((i, j) => i.orderNumber! - j.orderNumber!)
                    .map((f, index) => (
                      <Favorite
                        index={index}
                        favorite={f as IFavorite}
                        from={`/phone/favorites`}
                        key={index}
                        enableEdit={enableEdit}
                        removeFavorite={removeFavorite}
                      />
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </ScrollBar>
    </Container>
  )
}

export default Favorites

//region Style

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  overflow-x: hidden;
  grid-row-gap: 5px;
  padding-top: ${rem(0.5)};
`

const TitleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  padding-right: 3px;
`

//endregion
