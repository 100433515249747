import { User } from '../rest'
import { coreApi, coreUrl, host } from '../../configuration'
import isElectron from '../../helpers/isElectron'
import logger from '../../helpers/logger'
import log from '../../helpers/logger'
import { LoginMethod, LoginPayload } from '../../store/login/types'
import axios from 'axios'
import qs from 'query-string'

const electron = isElectron() ? window.require('electron') : null

/**
 * Dopo il redirect da parte di OC AUTH, viene preso il code dall'url presente nella barra del browser
 * e viene spedito di nuovo ad OC AUTH per ottenere un token da mandare al CORE
 * @param selectedLoginMethod
 * @param code
 *
 * @returns access_token { Promise<string> }
 */
export const getAuthToken = async (selectedLoginMethod: LoginMethod, code: string): Promise<string> => {
  //a partire dal codice ricevuto da auth faccio una POST per reperire il token validato del sistema di autenticazione
  const otherParams = qs.parse(selectedLoginMethod.token_url.split('?')[1])
  const body: any = {
    code,
    redirect_uri: `${host}/${window.occlientSettings.basePath || 'client'}`,
    ...otherParams
  }

  // Per l'autenticazione azure, è necessario includere il client secret solo nella versione desktop
  // Se non è già presente tra i parametri del token url, lo aggiungo
  if (electron && !body.client_secret) {
    body.client_secret = selectedLoginMethod.client_secret
  }

  try {
    const { data } = await axios.post(selectedLoginMethod.token_url, qs.stringify(body), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    return data.access_token
  } catch (e) {
    throw e
  }
}

/**
 * Con il token ottenuto da OC AUTH mandando il code di autorizzazione, si fa la richiesta al CORE
 * per ottenere un suo token
 * @param loginMethodId
 * @param payload
 */
export const coreLogin = async (loginMethodId: number, payload: any): Promise<LoginPayload> => {
  // eseguo il login sul core per ricevere token e user
  log.silly('Executing login against oc-core with method', loginMethodId)
  try {
    const { data } = await axios.post(`${coreUrl}/authentication/${loginMethodId}`, payload)
    return data.payload as LoginPayload
  } catch (e) {
    log.error(e)
    throw e
  }
}

/**
 * Ottiene un nuovo token mandando l'attuale token (ancora non scaduto)
 * @param token
 */
export const getNewToken = async (token: string) => {
  try {
    log.silly('Getting new token from core')
    const { data } = await axios.get(`${coreApi}/login/renew`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return data.payload as LoginPayload
  } catch (e) {
    log.error(e)
    throw e
  }
}

export const getPayloadByLoginMethod = (loginMethodId: number, token: string, others: Object = {}) => {
  let payload
  switch (loginMethodId) {
    default:
      payload = { ...others, token }
  }
  return payload
}

export const isTokenForUser = async (token: string, user: User): Promise<boolean> => {
  try {
    const response = await getNewToken(token)
    return response.user.id === user.id
  } catch (e) {
    logger.error(e)
    return false
  }
}
