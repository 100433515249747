import { FormControl, InputLabel, MenuItem, Select as MuiSelect } from '@mui/material'
import React, { useEffect, useState } from 'react'

type SelectProps<O> = {
  selected?: O | null
  options: O[]
  getLabel?: (arg0: O) => string
  getValue?: (arg0: O) => string | number
  required?: boolean
  onChange: (selected: O | null) => any
  disabled?: boolean
  style?: React.CSSProperties
  label?: string
}

export function Select<O = string | number>(props: SelectProps<O>) {
  const { options, disabled, style = { width: '100%' }, label } = props
  const [selected, setSelected] = useState<O | ''>('')

  useEffect(() => {
    setSelected(props.selected || '')
  }, [props.selected, options])

  const getLabel = (opt: O) => {
    if (typeof opt === 'string' || typeof opt === 'number') {
      return opt
    } else {
      if (!props.getLabel) throw new Error('Must define getLabel function if options are not strings or numbers')
      return props.getLabel(opt)
    }
  }

  const getValue = (opt: O) => {
    if (typeof opt === 'string' || typeof opt === 'number') {
      return opt
    } else {
      if (!props.getValue) throw new Error('Must define getValue function if options are not strings or numbers')
      return props.getValue(opt)
    }
  }

  return (
    <FormControl style={style} required={props.required}>
      {label && <InputLabel>{label}</InputLabel>}
      <MuiSelect
        fullWidth
        value={selected ? getValue(selected) : ''}
        disabled={typeof disabled === 'boolean' ? disabled : options.length === 0}
        variant="standard"
        style={defaultSelectStyle}
      >
        {!props.required && (
          <MenuItem
            value=""
            onClick={() => {
              setSelected('')
              props.onChange(null)
            }}
            style={{ ...defaultMenuItemStyle, color: 'transparent' }}
          >
            None
          </MenuItem>
        )}
        {options.map((o, i) => (
          <MenuItem
            key={i}
            value={getValue(o)}
            onClick={() => {
              setSelected(o)
              props.onChange(o)
            }}
            style={defaultMenuItemStyle}
          >
            {getLabel(o)}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  )
}

//region Style

const defaultSelectStyle: React.CSSProperties = {
  width: '100%',
  maxWidth: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}

const defaultMenuItemStyle: React.CSSProperties = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}
//endregion
