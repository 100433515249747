import ElectronProxy from '../../../api/electron'
import {User} from '../../../api/rest'
import StatusWithCounter from '../../common/StatusWithCounter'
import Notifications from '../../template/TopBar/Notifications'
import {mini, smaller} from '../../../constants/breakpoints'
import {
  CloseRounded,
  FullscreenExitRounded,
  MenuOpenRounded,
  MenuRounded,
  ReportProblemOutlined,
  SearchRounded
} from '@mui/icons-material'
import {
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grow,
  IconButton,
  Tooltip
} from '@mui/material'
import {Theme} from '@mui/material/styles/createTheme'
import {useTheme} from '@mui/material/styles'
import {setVisibility} from '../../../store/applicationState/actions'
import {
  getCallInProgress,
  getCoreConnected,
  getDimensions,
  getLostCalls,
  getMe,
  getPath,
  getReconnecting,
  getStatus,
  getTranslation,
  getUnreadEvents,
  getUnreadMessages
} from '../../../store/selectors'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'
import {AvatarWithStatus, Modal} from '../../common'
import CallInProgress from '../CallInProgress/CallInProgress'
import ProfileView from '../Profile/Profile'
import Search from '../Search/Search'

type TopBarProps = {
  showMenu: boolean
  setShowMenu: (arg0: boolean) => void
}

const TopBar: React.FC<TopBarProps> = ({showMenu, setShowMenu}) => {
  const [showProfileModal, setShowProfileModal] = useState(false)
  const [showSearch, setShowSearch] = useState(false)
  const [refreshDialogOpen, setRefreshDialogOpen] = useState(false)

  const me = useSelector(getMe)
  const {current} = useSelector(getPath)
  const {width} = useSelector(getDimensions)
  const callInProgress = useSelector(getCallInProgress)
  const lostCalls = useSelector(getLostCalls)
  const unreadMessages = useSelector(getUnreadMessages)
  const unreadEvents = useSelector(getUnreadEvents)
  const status = useSelector(getStatus)
  const reconnecting = useSelector(getReconnecting)
  const coreConnected = useSelector(getCoreConnected)
  const translation = useSelector(getTranslation)

  const muiTheme = useTheme<Theme>()

  const [user, setUser] = useState<User>(me)
  useEffect(() => setUser({...me, state: status}), [me, status])

  const dispatch = useDispatch()

  const close = () => {
    setShowSearch(false)
  }

  const showCallIcon =
    (callInProgress && (!current.includes('call') || current.includes('/settings')) && width <= smaller.width * 2) ||
    ((current.includes('/messenger') || current.includes('/customer-chat')) && width <= smaller.width * 4 - 120)

  return (
    <>
      <div className="occlient-drag-handle" style={topBarContainerStyle(!!callInProgress, width, muiTheme)}>
        <ShowMenuButton onClick={() => setShowMenu(!showMenu)}>
          <IconButton>
            {showMenu ? (
              <MenuOpenRounded/>
            ) : (
              <Badge
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                badgeContent={lostCalls || unreadMessages || unreadEvents}
                overlap="circular"
                color="secondary"
                variant="dot"
              >
                <MenuRounded/>
              </Badge>
            )}
          </IconButton>
        </ShowMenuButton>
        <Profile>
          <AvatarWithStatus
            onClick={() => setShowProfileModal(true)}
            user={user}
            statusForcedColor={reconnecting ? '#000000' : undefined}
          />
        </Profile>
        <StatusWithCounter style={{gridArea: 'status', placeSelf: 'center start'}}/>
        {!reconnecting && <Notifications/>}
        {showCallIcon && <CallInProgress/>}
        <div style={{gridArea: 'buttons', placeSelf: 'center'}}>
          {!coreConnected && <Tooltip title={translation.coreDisconnected}>
            <IconButton
              size="small"
              onClick={() => setRefreshDialogOpen(true)}
            >
              <ReportProblemOutlined sx={{color: 'error.main'}}/>
            </IconButton>
          </Tooltip>}
          {
            (!callInProgress || current.includes('call')) && (
              <IconButton size="small" onClick={() => setShowSearch(!showSearch)}>
                {showSearch ? (
                  <Tooltip title={translation?.tooltips?.close} disableInteractive>
                    <CloseRounded/>
                  </Tooltip>
                ) : (
                  <Tooltip title={translation?.tooltips?.search} disableInteractive>
                    <SearchRounded/>
                  </Tooltip>
                )}
              </IconButton>
            )}
          <IconButton
            size="small"
            onClick={() => {
              ElectronProxy.minifyClient(true)
              dispatch(setVisibility(false) as any)
              const width = window.occlient.settings.minified?.width || mini.width
              const browserHeadingHeight = window.outerHeight - window.innerHeight
              const height = (window.occlient.settings.minified?.height || mini.height) + browserHeadingHeight
              window.resizeTo(width, height)
            }
            }
          >
            <Tooltip title={translation?.tooltips?.minimize} disableInteractive>
              <FullscreenExitRounded/>
            </Tooltip>
          </IconButton>
        </div>
        <Modal closeModal={() => setShowProfileModal(false)} isVisible={showProfileModal} style={{maxWidth: 400}}>
          <ProfileView close={() => setShowProfileModal(false)}/>
        </Modal>
      </div>
      <Grow in={showSearch} mountOnEnter={true} unmountOnExit={true}>
        <Search close={close}/>
      </Grow>
      <Dialog
        open={refreshDialogOpen}
        onClose={() => setRefreshDialogOpen(false)}
      >
        <DialogTitle>
          {translation.pageReload}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {translation.pageReloadText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRefreshDialogOpen(false)}>{translation.no}</Button>
          <Button onClick={() => window.location.reload()} autoFocus>
            {translation.yes}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default TopBar

//region Style

const topBarContainerStyle = (callInProgress: boolean, globalWidth: number, theme: Theme) => ({
  gridArea: 'header',
  display: 'grid',
  gridTemplateColumns: `40px 42px auto 1fr ${callInProgress ? 'auto' : ''} auto`,
  gridTemplateAreas: `'menubutton profile status notifications ${callInProgress ? 'call-in-progress' : ''} buttons'`,
  background: theme.palette.background.default,
  alignContent: 'center',
  height: 'var(--topbar-height)',
  width: '100%',
  paddingRight: 'calc(var(--font-size) * 0.5)'
})

const ShowMenuButton = styled.div`
    grid-area: menubutton;
    place-self: center;
`

const Profile = styled.div`
    grid-area: profile;
    align-self: center;
    justify-self: center;
`

//endregion
