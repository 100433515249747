import { TurnServer } from '@bytewise/janus'
import { StatusChangeAction } from '../phone/types'
import { WsActionType } from '../sockets/types'
import { RootState } from '../types'
import { AnyAction } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { LanguageCode, User } from '../../api/rest'

export const LOGIN = 'LOGIN'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGOUT = 'LOGOUT'
export const SET_LOGIN_METHODS = 'SET_LOGIN_METHODS'
export const SELECT_LOGIN_METHOD = 'SELECT_LOGIN_METHOD'
export const SET_TOKEN = 'SET_TOKEN'
export const TOKEN_RENEWED = 'TOKEN_RENEWED'
export const SET_ME = 'SET_ME'
export const RENEW_TOKEN_TIMEOUT = 'RENEW_TOKEN_TIMEOUT'

export interface LoginState {
  user: User | null
  token: string | null
  error: string
  methods: LoginMethod[]
  selectedMethod: LoginMethod | null
  isAuthenticated: boolean
  me: User // oggetto più completo con anche lo state
  otpRequired: boolean
  renewTimeout: null | number
}

//region Types

export interface LoggedUser {
  id?: number
  username?: string
  name?: string
  surname?: string
  voips?: string[]
}

export interface LoginMethod {
  id: number
  name: string
  authorize_url: string
  token_url: string
  client_id: string
  client_secret: string
  login_url: string
  type?: string
}

export interface LoginPayload {
  user: User
  token: string
  config: { turn: TurnServer; peers: string[] }
  language: { code: LanguageCode; name: string }
  theme: 'light' | 'dark'
}

//endregion

//region Actions

export interface LoginAction {
  type: typeof LOGIN
  payload: LoginPayload
}

export interface SetTokenAction {
  type: typeof SET_TOKEN
  payload: string
}

export interface LogoutAction {
  type: typeof LOGOUT
}

export interface LoginErrorAction {
  type: typeof LOGIN_ERROR
  payload: string
}

export interface SelectLoginMethodAction {
  type: typeof SELECT_LOGIN_METHOD
  payload: LoginMethod | null
}

export interface SetLoginMethodsAction {
  type: typeof SET_LOGIN_METHODS
  payload: { methods: LoginMethod[]; otp: boolean }
}

export interface SetRenewTimeout {
  type: typeof RENEW_TOKEN_TIMEOUT
  payload: number | null
}

export interface RenewTokenAction {
  type: typeof TOKEN_RENEWED
  payload: LoginPayload
}

export interface SetMe {
  type: typeof SET_ME
  payload: User
}

export type CanDispatchAnyAction = ThunkAction<void, RootState, null, AnyAction>

export type LoginActionTypes =
  | LoginAction
  | LogoutAction
  | SetTokenAction
  | LoginErrorAction
  | SelectLoginMethodAction
  | SetLoginMethodsAction
  | SetMe
  | StatusChangeAction
  | WsActionType
  | RenewTokenAction
  | SetRenewTimeout

//endregion
