import { IFavorite } from '../../../../api/database/types'
import { rem } from '../../../../helpers/style'
import { CheckCircleOutlineRounded, EditRounded, GroupAddRounded, StarBorderRounded } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import { getDimensions, getMe, getTranslation, getUsers } from '../../../../store/selectors'
import React, { useCallback, useMemo, useState } from 'react'
import { DndContext, DragEndEvent } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Title, Modal } from '../../../common'
import { Favorite } from './Favorite'
import { useRest } from '../../../../hooks'
import logger from '../../../../helpers/logger'
import { SET_ME } from '../../../../store/login/types'
import { formatFavorites } from '../../../../helpers'
import ScrollBar from 'react-perfect-scrollbar'
import AddFavorites from '../../../pages/Phone/Favorites/AddFavorites'

const Favorites: React.FC = () => {
  const [enableEdit, setEnableEdit] = useState(false)
  const [showAddFavorites, setShowAddFavorites] = useState(false)
  const [localFavorites, setLocalFavorites] = useState<IFavorite[]>([])

  const translation = useSelector(getTranslation)
  const me = useSelector(getMe)
  const users = useSelector(getUsers)
  const { fontSize } = useSelector(getDimensions)
  const dispatch = useDispatch()
  const {
    put: reorder,
    remove,
    post: createFavorites
  } = useRest<Partial<IFavorite> | { favoritesIds: number[] }>('my-favorites', { lazy: true })
  const getListStyle = useCallback(
    () => ({
      background: 'inherit',
      width: '100%'
    }),
    []
  )

  const sortedFavorites = useMemo(() => {
    const favoritesToUse = localFavorites.length > 0 ? localFavorites : formatFavorites(me.favorites, users)
    return favoritesToUse.filter((f) => f.firstName).sort((a, b) => (a.orderNumber || 0) - (b.orderNumber || 0))
  }, [localFavorites, me.favorites, users])

  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event
      if (!over || active.id === over.id) return

      const oldIndex = sortedFavorites.findIndex((f) => String(f.favoriteId) === active.id)
      const newIndex = sortedFavorites.findIndex((f) => String(f.favoriteId) === over.id)

      if (oldIndex === -1 || newIndex === -1) return

      // Update local state for smooth UI
      const newFavoritesOrder = arrayMove(sortedFavorites, oldIndex, newIndex)
      const updatedFavorites = newFavoritesOrder.map((favorite, index) => ({
        ...favorite,
        orderNumber: index + 1
      }))

      setLocalFavorites(updatedFavorites)

      reorder('', { favorites: updatedFavorites } as any)
        .then((payload) => {
          dispatch({ type: SET_ME, payload })
          setLocalFavorites([])
        })
        .catch((e) => logger.error(e))
    },
    [sortedFavorites, reorder, dispatch]
  )

  const removeFavorite = useCallback(
    (favoriteId?: number) => {
      if (!favoriteId) return
      const updatedFavorites = sortedFavorites.filter((f) => f.favoriteId !== favoriteId)
      setLocalFavorites(updatedFavorites)

      remove(favoriteId)
        .then((payload) => {
          dispatch({ type: SET_ME, payload })
          setLocalFavorites([])
        })
        .catch((e) => {
          logger.error(e)
          setLocalFavorites([])
        })
    },
    [sortedFavorites, remove, dispatch]
  )

  const addFavorites = useCallback(async (favoritesIds: number[]): Promise<void> => {
    setShowAddFavorites(false)
    createFavorites({ favoritesIds })
      .then((payload) => {
        dispatch({ type: SET_ME, payload })
      })
      .catch((e) => logger.error(e))
  }, [createFavorites, dispatch])


  const getExcludedFromSelection = useCallback((): string[] => {
    return [...users.values()]
      .filter((user) => sortedFavorites.find((favorite) => favorite.favoriteId === user.id))
      .map((user) => user.username)
  }, [users, sortedFavorites])

  return (
    <Container>
      <TitleContainer>
        <Title label={translation.favorites} icon={<StarBorderRounded />} style={{ padding: '3px 5px' }} />
        <Modal closeModal={() => setShowAddFavorites(false)} isVisible={showAddFavorites} width="75v%" height="85%">
          <AddFavorites addFavorites={addFavorites} exclude={getExcludedFromSelection()} />
        </Modal>
        <IconButton onClick={() => setShowAddFavorites(true)}>
          <Tooltip title={translation.addToFavorites}>
            <GroupAddRounded />
          </Tooltip>
        </IconButton>
        <IconButton onClick={() => setEnableEdit(!enableEdit)} disabled={!sortedFavorites.length}>
          {enableEdit ? (
            <Tooltip title={translation?.tooltips?.apply}>
              <CheckCircleOutlineRounded />
            </Tooltip>
          ) : (
            <Tooltip title={translation?.tooltips?.edit}>
              <EditRounded />
            </Tooltip>
          )}
        </IconButton>
      </TitleContainer>
      <ScrollBar>
        <div style={{ paddingBottom: fontSize * 2 + 8, height: 'min-content' }}>
          <DndContext onDragEnd={onDragEnd}>
            <SortableContext
              items={sortedFavorites.map((f) => String(f.favoriteId))}
              strategy={verticalListSortingStrategy}>
              <div style={getListStyle()}>
                {sortedFavorites.map((f, index) => (
                  <Favorite
                    index={index}
                    favorite={f as IFavorite}
                    from={`/phone/favorites`}
                    key={String(f.favoriteId)}
                    enableEdit={enableEdit}
                    removeFavorite={removeFavorite}
                  />
                ))}
              </div>
            </SortableContext>
          </DndContext>
        </div>
      </ScrollBar>
    </Container>
  )
}

export default Favorites

//region Style

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  overflow-x: hidden;
  grid-row-gap: 5px;
  padding-top: ${rem(0.5)};
`

const TitleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  padding-right: 3px;
`

//endregion
