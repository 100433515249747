import {Messenger} from '../../../../api'
import {rem} from '../../../../helpers/style'
import {SendRounded, SentimentSatisfiedAltRounded} from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import {IconButton, Popover, Tooltip} from '@mui/material'
import {TextareaAutosize} from '@mui/base'
import {useTheme} from '@mui/material/styles'
import {addMessage, changeRoute, newConversation, readMessagesForConversation} from '../../../../store/actions'
import {getConversations, getDimensions, getMe, getPath, getTheme, getTranslation} from '../../../../store/selectors'
import Picker, {EmojiStyle, Theme} from 'emoji-picker-react'
import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation, useMatch} from 'react-router'
import styled from 'styled-components'
import {Conversation, Message} from '../../../../api/rest'
import ConversationTopBar from './ConversationTopBar'
import Messages from './Messages'

const ConversationPage: React.FC = () => {
  const [conversation, setConversation] = useState<Conversation | null>(null)
  const [message, setMessage] = useState('')
  const [showEmoji, setShowEmoji] = useState(false)
  const [noMoreMessages, setNoMoreMessages] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const conversations = useSelector(getConversations)
  const me = useSelector(getMe)
  const {current} = useSelector(getPath)
  const theme = useSelector(getTheme)
  const {height, width} = useSelector(getDimensions)
  const translation = useSelector(getTranslation)

  const dispatch = useDispatch()

  const muiTheme = useTheme()

  const {params} = useMatch('/messenger/:id')!
  const {state} = useLocation()

  // Gestione cambiamento schermata conversazioni
  useEffect(() => {
    if (!params.id) {
      dispatch(changeRoute({current: '/messenger', previous: current}))
      return
    }
    if (params.id === 'new') {
      if (!state || !state.otherMember) {
        dispatch(changeRoute({current: '/messenger', previous: current}))
        return
      }
      setConversation({
        id: '',
        members: [Messenger.getMemberFromUser(me), Messenger.getMemberFromUser(state.otherMember)],
        messages: [],
        lastMessage: 0
      })
      // non posso salvare /new altrimenti sul ricaricamento perderebbe lo state e non saprebbe
      // cosa renderizzare
    } else {
      const conv = conversations.get(params.id)
      // TODO: cercare prima di recuperarla dal server poi gestire errore not found
      if (!conv) return
      setConversation(conv)
      dispatch(readMessagesForConversation(conv.id))
      //TODO: gestire notfound
    }
    setNoMoreMessages(false)
  }, [params, conversations, current, dispatch, me, state])

  // Gestione nuovi messaggi
  useEffect(() => {
    if (!params.id) {
      dispatch(changeRoute({current: '/messenger'}))
      return
    }
    if (params.id === 'new') {
      // Controllo se mi ha scritta la persona con cui stavo per iniziare una conversazione
      const conv = Messenger.getConversationByMembers([...conversations.values()], conversation?.members)
      if (conv) {
        setConversation(conv)
        dispatch(changeRoute({current: `/messenger/${conv.id}`, previous: current}))
        return
      }
    } else {
      // Altrimenti risetto la conversazione con quella che trovo nello state per vedere i nuovi messaggi
      const conv = conversations.get(params.id)
      if (!conv) return
      setConversation({...conv})
    }
  }, [conversations, conversation?.members, current, dispatch, params.id])

  const sendMessage = useCallback(() => {
    if (!message) return
    setShowEmoji(false)
    // se la conversazione non è nuova
    if (conversation?.id) {
      const payload = {
        conversation: conversation?.id,
        body: message,
        user: Messenger.getMemberFromUser(me)
      }
      Messenger.sendMessage(payload, (feedback: { message: Message }) => {
        dispatch(addMessage(feedback.message))
        setMessage('')
        const messagesBox = document.getElementById('occlient-messenger-messages')
        if (messagesBox) messagesBox.scrollTop = messagesBox.scrollHeight
      })
    } else {
      // altrimenti al posto dell'id va mandato l'array di membri
      const payload = {
        members: conversation?.members,
        body: message,
        user: Messenger.getMemberFromUser(me)
      }
      Messenger.sendMessage(payload, (feedback: { conversation: Conversation & { message: Message } }) => {
        feedback.conversation.messages = [feedback.conversation.message]
        dispatch(newConversation(feedback.conversation))
        dispatch(changeRoute({current: `/messenger/${feedback.conversation.id}`}))
        setMessage('')
        const messagesBox = document.getElementById('occlient-messenger-messages')
        if (messagesBox) messagesBox.scrollTop = messagesBox.scrollHeight
      })
    }
  }, [message, conversation?.id, conversation?.members, dispatch, me])

  const handleTextareaChange = useCallback(
    (ev: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (ev.key === 'Enter' && !ev.shiftKey) {
        ev.preventDefault()
        sendMessage()
      }
    },
    [sendMessage]
  )

  return (
    <Container>
      {conversation && <ConversationTopBar conversation={conversation}/>}
      {conversation && (
        <Messages conversation={conversation} noMoreMessages={noMoreMessages} setNoMoreMessages={setNoMoreMessages}
                  setShowEmoji={setShowEmoji}/>
      )}
      <BottomBar>
        <InputContainer>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              sendMessage()
            }}
          >
            <Input
              maxRows={5}
              value={message}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                setMessage(e.target.value)
              }}
              onKeyDown={handleTextareaChange}
              autoFocus={true}
            />
          </form>
          {/* TODO: quando il server sarà pronto per l'upload di allegati, scommentare*/}
          {/*<IconButton size="small" style={{ width: 20, height: 20, placeSelf: 'center' }}>*/}
          {/*  <AttachFileRounded />*/}
          {/*</IconButton>*/}
          <div>
            <Tooltip title={translation.emoji} placement={'top'}>
              <IconButton
                size="small"
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  setShowEmoji(!showEmoji)
                  setAnchorEl(e.currentTarget)
                }}
                style={{width: 30, height: 30, placeSelf: 'center'}}
              >
                {!showEmoji ? <SentimentSatisfiedAltRounded/> : <CloseIcon/>}
              </IconButton>
            </Tooltip>
            <Popover anchorEl={anchorEl} anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                     open={showEmoji} onClose={() => {
              setShowEmoji(false)
              setAnchorEl(null)
            }} transformOrigin={{horizontal: 'right', vertical: 'bottom'}} sx={{overflow: 'hidden', opacity: 0.9}} slotProps={{paper: {sx: {height: height / 2, maxWidth: width-100}}}}>
              <Picker
                onEmojiClick={(data) => setMessage(message + data.emoji)}
                emojiStyle={EmojiStyle.NATIVE}
                width={'100%'}
                height={'99%'}
                theme={theme as Theme}
                previewConfig={{showPreview: false}}
              />
            </Popover>
          </div>
        </InputContainer>
        <Tooltip title={translation.send} placement={'top'}>
          <IconButton
            size="small"
            disabled={!message}
            color="primary"
            onClick={sendMessage}
            style={{background: !message ? muiTheme.palette.action.disabled : muiTheme.palette.primary.main, width: '30px', height: '30px'}}
          >
            <SendRounded htmlColor="#ffffff"/>
          </IconButton>
        </Tooltip>
      </BottomBar>
    </Container>
  )
}

export default ConversationPage

//region Style

const Container = styled.div`
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr;
  width: 100%;
  height: 100%;
  place-content: start center;
`

const BottomBar = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 10px;
  padding: 10px;
`

const InputContainer = styled.div`
  display: grid;
  grid-template-columns: auto 30px;
  border-radius: 20px;
  background: ${({theme}) => (theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.background.default)};
  padding: 0 10px;
`

const Input = styled(TextareaAutosize)`
  resize: none;
  background: transparent;
  border: none;
  outline: none;
  color: ${({theme}) => theme.palette.text.primary};
  font-family: 'Open Sans', sans-serif;
  font-size: ${rem(0.7)};
  width: 100%;
  height: 100%;
  padding-top: 5px;

  &:focus {
    outline: none;
  }
`

//endregion
