import isElectron from '../../../helpers/isElectron'
import {rem} from '../../../helpers/style'
import {CancelRounded, InfoRounded, LogoutRounded, SettingsRounded} from '@mui/icons-material'
import {Link as MuiLink} from '@mui/material'
import {handleLogout} from '../../../store/login/actions'
import {getDimensions, getMe, getStatus, getTranslation} from '../../../store/selectors'
import {DateTime} from 'luxon'
import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import styled, {DefaultTheme} from 'styled-components'
import {AvatarWithStatus} from '../../common'
import StateSelect from '../../common/StateSelect'
import withAuthentication from '../../hoc/withAuthentication'

type ProfileModalProps = {
  close: Function
}
const ProfileModal: React.FC<ProfileModalProps> = ({ close }) => {
  const user = useSelector(getMe)
  const translation = useSelector(getTranslation)
  const status = useSelector(getStatus)
  const { fontSize } = useSelector(getDimensions)

  const dispatch = useDispatch()

  /**
   * Funzione che restituisce la stringa ben formattata sull'informazione del
   * last status change
   * @param ts {string | null} timestamp
   */
  const getTimestampString = (ts: string | null) => {
    if (!ts) return ''
    const lastChange = DateTime.fromSQL(ts)
    const from = translation.fromTimestamp
    const of = translation.of
    const HHmm = lastChange.toLocaleString(DateTime.TIME_SIMPLE)
    const formattedDate = lastChange.toLocaleString(DateTime.DATE_SHORT)
    return `${from} ${HHmm} ${of} ${formattedDate}`
  }

  return (
    <ProfileContainer>
      <Profile>
        <AvatarWithStatus user={user} avatarSize="50px" statusSize="18px" fontSize="20px" />
        <UserInfo>
          {user.surname} {user.name}
        </UserInfo>
      </Profile>
      <StateSelect style={{ marginTop: rem(1.1), maxWidth: 250, placeSelf: 'center' }} />
      <Timestamp fontSize={fontSize}>{getTimestampString(status.lastchange)}</Timestamp>
      <Settings>
        <SettingsRow onClick={() => close()} to="/settings">
          <SettingsRounded width="20px" height="20px" />
          <MuiLink component="span" style={{ placeSelf: 'center' }}>
            {translation.settings}
          </MuiLink>
        </SettingsRow>
        <SettingsRow onClick={() => close()} to="/info">
          <InfoRounded width="20px" height="20px" />
          <MuiLink component="span" style={{ placeSelf: 'center' }}>
            {translation.info}
          </MuiLink>
        </SettingsRow>
        {window.occlient?.settings.showLogoutButtonOption && (
          <SettingsRow
            onClick={() => {
              dispatch(handleLogout())
              close()
            }}
            to="/login"
          >
            <LogoutRounded width="20px" height="20px" />
            <MuiLink component="span" style={{ placeSelf: 'center' }}>
              {translation.logout}
            </MuiLink>
          </SettingsRow>
        )}
        {!isElectron() && window.occlient.settings.showCloseOption && (
          <SettingsRow
            onClick={() => {
              window.occlient.unmount()
            }}
            to="/login"
          >
            <CancelRounded width="20px" height="20px" />
            <MuiLink component="span" style={{ placeSelf: 'center' }}>
              {translation.close}
            </MuiLink>
          </SettingsRow>
        )}
      </Settings>
    </ProfileContainer>
  )
}

export default withAuthentication(ProfileModal)

//region Style

const ProfileContainer = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 25% 60px auto 25%;
  grid-template-areas: 'profile' 'state' 'timestamp' 'settings';
  padding: 10px 5px;
  grid-row-gap: ${rem(0.3)};
`

const Profile = styled.div`
  display: grid;
  place-content: center;
  grid-template-rows: 0.6fr 0.4fr;
  grid-row-gap: 10px;
`

const UserInfo = styled.div<{ theme: DefaultTheme }>`
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
  color: ${({ theme }) => theme.palette.text?.primary};
`

const Settings = styled.div<{ theme: DefaultTheme }>`
  grid-area: settings;
  display: grid;
  grid-template-rows: auto;
  row-gap: 10px;
  place-self: center start;
  place-content: center start;
  margin-left: 15px;
  font-size: ${rem(0.8)};
  & svg {
    fill: ${({ theme }) => theme.palette.primary?.main};
  }
`

const SettingsRow = styled(Link)`
  width: 100%;
  display: grid;
  grid-template-columns: [icon] 30px [label] auto;
  column-gap: 10px;
  text-decoration: none;
  place-content: center start;

  &:hover {
    color: inherit;
  }
`

const Timestamp = styled.div<{ fontSize: number }>`
  color: ${({ theme }) => theme.palette.text?.secondary};
  font-size: ${({ fontSize }) => rem(0.75, fontSize)};
  text-align: center;
  width: 100%;
  grid-area: timestamp;
  align-self: start;
  justify-self: start;
`

//endregion
