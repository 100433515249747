import { NumPadButton } from '../../common'
import React from 'react'
import { Route, Routes } from 'react-router-dom'

import styled from 'styled-components'
import withAuthentication from '../../hoc/withAuthentication'
import AddressBook from './AddressBook/AddressBookEntryPoint'
import Call from './Call/Call'
import IncomingCall from './Call/IncomingCall'
import TransferringCall from './Call/TransferringCall'
import ContactInfo from './Contact/ContactEntryPoint'
import Favorites from './Favorites/FavoritesEntryPoint'
import Home from './Home/HomeEntryPoint'
import Keypad from './Keypad/KeypadEntryPoint'
import Registry from './Registry/RegistryEntryPoint'

const Phone: React.FC = () => {
  return (
    <Container id={'bytewise-phone'} tabIndex={0}>
      <Routes>
        <Route path={`address-book/:id?`} element={<AddressBook/>} />
        <Route path={`address-book/:type/:id/:from?`} element={<ContactInfo/>} />
        <Route path={`keypad/:number?`} element={<Keypad/>} />
        <Route path={`history`} element={<Registry/>} />
        <Route path={`favorites`} element={<Favorites/>} />
        <Route path={'call/:transfer?'} element={<Call/>} />
        <Route path={'incoming-call'} element={<IncomingCall/>} />
        <Route path={'transferring-call/:type'} element={<TransferringCall/>} />
        <Route path={`*`} element={<Home/>} />
      </Routes>
      <NumPadButton />
    </Container>
  )
}

export default withAuthentication(Phone)

//region Style

const Container = styled.div`
  display: grid;
  position: relative;
  grid-template-rows: 1fr;
  height: calc(var(--height) - var(--topbar-height));
  outline: none;
`

//endregion
