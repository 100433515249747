import { Reducer } from 'redux'
import * as t from './types'
import { MediaChatState } from './types'

export const initialState: t.ChatsState = {
  chats: new Map(),
  proactiveGuests: new Map(),
  bookedChats: new Map(),
  unreadEvents: 0,
  unreadEventsByChat: new Map(),
  sneakpeeks: new Map(),
  mediachat: null
}

type ChatsReducer = Reducer<t.ChatsState, t.ChatsActionTypes>

const customerChats: ChatsReducer = (state = initialState, action: t.ChatsActionTypes) => {
  switch (action.type) {
    case t.SET_CHATS:
      return { ...state, chats: action.payload }
    case t.SET_BOOKED_CHATS:
      return { ...state, bookedChats: action.payload }
    case t.SET_SNEAKPEEKS:
      return { ...state, sneakpeeks: action.payload }
    case t.SET_UNREAD_EVENTS:
      return {
        ...state,
        unreadEventsByChat: action.payload.unreadEventsByChat,
        unreadEvents: action.payload.unreadEvents
      }
    case t.SET_MEDIACHAT:
      return { ...state, mediachat: action.payload as MediaChatState }
    case t.ADD_PUBLISHER_SESSION:
      return { ...state, mediachat: state.mediachat ? { ...state.mediachat, publisherSession: action.payload } : null }
    case t.SET_PRIVATE_ID:
      return { ...state, mediachat: state.mediachat ? { ...state.mediachat, private_id: action.payload } : null }
    case t.SET_SUBSCRIBER_SESSIONS:
      return {
        ...state,
        mediachat: state.mediachat ? { ...state.mediachat, subscriberSessions: action.payload } : null
      }
    case t.SET_LOCAL_STREAM:
      return { ...state, mediachat: state.mediachat ? { ...state.mediachat, localStream: action.payload } : null }
    case t.SET_REMOTE_STREAMS:
      return { ...state, mediachat: state.mediachat ? { ...state.mediachat, remoteStreams: action.payload } : null }
    case t.SET_GUEST_STREAM:
      return { ...state, mediachat: state.mediachat ? { ...state.mediachat, guestStream: action.payload } : null }
    case t.PROACTIVE_GUESTS_LIST:
      return { ...state, proactiveGuests: action.payload }
    default:
      return state
  }
}

export default customerChats
