import { CustomerChat } from '../../../../api'
import { Chat, ChatEvent } from '../../../../api/rest'
import { download, getAuthorizationTokenForDownload } from '../../../../helpers/media'
import { rem } from '../../../../helpers/style'
import { DownloadForOfflineRounded, ExpandMoreRounded as GoBottomIcon } from '@mui/icons-material'
import { Button, CircularProgress, Fab, Fade, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { getMe, getTranslation } from '../../../../store/selectors'
import { DateTime } from 'luxon'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import usePrevious from '../../../../hooks/usePrevious'
import MessageBaloon from './ChatBodyMessage'

type Props = {
  chat: Chat
  noMoreMessages: boolean
  setNoMoreMessages: (a0: boolean) => void
}
const SpyChatBodyMessages: React.FC<Props> = ({ chat, noMoreMessages, setNoMoreMessages }) => {
  const me = useSelector(getMe)
  const [loadingMoreMessages, setLoadingMoreMessages] = useState(false)
  const [messagesBox, setMessagesBox] = useState<HTMLElement | null>(null)
  const [messagesLength, setMessagesLength] = useState<number>(0)
  const [showGoToBottomButton, setShowGoToBottomButton] = useState<boolean>(false)

  const prevMessagesLength = usePrevious<number>(messagesLength)

  const translation = useSelector(getTranslation)

  const muiTheme = useTheme()

  // scrolla giù appena il dom è pronto
  useLayoutEffect(() => {
    if (!messagesBox) return
    messagesBox.scrollTop = messagesBox.scrollHeight
  }, [messagesBox])

  useEffect(() => {
    setMessagesLength(chat.events.length)
    if (messagesBox && !loadingMoreMessages) {
      messagesBox.scrollTop = messagesBox.scrollHeight
    }
    if (loadingMoreMessages && chat) {
      // Scrolla in modo che sia visibile il primo messaggio
      setLoadingMoreMessages(false)
      const messageToShowIndex = chat.events.length - (prevMessagesLength || 0)
      const messageToShow = chat?.events[messageToShowIndex]
      if (!messageToShow) return
      const messageDiv = document.getElementById(messageToShow.date)
      if (!messageDiv) return
      messageDiv.scrollIntoView(false)
      setNoMoreMessages(prevMessagesLength === chat.events.length)
    }
  }, [chat, loadingMoreMessages, messagesBox, prevMessagesLength, setNoMoreMessages])

  const handleScrollUp = (container: HTMLElement) => {
    setShowGoToBottomButton(container.scrollHeight - container.scrollTop - 1 > container.clientHeight)
    if (noMoreMessages) return
    if (!chat || !chat.events || loadingMoreMessages) return
    if (container.scrollTop < 200) {
      setLoadingMoreMessages(true)
      const firstMessage = chat.events[0]
      if (!firstMessage) return
    }
  }

  const handleScrollDown = (container: HTMLElement) => {
    setShowGoToBottomButton(container.scrollHeight - container.scrollTop - 1 > container.clientHeight)
  }

  const handleFabClick = () => {
    if (messagesBox) {
      messagesBox.style.scrollBehavior = 'smooth'
      messagesBox.scrollTop = messagesBox.scrollHeight
      messagesBox.style.scrollBehavior = ''
    }
  }

  const renderDayInfo = (currentMessage: ChatEvent, index: number) => {
    if (!chat?.events) return null
    const prevIndex = index - 1
    const now = DateTime.now()
    const mexTime = DateTime.fromSQL(currentMessage.date)
    const isToday = now.hasSame(mexTime, 'day')
    // se è il primo elemento non mostro la data
    if (prevIndex >= 0) {
      // altrimenti verifico se tra questo messaggio e il precedente c'è un cambio di giorno
      const prevMex = chat.events[prevIndex]
      const prevMexTime = DateTime.fromSQL(prevMex.date)
      if (!prevMex) return null
      if (mexTime.hasSame(prevMexTime, 'day')) {
        // i due messaggi appartengono allo stesso giorno
        return null
      } else {
        // il giorno è cambiato
        return <DayInfo>{isToday ? translation.today : mexTime.toLocaleString(DateTime.DATE_SHORT)}</DayInfo>
      }
    }
  }

  const downloadAttachment = async (message: ChatEvent) => {
    const token = await getAuthorizationTokenForDownload(me.id, message.filename!)
    if (token) await download(token, message.file)
  }

  return (
    <Container
      containerRef={(ref) => setMessagesBox(ref)}
      onScrollUp={handleScrollUp}
      onScrollDown={handleScrollDown}
      options={{ suppressScrollX: true }}
    >
      <Fade
        in={loadingMoreMessages}
        style={{
          transitionDelay: loadingMoreMessages ? '800ms' : '0ms'
        }}
        unmountOnExit
      >
        <CircularProgress style={{ placeSelf: 'center' }} />
      </Fade>
      {(!chat || !chat.events || !chat.events.length) && <div />}
      {chat?.events?.map((message, i) => (
        <React.Fragment key={i}>
          {renderDayInfo(message, i)}
          {message.type === 'system' ? (
            message.subtype === 'new attachment' ? (
              <Attachment>
                <Typography variant={'subtitle2'} color="priamary" fontWeight={600}>
                  {CustomerChat.parseSystemMessage(message, translation)}
                </Typography>
                <Button
                  startIcon={<DownloadForOfflineRounded />}
                  size="small"
                  color="primary"
                  onClick={() => downloadAttachment(message)}
                >
                  {message.file}
                </Button>
              </Attachment>
            ) : (
              <SystemMessage>{CustomerChat.parseSystemMessage(message, translation)}</SystemMessage>
            )
          ) : (
            <MessageBaloon message={message} inTransfer={Boolean(chat.transferee && chat.transferer)} isSpy={true} />
          )}
        </React.Fragment>
      ))}
      <Fade in={showGoToBottomButton} style={fadeStyle} unmountOnExit>
        <Fab size={'small'} onClick={handleFabClick} style={{ backgroundColor: muiTheme.palette.action.active }}>
          <StyledGoToBottomIcon />
        </Fab>
      </Fade>
      {/*<ChatBodySneakpeek chatId={chat.id} />*/}
    </Container>
  )
}

export default SpyChatBodyMessages

//region Style

const fadeStyle: React.CSSProperties = {
  transitionDelay: '200ms',
  position: 'fixed',
  bottom: '60px',
  right: '17px',
  height: rem(1.4),
  width: rem(1.4)
}

const StyledGoToBottomIcon = styled(GoBottomIcon)`
  color: ${({ theme }) => (theme.palette.mode === 'dark' ? 'black' : 'white')};
`

const Container = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: ${rem(0.5)};
  overflow-y: auto;
`

const DayInfo = styled.div`
  width: fit-content;
  color: #ffffff;
  place-self: center;
  border-radius: 10px;
  background: ${({ theme }) => theme.palette.primary.light};
  padding: ${rem(0.3)} ${rem(0.6)};
  font-size: ${rem(0.55)};
  opacity: 0.8;
  text-transform: capitalize;
  margin: ${rem(0.3)} 0;
`

const SystemMessage = styled.div`
  place-self: center;
  border-radius: 15px;
  background: ${({ theme }) => (theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900])};
  color: ${({ theme }) => theme.palette.text?.primary};
  padding: ${rem(0.2)} ${rem(0.6)};
  font-size: ${rem(0.7)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: min(85%, 500px);
  margin: ${rem(0.4)} 0;
  min-height: 25px;
`

const Attachment = styled.div`
  place-self: center;
  border-radius: 15px;
  background: ${({ theme }) => (theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900])};
  color: ${({ theme }) => theme.palette.text?.primary};
  padding: ${rem(0.2)} ${rem(0.6)};
  font-size: ${rem(0.7)};
  margin: ${rem(0.4)} 0;
  height: fit-content;
  max-width: min(85%, 500px);

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  place-content: center;
  column-gap: 0.5rem;
`
//endregion
