import {Device, Videoroom} from '@bytewise/janus'
import {rem} from '../../../../helpers/style'
import {
  CameraRounded,
  MicOffRounded,
  MicRounded,
  SettingsRounded,
  SpeakerRounded,
  VideocamOffRounded,
  VideocamRounded
} from '@mui/icons-material'
import {Fade} from '@mui/material'
import {editSettings} from '../../../../store/applicationState/actions'
import {SelectedDevices} from '../../../../store/applicationState/types'
import {getDevices, getMessengerVideoRoom, getSelectedDevices, getTranslation} from '../../../../store/selectors'
import React, {useEffect, useMemo, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'
import logger from '../../../../helpers/logger'
import usePrevious from '../../../../hooks/usePrevious'
import {ControlButton, Modal, Select} from '../../../common'

type Props = {
  show: boolean
  modalContainer: HTMLElement | null
}

const VideoCallSettings: React.FC<Props> = ({ show, modalContainer }) => {
  const settingsButtonRef = useRef<HTMLButtonElement>(null)
  const [sendAudio, setSendAudio] = useState(true)
  const [sendVideo, setSendVideo] = useState(true)
  const [showDevices, setShowDevices] = useState(false)

  const videoroom = useSelector(getMessengerVideoRoom)
  const selectedDevices = useSelector(getSelectedDevices)
  const devices = useSelector(getDevices)
  const translation = useSelector(getTranslation)

  const prevDev = usePrevious<SelectedDevices>(selectedDevices)

  const dispatch = useDispatch()

  useEffect(() => {
    if (prevDev === selectedDevices) return
    if (!videoroom || !videoroom.publisherSession) return
    const opts: any = {}
    if (!prevDev.microphone && selectedDevices.microphone) {
      // prima non c'era alcun microfono, ora si
      opts.addAudio = true
      opts.audio = { deviceId: selectedDevices.microphone.deviceId }
    }
    if (selectedDevices.microphone) {
      // non ci si può affidare all'id perchè può essere "default" per due device dicersi
      if (prevDev.microphone?.label !== selectedDevices.microphone?.label) opts.replaceAudio = true
      opts.audio = { deviceId: selectedDevices.microphone.deviceId }
    }
    if (!selectedDevices.microphone) {
      // non ci sono più mirofoni
      opts.removeAudio = true
    }
    if (!prevDev.webcam && selectedDevices.webcam) {
      // prima non c'era alcun microfono, ora si
      opts.addVideo = true
      opts.video = { deviceId: selectedDevices.webcam.deviceId }
    }
    if (selectedDevices.webcam) {
      // non ci si può affidare all'id perchè può essere "default" per due device dicersi
      if (prevDev.webcam?.label !== selectedDevices.webcam?.label) opts.replaceVideo = true
      opts.video = { deviceId: selectedDevices.webcam.deviceId }
    }
    if (!selectedDevices.webcam) {
      // non ci sono più mirofoni
      opts.removeVideo = true
    }

    videoroom.publisherSession.renegotiate(opts).catch((err) => logger.error(err))
  }, [selectedDevices, prevDev, videoroom])

  const videoOptions = useMemo(() => {
    if (selectedDevices && selectedDevices.webcam) {
      return { deviceId: selectedDevices.webcam.deviceId }
    } else {
      return true
    }
  }, [selectedDevices])

  const addAudio = (session: Videoroom) => {
    try {
      session.unmuteAudio()
    } catch(e) {
      logger.error(e)
    }
    setSendAudio(true)
  }

  const removeAudio = (session: Videoroom) => {
    try {
      session.muteAudio()
    } catch(e) {
      logger.error(e)
    }
    setSendAudio(false)
  }

  const addVideo = (session: Videoroom) => {
    session
      .renegotiate({
        video: videoOptions,
        replaceVideo: true
      })
      .then(() => {
        setSendVideo(true)
      })
  }

  const removeVideo = (session: Videoroom) => {
    session
      .renegotiate({
        removeVideo: true
      })
      .then(() => {
        setSendVideo(false)
      })
  }

  const switchAudio = () => {
    if (!videoroom || !videoroom.publisherSession) return
    if (sendAudio) removeAudio(videoroom.publisherSession)
    else addAudio(videoroom.publisherSession)
  }

  const switchVideo = () => {
    if (!videoroom || !videoroom.publisherSession) return
    if (sendVideo) removeVideo(videoroom.publisherSession)
    else addVideo(videoroom.publisherSession)
  }

  return (
    <Fade in={show}>
      <Container>
        <ControlButton
          isActive={sendVideo}
          onClick={switchVideo}
          iconActive={<VideocamRounded />}
          iconDisactive={<VideocamOffRounded />}
        />
        <ControlButton
          isActive={sendAudio}
          onClick={switchAudio}
          iconActive={<MicRounded />}
          iconDisactive={<MicOffRounded />}
        />
        <ControlButton
          ref={settingsButtonRef}
          isActive={sendVideo}
          onClick={() => setShowDevices(true)}
          iconActive={<SettingsRounded />}
          iconDisactive={<SettingsRounded />}
        />

        <Modal
          closeModal={() => setShowDevices(false)}
          isVisible={showDevices}
          container={modalContainer || undefined}
          style={{ width: 'fit-content', height: 'fit-content' }}
        >
          <Devices>
            <div>
              <SelectLabel>
                <MicRounded />
                {translation.mic}
              </SelectLabel>
              <Select<Device>
                options={devices.filter((d) => d.kind === 'audioinput')}
                selected={selectedDevices.microphone}
                onChange={(selected) =>
                  dispatch(
                    editSettings({
                      selectedDevices: {
                        ...selectedDevices,
                        microphone: selected as MediaDeviceInfo
                      }
                    })
                  )
                }
                getLabel={(o) => o.label}
                getValue={(o) => o.deviceId}
                required={true}
                style={{ width: 'min(calc(var(--width) - 20px), 350px)' }}
              />
            </div>
            <div>
              <SelectLabel>
                <CameraRounded />
                {translation.camera}
              </SelectLabel>
              <Select<Device>
                options={devices.filter((d) => d.kind === 'videoinput')}
                selected={selectedDevices.webcam}
                onChange={(selected) => {
                  dispatch(
                    editSettings({
                      selectedDevices: {
                        ...selectedDevices,
                        webcam: selected as MediaDeviceInfo
                      }
                    })
                  )
                }}
                getLabel={(o) => o.label}
                getValue={(o) => o.deviceId}
                required={true}
                style={{ width: 'min(calc(var(--width) - 20px), 350px)' }}
              />
            </div>
            <div>
              <SelectLabel>
                <SpeakerRounded />
                {translation.speaker}
              </SelectLabel>
              <Select<Device>
                options={devices.filter((d) => d.kind === 'audiooutput')}
                selected={selectedDevices.audio}
                onChange={(selected) => {
                  dispatch(
                    editSettings({
                      selectedDevices: {
                        ...selectedDevices,
                        audio: selected as MediaDeviceInfo
                      }
                    })
                  )
                }}
                getLabel={(o) => o.label}
                getValue={(o) => o.deviceId}
                required={true}
                style={{ width: 'min(calc(var(--width) - 20px), 350px)' }}
              />
            </div>
          </Devices>
        </Modal>
      </Container>
    </Fade>
  )
}

export default VideoCallSettings

//region Style

const Container = styled.div`
  height: 50px;
  width: fit-content;
  border-radius: 15px;
  background: ${({ theme }) => theme.palette.background?.paper};
  padding: ${rem(0.3)} ${rem(0.8)};

  position: absolute;
  bottom: 10px;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  place-self: center;
  place-content: center;
  grid-column-gap: ${rem(0.5)};
`

const Devices = styled.div`
  width: 100%;
  display: grid;
  grid-row-gap: calc(var(--font-size) * 0.8);
  max-width: min(calc(var(--width) - 10px), 500px);
  place-self: center;
  place-content: center;
  padding: ${rem(0.5)};
`

const SelectLabel = styled.div`
  display: grid;
  grid-template-columns: 0.1fr 0.9fr;
  font-size: ${rem(0.9)};
  align-items: center;
  column-gap: 10px;
  margin-bottom: 10px;
  font-weight: bold;
`
//endregion
