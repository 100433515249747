import { RootState } from '../../store/types'
import { ThunkDispatch } from 'redux-thunk'
import CustomerChat from '../customerChat'
import { addChat, addSpiedChat } from '../../store/customerChats/actions'
import { changeRoute } from '../../store/applicationState/actions'
import logger from '../../helpers/logger'
import log from '../../helpers/logger'

/**
 * Classe di utility per la gestione da cli delle funzioni telefoniche
 */
export class ChatUtils {
  private _dispatch: ThunkDispatch<any, any, any> | Function
  private _getState: () => RootState

  /**
   * A richiamarlo ci penso solo OCClient una sola volta
   * @param dispatch
   * @param getState
   * @internal
   */
  constructor(dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) {
    this._dispatch = dispatch
    this._getState = getState
  }

  //region Getter e setter
  /** @internal */
  set dispatch(value: ThunkDispatch<any, any, any> | Function) {
    this._dispatch = value
  }

  /** @internal */
  get state(): RootState {
    return this._getState()
  }

  /** @internal */
  set getState(value: () => RootState) {
    this._getState = value
  }

  //endregion

  /** fa partire lo spy di una chat */
  public spy({ chatId, userId }: { chatId: string; userId: number }) {
    CustomerChat.requestSpy({ chatId, userId }, ({ error, errorMessage, chat }) => {
      if (error) {
        logger.error(errorMessage)
        return
      }
      if (chat) {
        logger.debug(chat)
        this._dispatch(addSpiedChat(chat))
        this._dispatch(changeRoute({ current: `/spy-customer-chat/${chat.id}` }))
      }
    })
  }

  public startProactiveChat({ departmentId, guestId }: { departmentId: number; guestId: string }) {
    CustomerChat.startProactiveChat({ departmentId, guestId }, (res) => {
      if (res.error) {
        log.error('Could not start proactive chat: ', res.message)
        return
      }
      this._dispatch(addChat(res.chat))
      this._dispatch(changeRoute({ current: `/customer-chat/${res.chat.id}` }))
    })
  }
}
