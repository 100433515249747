import { SocketName } from '../../api/sockets'

export const WS_CONNECT = 'WS_CONNECT'
export const WS_CONNECTING = 'WS_CONNECTING'
export const WS_CONNECTED = 'WS_CONNECTED'
export const WS_DISCONNECT = 'WS_DISCONNECT'
export const WS_DISCONNECTED = 'WS_DISCONNECTED'
export const WS_EMIT = 'WS_EMIT'
export const WS_ADD_EVENT_LISTENER = 'WS_ADD_EVENT_LISTENER'
export const WS_REMOVE_EVENT_LISTENER = 'WS_REMOVE_EVENT_LISTENER'

export type EventAckFunction = (arg0: EventPayload) => any

export type EventPayload = {
  [key: string]: any
  eventName: string
}

export type WsPayload = {
  name: SocketName
  logout?: boolean
}

export type WsAddEventListenerPayload = WsPayload & {
  listener: Function
  event: string
}

export type WsRemoveEventListenerPayload = WsPayload & { event: string }

export interface WsConnectAction {
  type: typeof WS_CONNECT
  payload: WsPayload
}

export interface WsConnectingAction {
  type: typeof WS_CONNECTING
  payload: WsPayload
}

export interface WsConnectedAction {
  type: typeof WS_CONNECTED
  payload: WsPayload
}

export interface WsDisconnectAction {
  type: typeof WS_DISCONNECT
  payload: WsPayload
}

export interface WsDisconnectedAction {
  type: typeof WS_DISCONNECTED
  payload: WsPayload
}

export interface WsEmitAction {
  type: typeof WS_EMIT
  payload: WsPayload
}

export interface WsAddEventListenerAction {
  type: typeof WS_ADD_EVENT_LISTENER
  payload: WsAddEventListenerPayload
}

export interface WsRemoveEventListenerAction {
  type: typeof WS_REMOVE_EVENT_LISTENER
  payload: WsRemoveEventListenerPayload[]
}

export type WsActionType =
  | WsConnectAction
  | WsConnectingAction
  | WsConnectedAction
  | WsDisconnectedAction
  | WsDisconnectAction
  | WsEmitAction
  | WsAddEventListenerAction
  | WsRemoveEventListenerAction
