import * as t from './types'

export const wsConnect = (payload: t.WsPayload): t.WsActionType => ({
  type: t.WS_CONNECT,
  payload
})
export const wsConnected = (payload: t.WsPayload): t.WsActionType => ({
  type: t.WS_CONNECTED,
  payload
})
export const wsDisconnect = (payload: t.WsPayload): t.WsActionType => ({
  type: t.WS_DISCONNECT,
  payload
})
export const wsDisconnected = (payload: t.WsPayload): t.WsActionType => ({
  type: t.WS_DISCONNECTED,
  payload
})
export const wsAddEventListener = (payload: t.WsAddEventListenerPayload): t.WsActionType => ({
  type: t.WS_ADD_EVENT_LISTENER,
  payload
})
export const wsRemoveEventListener = (payload: t.WsRemoveEventListenerPayload[]): t.WsActionType => ({
  type: t.WS_REMOVE_EVENT_LISTENER,
  payload: [...payload]
})
