import { getFontSize } from '../../helpers/common'
import { getDefaultValues } from './helpers'
import { LOGIN } from '../login/types'
import { Reducer } from 'redux'
import * as t from './types'

const { width, height, settings, visible } = getDefaultValues()

export const initialState: t.ApplicationState = {
  path: {
    current: sessionStorage.getItem('@occlient/currentPath') || '/phone',
    previous: sessionStorage.getItem('@occlient/previousPath') || ''
  },
  devices: [],
  dimensions: { width, height, fontSize: getFontSize(width, height), renderedPages: 1 },
  visible: visible,
  settings,
  turnServer: { host: '', user: '', password: '' },
  peers: [],
}

type ApplicationStateReducer = Reducer<t.ApplicationState, t.ApplicationStateActionTypes>

const applicationState: ApplicationStateReducer = (
  state = initialState,
  action: t.ApplicationStateActionTypes
): t.ApplicationState => {
  switch (action.type) {
    case t.SET_PATH:
      return { ...state, path: action.payload }
    case t.SET_DEVICES:
      return { ...state, devices: action.payload }
    case t.SET_DIMENSIONS:
      return { ...state, dimensions: {...state.dimensions, ...action.payload} }
    case t.SET_RENDERED_PAGES:
      return { ...state, dimensions: {...state.dimensions, renderedPages: action.payload} }
    case t.SET_VISIBILITY:
      return { ...state, visible: action.payload }
    case t.SET_SETTINGS:
      return {
        ...state,
        settings: action.payload
      }
    case LOGIN:
      return {
        ...state,
        turnServer: action.payload.config ? action.payload.config.turn : state.turnServer,
        peers: action.payload.config ? action.payload.config.peers.map((p: string) => new RegExp(p)) : state.peers
      }
    default:
      return state
  }
}

export default applicationState