import { IconButton, Tooltip } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

type ActionButtonProps = {
  onClick: Function
  onDoubleClick?: Function
  label?: string
  disabled?: boolean
  style?: React.CSSProperties
  size?: 'small' | 'medium'
  tooltip?: string
  children?: React.ReactNode
}

export const ActionButton: React.FC<ActionButtonProps> = (props) => {
  const { children, style = {}, onClick, onDoubleClick, label, disabled, size = 'medium', tooltip = '' } = props

  const enabled = () => {
    if (disabled === undefined || disabled === null) return true
    else return !disabled
  }

  return (
    <Tooltip title={tooltip} disableInteractive>
      <Container label={label} style={style} disabled={!enabled()}>
        <IconButton
          data-cy="action-button"
          disabled={!enabled()}
          onClick={() => enabled() && onClick()}
          onDoubleClick={() => enabled() && onDoubleClick && onDoubleClick()}
          size={size}
          style={{ width: 'fit-content', placeSelf: 'center' }}
        >
          {children}
        </IconButton>
        {label && <Label disabled={disabled}>{label}</Label>}
      </Container>
    </Tooltip>
  )
}

interface ContainerProps {
  label?: string
  disabled?: boolean
}

const Container = styled.div<ContainerProps>`
  place-self: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  ${({ label }) => label && 'display: grid; grid-template-rows: 3fr 1fr; '}
`

interface LabelProps {
  disabled?: boolean
}

const Label = styled.div<LabelProps>`
  place-self: center;
  color: ${({ theme, disabled }) => (disabled ? theme.palette.text?.disabled : theme.palette.text?.primary)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-size: calc(var(--font-size) * 0.6);
`
