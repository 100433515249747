import {ElectronProxy} from '../../api'
import {LanguageCode} from '../../api/rest'
import Floating from '../minified/Floating/Floating'
import {getTranslation} from '../../helpers/getTranslation'
import {changeRoute} from '../../store/applicationState/actions'
import {setTranslation} from '../../store/language/actions'
import {getDimensions, getIsAuthenticated, getMessengerVideoRoom, getVisibility} from '../../store/selectors'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation} from 'react-router'
import styled from 'styled-components'
import isElectron from '../../helpers/isElectron'
import VideoCall from '../pages/Messenger/videocall/VideoCall'
import NavBar from './NavBar'
import TopBar from './TopBar/TopBar'
import {Outlet} from "react-router-dom"
import logger from "../../helpers/logger";
import Loading from "../pages/Loading"
import {minimumDimensions} from "../../constants"

const LANGUAGE = sessionStorage.getItem('@occlient/selectedTranslation') || 'it_IT'

const Template: React.FC = () => {
  const [showMenu, setShowMenu] = useState(false)
  const [showTopbar, setShowTopbar] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [ready, setReady] = useState(false)

  const { width, height, fontSize } = useSelector(getDimensions)
  const isVisible = useSelector(getVisibility)
  const videoroom = useSelector(getMessengerVideoRoom)
  const isAuthenticated = useSelector(getIsAuthenticated)

  const minWidth = minimumDimensions.width
  const minHeight = minimumDimensions.height

  const dispatch = useDispatch()

  const location = useLocation()

  useEffect(() => {
    setShowTopbar(!error && isAuthenticated)
    if (!isAuthenticated || error) setShowMenu(false)
  }, [isAuthenticated, error])

  // Caricamento iniziale delle traduzioni
  useEffect(() => {
    setLoading(true)
    getTranslation(LANGUAGE)
      .then((translation) => {
        dispatch(setTranslation(LANGUAGE as LanguageCode, translation))
        ElectronProxy.changeLanguage(LANGUAGE as LanguageCode)
        setReady(true)
      })
      .catch((e) => {
        logger.error(e)
        setError(true)
        dispatch(changeRoute({ current: '/error' }))
      })
      .finally(() => setLoading(false))
  }, [dispatch])


  let resizeTimer: NodeJS.Timeout
  const throttleDelay = 100
  const resizePageDimensions = (width: number, height: number)=> {
    const browserHeadingHeight = window.outerHeight - window.innerHeight
    window.resizeTo(width, height + browserHeadingHeight)
  }

  window.addEventListener('resize', ()=>{
    const isMinimized = localStorage.getItem('@occlient/minimized') || false
    if(isVisible && !isMinimized) {
      clearTimeout(resizeTimer)
      let windowWidth = window.innerWidth
      let windowHeight = window.innerHeight
      if(!isElectron()) {
        if(windowWidth < minWidth || windowHeight < minHeight) {
          resizeTimer = setTimeout(()=> resizePageDimensions(minWidth, minHeight), throttleDelay)
        }
      }
    }
  });

  return (
    <>
      {isVisible && (
        <Container
          withShadow={false}
          width={width}
          height={height}
          fontSize={fontSize}
          showMenu={showMenu}
          showTopbar={showTopbar}
          isAuthenticated={isAuthenticated}
        >
          {isAuthenticated && ready && <TopBar showMenu={showMenu} setShowMenu={setShowMenu} />}
          {isAuthenticated && ready && <NavBar showMenu={showMenu} setShowMenu={setShowMenu} />}
          <Content data-cy={'content'}>{loading ? <Loading/> : <Outlet/>}</Content>
        </Container>
      )}
      <Floating />
      {videoroom && !location.pathname.includes('video-call') && <VideoCall hide={true} />}
    </>
  )
}

export default Template

//region Style

type ContainerProps = {
  width: number
  height: number
  fontSize: number
  showMenu: boolean
  showTopbar: boolean
  withShadow: boolean
  isAuthenticated: boolean
}
const Container = styled.div<ContainerProps>`
  // Variabili
  --width: ${({ width }) => `${width}px`};
  --height: ${({ height }) => `${height}px`};
  --font-size: ${({ fontSize }) => `${fontSize}px`};
  --topbar-height: ${({ showTopbar }) => (showTopbar ? '40px' : '0px')};
  --navbar-width: ${({ showMenu }) => (showMenu ? '40px' : '0px')};
  font-family: 'Open Sans', sans-serif;
  position: relative;
  display: grid;
  grid-template-columns: ${({ isAuthenticated }) => (!isAuthenticated ? '1fr' : 'min-content 1fr')};
  grid-template-rows: ${({ isAuthenticated }) => (!isAuthenticated ? '1fr' : 'var(--topbar-height) 1fr')};
  grid-template-areas: ${({ isAuthenticated }) => (!isAuthenticated ? 'main' : '"header header" "menu main"')};
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  background: ${({ theme }) => theme.palette.background.paper};
  color: ${({ theme }) => theme.palette.text?.primary};
  overflow: hidden;
  ${({ withShadow }) =>
    withShadow
      ? '-webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.75);' +
        '-moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.75);' +
        'box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.75);'
      : ''}
`

const Content = styled.div`
  grid-area: main;
  width: calc(var(--width) - var(--navbar-width));
  height: calc(var(--height) - var(--topbar-height));
  box-sizing: border-box;
  background: inherit;
  overflow: auto;
`

//endregion
