import { Messenger } from '..'
import logger from '../../helpers/logger'
import { changeRoute, setVisibility } from '../../store/actions'
import { getConversationsArray, getMe, getUsersArray } from '../../store/selectors'
import { RootState } from '../../store/types'
import { ThunkDispatch } from 'redux-thunk'

/**
 * Classe di utility per la gestione da cli delle funzioni telefoniche
 */
export class MessengerUtils {
  private _dispatch: ThunkDispatch<any, any, any> | Function
  private _getState: () => RootState

  /**
   * A richiamarlo ci penso solo OCClient una sola volta
   * @param dispatch
   * @param getState
   * @internal
   */
  constructor(dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) {
    this._dispatch = dispatch
    this._getState = getState
  }

  //region Getter e setter
  /** @internal */
  set dispatch(value: ThunkDispatch<any, any, any> | Function) {
    this._dispatch = value
  }
  /** @internal */
  get state(): RootState {
    return this._getState()
  }
  /** @internal */
  set getState(value: () => RootState) {
    this._getState = value
  }
  //endregion

  public goToConversation(username: string) {
    logger.silly(`Going to conversation with ${username}`)
    const users = getUsersArray(this.state)
    const user = users.find((u) => u.username === username)

    if (!user) {
      logger.warn(`Cannot find user with username ${username}`)
      return
    }
    const member = Messenger.getMemberFromUser(user)
    const conversations = getConversationsArray(this.state)
    const me = getMe(this.state)
    if (member.username === me.username) {
      logger.warn('Cannot chat with yourself')
      return
    }
    const myMember = Messenger.getMemberFromUser(me)
    const gotConv = Messenger.getConversationByMembers(conversations, [myMember, member])
    if (gotConv) {
      this._dispatch(changeRoute({ current: `/messenger/${gotConv.id}` }))
    } else {
      this._dispatch(changeRoute({ current: '/messenger/new', state: { otherMember: member } }))
    }
    this._dispatch(setVisibility(true))
  }
}
