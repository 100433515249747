export const event = 'chat:event'
export const booking = 'chat:booking'
export const booking_cancelled = 'chat:booking-cancelled'
export const booking_accept = 'chat:booking-accept'
export const booking_reject = 'chat:booking-reject'
export const new_message = 'chat:message'
export const sneakpeek = 'chat:sneakpeek'
export const sneakpeek_start = 'chat:sneakpeek-start'
export const sneakpeek_stop = 'chat:sneakpeek-stop'
export const request_media = 'chat:request-media'
export const stop_media = 'chat:stop-media'
export const transfer_department = 'chat:transfer-department'
export const transfer_request = 'chat:transfer-request'
export const transfer_operator = 'chat:transfer-operator'
export const transfer_accept = 'chat:transfer-accept'
export const transfer_complete = 'chat:transfer-complete'
export const transfer_reject = 'chat:transfer-reject'
export const transfer_rejected = 'chat:transfer-rejected'
export const transfer_deleted = 'chat:transfer-deleted'
export const transfer_cancel = 'chat:transfer-cancel'
export const close = 'chat:close'
export const start_proactive = 'chat:start-proactive'
export const booking_from_supervisor = 'chat:booking-from-supervisor'
export const booking_from_supervisor_rejected = 'chat:booking-from-supervisor-rejected'
export const booking_from_supervisor_accepted = 'chat:booking-from-supervisor-accepted'
export const request_spy = 'chat:request-spy'
export const stop_spy = 'chat:stop-spy'
