import { Statuses } from '../../api/rest'
import { LOGIN, LOGOUT } from '../login/types'
import { DateTime } from 'luxon'
import { Reducer } from 'redux'
import * as t from './types'

export const initialState: t.PhoneState = {
  callInProgress: null,
  status: {
    id: Statuses.disconnected,
    isStatus: true,
    lastchange: DateTime.now().toSQL(),
    name: 'DISCONNECTED'
  },
  selectedOutbound: null,
  vocalOrder: null,
  voips: [],
  lostCalls: 0,
  bitrate: null,
  reconnecting: false,
  coreConnected: false
}

type PhoneReducer = Reducer<t.PhoneState, t.PhoneActionTypes>

const phoneReducer: PhoneReducer = (state = initialState, action: t.PhoneActionTypes): t.PhoneState => {
  switch (action.type) {
    case t.STATUS_CHANGE:
      return { ...state, status: action.payload }
    case t.SELECT_OUTBOUND:
      return { ...state, selectedOutbound: action.payload }
    case t.ADD_VOIP:
      return { ...state, voips: [...state.voips, action.payload] }
    case t.REMOVE_VOIPS:
      return { ...state, voips: [] }
    case t.SET_CALL_IN_PROGRESS:
      return { ...state, callInProgress: action.payload }
    case t.CALL_STATUS_CHANGE:
      return {
        ...state,
        callInProgress: state.callInProgress ? { ...state.callInProgress, status: action.payload } : null
      }
    case t.SET_BITRATE:
      return {
        ...state,
        bitrate: action.payload
      }
    case t.SET_LOST_CALLS:
      return { ...state, lostCalls: action.payload }
    case LOGIN:
      return { ...state, status: action.payload.user.state }
    case t.SET_RECONNECTING:
      return { ...state, reconnecting: action.payload }
    case t.SET_CORE_CONNECTED:
      return { ...state, coreConnected: action.payload }
    case LOGOUT:
      return { ...state, reconnecting: false }
    default:
      return state
  }
}

export default phoneReducer
