import { Chat } from '../../../../api/rest'
import { readEventsForChat } from '../../../../store/customerChats/actions'
import { getChats, getUnreadEventsByChat } from '../../../../store/selectors'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useMatch } from 'react-router-dom'
import SpyChatBodyTopBar from '../../../pages/CustomerChat/conversation/SpyChatBodyTopBar'
import SpyChatBodyMessages from '../../../pages/CustomerChat/conversation/SpyChatBodyMessages'

const SpyChatBody: React.FC = () => {
  const [chat, setChat] = useState<Chat | null>(null)

  const { params } = useMatch('/spy-customer-chat/:id')!

  const chats = useSelector(getChats)
  const unreadEventsByChat = useSelector(getUnreadEventsByChat)

  const dispatch = useDispatch()

  useEffect(() => {
    setChat(chats.get(params.id!) || null)
  }, [chats, setChat, params.id])

  useEffect(() => {
    if (!chat) return
    // Rimuovo i messaggi non letti per la chat appena aperta
    const evs = unreadEventsByChat.get(chat.id)
    if (evs) dispatch(readEventsForChat(chat.id))
  }, [chat, dispatch, unreadEventsByChat])

  return (
    <Container>
      {chat && <SpyChatBodyTopBar chat={chat} />}
      {chat && <SpyChatBodyMessages chat={chat} noMoreMessages={true} setNoMoreMessages={() => {}} />}
    </Container>
  )
}

export default SpyChatBody

//region Style

const Container = styled.div`
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  width: 100%;
  height: 100%;
`

//endregion
