import CustomerChat from '../../../api/customerChat'
import ElectronProxy from '../../../api/electron'
import { Chat } from '../../../api/rest'
import { Avatar } from '../../common'
import { rem } from '../../../helpers/style'
import { CheckRounded, CloseRounded } from '@mui/icons-material'
import { CircularProgress, IconButton } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { changeRoute, setVisibility } from '../../../store/applicationState/actions'
import { addChat, unbookChat } from '../../../store/customerChats/actions'
import { getBookedChats, getTranslation } from '../../../store/selectors'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

export const IncomingChat: React.FC = () => {
  const dispatch = useDispatch()
  const translation = useSelector(getTranslation)
  const muiTheme = useTheme()

  const chatIncoming = [...useSelector(getBookedChats).values()]

  const chat = chatIncoming[chatIncoming.length - 1]

  const goToChat = (c: Chat) => {
    dispatch(changeRoute({ current: `/customer-chat/${c.id}`, previous: '/customer-chat' }) as any)
  }

  const bookedChatResponse = (chatId: string, response: boolean, supervisor?: number) => {
    if (!response) {
      dispatch(unbookChat(chatId) as any)
    }
    CustomerChat.bookedChatResponse({ chat: chatId, response, supervisor }, ({ error, chat }) => {
      if (error) {
        //TODO: gestire errore
        dispatch(unbookChat(chatId) as any)
        return
      }
      if (chat) {
        dispatch(addChat(chat) as any)
        ElectronProxy.minifyClient(false)
        dispatch(setVisibility(true) as any)
        goToChat(chat)
      }
    })
  }

  return (
    <Container>
      <InfoContainer>
        <TitleContainer>{translation.chatBookingFrom}</TitleContainer>
        <ChatInfo>
          <Avatar size={rem(1.7)} fontSize={rem(0.9)} style={{ placeSelf: 'start center' }}>
            <CircularProgress size={10} thickness={10} color="inherit" />
          </Avatar>
          <div style={{ display: 'grid', gridTemplateRows: '1fr 3fr' }}>
            <Name>
              {chat?.guest.info.name} {chat?.guest.info.surname}
            </Name>
            <DepartmentName>
              {chat?.supervisor && (
                <b>
                  Assegnata da {chat?.supervisor.name} {chat?.supervisor.surname} sul
                </b>
              )}
              {chat?.departments[0] && chat.departments[0].name}
            </DepartmentName>
          </div>
        </ChatInfo>
      </InfoContainer>
      {/*Bottoni per accettare/rifiutare la chat*/}
      <ButtonContainer>
        <IconButton
          style={{ backgroundColor: muiTheme.palette.error.main, width: 30, height: 30, placeSelf: 'start center' }}
          onClick={() => bookedChatResponse(chat?.id, false, chat?.supervisor?.id)}
        >
          <CloseRounded htmlColor="#FFFFFF" />
        </IconButton>
        <IconButton
          style={{ backgroundColor: muiTheme.palette.success.main, width: 30, height: 30, placeSelf: 'center' }}
          onClick={() => bookedChatResponse(chat?.id, true, chat?.supervisor?.id)}
        >
          <CheckRounded htmlColor="#FFFFFF" />
        </IconButton>
      </ButtonContainer>
    </Container>
  )
}

//region STYLE
const Container = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
`
const InfoContainer = styled.div`
  display: grid;
  gap: 5px;
  grid-template-rows: 1fr 2fr;
`
const TitleContainer = styled.div`
  padding-left: 10px;
  padding-top: 10px;
  font-size: ${rem(0.75)};
  color: ${({ theme }) => theme.palette.primary.main};
  display: grid;
  grid-template-columns: max-content auto;
  gap: 5px;
`

const ChatInfo = styled.div`
  padding-left: 20px;
  display: grid;
  grid-template-columns: 1fr 3fr;
  column-gap: 10px;
`
const ButtonContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  padding-top: 15px;
`

const Name = styled.div`
  font-size: ${rem(0.7)};
  place-self: start;
`

const DepartmentName = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  font-size: ${rem(0.65)};
  height: 100%;
  width: 100%;
  place-self: center start;
`
//endregion
