import { ApiWithRedux } from '../ApiWithRedux'
import { Chat, ChatEvent, Guest, User, UserForChat } from '../rest'
import * as evs from '../sockets/core/eventsTypes/chatEventsTypes'
import Janus, { GetVideoRoomOptions, JanusJS, Videoroom } from '@bytewise/janus'
import { recordPath } from '../../configuration'
import logger from '../../helpers/logger'
import {
  addChatRemoteStream,
  addChatSubscriberSession,
  removeChatRemoteStream,
  removeChatSubscriberSession,
  setChatLocalStream,
  setChatPrivateId,
  setGuestStream,
  updateMediaChat
} from '../../store/customerChats/actions'
import * as t from '../../store/customerChats/types'
import { MediaChatState, MediaType } from '../../store/customerChats/types'
import { Translation } from '../../store/language/types'
import { getMe, getSelectedDevices, getTranslation } from '../../store/selectors'
import { WS_EMIT } from '../../store/sockets/types'
import { DateTime } from 'luxon'

// Il Guest non può avere un id auto assegnato nella videoroom perché potrebbe collidere
// con uno degli id dei partecipanti, gli si assegna il numero massimo assegnabile agli id
// degli utenti di OC per evitare collisioni (0 non è accettato da janus)
const guestDefaultId = 4294967295

class CustomerChat extends ApiWithRedux {
  private _janus: Janus = new Janus()
  private _mediachat: MediaChatState | null = null
  token: string | null

  constructor() {
    super()
    this.token = sessionStorage.getItem('@occlient/token')
    window.addEventListener('beforeunload', async () => await this.handleLogout())
  }

  set janus(janus: Janus) {
    this._janus = janus
  }

  //region Videoroom Plugin

  getVideoRoom(opts?: GetVideoRoomOptions): Promise<Videoroom> {
    return this._janus.getVideoroom(opts)
  }

  getRandomString(): string {
    return this._janus.randomString(16)
  }

  async initializeVideoRoom(chatId: string, type: MediaType): Promise<void> {
    logger.silly('Initialiazing VideoRoom for media chat...')

    let room
    let pin = this.getRandomString()

    let publisherSession
    try {
      logger.silly('Creating publisher session...')
      publisherSession = await this.getVideoRoom()
    } catch (err) {
      logger.error('Could not initialize publisher session', err)
      return
    }

    if (!publisherSession) {
      return
    }

    logger.silly('Adding publisher session events listeners...')
    this.addPublisherSessionEventListeners(publisherSession, type)
    // Crea la mediachat su janus per ottenere l'id
    try {
      logger.silly('Creating videoroom on janus...')
      room = await publisherSession.create({
        publishers: 4,
        pin,
        bitrate: 256000,
        videocodec: 'vp9',
        record: false,
        rec_path: recordPath
      })
    } catch (err) {
      logger.error('Could not create videoroom', err)
      return
    }

    // esegue il join come publisher nella videoroom (scaturirà la ricezione degli eventi sulla publisher session)
    const me = getMe(this.state)

    try {
      await publisherSession.join('publisher', {
        room,
        pin,
        id: me.id,
        displayName: `${me.surname} ${me.name} `
      })
    } catch (err) {
      logger.error('Could not join videoroom', err)
      return
    }

    // La videoroom è inizializzata, si può iniziare ad aggiornare lo stato di redux
    this._mediachat = { room, pin, publisherSession, type, chatId, sender: this.getUserForChat(me) }
    logger.silly(`Done videoroom created with room: ${room}`)

    this.dispatch(updateMediaChat(this._mediachat))
  }

  addPublisherSessionEventListeners(session: Videoroom, type: MediaType) {
    // Quando il client stesso esegue il join con successo
    session.on('joined', async (info: { private_id: number; publishers: JanusJS.Publisher[] }) => {
      logger.silly('VIDEOROOM EVENT: joined', info)
      const selectedDevices = getSelectedDevices(this.state)
      // Appena entro mando audio e video nella room con i device selezionati
      const devices = {
        audioinput: selectedDevices.microphone,
        videoinput: selectedDevices.webcam,
        audiooutput: selectedDevices.audio
      }

      try {
        await session.sendStream({ audio: true, video: type === 'video', devices })
      } catch (err) {
        logger.error('Could not send stream', err)
        return
      }
      if (this._mediachat) this._mediachat.private_id = info.private_id
      this.dispatch(setChatPrivateId(info.private_id))
      // e mi iscrivo come subscriber per ogni publisher già presente nella stanza
      info.publishers.forEach((p) => this.createSubscriberSession(p))
    })
    // Quando uno dei publisher esce dalla room
    session.on('leaved', (publisherId: number) => {
      logger.silly('VIDEOROOM EVENT: leaved', publisherId)
      this.dispatch(removeChatSubscriberSession(publisherId))
      this.dispatch(removeChatRemoteStream(publisherId))
    })
    // Quando la libreria janus è riuscita a recuperare il proprio stream locale
    session.on('localstream', (stream: MediaStream) => {
      logger.silly('VIDEOROOM EVENT: localstream', stream)
      this.dispatch(setChatLocalStream(stream))
    })
    // Quando si aggiungono dei publishers
    session.on('publishers', (publishers: JanusJS.Publisher[]) => {
      logger.silly('VIDEOROOM EVENT: publishers', publishers)
      publishers.forEach((p) => this.createSubscriberSession(p))
    })
  }

  async createSubscriberSession(publisher: JanusJS.Publisher) {
    const vr = this._mediachat
    if (!vr) return
    logger.debug('Adding subscriber session for', publisher, vr)
    if (!vr || !vr.pin || !vr.room || !vr.private_id) return
    const subscriberSessions = vr.subscriberSessions || new Map()
    if (subscriberSessions.get(publisher.id)) return

    let subscriberSession: Videoroom
    try {
      subscriberSession = await this.getVideoRoom()
    } catch (err) {
      logger.error('Could not get subscriber session', err)
      return
    }

    if (!subscriberSession) return

    logger.debug('Got subscriber session', subscriberSession)
    subscriberSession.on('remotestream', (stream: MediaStream) => {
      logger.silly('VIDEOROOM EVENT: remotestream', stream)
      if (publisher.id === guestDefaultId) {
        logger.silly('Got remostream for guest')
        // Il guest entra sempre con id 4294967295
        this.dispatch(setGuestStream(stream))
      } else {
        // tutti gli operatori entrano con il proprio id
        this.dispatch(addChatRemoteStream({ id: publisher.id, stream }))
      }
    })

    try {
      await subscriberSession.join('subscriber', {
        room: vr.room,
        pin: vr.pin,
        publisher: publisher.id,
        subscriber: vr.private_id
      })
    } catch (err) {
      logger.error('Could not join videoroom as a subscriber', err)
      return
    }
    this.dispatch(addChatSubscriberSession({ id: publisher.id, session: subscriberSession }))

    logger.debug('Correctly joined as subscriber')
  }

  async closeMediaChat() {
    if (!this._mediachat) return
    if (this._mediachat.room && this._mediachat.publisherSession) {
      await this._mediachat.publisherSession.destroy(this._mediachat.room).catch((err: any) => logger.error(err))
    }
    this._mediachat = null
    this.dispatch({
      type: t.SET_MEDIACHAT,
      payload: null
    })
  }
  //endregion

  async handleLogout() {
    this.token = null
    if (this._mediachat && this._mediachat.publisherSession) {
      try {
        await this._mediachat.publisherSession.leave()
      } catch (err) {
        logger.error('Could not leave mediachat on logout')
      }
    }
  }

  requestSpy({ chatId, userId }: { chatId: string; userId: number }, callback: SpyChatResponseFeedback) {
    this.dispatch({
      type: WS_EMIT,
      payload: {
        name: 'core',
        eventName: evs.request_spy,
        eventPayload: { chatId, userId },
        eventAckFunction: callback || function () {}
      }
    })
  }

  bookedChatResponse(
    payload: { chat: string; response: boolean; supervisor?: number },
    callback?: BookedChatResponseFeedback
  ) {
    let eventToSend
    if (payload.supervisor)
      eventToSend = payload.response ? evs.booking_from_supervisor_accepted : evs.booking_from_supervisor_rejected
    else eventToSend = payload.response ? evs.booking_accept : evs.booking_reject

    console.log('*****************************', payload)
    this.dispatch({
      type: WS_EMIT,
      payload: {
        name: 'core',
        eventName: eventToSend,
        eventPayload: { chat: payload.chat, supervisorId: payload.supervisor },
        eventAckFunction: callback || function () {}
      }
    })
  }

  sendEvent(payload: ChatEvent) {
    this.dispatch({
      type: WS_EMIT,
      payload: {
        name: 'core',
        eventName: evs.new_message,
        eventPayload: payload
      }
    })
  }

  closeChat(chatId: string) {
    this.dispatch({
      type: WS_EMIT,
      payload: {
        name: 'core',
        eventName: evs.close,
        eventPayload: { chat: chatId }
      }
    })
  }

  transferToOperator(chatId: string, operatorId: number, callback?: (fb: boolean) => void) {
    this.dispatch({
      type: WS_EMIT,
      payload: {
        name: 'core',
        eventName: evs.transfer_operator,
        eventPayload: { chat: chatId, operator: operatorId },
        eventAckFunction: callback || function () {}
      }
    })
  }

  transferToDepartment(chatId: string, departmentId: number, callback?: (fb: boolean) => void) {
    this.dispatch({
      type: WS_EMIT,
      payload: {
        name: 'core',
        eventName: evs.transfer_department,
        eventPayload: { chat: chatId, department: departmentId },
        eventAckFunction: callback || function () {}
      }
    })
  }

  cancelTransfer(chatId: string, callback: (fb: boolean) => void) {
    this.dispatch({
      type: WS_EMIT,
      payload: {
        name: 'core',
        eventName: evs.transfer_cancel,
        eventPayload: { chat: chatId },
        eventAckFunction: callback
      }
    })
  }

  transferAccept(chatId: string, callback: (fb: boolean) => void) {
    this.dispatch({
      type: WS_EMIT,
      payload: {
        name: 'core',
        eventName: evs.transfer_accept,
        eventPayload: chatId,
        eventAckFunction: callback
      }
    })
  }

  transferReject(chatId: string) {
    this.dispatch({
      type: WS_EMIT,
      payload: {
        name: 'core',
        eventName: evs.transfer_reject,
        eventPayload: chatId
      }
    })
  }

  sneakpeekStart(chatId: string) {
    this.dispatch({
      type: WS_EMIT,
      payload: {
        name: 'core',
        eventName: evs.sneakpeek_start,
        eventPayload: { chat: chatId }
      }
    })
  }

  sneakpeekStop(chatId: string) {
    this.dispatch({
      type: WS_EMIT,
      payload: {
        name: 'core',
        eventName: evs.sneakpeek_stop,
        eventPayload: { chat: chatId }
      }
    })
  }

  requestMedia() {
    if (!this._mediachat) return
    this.dispatch({
      type: WS_EMIT,
      payload: {
        name: 'core',
        eventName: evs.request_media,
        eventPayload: {
          chat: this._mediachat.chatId,
          type: this._mediachat.type,
          room: this._mediachat.room,
          pin: this._mediachat.pin
        },
        eventAckFunction: (done: boolean) => {
          if (!done) this.closeMediaChat().catch(() => {})
        }
      }
    })
  }

  stopMedia(chatId: string) {
    this.dispatch({
      type: WS_EMIT,
      payload: {
        name: 'core',
        eventName: evs.stop_media,
        eventPayload: { chat: chatId }
      }
    })
  }

  startProactiveChat(payload: { departmentId: number; guestId: string }, callback: StartProactiveFeedback) {
    this.dispatch({
      type: WS_EMIT,
      payload: {
        name: 'core',
        eventName: evs.start_proactive,
        eventPayload: { department: payload.departmentId, guest: payload.guestId },
        eventAckFunction: callback
      }
    })
  }

  //region Helpers

  sortByLastMessageDate(a: Chat, b: Chat): number {
    const lastMessageA = a.events[a.events.length - 1]
    const lastMessageB = b.events[b.events.length - 1]

    if (!lastMessageA) return 1
    if (!lastMessageB) return -1

    return DateTime.fromSQL(lastMessageB.date).toMillis() - DateTime.fromSQL(lastMessageA.date).toMillis()
  }

  getGuestFullname(g: Guest): string {
    if (!g || !g.info || !g.info.name || !g.info.surname) return getTranslation(this.state).visitor
    return `${g.info.surname} ${g.info.name}`
  }

  parseSystemMessage(event: ChatEvent, translation: Translation): string {
    if (!event.subtype) return ''
    let result = translation.customerChatSystemEvents[event.subtype]

    if (!result) {
      console.log('NEW SYSTEM EVENT:', event)
      return ''
    }

    // recupera la stringa di accesso all'oggetto dentro event
    const params = result.match(/<[A-z]+[.A-z]*>/g)

    if (params) {
      params.forEach(
        (p: string) => (result = result.replace(p, accessPropertyByString(event, p.substring(1, p.length - 1))))
      )
    }

    return result
  }

  getUserForChat = (user: User): UserForChat => ({
    id: user.id,
    username: user.username,
    name: user.name,
    surname: user.surname
  })

  //endregion
}

const customerChat = new CustomerChat()
export default customerChat

const accessPropertyByString = (root: Object, accessString: string) => {
  try {
    const props = accessString.split('.')
    let result = root

    props.forEach((p) => {
      // @ts-ignore
      result = result[p]
    })

    return result || ''
  } catch (e) {
    return ''
  }
}

//region Types

type BookedChatResponseFeedback = (fb: { error: boolean; chat: Chat }) => void

type SpyChatResponseFeedback = (fb: { error: boolean; errorMessage: string; chat: Chat }) => void

type StartProactiveFeedback = (fb: { error: boolean; message: string; chat: Chat }) => void

//endregion
