import { Message } from '../../../../api/rest'
import { MessageAck } from '../../../common'
import { emojiRegex, linkRegex, mailRegex } from '../../../../constants/regexs'
import { stripHtml } from '../../../../helpers/common'
import { rem } from '../../../../helpers/style'
import { getMe } from '../../../../store/selectors'
import { DateTime } from 'luxon'
import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

type Props = {
  message: Message
  isGroup: boolean
  isSequence: boolean
}

const MessageBaloon: React.FC<Props> = ({ message, isGroup, isSequence }) => {
  const me = useSelector(getMe)

  const isMine: boolean = useMemo(() => message.sender.username === me.username, [me, message])

  const myNumber = useCallback(() => {
    let num = 0
    for (let i = 0; i < message.acks.length - 1; i++) {
      if (message.acks[i].username === me.username) {
        num = i
        break
      }
    }
    return num
  }, [me, message.acks])

  const selectColor = (number: number) => {
    const hue = number * 137.508 // use golden angle approximation
    return `hsl(${hue},50%,75%)`
  }

  const emojisOnly: number = useMemo(() => {
    if (message.type !== 'text') return 0
    // se il messaggio è composto solo da emoji
    if (emojiRegex.test(message.body)) {
      // conta quante sono
      return countEmojis(message.body)
    } else {
      return 0
    }
  }, [message])

  const parseMessageText = (text: string) => {
    text = stripHtml(text)
    const mailto = `<a href='mailto:*' target='_blank' rel='noopener noreferrer' ">mailto:*</a>`

    //sostituisco le mail altrimenti vengono
    // catturate dalla regex dei link
    // e interpretate male
    const mails = text.match(mailRegex)
    if (mails && mails.length > 0)
      mails.forEach((mail) => {
        text = text.split(mail).join(mailto)
      })
    //inserisco i link nel body del messaggio
    const links = text.match(linkRegex)
    const realLinks: string[] = []
    if (links && links.length > 0) {
      links
        .filter((l, index) => links.indexOf(l) === index)
        .map((l) => {
          if (!l.toLowerCase().includes('http')) {
            const completeLink = 'http://' + l
            realLinks.push(completeLink)
          } else {
            realLinks.push(l)
          }
          return l
        })
        .forEach((link, index) => {
          text = text
            .split(link)
            .join(
              `<a href='${realLinks[index]}' target='_blank' rel='noopener noreferrer'" class="MuiTypography-root-bWuLxc jndiPU MuiTypography-root MuiTypography-inherit MuiLink-root-irSnZT WsIWF MuiLink-root MuiLink-underlineAlways">${link}</a>`
            )
        })
    }
    //inserisco la mail nel body del messaggio
    if (mails && mails.length > 0)
      mails.forEach((mail) => {
        text = text
          .split(mailto)
          .join(
            `<a href='mailto:${mail}'" class="MuiTypography-root-bWuLxc jndiPU MuiTypography-root MuiTypography-inherit MuiLink-root-irSnZT WsIWF MuiLink-root MuiLink-underlineAlways">${mail}</a>`
          )
      })
    return text
  }

  return (
    <Container mine={isMine} id={message.id} isGroup={isGroup && !isMine}>
      {isGroup && !isMine && !isSequence && (
        <Name color={selectColor(myNumber())}>
          {message.sender.name} {message.sender.surname}
        </Name>
      )}
      <Text
        mine={isMine}
        emojisOnly={emojisOnly}
        dangerouslySetInnerHTML={{ __html: parseMessageText(message.body) }}
      />
      <Bottom mine={isMine}>
        <div style={{ opacity: 0.7, placeSelf: 'center' }}>
          {DateTime.fromMillis(message.timestamp).toLocaleString(DateTime.TIME_SIMPLE)}
        </div>
        {isMine && <MessageAck message={message} />}
      </Bottom>
    </Container>
  )
}

export default MessageBaloon

const countEmojis = (str: string) => {
  const re = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g
  return ((str || '').match(re) || []).length
}

//region Style

const Container = styled.div<{ mine: boolean; isGroup: boolean }>`
  display: grid;
  overflow-wrap: anywhere;

  background: ${({ mine, theme }) => {
    if (theme.palette.mode === 'light') return mine ? '#def9ff' : theme.palette.grey[100]
    else return mine ? theme.palette.primary.light : '#3e3e3e'
  }};
  width: fit-content;
  height: fit-content;
  padding: ${rem(0.5)};
  border-radius: 15px;
  ${({ mine }) => mine && 'border-top-right-radius: 0;'};
  ${({ mine }) => !mine && 'border-top-left-radius: 0;'};
  align-self: ${({ mine }) => (mine ? 'flex-end' : 'flex-start')};
  font-size: ${rem(0.75)};
  max-width: min(85%, 500px);
  grid-row-gap: ${rem(0.1)};
  color: ${({ theme }) => theme.palette.text?.primary};
  margin: ${rem(0.15)} 0;
`

const Name = styled.div<{ color: string }>`
  font-weight: 700;
  color: ${({ color }) => color};
`
const Bottom = styled.div<{ mine: boolean }>`
  font-size: ${rem(0.5)};
  display: grid;
  grid-template-columns: ${({ mine }) => (mine ? 'auto 10px' : 'auto')};
  place-content: center end;
  grid-column-gap: ${rem(0.4)};
  height: 100%;
`

const Text = styled.span<{ emojisOnly: number; mine: boolean }>`
  font-size: ${({ emojisOnly }) => (emojisOnly ? css`max(calc(40px / ${emojisOnly}), ${rem(0.65)})` : 'inherit')};
  letter-spacing: ${({ emojisOnly }) => (emojisOnly ? '5px' : 'unset')};
  overflow-wrap: anywhere;
  hyphens: auto;
  display: inline-block;
  user-select: text;
  a {
    color: inherit;
    hyphens: auto;
    overflow-wrap: anywhere;
    display: inline-block;
  }
`
//endregion
