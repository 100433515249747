import { DialpadRounded } from '@mui/icons-material'
import { Fab } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { changeRoute } from '../../store/applicationState/actions'
import { getDimensions, getPath, getTheme } from '../../store/selectors'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { smaller } from '../../constants'

export const NumPadButton: React.FC = () => {
  const { current } = useSelector(getPath)
  const { fontSize, width } = useSelector(getDimensions)
  const theme = useSelector(getTheme)

  const dispatch = useDispatch()

  const { pathname } = useLocation()

  const muiTheme = useTheme()

  const showNumPad =
    !pathname.includes('keypad') &&
    !pathname.includes('call') &&
    !pathname.match(/$\/phone\/address-book\/.*\/.*/g) &&
    width < smaller.width * 2

  const handleFabClick = () => {
    dispatch(changeRoute({ current: '/phone/keypad', previous: current }) as any)
  }

  return showNumPad ? (
    <Fab
      aria-label="numpad"
      size="small"
      style={{
        backgroundColor: theme === 'light' ? '#115B72' : '#37C6F2',
        position: 'absolute',
        bottom: 10,
        right: 20,
        width: fontSize * 2,
        height: fontSize * 2
      }}
      onClick={handleFabClick}
    >
      <DialpadRounded htmlColor={muiTheme.palette.background.paper} />
    </Fab>
  ) : null
}
