import { connectRedux } from '../api'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { createLogger } from 'redux-logger'
import { thunk } from 'redux-thunk'
import { OCClient } from '../api'
import applicationState from './applicationState/reducer'
import customerChats from './customerChats/reducer'
import entities from './entities/reducer'
import language from './language/reducer'
import login from './login/reducer'
import messenger from './messenger/reducer'
import phone from './phone/reducer'
import socketMiddleware from './sockets/middleware'
import theme from './theme/reducer'

export const rootReducer = combineReducers({
  login,
  language,
  theme,
  phone,
  customerChats,
  applicationState,
  entities,
  messenger
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const middlewares = [thunk, socketMiddleware]

if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_REDUX_LOGGER) {
  const logger = createLogger({ collapsed: true })
  middlewares.push(logger as any)
}

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)))

connectRedux(store.dispatch, store.getState)

if (window.occlient) {
  window.occlient.dispatch = store.dispatch
  window.occlient.getState = store.getState
} else {
  window.occlient = new OCClient(store.dispatch, store.getState, window.occlientSettings)
}

export default store
