import { rem } from '../../../helpers/style'
import { Card, Popper } from '@mui/material'
import { changeRoute } from '../../../store/applicationState/actions'
import { CallStatus } from '../../../store/phone/types'
import { getCallInProgress, getDimensions, getPath } from '../../../store/selectors'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { DefaultTheme } from 'styled-components'
import MuteIcon from '../../animated-icons/call/mute'
import PhoneIcon from '../../animated-icons/call/ongoing'
import PauseIcon from '../../animated-icons/call/pause'
import TransferIcon from '../../animated-icons/call/transfer'
import CallInfo from '../../pages/Phone/Call/CallInfo/CallInfo'
import CallInTransferring from '../../pages/Phone/Call/CallInfo/CallInfoTransferring'

const CallInProgress: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const callInProgress = useSelector(getCallInProgress)
  const { fontSize } = useSelector(getDimensions)
  const { current } = useSelector(getPath)

  const dispatch = useDispatch()

  const getPhoneIconStyle = (callStatus?: CallStatus): string | undefined => {
    if (!callStatus) return
    if (callStatus === CallStatus.incoming) return '#1BB21F'
    if (callStatus === CallStatus.ongoing) return '#1BB21F'
    if (callStatus === CallStatus.hold) return '#D50D0D'
    if (callStatus === CallStatus.muted) return '#D50D0D'
    return '#2B618D'
  }

  const getPhoneIcon = () => {
    if (!callInProgress) return
    switch (+callInProgress.status) {
      case CallStatus.hold:
        return <PauseIcon />
      case CallStatus.muted:
        return <MuteIcon />
      case CallStatus.transferring:
        return <TransferIcon />
      default:
        return <PhoneIcon />
    }
  }

  const getCallLink = (): string => {
    if (!callInProgress) return '/phone'
    switch (+callInProgress.status) {
      case CallStatus.incoming:
        if (current.includes('messenger') || current.includes('customer-chat')) return '/phone'
        else return '/phone/incoming-call'
      case CallStatus.transferring:
        return '/phone/transferring-call/:type'
      default:
        return '/phone/call'
    }
  }

  const handleClick = () => {
    const callLink = getCallLink()
    // si torna per forza alla home se si è navigato fuori, non c'è modo di ricordare
    dispatch(changeRoute({ current: callLink, previous: '/phone' }) as any)
  }

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  return (
    <Container onClick={handleClick}>
      <CallIconContainer
        color={getPhoneIconStyle(callInProgress?.status)}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {getPhoneIcon()}
      </CallIconContainer>
      <Popper id="mouse-over-popover" open={!!anchorEl} anchorEl={anchorEl} placement="bottom-start">
        <Card style={{ padding: rem(0.8, fontSize) }}>
          <InfoContainer>
            {callInProgress?.status === CallStatus.transferring ? (
              <CallInTransferring counterFont={0.5} labelFont={0.65} />
            ) : (
              <CallInfo counterFont={0.5} labelFont={0.75} />
            )}
          </InfoContainer>
        </Card>
      </Popper>
    </Container>
  )
}

export default CallInProgress

//region Style

const Container = styled.div`
  place-content: center;
  display: grid;
`

const CallIconContainer = styled.div<{ theme: DefaultTheme; color?: string }>`
  display: grid;
  & > svg {
    width: calc(var(--topbar-height) - 10px);
    height: calc(var(--topbar-height) - 10px);
    cursor: pointer;
  }
`
const InfoContainer = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
`

//endregion
