import React from 'react'
import { CirclePulse } from './common'

const Transfer: React.FC = () => {
  return (
    <svg className="button" viewBox="0 0 40 40">
      <CirclePulse cx="50%" cy="50%" r="10px" inner="#45a3e6" outer="#148de3" />
      <CirclePulse delay="1s" cx="50%" cy="50%" r="10px" inner="#45a3e6" outer="#148de3" />
      <circle fill="#45a3e6" cx="50%" cy="50%" r="12px" />
      <svg width="20px" height="20px" viewBox="0 0 24 24" fill="white" x="10" y="10">
        <path fill="none" d="M0 0h24v24H0V0z" />
        <path d="M22.65 5.65l-3.79-3.79c-.32-.32-.86-.1-.86.35V4h-3.5c-.28 0-.5.22-.5.5v3c0 .28.22.5.5.5H18v1.79c0 .45.54.67.85.35l3.79-3.79c.2-.19.2-.51.01-.7zm-3.42 9.61l-2.54-.29c-.61-.07-1.21.14-1.64.57l-1.84 1.84c-2.83-1.44-5.15-3.75-6.59-6.59l1.85-1.85c.43-.43.64-1.03.57-1.64l-.29-2.52c-.12-1.01-.97-1.77-1.99-1.77H5.03c-1.13 0-2.07.94-2 2.07.53 8.54 7.36 15.36 15.89 15.89 1.13.07 2.07-.87 2.07-2v-1.73c.01-1.01-.75-1.86-1.76-1.98z" />
      </svg>
    </svg>
  )
}

export default Transfer
