import { ThunkDispatch } from 'redux-thunk'
import { Socket } from 'socket.io-client'
import log from '../../../helpers/logger'
import * as a from '../../../store/actions'
import { userLeftVideoRoom, videoRequestResponseReceived, videoRoomRequestCanceled } from '../../../store/actions'
import { RootState } from '../../../store/types'
import { Conversation, Member, Message } from '../../rest'
import * as t from './eventsTypes'

type TD = ThunkDispatch<RootState, null, any>

export const addEventListeners = (socket: Socket, dispatch: TD) => {
  log.debug('Adding event listeners for messenger socket')
  socket.on(t.conversations_list, (conversations: Conversation[]) => {
    dispatch(a.conversationsList(conversations))
  })
  socket.on(t.new_group, (conversation: Conversation) => {
    dispatch(a.newConversation(conversation))
  })
  socket.on(t.new_message, (message: Message) => {
    dispatch(a.messageReceived(message))
  })
  socket.on(t.receive_ack, (payload: { conversation: string; message: string; user: Member; timestamp: number }) => {
    dispatch(a.ackReceived({ ...payload, type: 'received' }))
  })
  socket.on(t.read_ack, (payload: { conversation: string; message: string; user: Member; timestamp: number }) => {
    dispatch(a.ackReceived({ ...payload, type: 'read' }))
  })
  socket.on(t.add_users_to_group, (payload: { conversation: string; members: Member[]; messages: Message[] }) => {
    dispatch(a.addUsersToGroup(payload))
  })
  socket.on(t.remove_user_from_group, (payload: { conversation: string; member: Member; messages: Message[] }) => {
    dispatch(a.removeUserFromGroup(payload))
  })
  socket.on(t.add_admin_to_group, (payload: { conversation: string; admin: Member }) => {
    dispatch(a.addAdminToGroup(payload))
  })
  socket.on(t.video_request, (payload: { sender: Member; pin: string; room: number; members: Member[] }) => {
    dispatch(a.videoRequestReceived(payload))
  })
  socket.on(t.video_response, (payload: { response: boolean; sender: Member }) => {
    dispatch(videoRequestResponseReceived(payload))
  })
  socket.on(t.video_user_left, (payload: { user: Member }) => {
    dispatch(userLeftVideoRoom(payload))
  })
  socket.on(t.video_request_cancel, () => {
    dispatch(videoRoomRequestCanceled())
  })
}

export const subscribeToChannels = () => {
  //socket.emit('subscribe', 'status-change')
}

export const events = [...Object.values(t)]
