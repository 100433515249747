import { IconButton } from '@mui/material'
import React from 'react'

type Props = {
  labelActive?: string
  labelDisactive?: string
  iconActive: React.ReactElement
  iconDisactive: React.ReactElement
  isActive: boolean
  onClick: () => void
  containerStyle?: React.CSSProperties
  onMouseEnter?: () => any
  onMouseLeave?: () => any
  disabled?: boolean
}
export const ControlButton = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const {
    isActive,
    iconActive,
    iconDisactive,
    onClick,
    containerStyle = {},
    disabled,
    onMouseEnter = () => {},
    onMouseLeave = () => {}
  } = props

  return (
    <IconButton
      ref={ref}
      onClick={() => (disabled === undefined ? onClick() : !disabled && onClick())}
      style={containerStyle}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      disabled={disabled}>
      {isActive ? iconActive : iconDisactive}
    </IconButton>
  )
})
