import { User } from '../../../../api/rest'
import { rem } from '../../../../helpers/style'
import { Button, TextField } from '@mui/material'
import { changeRoute, newConversation } from '../../../../store/actions'
import { getMe, getPath, getTranslation } from '../../../../store/selectors'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import messenger from '../../../../api/messenger'
import { UserSelection } from '../../../common'

type Props = {
  closeModal: Function
}
const NewGroup: React.FC<Props> = ({ closeModal }) => {
  const [nameInput, setNameInput] = useState('')
  const [name, setName] = useState('')
  const [selectedUsers, setSelectedUsers] = useState<Map<number, User>>(new Map())

  const me = useSelector(getMe)
  const { current } = useSelector(getPath)
  const translation = useSelector(getTranslation)
  const dispatch = useDispatch()

  const createGroup = () => {
    if (selectedUsers.size < 2) return
    messenger.createGroup(name, [me, ...selectedUsers.values()], ({ conversation }) => {
      closeModal()
      dispatch(newConversation(conversation))
      dispatch(changeRoute({ current: `/messenger/${conversation.id}`, previous: current }))
    })
  }

  return (
    <Container chooseName={!name}>
      <Label>
        {translation.newGroup} {name && `(${name})`}
      </Label>
      {name ? (
        <>
          <UserSelection selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} />
          <Button color="primary" type="submit" disabled={selectedUsers.size < 2} onClick={createGroup}>
            {translation.create}
          </Button>
        </>
      ) : (
        <Form
          onSubmit={(e: React.FormEvent) => {
            e.preventDefault()
            setName(nameInput)
          }}>
          <TextField
            variant="outlined"
            size="small"
            style={{ minHeight: 0 }}
            value={nameInput}
            onChange={(e) => setNameInput(e.currentTarget.value)}
          />
          <Button color="primary" type="submit" disabled={!nameInput}>
            {translation.next}
          </Button>
        </Form>
      )}
    </Container>
  )
}

export default NewGroup

//region Style

const Container = styled.div<{ chooseName: boolean }>`
  display: grid;
  grid-template-rows: ${({ chooseName }) => (chooseName ? '1fr 2fr' : '1fr 1fr 8fr 1fr')};
  grid-template-columns: 1fr;
  width: 100%;
  height: ${({ chooseName }) => (chooseName ? 'fit-content' : '100%')};
  place-content: start center;
`

const Label = styled.div`
  font-size: ${rem(0.8)};
  color: ${({ theme }) => theme.palette.primary.main};
`

const Form = styled.form`
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-rows: 2fr 1fr;
`

//endregion
