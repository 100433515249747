import { Messenger } from '../../../../api'
import { rem } from '../../../../helpers/style'
import { Button } from '@mui/material'
import { addUsersToVideoRoom } from '../../../../store/actions'
import { getDimensions, getMessengerVideoRoom, getTranslation, getUsersArray } from '../../../../store/selectors'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Statuses, User } from '../../../../api/rest'
import { UserSelection } from '../../../common'

type Props = {
  closeModal: Function
}
const AddInVideoCall: React.FC<Props> = ({ closeModal }) => {
  const [selectedUsers, setSelectedUsers] = useState<Map<number, User>>(new Map())
  const videoroom = useSelector(getMessengerVideoRoom)
  const translation = useSelector(getTranslation)
  const users = useSelector(getUsersArray)
  const { fontSize } = useSelector(getDimensions)
  const dispatch = useDispatch()

  const getExlcudedFromSelection = () => {
    if (!videoroom || !videoroom.members) return []
    const members = videoroom.members.map((m) => m.username)
    const offlineUsers = users.filter((u) => u.state && u.state.id !== Statuses.idle).map((u) => u.username)
    return [...new Set(members.concat(offlineUsers))]
  }

  const inviteSelectedUsers = () => {
    if (selectedUsers.size === 0) {
      closeModal()
      return
    }

    dispatch(addUsersToVideoRoom([...selectedUsers.values()].map(Messenger.getMemberFromUser)))
    closeModal()
  }

  return (
    <Container>
      <Title fontSize={fontSize}>{translation.addInVideoCall}</Title>
      <UserSelection
        selectedUsers={selectedUsers}
        setSelectedUsers={(users) => {
          if (!videoroom || !videoroom.members) return
          if (users.size + videoroom.members.length > 4) return
          setSelectedUsers(users)
        }}
        exclude={getExlcudedFromSelection()}
      />
      <Button color="primary" onClick={inviteSelectedUsers} disabled={selectedUsers.size === 0}>
        {translation.next}
      </Button>
      <Button
        color="secondary"
        onClick={() => {
          setSelectedUsers(new Map())
          closeModal()
        }}
      >
        {translation.cancel}
      </Button>
    </Container>
  )
}

export default AddInVideoCall

//region Style

const Container = styled.div`
  display: grid;
  grid-template-rows: 30px 30px auto 40px 40px;
  grid-template-columns: 1fr;
  row-gap: ${rem(0.4)};
  width: 100%;
  height: 100%;
`

const Title = styled.div<{ fontSize: number }>`
  font-size: ${({ fontSize }) => rem(1.1, fontSize)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`
//nedregion
