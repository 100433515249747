import { Videoroom } from '@bytewise/janus'
import { AnyAction } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { Conversation, Member, Message } from '../../api/rest'
import { RootState } from '../types'

export const CONVERSATIONS_LIST = 'CONVERSATIONS_LIST'
export const SET_ACTIVE_CONVERSATION = 'SET_ACTIVE_CONVERSATION'
export const ADD_MESSAGE = 'ADD_MESSAGE'
export const SET_UNREAD_MESSAGES = 'SET_UNREAD_MESSAGES'
export const SET_VIDEOROOM = 'SET_VIDEOROOM'
export const ADD_PUBLISHER_SESSION = 'ADD_PUBLISHER_SESSION'
export const SET_PRIVATE_ID = 'SET_PRIVATE_ID'
export const SET_SUBSCRIBER_SESSIONS = 'SET_SUBSCRIBER_SESSIONS'
export const SET_LOCAL_STREAM = 'SET_LOCAL_STREAM'
export const SET_REMOTE_STREAMS = 'SET_REMOTE_STREAMS'
export const VIDEOROOM_UPDATE = 'VIDEOROOM_UPDATE'

export type Invite = {
  username: string
  response?: boolean
  pending: boolean
  fullname: string
}
export type VideoRoomState = {
  pin?: string
  room?: number
  sender?: Member
  members?: Member[]
  publisherSession?: Videoroom
  subscriberSessions?: Map<number, Videoroom>
  localStream?: MediaStream
  remoteStreams?: Map<number, MediaStream>
  private_id?: number
  invites?: Invite[]
  startTime?: number
}

export interface MessengerState {
  conversations: Map<string, Conversation>
  activeConversation: string | null
  unreadMessagesByChat: Map<string, string[]>
  unreadMessages: number
  videoroom: VideoRoomState | null
}

export interface ConversationsListAction {
  type: typeof CONVERSATIONS_LIST
  payload: Map<string, Conversation>
}

export interface SetActiveConversationAction {
  type: typeof SET_ACTIVE_CONVERSATION
  payload: string | null
}

export interface AddMessageAction {
  type: typeof ADD_MESSAGE
  payload: Message
}

export interface SetUnreadMessages {
  type: typeof SET_UNREAD_MESSAGES
  payload: { unreadMessagesByChat: Map<string, string[]>; unreadMessages: number }
}

export interface SetUnreadMessagesWithoutTotal {
  type: typeof SET_UNREAD_MESSAGES
  payload: { unreadMessagesByChat: Map<string, string[]>; unreadMessages: number }
}

export interface SetVideoRoom {
  type: typeof SET_VIDEOROOM
  payload: VideoRoomState | null
}

export interface AddPublisherSession {
  type: typeof ADD_PUBLISHER_SESSION
  payload: Videoroom
}

export interface SetPrivateId {
  type: typeof SET_PRIVATE_ID
  payload: number
}

export interface SetSubscriberSessions {
  type: typeof SET_SUBSCRIBER_SESSIONS
  payload: Map<number, Videoroom>
}

export interface SetLocalStream {
  type: typeof SET_LOCAL_STREAM
  payload: MediaStream
}

export interface SetRemoteStreams {
  type: typeof SET_REMOTE_STREAMS
  payload: Map<number, MediaStream>
}

export interface VideoroomUpdate {
  type: typeof VIDEOROOM_UPDATE
  payload: Partial<VideoRoomState>
}

export type ConversationsListThunkAction = ThunkAction<void, RootState, null, ConversationsListAction>
export type AddMessageThunkAction = ThunkAction<void, RootState, null, ConversationsListAction>
export type NewConversationThunkAction = ThunkAction<void, RootState, null, ConversationsListAction>
export type ReadMessagesForConversationThunkAction = ThunkAction<void, RootState, null, SetUnreadMessagesWithoutTotal>
export type AddUnreadMessageThunkAction = ThunkAction<void, RootState, null, SetUnreadMessagesWithoutTotal>
export type AckReceivedThunkAction = ThunkAction<void, RootState, null, ConversationsListAction>
export type SearchAfterThunkAction = ThunkAction<void, RootState, null, ConversationsListAction>
export type AddRemoveUsersToGroupThunkAction = ThunkAction<void, RootState, null, ConversationsListAction>
export type UpdateVideoRoomAction = ThunkAction<void, RootState, null, SetVideoRoom>
export type AddRemovePublisherSessionThunkAction = ThunkAction<void, RootState, null, SetSubscriberSessions>
export type AddRemoveRemoteStreamsThunkAction = ThunkAction<void, RootState, null, SetRemoteStreams>
export type CanDispatchAnyAction = ThunkAction<void, RootState, null, AnyAction>

export type MessengerActionTypes =
  | ConversationsListAction
  | SetActiveConversationAction
  | AddMessageAction
  | SetUnreadMessages
  | SetVideoRoom
  | AddPublisherSession
  | SetPrivateId
  | SetSubscriberSessions
  | SetLocalStream
  | SetRemoteStreams
  | VideoroomUpdate
