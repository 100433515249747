import {Device} from '@bytewise/janus'
import {DynamicVolumeIcon} from '../../common'
import {smaller} from '../../../constants'
import {rem} from '../../../helpers/style'
import {
  Brightness4Rounded,
  CameraRounded,
  MicRounded,
  NotificationsActiveRounded,
  RingVolumeRounded,
  SpeakerRounded
} from '@mui/icons-material'
import TranslateIcon from '@mui/icons-material/Translate'
import {Slider, Switch} from '@mui/material'
import {editSettings, setTheme} from '../../../store/actions'
import {
  getAudioVolume,
  getDevices,
  getNotifications,
  getRingerVolume,
  getSelectedDevices,
  getSelectedLanguage,
  getTheme,
  getTranslation
} from '../../../store/selectors'
import React, {useCallback, useState} from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import {useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'
import {Select, Title} from '../../common'
import withAuthentication from '../../hoc/withAuthentication'
import {useRest} from '../../../hooks'
import {LanguageCode} from '../../../api/rest'
import logger from '../../../helpers/logger'

const SettingsPage: React.FC = () => {
  const theme = useSelector(getTheme)

  const dispatch = useDispatch()

  const notifications = useSelector(getNotifications)
  const translation = useSelector(getTranslation)
  const ringerVolume = useSelector(getRingerVolume)
  const audioVolume = useSelector(getAudioVolume)
  const devices = useSelector(getDevices)
  const selectedDevices = useSelector(getSelectedDevices)
  const selectedLanguage: LanguageCode = useSelector(getSelectedLanguage)

  const { results: languages } = useRest<{ name: string; code: LanguageCode }>('languages')
  const { post: changeUserLanguage } = useRest('change-my-language', { lazy: true })
  const { post: changeUserTheme } = useRest('change-my-theme', { lazy: true })
  const [ringerVolumeView, setRingerVolumeView] = useState(ringerVolume)
  const [audioVolumeView, setAudioVolumeView] = useState(audioVolume)

  const handleChangeTheme = useCallback(
    (theme: 'dark' | 'light') => {
      changeUserTheme({ theme })
        .then(() => {
          dispatch(setTheme(theme) as any)
        })
        .catch((e) => {
          logger.error(e)
        })
    },
    [changeUserTheme, dispatch]
  )

  const handleChangeLang = useCallback(
    (langCode?: LanguageCode) => {
      if (langCode) {
        changeUserLanguage({ language: langCode })
          .then(() => {
            window.occlient.setLanguage(langCode)
          })
          .catch((e) => {
            logger.error(e)
          })
      }
    },
    [changeUserLanguage]
  )

  return (
    <ScrollBar>
      <Page>
        <Switches>
          {window.occlient.settings.showChooseTranslations && (
            <SwitchesRow>
              <Title
                label={translation.changeLanguage}
                icon={<TranslateIcon />}
                labelStyle={{ fontWeight: 600, fontSize: 'inherit' }}
              />
              <Select<{ code: LanguageCode; name: string }>
                options={languages}
                selected={languages.find((item) => item.code === selectedLanguage)}
                onChange={(o) => handleChangeLang(o?.code)}
                getLabel={(o) => o.name}
                getValue={(o) => o.code}
                required={true}
                style={{ width: '100%' }}
              />
            </SwitchesRow>
          )}
          {window.occlient.settings.showThemeButton && (
            <SwitchesRow>
              <Title
                label={translation.darkTheme}
                icon={<Brightness4Rounded />}
                labelStyle={{ fontWeight: 600, fontSize: 'inherit' }}
              />
              <Switch
                size="small"
                color="primary"
                checked={theme === 'dark'}
                onChange={() => {
                  handleChangeTheme(theme === 'dark' ? 'light' : 'dark')
                }}
              />
            </SwitchesRow>
          )}
          <SwitchesRow>
            <Title
              label={translation.notify}
              icon={<NotificationsActiveRounded />}
              labelStyle={{ fontWeight: 600, fontSize: 'inherit' }}
            />
            <Switch
              size="small"
              color="primary"
              checked={notifications}
              disabled={!('Notification' in window) || Notification.permission === 'denied'}
              onChange={() => {
                dispatch(editSettings({ notifications: !notifications }) as any) as any
              }}
            />
          </SwitchesRow>
        </Switches>
        <Devices>
          <Row>
            <Title
              label={translation.mic}
              icon={<MicRounded />}
              labelStyle={{ fontWeight: 600, fontSize: 'inherit' }}
            />
            <Select<Device>
              options={devices.filter((d) => d.kind === 'audioinput' && d.label)}
              selected={selectedDevices.microphone}
              onChange={(selected) => {
                dispatch(
                  editSettings({
                    selectedDevices: {
                      ...selectedDevices,
                      microphone: selected as MediaDeviceInfo
                    }
                  }) as any
                )
              }}
              getLabel={(o) => o.label}
              getValue={(o) => o.deviceId}
              required={true}
              style={{ width: '100%' }}
            />
          </Row>
          <Row>
            <Title
              label={translation.speaker}
              icon={<SpeakerRounded />}
              labelStyle={{ fontWeight: 600, fontSize: 'inherit' }}
            />
            <Select<Device>
              options={devices.filter((d) => d.kind === 'audiooutput' && d.label)}
              selected={selectedDevices.audio}
              onChange={(selected) => {
                dispatch(
                  editSettings({
                    selectedDevices: {
                      ...selectedDevices,
                      audio: selected as MediaDeviceInfo
                    }
                  }) as any
                )
              }}
              getLabel={(o) => o.label}
              getValue={(o) => o.deviceId}
              required={true}
              style={{ width: '100%' }}
            />
            <SliderContainer>
              <DynamicVolumeIcon volume={audioVolumeView} />
              <CustomSlider
                min={0}
                max={1}
                step={0.1}
                value={audioVolumeView}
                color="primary"
                onChange={(_: any, newVolume: number | number[]) => setAudioVolumeView(newVolume as number)}
                onChangeCommitted={(_: any, newVolume: number | number[]) => dispatch(editSettings({ audioVolume: newVolume as number }) as any)}
              />
            </SliderContainer>
          </Row>
          <Row>
            <Title
              label={translation.ringtone}
              icon={<RingVolumeRounded />}
              labelStyle={{ fontWeight: 600, fontSize: 'inherit' }}
            />
            <Select<Device>
              options={devices.filter((d) => d.kind === 'audiooutput' && d.label)}
              selected={selectedDevices.ringer}
              onChange={(selected) => {
                dispatch(
                  editSettings({
                    selectedDevices: {
                      ...selectedDevices,
                      ringer: selected as MediaDeviceInfo
                    }
                  }) as any
                )
              }}
              getLabel={(o) => o.label}
              getValue={(o) => o.deviceId}
              required={true}
              style={{ width: '100%' }}
            />
            <SliderContainer>
              <DynamicVolumeIcon volume={ringerVolumeView} />
              <CustomSlider
                min={0}
                max={1}
                step={0.1}
                value={ringerVolumeView}
                color="primary"
                onChange={(_: any, newVolume: number | number[]) => setRingerVolumeView(newVolume as number)}
                onChangeCommitted={(_: any, newVolume: number | number[]) => dispatch(editSettings({ ringerVolume: newVolume as number }) as any)}
              />
            </SliderContainer>
          </Row>
          <Row>
            <Title
              label={translation.camera}
              icon={<CameraRounded />}
              labelStyle={{ fontWeight: 600, fontSize: 'inherit' }}
            />
            <Select<Device>
              options={devices.filter((d) => d.kind === 'videoinput' && d.label)}
              selected={selectedDevices.webcam}
              onChange={(selected) => {
                dispatch(
                  editSettings({
                    selectedDevices: {
                      ...selectedDevices,
                      webcam: selected as MediaDeviceInfo
                    }
                  }) as any
                )
              }}
              getLabel={(o) => o.label}
              getValue={(o) => o.deviceId}
              required={true}
              style={{ width: '100%' }}
            />
          </Row>
        </Devices>
      </Page>
    </ScrollBar>
  )
}

export default withAuthentication(SettingsPage)

//region Style

const Page = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  place-items: start center;
  overflow: hidden;
  gap: ${rem(0.5)};
  padding: 10px;
  font-size: ${rem(0.8)};
  font-weight: bold;
  justify-self: center;
  max-width: 400px;
`

const Switches = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  place-items: center start;
  place-self: center;
  place-content: center;
`

const SwitchesRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 5px;
  grid-row-gap: ${rem(0.3)};
  place-items: center start;
  place-self: center;
  place-content: center;
`

const Devices = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(0.5)};
  place-self: start center;
  place-items: start center;
  width: 100%;
  height: 100%;
`
const Row = styled.div`
  display: flex;
  flex-direction: column;
  place-items: center;
  width: 100%;
`

const SliderContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  place-items: center;
  grid-column-gap: ${rem(0.5)};
  width: 90%;
`
const CustomSlider = styled(Slider)(() => ({
  width: '90%',
  padding: '20px 0',
  placeSelf: 'center start',
  maxWidth: smaller.width,
  '& .MuiSlider-thumb': {
    height: 15,
    width: 15
  }
}))
//endregion
