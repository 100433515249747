import withMorePages from '../../hoc/withMorePages'
import Messenger from '../../pages/Messenger/Messenger'
import KeyPadOrCall from '../../pages/Phone/KeyPadOrCall/KeyPadOrCall'
import { getMessengerVideoRoom } from '../../../store/selectors'
import React from 'react'
import { useSelector } from 'react-redux'

const MessengerWithKeypad: React.FC = () => {
  const videoroom = useSelector(getMessengerVideoRoom)
  if (videoroom) return <Messenger />
  else {
    const AO = withMorePages(Messenger, [KeyPadOrCall], { maxPages: 2, pageMinWidth: 500, stretchFirst: true })
    return <AO />
  }
}
export default MessengerWithKeypad
