import logger from '../helpers/logger'
import axios from 'axios'
import qs from 'query-string'
import { useCallback, useEffect, useState } from 'react'
import { coreApi, statsUrl } from '../configuration'
import { makeHeader } from './helpers'
import { AnyObject } from './types'

function useElastic<E = AnyObject, F = AnyObject>(entity: ElasticEntity, options: Options<F> = {}): HookObject<E, F> {
  const { lazy = true } = options
  const [total, setTotal] = useState(0)
  const [results, setResults] = useState<E[]>([] as E[])
  const [aggregations, setAggregations] = useState<AnyObject | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  /**
   * recupera informazioni storiche per calls chats e le altre entities che sono salvate su elastic
   */
  const get = useCallback(
    async ({ filters, afterPath }: Parameters<F> = {}): Promise<{
      payload: E[]
      total: number
    }> => {
      let completeUrl = `${coreApi}/history/${entity}`
      if (afterPath) completeUrl += afterPath
      if (filters) {
        const searchFilters: Partial<F> = {}
        Object.entries(filters).forEach(([name, filter]) => {
          if (Array.isArray(filter) && filter.length > 0) {
            searchFilters[name as keyof F] = filter as any
          }
          if (filter) searchFilters[name as keyof F] = filter as any
        })
        completeUrl += `?${qs.stringify(searchFilters)}`
      }

      logger.silly(`GET: ${completeUrl}`)
      setLoading(true)

      let token: string
      try {
        const { data } = await axios.get(completeUrl, makeHeader())
        if (!data.payload) throw new Error('missing token in core response')
        token = data.payload
      } catch (e: any) {
        setError(e.message)
        setLoading(false)
        return { payload: [], total: 0 }
      }

      return axios
        .get(`${statsUrl}/${entity}`, { headers: { Authorization: 'Bearer ' + token } })
        .then(({ data }) => {
          setTotal(data.hits.total.value)
          setResults(data.hits.hits.map((d: any) => ({ elasticId: d._id, ...d._source })))
          return data
        })
        .catch((error) => {
          setLoading(false)
          setError(error.message)
        })
        .finally(() => setLoading(false))
    },
    [entity]
  )

  /** recupero dei dati statistici con aggregazioni
   * NB: per le statistiche la prima pagina viene data "gratis" da elastic, se invece si vuole andare a pagine
   * successive si utilizza l'altro metodo, get, perché così non vengono ricomputate le statistiche e non viene
   * aggiornato il grafico per rimettere gli stessi dati*/
  const getStats = useCallback(
    async ({ filters, afterPath, aggs = {} }: Parameters<F> = {}): Promise<{
      payload: E[]
      total: number
    }> => {
      let completeUrl = `${coreApi}/history/${entity}/stats`
      if (afterPath) completeUrl += afterPath
      if (filters) {
        const searchFilters: Partial<F> = {}
        Object.entries(filters).forEach(([name, filter]) => {
          if (Array.isArray(filter) && filter.length > 0) {
            searchFilters[name as keyof F] = filter as any
          }
          if (filter) searchFilters[name as keyof F] = filter as any
        })
        completeUrl += `?${qs.stringify(searchFilters)}`
      }
      logger.silly(`POST: ${completeUrl}`)
      setLoading(true)

      let token: string
      try {
        const { data } = await axios.post(completeUrl, { ...aggs }, makeHeader())
        if (!data.payload) throw new Error('missing token in core response')
        token = data.payload
      } catch (e: any) {
        setError(e.message)
        setLoading(false)
        return { payload: [], total: 0 }
      }

      logger.silly(`GET: ${statsUrl}/${entity}/stats`)

      return axios
        .get(`${statsUrl}/${entity}/stats`, { headers: { Authorization: 'Bearer ' + token } })
        .then(({ data }) => {
          setTotal(data.hits.total.value)
          setResults(data.hits.hits.map((d: any) => d._source))
          setAggregations(data.aggregations)
          logger.silly('statsData', data)
          return data
        })
        .catch((error) => {
          setLoading(false)
          setError(error.message)
        })
        .finally(() => setLoading(false))
    },
    [entity]
  )

  useEffect(() => {
    if (lazy) return
    if (options.initialAggs) {
      getStats({ filters: options.initialFilters, aggs: options.initialAggs }).catch((err) => logger.error(err))
    } else {
      get({ filters: options.initialFilters }).catch((err) => logger.error(err))
    }
  }, [options.initialFilters, options.initialAggs, get, lazy, getStats])

  return {
    total,
    results,
    aggregations,
    setResults,
    loading,
    error,
    get,
    getStats
  }
}

export default useElastic

//region Types

type ElasticEntity = 'calls' | 'chats' | 'users' | 'sms' | 'actionlogs' | 'calls-registry'

interface Options<F> {
  lazy?: boolean
  initialFilters?: F
  initialAggs?: AnyObject
}

interface HookObject<E = AnyObject, F = AnyObject> {
  results: E[]
  aggregations: AnyObject | null
  total: number
  loading: boolean
  error: string
  get: (args?: Parameters<F>) => Promise<{ payload: E[]; total: number }>
  getStats: (args?: Parameters<F>) => Promise<{ payload: E[]; total: number }>
  setResults: (a: E[]) => void
}

interface Parameters<F> {
  filters?: F
  id?: number | string
  afterPath?: string
  aggs?: AnyObject
  withoutNotification?: boolean
  filename?: string
}

//endregion
